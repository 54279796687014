import React from "react";

import PlanUpgradeBanner from "../components/PlanUpgradeBanner";
import Page from "../components/Page";
import { Grid } from "@mui/material";
import AccountEditor from "../modules/account/AccountEditorForm";

const AccountSettings = () => {
  return (
    <Page title="Settings">
      <PlanUpgradeBanner />
      <Grid item xs={12}>
        <Page.Title>Settings</Page.Title>
        <AccountEditor />
      </Grid>
    </Page>
  );
};

export default AccountSettings;
