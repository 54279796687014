/* Return the IG URL params as an object

On a canceled login, we still go to the redirect URI but with error params:
https://developers.facebook.com/docs/facebook-login/guides/advanced/manual-flow#nonjscancel
*/

const getInstagramURLParams = () => {
  const searchParams = new URLSearchParams(window.location.search);

  let code = searchParams.get("code");
  const error = searchParams.get("error");
  const error_reason = searchParams.get("error_reason");
  const error_description = searchParams.get("error_description");

  // Remove the trailing #_ from the code, if it's there
  code = code?.replace("#_", "");

  return {
    code,
    error,
    error_reason,
    error_description,
  };
};

// Function to generate instagram redirect URI for this env
const getInstagramRedirectURI = () => {
  const redirectDomain = `${window.location.protocol}//${window.location.host}`;
  const redirectURI = `${redirectDomain}/instagram/auth`;

  return redirectURI;
};

// @docs https://developers.facebook.com/docs/instagram/business-login-for-instagram
/*
https://www.facebook.com/dialog/oauth
  ?client_id={client-id}
  &display={display}
  &extras={extras}
  &redirect_uri={redirect-uri}
  &response_type={response_type}
  &scope={scope}

  Ex: https://www.facebook.com/v16.0/dialog/oauth?client_id=442224939723604&display=page&extras={"setup":{"channel":"IG_API_ONBOARDING"}}&redirect_uri=https://my-clever-redirect-url.com/success/&response_type=token&scope=instagram_basic,instagram_content_publish,instagram_manage_comments,instagram_manage_insights,pages_show_list,pages_read_engagement


  Scopes: https://developers.facebook.com/docs/permissions/reference
*/
const getInstagramOAuthRedirectURI = () => {
  const fbAppID = "534789785469920";

  const redirectParams = new URLSearchParams();
  redirectParams.append("client_id", fbAppID);
  redirectParams.append("display", "page");
  redirectParams.append("extras", { setup: { channel: "IG_API_ONBOARDING" } });
  redirectParams.append("redirect_uri", getInstagramRedirectURI());
  redirectParams.append("response_type", "code");
  redirectParams.append(
    "scope",
    "instagram_basic,instagram_content_publish,instagram_manage_comments,instagram_manage_insights,pages_show_list,pages_read_engagement"
  );

  const url =
    "https://www.facebook.com/v16.0/dialog/oauth?" + redirectParams.toString();
  return url;
};

export {
  getInstagramURLParams,
  getInstagramRedirectURI,
  getInstagramOAuthRedirectURI,
};
