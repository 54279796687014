import React from "react";

import { useTheme } from "@mui/material/styles";
import "./storyBubbles.css";

export default function StoryBubbles({ stories }) {
  const theme = useTheme();

  // If no stories are set, give a skeleton
  const noStories = stories === null || stories.length === 0;
  if (noStories) {
    stories = [
      {
        id: "1",
        thumbnail_text: "No Stories Yet",
        thumbnail_url: "/img/empty_story.svg",
      },
    ];
  }

  return (
    <div className="kahani-thumbnails-flex-container">
      {stories.map((story) => (
        <div key={`story-${story.id}`} className={`kahani-thumbnail-container`}>
          <div
            className={`kahani-thumbnail`}
            role="button"
            style={{ borderColor: "#212121" }}
          >
            <img src={story.thumbnail_url} alt={story.thumbnail_text} />

            <br />
          </div>
          <span
            className="kahani-thumbnail-label"
            style={{
              fontSize: "14px",
              marginTop: "8px",
              color: noStories
                ? theme.palette.text.secondary
                : theme.palette.text.primary,
            }}
          >
            {story.thumbnail_text}
          </span>
        </div>
      ))}
    </div>
  );
}
