import React from "react";
import { useLocation } from "react-router";

import Page from "../components/Page";

import ShopifyLoginForm from "../modules/login/ShopifyLoginForm";
import KahaniLoginForm from "../modules/login/KahaniLoginForm";
import { shopifyOAuthRedirect } from "../utilities/shopifyKey";
import { getUser } from "../utilities/parseCookie";
import { useAuth } from "../context/auth";

// Styling
import { Alert, AlertTitle, Grid } from "@mui/material";

export default function Login() {
  const auth = useAuth();
  let location = useLocation();

  // Get params from URL
  const searchParams = new URLSearchParams(location.search);
  const wasExpired = searchParams.get("exp") !== null;

  const from =
    location.state && location.state.from ? location.state.from.pathname : "/";

  const alertTitle = wasExpired ? "Session Expired" : "Please sign in";
  const alertContent = wasExpired
    ? "Your session has expired. Please log in again."
    : "You must log in to view the page at " + from;

  // If token was expired & was a shopify token, then redirect to shopify login instead
  if (auth.isShopifyAccount && wasExpired) {
    // Get shop from local storage
    const account = getUser();

    if (account && account.name && account.name.endsWith(".myshopify.com")) {
      // Clear local storage and redirect to shopify login
      localStorage.clear();
      shopifyOAuthRedirect(account.name);
    }
  }

  const showKahani = !wasExpired || (wasExpired && !auth.isShopifyAccount);

  return (
    <Page title="Log In">
      <Grid container sx={{ justifyContent: "center" }}>
        <Grid item sm={4} sx={{ mt: 5 }}>
          <Alert severity="warning">
            <AlertTitle>{alertTitle}</AlertTitle>
            {alertContent}
          </Alert>
          <ShopifyLoginForm />
          {showKahani && <KahaniLoginForm from={from} />}
        </Grid>
      </Grid>
    </Page>
  );
}
