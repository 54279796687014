import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";

import Page from "../components/Page";
import CollectionEditor from "../modules/collectionEditor/CollectionEditor";
import PageTargetingCollectionNotice from "../modules/pageTargeting/CollectionNotice";

import { useCollection } from "../api/fetchCollectionData";
import { hasOverrideAccountID } from "../context/auth";

import { Alert, AlertTitle, Button, Grid, Paper, Stack } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import PreviewIcon from "@mui/icons-material/Preview";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export default function EditCollection() {
  const { enqueueSnackbar } = useSnackbar();
  const { collectionID } = useParams();
  const { error, collection, isLoading } = useCollection(collectionID);
  const navigate = useNavigate();

  const [editCollectionMetadataModalOpen, setEditCollectionMetadataModalOpen] =
    useState(false);
  const [livePreviewDialog, setLivePreviewDialog] = useState(false);
  const [livePreviewDialogAnchorEl, setLivePreviewDialogAnchorEl] =
    useState(null);

  // handle collection not found
  const validCollection = collection && !error && !isLoading && collection.id;

  const buttonStyle = {
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
  };

  const typeName =
    collection.internal_description === "||LP||"
      ? "Landing Page"
      : "Story Block";

  const landingPageUrl = `https://cdn.kahaniapp.com/landingpage.html?collectionID=${collection.id}&accountID=${collection.account_id}`;

  return (
    <Page title={"Edit " + collection.internal_name} isLoading={isLoading}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Page.Title>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <div>
                {typeName}: {collection.internal_name}
              </div>
              <div style={{ display: "flex", gap: "10px" }}>
                <Button
                  startIcon={<SettingsIcon />}
                  onClick={() => {
                    setEditCollectionMetadataModalOpen(true);
                  }}
                  variant="outlined"
                  sx={buttonStyle}
                >
                  Settings
                </Button>

                <Button
                  variant="outlined"
                  startIcon={<PreviewIcon />}
                  onClick={(e) => {
                    if (collection.internal_description !== "||LP||") {
                      // Track event
                      window.analytics.track("collection-live-preview", {
                        collection_id: collection.id,
                        collection_name: collection.name,
                        admin_action: hasOverrideAccountID(),
                      });

                      setLivePreviewDialogAnchorEl(e.currentTarget);
                      setLivePreviewDialog(true);
                    } else {
                      window.open(landingPageUrl);
                    }
                  }}
                  sx={buttonStyle}
                >
                  Preview
                </Button>
                {collection.internal_description === "||LP||" ? (
                  <Button
                    variant="outlined"
                    disabled={!collection.stories.length}
                    onClick={() => {
                      enqueueSnackbar("URL Copied!", {
                        variant: "success",
                      });
                      navigator.clipboard.writeText(landingPageUrl);
                    }}
                    sx={buttonStyle}
                    startIcon={<ContentCopyIcon />}
                  >
                    Copy Landing Page URL
                  </Button>
                ) : (
                  <PageTargetingCollectionNotice collectionID={collection.id} />
                )}
              </div>
            </Stack>
          </Page.Title>
        </Grid>
      </Grid>

      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {validCollection ? (
          <CollectionEditor
            collection={collection}
            setEditCollectionMetadataModalOpen={
              setEditCollectionMetadataModalOpen
            }
            editCollectionMetadataModalOpen={editCollectionMetadataModalOpen}
            livePreviewDialogAnchorEl={livePreviewDialogAnchorEl}
            setLivePreviewDialogAnchorEl={setLivePreviewDialogAnchorEl}
            setLivePreviewDialog={setLivePreviewDialog}
            livePreviewDialog={livePreviewDialog}
          />
        ) : (
          <Alert severity="error">
            <AlertTitle>Story Block not found</AlertTitle>
            The Story Block you're trying to edit doesn't exist.
            <br />
            <br />
            <Button
              variant="contained"
              color="warning"
              onClick={() => navigate("/story_blocks")}
            >
              Go back to the Story Blocks list
            </Button>
          </Alert>
        )}
        {error && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {error}
          </Alert>
        )}
      </Paper>
    </Page>
  );
}
