import React from "react";
import { createRoot } from 'react-dom/client';
import preval from "preval.macro";
import env from "react-dotenv";
import { datadogLogs } from "@datadog/browser-logs";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import packageJson from "../package.json";

import {App} from "./App";
import reportWebVitals from "./reportWebVitals";
import { store, persistor } from "./redux/store";

import "./index.css";

const queryClient = new QueryClient();

const dateTimeStamp = preval`module.exports = new Date().toISOString();`;

// Filter the timestamp value for use as datadog version #
const versionRegex = /[^a-z0-9_:./-]/g;
const sanitizedVersion = "v" + dateTimeStamp.replace(versionRegex, "");

datadogLogs.init({
  clientToken: "pubd827ab217f5caf300bc111e6cb49e421",
  site: "datadoghq.com",
  forwardErrorsToLogs: true,
  sampleRate: 100,
  service: "kahani-portal",
  env: process.env.NODE_ENV,
  version: sanitizedVersion,
  forwardConsoleLogs: ["log", "error", "warn"],
});

// Logging out information for troubleshooting
console.info(
  `%cKahani Portal Version: ${packageJson.version}\nDeployed: ${dateTimeStamp}`,
  "font-size: 16px;"
);
console.info("Build ID: " + env?.COMMIT_REF);
console.info("Review ID: ", env?.REVIEW_ID);
console.info("Pull request: ", env?.PULL_REQUEST);
console.info("Branch: ", env?.BRANCH);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
