import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import RightCloseIcon from "../../modules/assets/RightCloseIcon";
import { duplicateStoryBlock } from "../../redux/api/storyBlockAPI";
import { invalidateCollectionCache } from "../../api/fetchCollectionData";

const style = {
  topMargin: {
    marginTop: 1,
  },
  box: {
    bgcolor: "background.paper",
    boxShadow: 24,
    display: "flex",
    flexDirection: "column",
    height: 275,
    justifyContent: "space-between",
    left: "50%",
    p: 3,
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
  },
};

export default function DuplicateStoryBlockModal({ storyBlock, open, onClose }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [duplicating, setDuplicating] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const onDuplicateClick = async () => {
    if (duplicating) return;

    setDuplicating(true);
    
    try {
      const resData = await duplicateStoryBlock({
        id: storyBlock.id,
        newName: `${storyBlock.internal_name} Copy`,
      });
      invalidateCollectionCache();
      enqueueSnackbar("Story Block duplicated successfully!", {
        variant: "success",
      });
      handleClose();
      navigate(`/story_blocks/${resData.id}`);
    } catch(error) {
      enqueueSnackbar("Story Block not able to be Duplicated", {
        variant: "error",
      });
    }

    setDuplicating(false);
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style.box}>
        <Typography variant="h5">
          Duplicate Story Block
          <RightCloseIcon onClick={handleClose} />
        </Typography>
        <Alert severity="warning">
          <AlertTitle>
            <strong>
              You will be able to edit the new Story Block without affecting
              this one.
            </strong>
          </AlertTitle>
        </Alert>
        <Stack direction="row" justifyContent="end" sx={style.topMargin}>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>

          <LoadingButton
            loading={duplicating}
            loadingPosition="start"
            variant="contained"
            startIcon={<ContentCopyIcon />}
            onClick={onDuplicateClick}
            style={{ marginLeft: "10px" }}
          >
            Duplicate
          </LoadingButton>
        </Stack>
      </Box>
    </Modal>
  );
}
