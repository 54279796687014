import React, { useState } from "react";

import { usePlanRedeem } from "../api/redeemPlanCode";

import {
  Alert,
  AlertTitle,
  Button,
  Chip,
  CircularProgress,
  LinearProgress,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

export default function PlanRedemptionForm() {
  return (
    <Paper
      sx={{
        p: 3,
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        zIndex: "1",
        transform: "translate(-50%, -50%)",
        left: "50%",
        top: "50%",
        width: "50%",
      }}
      elevation={24}
    >
      <PlanRedemptionFormInner />
    </Paper>
  );
}

export const PlanRedemptionFormInner = () => {
  const [redemptionCode, setRedemptionCode] = useState("");
  const {
    mutate: redeem,
    isError,
    error,
    isLoading,
    isSuccess,
  } = usePlanRedeem();

  const handleCodeRedeem = () => {
    redeem(redemptionCode, {
      onSuccess: (res) => {
        // If already active, just redirect to the success page
        if (res?.active) {
          // Redirect to collections page w/ the success message
          window.location.assign("/story_blocks?payment_status=success");
          return;
        }

        window.location.assign(res?.confirmation_url);
      },
    });
  };

  return (
    <>
      <Typography variant="h6" gutterBottom>
        <Chip label="BETA" color="primary" variant="outlined" /> Open Beta Plan
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: "1rem" }}>
        <span>
          You can try out Kahani for free, but to publish Kahani Story Blocks to
          your store, you must upgrade your subscription. Input your plan
          redemption code in order to approve payment.
        </span>
        <br />
        <br />
        <span style={{ color: "grey", fontSize: "14px" }}>
          A member of the Kahani team will provide your paid plan code based on
          your monthly sessions. Email{" "}
          <a href="mailto:support@kahaniapp.com">support@kahaniapp.com</a> if
          needed.
        </span>
      </Typography>
      <div style={{ display: "flex" }}>
        <TextField
          id="outlined-basic"
          label="Plan Code"
          variant="outlined"
          style={{ flexGrow: "1", marginRight: "10px" }}
          onChange={(e) => setRedemptionCode(e.target.value)}
          value={redemptionCode}
          disabled={isLoading || isSuccess}
          onKeyDown={(e) => {
            if (e.keyCode === 13 && redemptionCode) handleCodeRedeem();
          }}
        />
        <Button
          variant="contained"
          onClick={handleCodeRedeem}
          disabled={!redemptionCode || isLoading || isSuccess}
        >
          Redeem
        </Button>
      </div>
      {isLoading && <LinearProgress sx={{ marginTop: "10px" }} />}
      {isSuccess && (
        <Alert
          severity="success"
          sx={{ marginTop: "10px" }}
          icon={<CircularProgress size={20} />}
        >
          Redirecting...
        </Alert>
      )}
      {isError && (
        <Alert severity="error" sx={{ marginTop: "10px" }}>
          <AlertTitle>Error</AlertTitle>
          {`${error}` ?? "Unknown error. Please contact support."}
        </Alert>
      )}
    </>
  );
};
