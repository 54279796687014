import React from "react";

import CTAList from "./CTAList";

import { useCTAs } from "../../api/fetchCTAData";

import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from "@mui/material";

export default function CTASelectorDialog({
  buttonLabel,
  open,
  onSelect,
  onClose,
}) {
  const { error: ctasError, isLoading: ctasAreLoading, ctas } = useCTAs();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth="lg"
    >
      <DialogTitle id="form-dialog-title">Select CTA</DialogTitle>
      <DialogContent>
        {ctasError && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {ctasError}
          </Alert>
        )}

        {!ctasError && (
          <CTAList buttonLabel={buttonLabel} onSelect={onSelect} ctas={ctas} />
        )}
        {ctasAreLoading && (
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={80} />
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
