import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Modal,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";

import { apiCall } from "../../utilities/request";
import { ctaURLs } from "../../constants/apiURLs";
import {
  createChapterRequest,
  reorderChaptersRequest,
  updateChapterRequest,
} from "../slices/storyBlockSlice";
import {
  toggleEditChapterModal,
  selectEditChapterModalOpen,
  toggleMediaModal,
  selectChapterEdit,
  selectSelectedChapter,
  selectSelectedMedia,
  setSelectedMedia,
} from "../slices/viewSlice";
import RightCloseIcon from "../../modules/assets/RightCloseIcon";

const style = {
  topMargin: {
    marginTop: '16px',
  },
  box: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1200,
    height: 700,
    bgcolor: "background.paper",
    boxShadow: 24,
    display: "flex",
    justifyContent: "space-between",
  },
  startButton: {
    height: 80,
    display: "flex",
    flexDirection: "column",
  },
  workArea: { p: 3, width: "100%" },
  previewImage: {
    width: 550,
    backgroundColor: "lightGrey",
  },
  footer: {
    textAlign: "center",
    position: 'absolute',
    bottom: '100px',
    width: 'calc(100% - 550px)',
  },
};

export default function EditChapterModal({ collection, selectedStory }) {
  const dispatch = useDispatch();
  const editChapterModalOpen = useSelector(selectEditChapterModalOpen);
  const editMode = useSelector(selectChapterEdit);
  const selectedChapter = useSelector(selectSelectedChapter);
  const selectedMedia = useSelector(selectSelectedMedia);
  const [isCTAEnabled, setIsCTAEnabled] = useState(false);
  const [chapterName, setChapterName] = useState(
    editMode ? selectedChapter.internal_name : ""
  );
  const [ctaButtonText, setCtaButtonText] = useState("");
  const [showCtaButtonTextError, setShowCtaButtonTextError] = useState(false);
  const [ctaLinkUrl, setCtaLinkUrl] = useState("");
  const [showCtaLinkUrlError, setShowCtaLinkUrlError] = useState(false);
  const [assetUrl, setAssetUrl] = useState("");

  useEffect(() => {
    if (editMode) {
      setChapterName(selectedChapter.internal_name);
      setIsCTAEnabled(!!selectedChapter?.ctas?.length);
      setCtaButtonText(
        !!selectedChapter?.ctas?.length ? selectedChapter.ctas[0].link_text : ""
      );
      setCtaLinkUrl(
        !!selectedChapter?.ctas?.length ? selectedChapter.ctas[0].link_url : ""
      );

      let url =
        selectedChapter?.asset?.thumbnail_url ??
        selectedChapter?.asset?.url ??
        selectedChapter?.asset?.image_original_url;
      if (!url || url === "") {
        url = selectedChapter?.asset?.ImageOriginalURL;
      }
      if (!url || url === "" || url.includes("ucarecdn")) {
        url = selectedChapter?.asset?.ThumbnailURL;
      }
      setAssetUrl(url);
    }
    // selected media not updating after story creation
    if (selectedMedia?.thumbnail_url ?? selectedMedia?.image_original_url) {
      setAssetUrl(
        selectedMedia?.thumbnail_url ?? selectedMedia?.image_original_url
      );
    }
  }, [
    editMode,
    selectedChapter.internal_name,
    selectedChapter?.ctas,
    selectedChapter?.ctas?.length,
    selectedChapter?.asset?.thumbnail_url,
    selectedChapter?.asset?.image_original_url,
    selectedChapter?.asset?.ImageOriginalURL,
    selectedChapter?.asset?.ThumbnailURL,
    selectedChapter?.asset?.url,
    selectedMedia?.thumbnail_url,
    selectedMedia?.image_original_url,
  ]);

  const handleClose = () => {
    setChapterName("");
    setIsCTAEnabled(false);
    setCtaButtonText("");
    setAssetUrl("");
    dispatch(setSelectedMedia({}));
    dispatch(toggleEditChapterModal({ mode: false, chapter: {} }));
  };

  const onSaveClick = () => {
    if (isCTAEnabled && (!ctaButtonText || !ctaLinkUrl)) {
      setShowCtaButtonTextError(!ctaButtonText);
      setShowCtaLinkUrlError(!ctaLinkUrl);
      return;
    }

    const ctaRequestPayload = {
      internal_name: ctaButtonText,
      internal_description: "",
      type: "link",
      link_text: ctaButtonText,
      link_url: ctaLinkUrl,
    };

    if (editMode) {
      if (isCTAEnabled) {
        // Create new CTA if CTAs array is empty
        if (selectedChapter?.ctas?.length === 0) {
          apiCall
            .post(ctaURLs.list(), ctaRequestPayload)
            .then((res) => {
              const payload = {
                id: selectedChapter?.id,
                asset_id:
                  selectedMedia?.id ?? selectedChapter?.asset?.id,
                cta_id: res.data?.id,
                dwell: selectedChapter.dwell,
                internal_name: chapterName,
                internal_description:
                  selectedChapter.internal_description,
              };

              dispatch(updateChapterRequest(payload)).then(() => {
                handleClose();
              });
            });
        } else {
          apiCall
            .put(
              ctaURLs.cta(selectedChapter?.ctas?.[0]?.id),
              ctaRequestPayload
            )
            .then(() => handleClose());
        }
      } else {
        // delete if ctas array not empty
        const payload = {
          id: selectedChapter?.id,
          asset_id:
            selectedMedia?.id ?? selectedChapter?.asset?.id,
          cta_id: undefined,
          dwell: selectedChapter.dwell,
          internal_name: chapterName,
          internal_description:
            selectedChapter.internal_description,
        };

        dispatch(updateChapterRequest(payload)).then(() => {
          handleClose();
        });
      }
    } else {
      if (isCTAEnabled) {
        apiCall.post(ctaURLs.list(), ctaRequestPayload).then((res) => {
          const payload = {
            id: undefined,
            asset_id: selectedMedia?.id,
            cta_id: res.data?.id,
            dwell: 7,
            internal_name: chapterName,
            internal_description: "",
          };

          dispatch(createChapterRequest(payload)).then((res) => {
            // Order into Story
            const currentChapterIDs = selectedStory.chapters
              ? selectedStory.chapters.map(
                  (chapter) => chapter.id
                )
              : [];
            let newChapterIds = [
              ...currentChapterIDs,
              res.payload.id,
            ];

            dispatch(
              reorderChaptersRequest({
                id: selectedStory.id,
                newChapterIds,
              })
            ).then(() => handleClose());
          });
        });
      }
    }
  }

  return (
    <Modal
      open={editChapterModalOpen}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style.box}>
        <Box sx={style.workArea}>
          <Typography id="modal-title" variant="h6">
            {editMode ? "Edit Chapter" : "Add Chapter"}
            <RightCloseIcon onClick={handleClose} />
          </Typography>
          <Grid container style={style.topMargin}>
            <Grid item xs={12} style={style.topMargin}>
              <Typography variant="body1">Media</Typography>
              <Button
                variant="outlined"
                onClick={() => dispatch(toggleMediaModal({ type: "all" }))}
                style={style.topMargin}
              >
                {assetUrl ? 'Edit Chapter Media' : 'Select Chapter Media'}
              </Button>
            </Grid>
            <Grid item xs={7} style={style.topMargin}>
              <TextField
                label="Chapter Name"
                value={chapterName}
                onChange={(e) => setChapterName(e.target.value)}
                helperText="How this chapter will be identified in Google Analytics"
                fullWidth
                style={style.topMargin}
              />
            </Grid>

            {/* CTA Toggle */}
            <Grid item xs={12} style={style.topMargin}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isCTAEnabled}
                    onChange={(e) => setIsCTAEnabled(e.target.checked)}
                  />
                }
                label="Enable CTA Button"
                labelPlacement="start"
                sx={{ m: 0 }}
              />
            </Grid>
            {isCTAEnabled &&
              <>
                <Grid item xs={7}>
                  <TextField
                    label={`CTA Button Text${showCtaButtonTextError ? ' (Required)' : ''}`}
                    value={ctaButtonText}
                    onChange={(e) => setCtaButtonText(e.target.value)}
                    style={style.topMargin}
                    error={showCtaButtonTextError}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    label={`CTA Link URL${showCtaLinkUrlError ? ' (Required)' : ''}`}
                    value={ctaLinkUrl}
                    onChange={(e) => setCtaLinkUrl(e.target.value)}
                    style={style.topMargin}
                    error={showCtaLinkUrlError}
                    fullWidth
                  />
                </Grid>
              </>
            }
          </Grid>

          <Grid container style={style.footer}>
            <Grid item xs={12}>
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{ ml: 1 }}
                startIcon={<SaveIcon />}
                disabled={!assetUrl}
                onClick={onSaveClick}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box
          style={{
            ...style.previewImage,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              height: "100%",
              width: "100%",
              background: `url(${assetUrl}) 50%/cover no-repeat`,
            }}
          />
          {ctaButtonText && isCTAEnabled && (
            <div
              style={{
                position: "absolute",
                bottom: "40px",
              }}
            >
              <div
                style={{
                  color: "white",
                  backgroundColor: collection?.style?.primary_color ?? "black",
                  padding: "5px 40px",
                  borderRadius: "5px",
                  fontSize: "24px",
                }}
              >
                {ctaButtonText}
              </div>
            </div>
          )}
        </Box>
      </Box>
    </Modal>
  );
}
