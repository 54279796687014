import HomeIcon from "@mui/icons-material/Home";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";

const searchableCollectionFields = ["title", "id", "handle"];
const searchableProductFields = ["title", "id", "handle"];

export const getIdFromShopifyId = (shopifyId) => {
  const items = shopifyId.split("/");
  return items[items.length - 1];
};

export const createSearchableLabel = (item, fields) => {
  const searchableLabel = [];
  fields.forEach((field) => searchableLabel.push(item[field]));

  return searchableLabel.join(" * ");
};

export const sortData = (data, sortType) => {
  switch (sortType) {
    case "a-z":
      return data.sort((a, b) =>
        a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
      );
    case "z-a":
      return data.sort((a, b) =>
        a.title.toLowerCase() > b.title.toLowerCase() ? -1 : 1
      );
    case "updated-latest":
      return data.sort((a, b) => (a.updatedAt > b.updatedAt ? -1 : 1));
    case "updated-earliest":
      return data.sort((a, b) => (a.updatedAt < b.updatedAt ? -1 : 1));
    case "published-latest":
      return data.sort((a, b) => (a.publishedAt > b.publishedAt ? -1 : 1));
    case "published-earliest":
      return data.sort((a, b) => (a.publishedAt < b.publishedAt ? -1 : 1));
    case "created-latest":
      return data.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
    case "created-earliest":
      return data.sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1));
    default:
      return data;
  }
};

export const formatShopifyCollectionData = (data, filters) => {
  if (!data || !Array.isArray(data)) return [];

  const formatted = data.map((collection) => ({
    ...collection,
    id: getIdFromShopifyId(collection.id),
    label: createSearchableLabel(collection, searchableCollectionFields), // contains terms that are searchable
    infoTitle: `/collections/${collection.handle}`,
    secondaryTitle: getIdFromShopifyId(collection.id),
  }));

  return filters?.sort ? sortData(formatted, filters?.sort) : formatted;
};

export const formatShopifyProductsData = (data, filters) => {
  if (!data || !Array.isArray(data)) return [];

  const filtered = data?.filter((p) =>
    filters?.activeOnly && p.status !== "ACTIVE" ? false : true
  );

  const formatted = filtered.map((product) => ({
    ...product,
    id: getIdFromShopifyId(product.id),
    label: createSearchableLabel(product, searchableProductFields), // contains terms that are searchable
    infoTitle: `/products/${product.handle}`,
    secondaryTitle: getIdFromShopifyId(product.id),
    url: product?.featuredImage?.url,
  }));

  return filters?.sort ? sortData(formatted, filters?.sort) : formatted;
};

export const formatStoryBlockInternalName = (selection, title = "") => {
  switch (selection) {
    case "homepage":
      return "Home Page";
    case "collection_page":
      return title;
    case "product_page":
      return title;
    default:
      return "Unknown";
  }
};

export const formatStoryBlockInternalDesc = (
  selection,
  id = "",
  handle = ""
) => {
  switch (selection) {
    case "homepage":
      return "Home Page";
    case "collection_page":
      return `Collection Page | ID: ${id} | Handle: ${handle}`;
    case "product_page":
      return `Product Page | ID: ${id} | Handle: ${handle}`;
    default:
      return "Home Page";
  }
};

export const formatShopifyHandle = (collection) => {
  let page = "";
  let icon = "";
  let id = "";
  let type = "";
  if (collection?.internal_description?.includes("Home Page")) {
    page = "Home Page";
    icon = <HomeIcon />;
    id = "";
    type = "homepage";
  } else if (collection?.internal_description?.includes("Collection Page")) {
    const parts = collection?.internal_description?.split(" ");
    const handle = parts[parts.length - 1];
    page = `collections/${handle}`;
    icon = <LibraryBooksIcon />;
    id = parts[4];
    type = "collection_page";
  } else if (collection?.internal_description?.includes("Product Page")) {
    const parts = collection?.internal_description?.split(" ");
    const handle = parts[parts.length - 1];
    page = `product/${handle}`;
    icon = <LocalOfferIcon />;
    id = parts[4];
    type = "product_page";
  }

  return { page, icon, id, type };
};
