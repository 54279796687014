import React from "react";
import { v4 as uuidv4 } from "uuid";

import {
  Button,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

const RuleList = ({ rules: _rules, onChange }) => {
  const ruleHasChanged = (rule, i) => {
    // Update this specific rule
    setRules((oldRules) => {
      let newRules = Array.from(oldRules);
      newRules[i] = rule;

      onChange(newRules);
      return newRules;
    });
  };

  const addRule = () => {
    // If the last rule in the list is a shopify rule, then use shopify as the type
    let category = "url";
    let type = "contains";
    if (rules.length > 0) {
      const lastRule = rules[rules.length - 1];
      if (lastRule.category === "shopify") {
        category = "shopify";
        type = "collection_page";
      }
    }

    const newRule = {
      id: uuidv4(),
      category: category,
      type: type,
      value: "",
    };

    setRules((oldRules) => {
      onChange([...oldRules, newRule]);
      return [...oldRules, newRule];
    });
  };

  const removeRule = (ruleID) => {
    // Remove this rule from the list
    setRules((oldRules) => {
      let newRules = [...oldRules];
      newRules = newRules.filter((r) => r.id !== ruleID);
      onChange(newRules);

      return newRules;
    });
  };

  const [rules, setRules] = React.useState(_rules);

  return (
    <>
      {rules &&
        rules.map((rule, i) => (
          <Rule
            key={rule.id}
            rule={rule}
            onChange={(rule) => {
              ruleHasChanged(rule, i);
            }}
            onDelete={(ruleID) => removeRule(ruleID)}
          />
        ))}
      <Divider
        sx={{
          marginTop: "1rem",
          marginBottom: "1rem",
        }}
      />
      <RuleAddButton onClick={addRule} />
    </>
  );
};

const Rule = React.memo(
  ({ rule, onChange, onDelete }) => {
    return (
      <WrappedRule
        ruleID={rule.id}
        ruleValue={rule.value}
        ruleType={rule.type}
        ruleCategory={rule.category}
        onChange={onChange}
        onDelete={onDelete}
      />
    );
  },
  (prev, next) => {
    // Only update when value or type change
    return (
      prev.ruleValue === next.ruleValue &&
      prev.ruleType === next.ruleType &&
      prev.ruleCategory === next.ruleCategory
    );
  }
);

const WrappedRule = ({
  ruleID,
  ruleType,
  ruleValue,
  ruleCategory,
  onChange,
  onDelete,
}) => {
  const [type, _setType] = React.useState(ruleType);
  const [value, _setValue] = React.useState(ruleValue);
  const [category, _setCategory] = React.useState(ruleCategory);

  const set = (field, _value) => {
    let newRule = {
      id: ruleID,
      category: category ? category : "url",
      type: type,
      value: value,
    };
    newRule[field] = _value;

    switch (field) {
      case "type":
        _setType(_value);
        break;
      case "value":
        _setValue(_value);
        break;
      case "category":
        _setCategory(_value);
        break;
      default:
        console.error("Unknown field: " + field);
    }

    onChange(newRule);
  };

  const valueError = () => {
    if (!value) {
      return "Empty rules will have unexpected behavior";
    }
    return null;
  };

  const textFieldPlaceholder = () => {
    if (category === "url") {
      return "https://example.com/landingPageFB?utm_source=facebook&utm_medium=social&utm_campaign=landingPage";
    } else if (category === "shopify") {
      return "1654398043";
    }
  };

  // We don't need any additional input on the homepage shopify rule
  const disableTextField = () => {
    if (category === "shopify" && type === "homepage") {
      return true;
    }
    return false;
  };

  return (
    <Grid
      container
      spacing={5}
      sx={{
        marginBottom: "0.5rem",
      }}
    >
      <Grid item xs={3}>
        {category === "url" && (
          <Select
            value={type}
            onChange={(e) => set("type", e.target.value)}
            fullWidth
            size="small"
          >
            <MenuItem value="contains">Contains</MenuItem>
            {/* <MenuItem value="not_contains">Does not contain</MenuItem> */}
            <MenuItem value="equals">Equals</MenuItem>
            <MenuItem value="ends_with">Ends With</MenuItem>
            <MenuItem value="starts_with">Starts With</MenuItem>
          </Select>
        )}
        {category === "shopify" && (
          <Select
            value={type}
            onChange={(e) => set("type", e.target.value)}
            fullWidth
            size="small"
          >
            <MenuItem value="homepage">Homepage</MenuItem>
            <MenuItem value="product_page">Product Page</MenuItem>
            <MenuItem value="collection_page">Collection Page</MenuItem>
          </Select>
        )}
      </Grid>
      <Grid item xs={8}>
        {disableTextField() ? (
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ marginTop: 1 }}
          >
            No additional input required for this rule type
          </Typography>
        ) : (
          <TextField
            value={value}
            onChange={(e) => set("value", e.target.value)}
            fullWidth
            size="small"
            placeholder={textFieldPlaceholder()}
            error={valueError()}
            helperText={valueError()}
          />
        )}
      </Grid>
      <Grid
        item
        xs={1}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <IconButton
          aria-label="Delete rule"
          color="error"
          onClick={() => {
            // Delete the rule
            onDelete(ruleID);
          }}
        >
          <RemoveCircleOutlineIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

const RuleAddButton = ({ onClick }) => {
  return (
    <Button
      variant="outlined"
      color="primary"
      startIcon={<AddIcon />}
      sx={{
        marginBottom: "1rem",
      }}
      onClick={onClick}
      size="small"
    >
      Add Rule
    </Button>
  );
};

export default RuleList;
