import React from "react";
import { Alert, AlertTitle, Stack, Button, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import { useSnackbar } from "notistack";

import { useCollections } from "../../api/fetchCollectionData";
import PlanUpgradeBanner from "../../components/PlanUpgradeBanner";
import Page from "../../components/Page";
import PlanValidationStatus from "../../components/PlanValidationStatus";
import { StoryBlockList } from "./StoryBlockList";
import StoryBlockLocationModal from "./StoryBlockLocationModal";
import { apiCall } from "../../utilities/request";
import { collectionURLs } from "../../constants/apiURLs";
import { useAuth } from "../../context/auth";

export const StoryBlocks = () => {
  const { error, collections: storyBlocks, isLoading } = useCollections();
  const [showCreateStoryBlockModal, setShowCreateStoryBlockModal] =
    React.useState(false);

  const auth = useAuth();

  const { enqueueSnackbar } = useSnackbar();

  // Make a request to start the storyMaker generation process
  const storyMakerStart = () => {
    apiCall
      .put(collectionURLs.storyMaker())
      .then(() => {
        enqueueSnackbar(
          "StoryMaker generation started. It can take as long as 20 minutes. Check back later.",
          { variant: "success" }
        );
      })
      .catch((error) => {
        // If error body is present, use that
        if (error.response?.data) {
          if (error.response.data?.error_code === 3011) {
            // Already a job running
            enqueueSnackbar(
              "A StoryMaker job is already running. Please wait for it to finish before starting another.",
              { variant: "warning" }
            );
            return;
          }
        }

        // Otherwise, use the default error message
        enqueueSnackbar(
          "An error occurred while starting the StoryMaker job. Please talk to Adam.",
          { variant: "error" }
        );
        console.group("StoryMaker error");
        console.error("Story maker could not run");
        console.error(error);
        console.groupEnd();
      });
  };

  return (
    <Page title="Story Blocks" isLoading={isLoading}>
      <Grid item xs={12}>
        <PlanValidationStatus />
        <PlanUpgradeBanner />
        <Page.Title>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <span id="story-block-page-title">Story Blocks</span>

            {auth.isAdmin && (
              <Button
                variant="contained"
                color="secondary"
                startIcon={<AutoFixHighIcon />}
                onClick={storyMakerStart}
                style={{ marginLeft: "18px" }}
              >
                Start StoryMaker
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              id="create-collection-button"
              startIcon={<AddIcon />}
              onClick={() =>
                setShowCreateStoryBlockModal(!showCreateStoryBlockModal)
              }
              style={{ marginLeft: "18px" }}
            >
              Create Story Block
            </Button>
          </Stack>
        </Page.Title>
        {error ? (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {error}
          </Alert>
        ) : (
          <StoryBlockList
            storyBlocks={storyBlocks}
            storyBlockType="regular"
            onCreateStoryBlockClick={() => setShowCreateStoryBlockModal(true)}
          />
        )}
      </Grid>
      <StoryBlockLocationModal
        edit={false}
        open={showCreateStoryBlockModal}
        onClose={() => setShowCreateStoryBlockModal(false)}
      />
    </Page>
  );
};
