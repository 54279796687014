import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useSnackbar } from "notistack";
import { useTheme } from "@mui/material/styles";
import { Button, Grid, Paper, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import StoryOptions from "../StoryOptions";
import { AddStoryModal } from "./AddStoryModal";
import { reorderStories } from "../../../redux/api/storyBlockAPI";
import { invalidateCollectionCache } from "../../../api/fetchCollectionData";

const style = {
  stack: {
    overflowX: "auto",
    overflowY: "hidden",
    maxHeight: "100%",
    width: "100%",
  },
  storyCard: {
    minHeight: "100%",
    width: "120px",
    borderRadius: 0,
    border: "none",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
    lineHeight: "20px",
    paddingTop: "25px",
    paddingLeft: "5px",
    paddingRight: "5px",
    fontSize: "14px",
    color: "black",
    "&:hover": { border: "none" },
  },
  storyImage: {
    minHeight: "75px",
    width: "75px",
    borderRadius: "50%",
    marginBottom: "10px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  addCard: {
    minHeight: "100%",
    minWidth: "120px",
    borderRadius: 0,
    border: "none",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    paddingTop: "25px",
    color: "black",
    "&:hover": { border: "none" },
  },
  addIcon: {
    height: "75px",
    width: "75px",
    borderRadius: "50%",
    marginBottom: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default function StoriesSection({
  storyBlock,
  selectedStoryId,
  setSelectedStoryId,
  updateLivePreview
}) {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [isStoryDragging, setIsStoryDragging] = useState(false);
  const [stories, setStories] = useState(storyBlock?.stories);
  const [hover, setHover] = useState(null);
  const [showAddStoryModal, setShowAddStoryModal] = useState(false);

  const setDragStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "rgb(238 248 238 / 76%)" : "transparent",
    display: "flex",
  });

  useEffect(() => {
    setStories(storyBlock?.stories);
  }, [storyBlock?.stories]);

  const onChangeStoryOrder = async (event) => {
    setIsStoryDragging(false);

    const originalLocation = event.source.index;
    const newLocation = event.destination.index;

    const originalOrder = storyBlock?.stories.map((s) => s.id);

    const elm = originalOrder.splice(originalLocation, 1)[0];
    originalOrder.splice(newLocation, 0, elm);

    const originalOrderState = storyBlock?.stories;
    const newOriginalOrderState = originalOrderState.slice();
    const elmState = newOriginalOrderState.splice(
      originalLocation,
      1
    )[0];
    newOriginalOrderState.splice(newLocation, 0, elmState);

    setStories(newOriginalOrderState);

    try {
      await reorderStories({id: storyBlock.id, newStoryIDs: originalOrder});
      updateLivePreview();
      enqueueSnackbar("Stories reordered successfully!", {variant: "success"});
      invalidateCollectionCache();
    } catch (error) {
      enqueueSnackbar("Stories Unable to be Reordered!", {variant: "error"});
      setStories(originalOrderState);
    }
  }

  return (
    <Grid item xs={12}>
      <Paper elevation={1}>
        <DragDropContext
          onDragStart={() => setIsStoryDragging(true)}
          onDragEnd={onChangeStoryOrder}
        >
          <Droppable direction="horizontal" droppableId="stories">
            {(provided, snapshot) => (
              <Stack
                direction="row"
                className="stories"
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={setDragStyle(snapshot.isDraggingOver)}
                sx={style.stack}
                justifyContent="center"
              >
                {stories?.map((s, index) => {
                  return (
                    <Draggable key={s.id} draggableId={s.id} index={index}>
                      {(provided) => (
                        <div
                          key={s.id}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div
                            key={s.id}
                            onClick={() => setSelectedStoryId(s.id)}
                            style={{
                              ...style.storyCard,
                              backgroundColor:
                                selectedStoryId === s.id
                                  ? theme.palette.grey[200]
                                  : "white",
                            }}
                            onMouseEnter={() => setHover(s.id)}
                            onMouseLeave={() => setHover(null)}
                          >
                            <div>
                              <div
                                style={{
                                  ...style.storyImage,
                                  background: `url(${
                                    s?.thumbnail_url ??
                                    s?.asset?.ImageSquareCropURL ??
                                    s?.asset?.ImageOriginalURL
                                  }) 100%/contain`,
                                }}
                              />
                              <div>{s.internal_name}</div>
                            </div>
                            {hover === s.id ? (
                              <StoryOptions selectedStoryId={selectedStoryId} updateLivePreview={updateLivePreview} />
                            ) : (
                              <div style={{ height: "36px" }} />
                            )}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                <Button
                  sx={{...style.addCard, display: isStoryDragging ? "none" : ""}}
                  onClick={() => setShowAddStoryModal(true)}
                >
                  <div style={{...style.addIcon, backgroundColor: theme.palette.primary.light}}>
                    <AddIcon />
                  </div>
                  Add Story
                </Button>
                {provided.placeholder}
              </Stack>
            )}
          </Droppable>
        </DragDropContext>
      </Paper>
      <AddStoryModal
        open={showAddStoryModal}
        onClose={() => {
          setShowAddStoryModal(false);
          updateLivePreview();
        }}
        onStoryCreated={(newStory) => setSelectedStoryId(newStory.id)}
      />
    </Grid>
  );
}
