import { useQuery } from "react-query";

import queryClient from "../constants/queryClient";
import { shopifyURLs } from "../constants/apiURLs";
import { API_QUERY_KEYS } from "../constants/textMappings";
import { invalidateAccountCache } from "./fetchAccountData";
import request from "../utilities/request";

export const useChargeValidation = (charge_id) => {
  const { data, ...meta } = useQuery(API_QUERY_KEYS.CHARGE_VALIDATION_REQUEST, () => {
    return request().put(shopifyURLs.validateCharge(charge_id));
  });

  invalidateAccountCache();
  
  return { data, ...meta };
};

export const invalidateChargeValCache = () => {
  queryClient.invalidateQueries(API_QUERY_KEYS.CHARGE_VALIDATION_REQUEST);
};