import React from "react";

import PlanUpgradeBanner from "../components/PlanUpgradeBanner";
import Page from "../components/Page";
import AppBlockWizard from "../modules/shopify/AppBlock";

const ShopifyAppBlock = () => {
  return (
    <Page title="App Block Installation">
      <PlanUpgradeBanner />
      <Page.Title>App Block Installation</Page.Title>
      <AppBlockWizard />
    </Page>
  );
};

export default ShopifyAppBlock;
