import { SHOPIFY_SCOPES } from "../constants/textMappings";
import { getEnvironment } from "./environment";

// Allow the use of different shopify apps depending on the environment

const shopifyPublicAPIKey = () => {
  switch (getEnvironment()) {
    case "production":
      return "af2bd5c4cf47e5c2a96bfdf57f74e246";
    default:
      return "09fba0ce35533553cd4edd9c550e18e7";
  }
};

export const shopifyOAuthRedirect = (shop) => {
  // Generate a random nonce
  const nonce = Math.floor(Math.random() * 1000000);

  // Use the current protocol and domain as the redirect domain
  const redirectDomain = `${window.location.protocol}//${window.location.host}`;

  const redirectSearchParams = new URLSearchParams();
  redirectSearchParams.set("client_id", shopifyPublicAPIKey());
  redirectSearchParams.set("scope", SHOPIFY_SCOPES); // Permissions
  redirectSearchParams.set("redirect_uri", redirectDomain + "/auth/callback"); // Redirect URL
  redirectSearchParams.set("state", nonce); // State
  redirectSearchParams.set("grant_type", "value"); // Grant type

  const redirectURL = "https://" + shop + "/admin/oauth/authorize";
  const redirectURLWithParams =
    redirectURL + "?" + redirectSearchParams.toString();

  window.location.assign(redirectURLWithParams);
};
