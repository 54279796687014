import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";
import { QRCodeSVG } from "qrcode.react";
import { useTheme } from "@mui/material/styles";
import {
  Alert,
  AlertTitle,
  Button,
  ButtonGroup,
  Divider,
  Grid,
  Popover,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import PreviewIcon from "@mui/icons-material/Preview";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import Page from "../../components/Page";
import StoryBlockEditor from "./StoryBlockEditor";
import DeleteStoryBlockModal from "./DeleteStoryBlockModal";
import StyleSettingsModal from "./StyleSettingsModal";
import StoryBlockLocationModal from "./StoryBlockLocationModal";
import DuplicateStoryBlockModal from "./DuplicateStoryBlockModal";
import PublishModal from "./PublishModal";
import MediaModal from "../../components/MediaModal";
import { hasOverrideAccountID } from "../../context/auth";
import { useLivePreviewHost } from "../../utilities/livePreview";
import { formatShopifyHandle } from "../../utilities/formatShopifyData";
import { useCollection } from "../../api/fetchCollectionData";
import { updateStoryBlock } from "../../redux/api/storyBlockAPI";
import { usePageTargetingRulesets } from "../../api/fetchPageTargetingRulesets";

export function StoryBlock() {
  const { enqueueSnackbar } = useSnackbar();
  const { storyBlockId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const {collection: storyBlock, isLoading, isRefetching} = useCollection(storyBlockId);
  const [livePreviewDialog, setLivePreviewDialog] = useState(false);
  const [livePreviewDialogAnchorEl, setLivePreviewDialogAnchorEl] = useState(null);
  const [optionsDialog, setOptionsDialog] = useState(false);
  const [optionsDialogAnchorEl, setOptionsDialogAnchorEl] = useState(null);
  const [showEditStoryBlockLocationModal, setShowEditStoryBlockLocationModal] = useState(false);
  const [showDeleteStoryBlockModal, setShowDeleteStoryBlockModal] = useState(false);
  const [styleSettingsModalIsOpen, setStyleSettingsModalIsOpen] = useState(false);
  const [showDuplicateStoryBlockModal, setShowDuplicateStoryBlockModal] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [isNameFocused, setIsNamedFocused] = useState(false);
  const [name, setName] = useState(storyBlock?.internal_name);
  const {rulesets, isLoading: isLoadingRulesets} = usePageTargetingRulesets();
  const matchingRuleset = rulesets.find(ruleset => ruleset.collection_id === storyBlockId);
  const validStoryBlock = storyBlock?.id;
  const isPublished = matchingRuleset?.active ?? false;

  const buttonStyle = {
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
  };

  const landingPageUrl = `https://cdn.kahaniapp.com/landingpage.html?collectionID=${storyBlock?.id}&accountID=${storyBlock?.account_id}`;

  const previewHost = useLivePreviewHost(
    storyBlock?.id,
    storyBlock?.account_id
  );


  const updateLivePreview = () => {
    previewHost.triggerUpdate();
  };

  const style = {
    optionsDropdown: {
      border: "none",
      color: theme.palette.text.primary,
      "&:hover": { border: "none" },
      justifyContent: 'flex-start',
    },
  };

  // resets title when story block duplicated
  useEffect(() => {
    setName(storyBlock?.internal_name);
  }, [storyBlock?.internal_name]);

  // Opens edit story block location modal if no ruleset exists
  useEffect(() => {
    if (!matchingRuleset && !isLoadingRulesets && storyBlock?.id) {
      setShowEditStoryBlockLocationModal(true);
    }
  }, [matchingRuleset, isLoadingRulesets, storyBlock?.id]);

  return (
    <Page title={"Edit " + storyBlock?.internal_name}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Page.Title>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack direction="column" sx={{ width: "100%" }}>
                {!isNameFocused ? (
                  <Typography
                    variant="h3"
                    onClick={() => setIsNamedFocused(true)}
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                        borderBottom: "1px solid grey",
                      },
                    }}
                  >
                    {name}
                  </Typography>
                ) : (
                  <TextField
                    autoFocus
                    fullWidth
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    onBlur={async () => {
                      try {
                        await updateStoryBlock({...storyBlock, internal_name: name});
                        enqueueSnackbar("Story Block title updated successfully!", {variant: "success"});
                      } catch(error) {
                        console.error('error updating story block title', error);
                        enqueueSnackbar("Story Block title failed to update", {variant: "error"});
                      }
                      setIsNamedFocused(false);
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: "50px",
                        fontSize: "48px",
                      },
                      "& input": {
                        paddingLeft: 0,
                      },
                      "& fieldset": {
                        border: "none",
                        borderBottom: "1px solid grey",
                        borderRadius: 0,
                      },
                    }}
                  />
                )}
                <Typography
                  variant="body2"
                  color={theme.palette.text.secondary}
                  sx={{ display: "flex", alignItems: "center", marginTop: 1 }}
                >
                  {formatShopifyHandle(storyBlock).icon}
                  <span style={{ marginLeft: 10 }}>
                    {formatShopifyHandle(storyBlock).page}
                  </span>
                </Typography>
              </Stack>
              <div style={{ display: "flex", gap: "10px", marginLeft: 50 }}>
                <Button
                  variant="outlined"
                  onClick={(e) => {
                    setOptionsDialogAnchorEl(e.currentTarget);
                    setOptionsDialog(true);
                  }}
                  sx={buttonStyle}
                >
                  <MoreHorizIcon />
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<PreviewIcon />}
                  onClick={(e) => {
                    if (storyBlock?.internal_description !== "||LP||") {
                      // Track event
                      window.analytics.track("collection-live-preview", {
                        collection_id: storyBlock?.id,
                        collection_name: storyBlock?.name,
                        admin_action: hasOverrideAccountID(),
                      });

                      setLivePreviewDialogAnchorEl(e.currentTarget);
                      setLivePreviewDialog(true);
                    } else {
                      window.open(landingPageUrl);
                    }
                  }}
                  sx={buttonStyle}
                >
                  Preview
                </Button>
                {
                  storyBlock?.internal_description === "||LP||" ? (
                    <Button
                      variant="outlined"
                      disabled={!storyBlock?.stories.length}
                      onClick={() => {
                        enqueueSnackbar("URL Copied!", {
                          variant: "success",
                        });
                        navigator.clipboard.writeText(landingPageUrl);
                      }}
                      sx={buttonStyle}
                      startIcon={<ContentCopyIcon />}
                    >
                      Copy Landing Page URL
                    </Button>
                  ) : null
                }
                <Button
                  variant={isPublished ? "outlined" : "contained"}
                  onClick={() => setShowPublishModal(true)}
                  disabled={!matchingRuleset}
                >
                  {isPublished ? 'Unpublish' : 'Publish'}
                </Button>
              </div>
            </Stack>
          </Page.Title>
        </Grid>
      </Grid>

      {validStoryBlock && !isLoading && (
        <StoryBlockEditor updateLivePreview={updateLivePreview} />
      )}

      {!validStoryBlock && !isLoading && !isRefetching && (
        <Alert severity="error">
          <AlertTitle>Story Block not found</AlertTitle>
          The Story Block you're trying to edit doesn't exist.
          <br />
          <br />
          <Button
            variant="contained"
            color="warning"
            onClick={() => navigate("/story_blocks")}
          >
            Go back to the Story Blocks list
          </Button>
        </Alert>
      )}

      {/* Popover for Live Preview */}
      <Stack spacing={2} direction="row" alignItems="center">
        <Popover
          open={livePreviewDialog}
          anchorEl={livePreviewDialogAnchorEl}
          onClose={() => {
            setLivePreviewDialog(false);
            setLivePreviewDialogAnchorEl(null);
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Grid
            container
            style={{
              padding: "1rem 2rem",
              width: "100%",
              maxWidth: "400px",
            }}
          >
            <Typography variant="h6" sx={{ pb: 2 }}>
              Live Preview
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<PreviewIcon />}
              fullWidth
              onClick={() => {
                // Open preview window in a new tab
                previewHost.openPreview();

                // Close the dialog
                setLivePreviewDialog(false);
                setLivePreviewDialogAnchorEl(null);
              }}
            >
              Open in new tab
            </Button>
            <Typography
              variant="body1"
              style={{
                marginTop: "1rem",
              }}
            >
              Or scan this code on your mobile device
            </Typography>
            {/* Placeholder image for QR code */}
            <QRCodeSVG
              value={previewHost.previewURL()}
              size={250}
              includeMargin={true}
              style={{
                width: "100%",
              }}
            />
          </Grid>
        </Popover>
      </Stack>

      {/* Options Dialog */}
      <Stack>
        <Popover
          open={optionsDialog}
          anchorEl={optionsDialogAnchorEl}
          onClose={() => {
            setOptionsDialog(false);
            setOptionsDialogAnchorEl(null);
          }}
          anchorOrigin={{ vertical: "bottom", horizontal: 'left' }}
        >
          <ButtonGroup orientation="vertical">
            <Button
              sx={style.optionsDropdown}
              onClick={() => {
                setOptionsDialog(false);
                setOptionsDialogAnchorEl(null);
                setShowEditStoryBlockLocationModal(true);
              }}
            >
              Change Publish Location
            </Button>
            <Button
              sx={style.optionsDropdown}
              onClick={() => {
                setOptionsDialog(false);
                setOptionsDialogAnchorEl(null);
                setStyleSettingsModalIsOpen(true);
              }}
            >
              Update Style
            </Button>
            <Divider />
            <Button
              sx={style.optionsDropdown}
              onClick={() => {
                setOptionsDialog(false);
                setOptionsDialogAnchorEl(null);
                setShowDuplicateStoryBlockModal(true);
              }}
            >
              Duplicate
            </Button>
            <Button
              sx={style.optionsDropdown}
              onClick={() => {
                setOptionsDialog(false);
                setOptionsDialogAnchorEl(null);
                setShowDeleteStoryBlockModal(true);
              }}
            >
              Delete
            </Button>
          </ButtonGroup>
        </Popover>
      </Stack>
      <DeleteStoryBlockModal storyBlock={storyBlock} open={showDeleteStoryBlockModal} onClose={() => setShowDeleteStoryBlockModal(false)} />
      {storyBlock?.id &&
        <StyleSettingsModal storyBlock={storyBlock} open={styleSettingsModalIsOpen} onClose={() => setStyleSettingsModalIsOpen(false)} />
      }
      <StoryBlockLocationModal
        open={showEditStoryBlockLocationModal}
        edit={true}
        onClose={() => setShowEditStoryBlockLocationModal(false)}
      />
      <DuplicateStoryBlockModal
        storyBlock={storyBlock}
        open={showDuplicateStoryBlockModal}
        onClose={() => setShowDuplicateStoryBlockModal(false)}
      />
      <PublishModal open={showPublishModal} onClose={() => setShowPublishModal(false)} storyBlockId={storyBlockId} />
      <MediaModal />
    </Page>
  );
}
