import { v4 as uuidv4 } from "uuid";
import { updateRulesets } from "../slices/pageTargetingSlice";
import { store } from "../store";
import { formatShopifyHandle } from "../../utilities/formatShopifyData";

export const createStoryBlockListener = {
  type: "storyBlocks/createStoryBlock/fulfilled",
  effect: async (action, listenerApi) => {
    // @todo page selection and value data
    const state = store.getState();
    const ruleSets = state?.pageTargeting?.rulesets?.list;

    const priorities = ruleSets
      .map((r) => r.priority)
      .sort(function (a, b) {
        return b - a;
      });
    let newPriority = 0;
    if (priorities[0]) {
      newPriority = priorities[0] + 1;
    }

    const data = [
      ...ruleSets,
      {
        id: uuidv4(),
        rules: [
          {
            id: uuidv4(),
            category: "shopify",
            type: formatShopifyHandle(action.payload).type, // depends on selection
            value: formatShopifyHandle(action.payload)?.id, // either product or collection id
          },
        ],
        mode: "any",
        collection_id: action.payload.id,
        priority: newPriority,
        active: false,
      },
    ];

    console.log("YYY: ", data, action);

    // this will update the rulesets, find a way to get old rulesets so they aren't erased
    listenerApi.dispatch(updateRulesets(data));
  },
};
