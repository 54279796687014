import React, { useRef, useState, useEffect, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import CreatableSelect from "react-select/creatable";
import { useSnackbar } from "notistack";
import { Widget } from "@uploadcare/react-widget";
import uploadcare from "uploadcare-widget";

import { LoadingButton } from "@mui/lab";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CheckIcon from "@mui/icons-material/Check";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Button,
  DialogActions,
  TableRow,
  Table,
  TableHead,
  TableBody,
  TableCell,
  Alert,
  LinearProgress,
  CircularProgress,
} from "@mui/material";

import { uploadAsset } from "../../utilities/assets";
import { invalidateAssetCache } from "../../api/fetchAssetData";
import {
  useAssetTags,
  invalidateAssetTagCache,
} from "../../api/fetchAssetTagData";

import "./AssetUploader.css";
import { hasOverrideAccountID } from "../../context/auth";

const uploadCare = true;

const AssetUploader = ({
  autoUpload,
  onDone,
  allowMultiple,
  onAssetUploaded = (data) => {
    console.log("Asset uploaded: ", data);
  },
  onMinValidationFailed,
  min = 1,
  uploadType = "image",
  selectedAsset,
  setSelectedAsset = () => {},
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const widgetApi = useRef();

  const [internal_name, setInternalName] = useState("");
  const [loading, setLoading] = useState(false);
  const [saveError, setSaveError] = useState(null);
  const [tags, setTags] = useState([]);
  const [uploadCareFiles, setUploadCareFiles] = useState([]);
  const [uploadMetadataModalOpen, setUploadMetadataModalOpen] = useState(false);

  // state for multi-upload
  const [filesProcessed, setFilesProcessed] = useState(0);
  const [multiUploadModalOpen, setMultiUploadModalOpen] = useState(false);
  const [runMultiUpload, setRunMultiUpload] = useState(false);

  // If undefined, allowMultiple is false
  allowMultiple = typeof allowMultiple === "boolean" ? allowMultiple : false;

  const usedTags = useAssetTags(); // Get currently used asset tags
  // When usedTags is set, set the tags to the options
  const tagOptions = usedTags
    ? usedTags.tags.map((tag) => ({
        value: tag,
        label: tag,
      }))
    : [];

  // Stuff for the multi-file uploader
  const incrementFilesProcessed = () => {
    setFilesProcessed((filesProcessed) => filesProcessed + 1);
  };

  const resetMultiUploader = () => {
    setRunMultiUpload(false);
    setFilesProcessed(0);
  };

  const processMultiUpload = (e) => {
    e.preventDefault();
    setRunMultiUpload(true);
  };

  // Process an upload request
  const processSingleUpload = (e) => {
    e.preventDefault();

    // error checking/data validation
    // Require name field
    if (internal_name === "") {
      setSaveError("Name is required");
      return;
    }

    setSaveError(null);

    // Process tags into an array
    let outTags = [];
    tags.forEach((tag) => {
      outTags.push(tag.value);
    });

    // Upload the file
    setLoading(true);

    let data;
    let urlUpload;
    if (uploadCareFiles[0]?.cdnUrl) {
      urlUpload = true;

      const file = uploadCareFiles[0];
      const cropped = !!file.crop;
      let height;
      let width;
      if (file.isImage) {
        width = cropped ? file.crop.width : file.originalImageInfo.width;
        height = cropped ? file.crop.height : file.originalImageInfo.height;
      }

      let optimization = file.isImage
        ? "-/preview/-/quality/smart/-/format/auto/"
        : "";

      const isGif = file.mimeType === "image/gif";
      if (isGif) {
        optimization = "gif2video";
      }

      data = {
        mime_type: file.mimeType,
        type: uploadTypeName(file.isImage),
        width,
        height,
        filename: file.name,
        internal_name,
        internal_description: "",
        url: file?.cdnUrl + optimization,
      };
    } else {
      data = {
        file: acceptedFiles[0],
        internal_name,
      };
    }

    uploadAsset({
      file: data,
      tags: outTags,
      urlUpload,
      onProgress: (progress) => {
        console.debug("Now " + progress + "% complete!");
      },
      onUploadComplete: () => {
        console.log("Upload complete! Processing...");
      },
      onProcessingComplete: (res) => {
        console.log("Processing complete! Totally done.");

        console.info("Upload successful");

        // Invalidate the current asset list
        invalidateAssetTagCache();
        invalidateAssetCache();

        // Notify success
        enqueueSnackbar("Saved Asset", {
          variant: "success",
        });

        // Close modal
        setUploadMetadataModalOpen(false);

        // Return the asset to our parent
        if (onDone) {
          console.log("Done! Here is the new asset:");
          console.log(res);
          onDone(res);
        }

        onAssetUploaded({ record: res });
      },
      onError: (err) => {
        console.error("Upload failed");
        console.error(err);
        if (err.error_code && err.error_code === 2001) {
          setSaveError("This file has already been uploaded");
          return;
        }
        setSaveError(err.error + err.details);
      },
      always: () => {
        setLoading(false);
      },
    });
  };

  const onDrop = useCallback((acceptedFiles, selectedFiles) => {
    if (uploadCare) {
      setUploadCareFiles(selectedFiles);
    }

    if (
      (uploadCare && selectedFiles.length < min) ||
      (!uploadCare && acceptedFiles.length < min)
    ) {
      onMinValidationFailed(
        `Please select at least ${min} images or videos to upload`
      );
      return;
    }

    // Disable old upload
    resetMultiUploader();

    // Remove any previous error
    setSaveError(null);

    // if multiple not allowed, but multiple selected, throw error
    if (
      !allowMultiple &&
      ((uploadCare && selectedFiles.length > 1) ||
        (!uploadCare && acceptedFiles.length > 1))
    ) {
      alert(
        "You must upload only 1 file.\nTo bulk upload, go to the Media Library."
      );
      return;
    }

    // If only one file was dropped, use the normal form
    if (uploadCare && selectedFiles.length === 1) {
      selectedFiles.forEach((file) => {
        console.info(file.name);
        console.info(file);

        // Open the modal
        setUploadMetadataModalOpen(true);

        // Clear existing fields
        setInternalName(file.name);
        setTags([]);
      });
    } else if (!uploadCare && acceptedFiles.length === 1) {
      acceptedFiles.forEach((file) => {
        console.info(file.name);
        console.info(file);

        // Open the modal
        setUploadMetadataModalOpen(true);

        // Clear existing fields
        setInternalName(file.name);
        setTags([]);
      });
    } else {
      // If multiple files were dropped, use the multi-file form
      setMultiUploadModalOpen(true);
      setTags([]);
      if (autoUpload) {
        setRunMultiUpload(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
    allowMultiple,
  });

  React.useEffect(() => {
    if (autoUpload) {
      if (filesProcessed > 0 && filesProcessed === acceptedFiles.length) {
        setMultiUploadModalOpen(false);
        resetMultiUploader();
        onDone();
      }
    }
  }, [autoUpload, filesProcessed, acceptedFiles.length, onDone]);

  React.useEffect(() => {
    if (selectedAsset !== "") {
      widgetApi?.current?.openDialog();
    }
  }, [selectedAsset]);

  const alterLocale = {
    buttons: {
      choose: {
        files: {
          other: "Upload New File",
        },
      },
    },
  };

  return (
    <>
      {uploadCare ? (
        <div>
          <Widget
            localeTranslations={alterLocale}
            tabs={
              selectedAsset
                ? "file"
                : "file facebook dropbox instagram gdrive url"
            }
            debugUploads={true}
            inputAcceptTypes="video/*, image/*"
            ref={widgetApi}
            onFileSelect={async (group) => {
              if (group) {
                const files = await Promise.all(group.files());
                onDrop([], files);
                widgetApi.current.reloadInfo();
              }
            }}
            onDialogOpen={(dialog) => {
              if (selectedAsset) {
                dialog.hideTab("file");
                dialog.switchTab("preview");
                dialog.addFiles([
                  uploadcare.fileFrom("url", selectedAsset, {
                    publicKey: process.env.REACT_APP_UPLOAD_CARE_PUBLIC_KEY,
                  }),
                ]);
              } else {
                dialog.fileColl.onAdd.add(async (state) => {
                  const file = await state.promise();
                  // Track when a file was selected and the media source
                  window.analytics.track("media-file-selected", {
                    source: file.sourceInfo?.source,
                    admin_action: hasOverrideAccountID(),
                  });
                });
              }
            }}
            onDialogClose={() => {
              setSelectedAsset("");
            }}
            publicKey={process.env.REACT_APP_UPLOAD_CARE_PUBLIC_KEY}
            id="file"
            previewStep="true"
            multiple="true"
            doNotStore="true"
          />
        </div>
      ) : (
        <div {...getRootProps({})}>
          <input {...getInputProps()} />
          <Typography variant="h6">Upload</Typography>
          <Typography variant="body1">
            Add Media: Drag or click to select files you’d like to use in
            stories.{" "}
          </Typography>
        </div>
      )}

      {/* Modal that opens to supply metadata for a single file*/}
      <Dialog
        open={uploadMetadataModalOpen}
        onClose={() => setUploadMetadataModalOpen(false)}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">Upload Media</DialogTitle>
        <DialogContent>
          <TextField
            id="internal_name"
            label="Name"
            variant="outlined"
            fullWidth={true}
            value={internal_name}
            margin="normal"
            onChange={(e) => setInternalName(e.target.value)}
            onKeyDown={(e) => e.stopPropagation()}
            required
          />
          <Typography variant="subtitle1" style={{ marginBottom: "10px" }}>
            Tags
          </Typography>
          <CreatableSelect
            value={tags}
            defaultValue={tags}
            onChange={setTags}
            options={tagOptions}
            isMulti={true}
            isSearchable={true}
            placeholder="Add or select tags"
          />
          <Typography variant="subtitle2" style={{ marginBottom: "10px" }}>
            Add tags to make finding this asset easy later
          </Typography>
          <LoadingButton
            loading={loading}
            color="secondary"
            loadingPosition="start"
            variant="contained"
            onClick={processSingleUpload}
            startIcon={<CloudUploadIcon />}
            fullWidth={true}
            style={{
              marginTop: "1rem",
            }}
          >
            Begin Upload
          </LoadingButton>
          {saveError && (
            <Alert title="Error" color="error">
              {saveError}
            </Alert>
          )}
          <DialogActions>
            <Button onClick={() => setUploadMetadataModalOpen(false)}>
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

      {/* Modal that opens to supply metadata and upload status for multiple files */}
      <Dialog
        open={multiUploadModalOpen}
        onClose={() => setMultiUploadModalOpen(false)}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">Upload Media</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" style={{ marginBottom: "10px" }}>
            Tags
          </Typography>
          <CreatableSelect
            value={tags}
            defaultValue={tags}
            onChange={setTags}
            options={tagOptions}
            isMulti={true}
            isSearchable={true}
            placeholder="Add or select tags"
            isDisabled={runMultiUpload}
          />
          <Typography variant="subtitle2" style={{ marginBottom: "10px" }}>
            Add tags to make finding this asset easy later. These tags will be
            applied to every asset in this upload.
          </Typography>

          {/* @todo show status here of X completed of Y (would also be useful to fire off events for notifications) */}

          <Typography variant="subtitle1" style={{ marginBottom: "10px" }}>
            Processed {filesProcessed} of{" "}
            {uploadCare ? uploadCareFiles.length : acceptedFiles.length}
          </Typography>
          <LinearProgress
            variant="determinate"
            value={
              uploadCare
                ? filesProcessed / uploadCareFiles.length
                : (filesProcessed / acceptedFiles.length) * 100
            }
            style={{
              marginTop: "1rem",
            }}
            color="secondary"
          />

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Size</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {uploadCare
                ? uploadCareFiles.map((file) => (
                    <UploadLineItem
                      key={file?.name}
                      internal_name={file?.name}
                      file={file}
                      tags={tags}
                      upload={runMultiUpload}
                      uploadType={uploadType}
                      onEnd={(data) => {
                        // Increment the files processed counter
                        incrementFilesProcessed();

                        if (data.record) {
                          onAssetUploaded(data);
                        }
                      }}
                    />
                  ))
                : acceptedFiles.map((file) => (
                    <UploadLineItem
                      key={file?.name}
                      internal_name={file?.name}
                      file={file}
                      tags={tags}
                      upload={runMultiUpload}
                      uploadType={uploadType}
                      onEnd={(data) => {
                        // Increment the files processed counter
                        incrementFilesProcessed();

                        if (data.record) {
                          onAssetUploaded(data);
                        }
                      }}
                    />
                  ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          {/* Display cancel button if not running an upload */}
          {!runMultiUpload && (
            <Button onClick={() => setMultiUploadModalOpen(false)}>
              Cancel
            </Button>
          )}

          {/* Display a done button if upload has processed all files */}
          {(uploadCare
            ? filesProcessed === uploadCareFiles.length
            : filesProcessed === acceptedFiles.length) && (
            <Button
              onClick={() => {
                setMultiUploadModalOpen(false);
                onDone(filesProcessed);
              }}
              variant="contained"
            >
              Done
            </Button>
          )}

          {/* Display loading & disabled button if still uploading files */}
          {runMultiUpload &&
          filesProcessed <
            (uploadCare ? uploadCareFiles.length : acceptedFiles.length) ? (
            <Button disabled>
              <CircularProgress size={20} />
              Uploading...
            </Button>
          ) : null}

          <Button
            disabled={runMultiUpload}
            variant="contained"
            onClick={processMultiUpload}
          >
            Begin Upload
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AssetUploader;

/* Status moves from:
  1. pending: waiting for upload button
  2. uploading:  uploading bytes to server
  3. processing: server is processing asset
  4. complete: asset has been uploaded
  5. error: error occurred
*/
function UploadLineItem({
  file,
  internal_name,
  tags,
  upload = false,
  onEnd,
  uploadType,
}) {
  const [error, setError] = useState(null);
  const [hasUploaded, setHasUploaded] = useState(false);
  const [progress, setProgress] = useState(0);
  const [rowColor, setRowColor] = useState("#fafafa");
  const [status, setStatus] = useState("pending");
  const [statusMessage, setStatusMessage] = useState("");

  // Process tags into an array
  let outTags = [];
  tags.forEach((tag) => {
    outTags.push(tag.value);
  });

  let asset = useRef(null);

  // If upload changes from false to true, perform the upload
  useEffect(() => {
    if (upload && !hasUploaded) {
      setHasUploaded(true);
      setStatus("uploading");

      let data;
      let urlUpload;
      if (file?.cdnUrl) {
        const { isImage } = file;
        urlUpload = true;

        const cropped = !!file.crop;
        let height;
        let width;
        if (isImage) {
          width = cropped ? file.crop.width : file.originalImageInfo.width;
          height = cropped ? file.crop.height : file.originalImageInfo.height;
        }

        let optimization = isImage
          ? "-/preview/-/quality/smart/-/format/auto/"
          : "";

        const isGif = file.mimeType === "image/gif";
        if (isGif) {
          optimization = "gif2video";
        }

        data = {
          mime_type: file.mimeType,
          type: uploadTypeName(isImage),
          width,
          height,
          filename: file.name,
          internal_name,
          internal_description: "",
          url: file?.cdnUrl + optimization,
        };
      } else {
        data = {
          file,
          internal_name,
        };
      }

      uploadAsset({
        file: data,
        tags: outTags,
        urlUpload,
        onProgress: (progress) => {
          setStatus("uploading");
          setProgress(progress);
        },
        onUploadComplete: () => {
          setStatus(
            <>
              <CircularProgress
                style={{
                  marginRight: "10px",
                }}
                size={20}
                color="secondary"
              />
              Processing...
            </>
          );
        },
        onProcessingComplete: (res) => {
          asset.current = res;

          // Invalidate the current asset list
          invalidateAssetTagCache();
          invalidateAssetCache();

          setStatus("complete");
        },
        onError: (error) => {
          setStatus("error");

          console.error(error);

          if (error.error_code && error.error_code === 2001) {
            setError("This file has already been uploaded");
            setStatus("warning");
            return;
          }

          // If error details not set, then it's a generic error
          if (!error.details) {
            setError("Problem uploading media");
            return;
          }

          // Attempt some sense at sanitizing the error message
          let errorMessage = error.details;

          // If contains "unsupported MIME type", then it's not an image/video
          if (errorMessage.includes("unsupported MIME type")) {
            errorMessage = "Unsupported media type";
          }

          setError(errorMessage);
        },
        always: () => {
          onEnd({
            record: asset.current,
            file,
          });
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upload, hasUploaded]);

  // When status or progress change, update the status message
  useEffect(() => {
    switch (status) {
      case "pending":
        setStatusMessage("Push upload button");
        break;
      case "uploading":
        setRowColor("#AA71A7");
        setStatusMessage("Uploading: " + progress.toFixed(2) + "%");
        break;
      case "processing":
        setRowColor("#7A86AD");
        setStatusMessage("Processing...");
        break;
      case "complete":
        setRowColor("#9BC362");
        setStatusMessage(<CheckIcon />);
        break;
      case "error":
        setRowColor("#D3696C");
        setStatusMessage(
          <Alert severity="error" size="small">
            {error}
          </Alert>
        );
        break;
      case "warning":
        setRowColor("#DCDCAA");
        setStatusMessage(
          <Alert severity="warning" size="small">
            {error}
          </Alert>
        );
        break;
      default:
        setStatusMessage(status);
    }
  }, [status, progress, error]);

  const fileSizeKB = (file.size / 1024).toFixed(2);

  return (
    <TableRow
      key={file.name}
      style={{
        backgroundColor: rowColor,
        border: "1px solid #ccc",
        borderRadius: "0.5rem",
        padding: "1rem",
        margin: "1rem",
      }}
    >
      <TableCell>{file.name}</TableCell>
      <TableCell>{fileSizeKB} KB</TableCell>
      <TableCell>{statusMessage}</TableCell>
    </TableRow>
  );
}

function uploadTypeName(isImage) {
  const useMux = window.localStorage.getItem("debug_use_mux");

  if (isImage) {
    return "image";
  } else if (useMux) {
    return "mux_stream";
  } else {
    return "cloudflare_stream";
  }
}
