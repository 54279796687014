import { useReducer } from "react";

import { generateSetters, generateInitialState, reducer } from "./baseModel";

const settableKeys = {
  internalDescription: "internal_description",
  internalName: "internal_name",
  customCss: "custom_css",
  customJavascript: "custom_js",
  style: "style",
};

const initialState = {
  internal_description: "",
  internal_name: "",
  custom_css: "",
  custom_js: "",
  style: {
    primary_color: "",
    accent_color_1: "",
    accent_color_2: "",
    heading_font: "",
    body_font: "",
  },
  account_id: "",
  published: false,
  last_updated: "",
};

const useCollectionDetails = (collection) => {
  const [state, dispatch] = useReducer(
    reducer,
    generateInitialState({
      ...initialState,
      ...collection,
    })
  );
  const setters = generateSetters(settableKeys, dispatch);

  const returnValue = {
    ...setters,
    ...state,
  };

  return returnValue;
};

export default useCollectionDetails;
