import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import Confetti from "react-confetti";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";

export default function PlanValidationStatus() {
  const [open, setOpen] = useState(true);

  const [searchParams] = useSearchParams();
  const paymentStatus = searchParams.get("payment_status");
  const paymentSuccess = paymentStatus === "success";

  if (!paymentStatus) return null;

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {paymentSuccess
            ? "Congratulations! You have successfully activated your plan."
            : "Something went wrong."}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {paymentSuccess
              ? "You can now publish Kahani Story Blocks to your store."
              : "Please contact a Kahani support team member."}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      {paymentSuccess && (
        <Confetti numberOfPieces={1500} tweenDuration={50000} recycle={false} />
      )}
    </>
  );
}
