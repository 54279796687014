function listEqual(a, b) {
  return (
    JSON.stringify(rulesetsImportantFieldsOnly(a)) ===
    JSON.stringify(rulesetsImportantFieldsOnly(b))
  );
}

function oneEqual(a, b) {
  return (
    JSON.stringify(rulesetImportantFieldsOnly(a)) ===
    JSON.stringify(rulesetImportantFieldsOnly(b))
  );
}

function rulesetsImportantFieldsOnly(rulesets) {
  // Only keep important fields for comparing rulesets

  return rulesets.map((ruleset) => {
    return rulesetImportantFieldsOnly(ruleset);
  });
}

function rulesetImportantFieldsOnly(ruleset) {
  return {
    mode: ruleset.mode,
    priority: ruleset.priority,
    collection_id: ruleset.collection_id,
    rules: ruleset.rules.map((rule) => {
      return {
        category: rule.category,
        type: rule.type,
        value: rule.value,
      };
    }),
  };
}

// Returns if a ruleset matches the given URL or null
const RulesetListMatch = (rulesets, testURL) => {
  // Return null if rulesets is null or empty
  if (!rulesets || rulesets.length === 0) return null;

  // Loop through rulesets
  for (let i = 0; i < rulesets.length; i++) {
    const ruleset = rulesets[i];

    switch (ruleset.mode) {
      case "any":
        if (rulesetMatchAny(ruleset, testURL)) return rulesets[i];
        break;
      case "all":
        if (rulesetMatchAll(ruleset, testURL)) return rulesets[i];
        break;
      default:
        console.error("ruleset mode invalid: " + ruleset.mode);
        return null;
    }
  }

  return null;
};

// Return true if any rules match the given params
const rulesetMatchAny = (ruleset, testURL) => {
  // Loop through rules & return true if any match
  for (let i = 0; i < ruleset.rules.length; i++) {
    const rule = ruleset.rules[i];

    if (ruleMatch(rule, testURL)) return true;
  }

  return false;
};

// Return true if all rules match the given params
const rulesetMatchAll = (ruleset, testURL) => {
  // Loop through rules & return true if all match
  for (let i = 0; i < ruleset.rules.length; i++) {
    const rule = ruleset.rules[i];

    if (!ruleMatch(rule, testURL)) return false;
  }
};

const ruleMatch = (rule, testURL) => {
  // Standardize some values by removing leading/trailing whitespace and convering to lower case
  const standardizedURL = testURL.trim().toLowerCase();
  const standardizedRuleValue = rule.value.trim().toLowerCase();

  // check rule against search params
  switch (rule.category) {
    case "url":
      switch (rule.type) {
        case "contains":
          // Return true if the URL contains the rule value
          return standardizedURL.includes(standardizedRuleValue);
        case "not_contains":
          // Return true if the URL does not contain the rule value
          return !standardizedURL.includes(standardizedRuleValue);
        case "equals":
          // Return true if the URL equals the rule value
          return standardizedURL === standardizedRuleValue;
        case "starts_with":
          // Return true if the URL starts with the rule value
          return standardizedURL.startsWith(standardizedRuleValue);
        case "ends_with":
          // Return true if the URL ends with the rule value
          return standardizedURL.endsWith(standardizedRuleValue);
        default:
          console.error("Invalid rule type: " + rule.type);
          return false;
      }
    case "shopify":
      switch (rule.type) {
        case "homepage":
          // @todo
          console.debug("homepage rule not implemented yet");
          return false;
        case "product_page":
          // @todo
          console.debug("product_page rule not implemented yet");
          return false;
        case "collection_page":
          // @todo
          console.debug("collection_page rule not implemented yet");
          return false;
        default:
          console.error("Invalid rule type: " + rule.type);
          return false;
      }

    default:
      console.error("Invalid rule category: " + rule.category);
      return false;
  }
};

export const rulesetsUtils = {
  listEqual,
  oneEqual,
  simpleRulesets: rulesetsImportantFieldsOnly,
  simpleRuleset: rulesetImportantFieldsOnly,
  RulesetListMatch,
};
