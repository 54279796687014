/*
    Component for setting the crop parameters for an asset. Takes in an asset
    and an aspect ratio. Calls the API to update the crop parameter for the
    asset. (x,y,w,h).

    Aspect ratio only accepts constants CROP_VERTICAL_9_16 and CROP_SQUARE_1_1
*/
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Slider,
  Stack,
  DialogActions,
  CircularProgress,
  Alert,
  Divider,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import Cropper from "react-easy-crop";
import { saveCrop } from "../../api/saveCrop";
import RightCloseIcon from "./RightCloseIcon";
import SaveIcon from "@mui/icons-material/Save";
import { CROP_SQUARE_1_1, CROP_VERTICAL_9_16 } from "../../constants/assets";
import { assetOriginalLink } from "../../api/fetchAssetData";
import { SlideUpFromBottom } from "../../utilities/transitions";

export const AssetCropper = ({ asset, onCropUpdate, aspectRatio }) => {
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  const [error, setError] = React.useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null);

  const imgURL = assetOriginalLink(asset);

  // Different settings for different crop aspect ratios
  let shape = "rect";
  let initialCroppedAreaPixels = {};
  switch (aspectRatio) {
    case CROP_SQUARE_1_1:
      shape = "round";

      // Does the asset have square crop settings?
      const settings = asset?.image_square_crop;
      if (settings) {
        initialCroppedAreaPixels = {
          x: settings.x,
          y: settings.y,
          width: settings.w,
          height: settings.h,
        };
      } else {
        // Get the smaller of width or height to determine the biggest square that can fit
        const size = Math.min(asset.width, asset.height);

        // Then center the square in the width/height of the image
        initialCroppedAreaPixels = {
          x: (asset.width - size) / 2,
          y: (asset.height - size) / 2,
          width: size,
          height: size,
        };
      }
      break;
    case CROP_VERTICAL_9_16:
      shape = "rect";

      // Does the asset have vertical crop settings?
      const verticalSettings = asset?.image_vertical_crop;
      if (verticalSettings) {
        initialCroppedAreaPixels = {
          x: verticalSettings.x,
          y: verticalSettings.y,
          width: verticalSettings.w,
          height: verticalSettings.h,
        };
      } else {
        // Then center the crop in the width/height of the image
        initialCroppedAreaPixels = findLargestRectangle(
          asset.width,
          asset.height
        );
      }
      break;
    default:
      console.error("Invalid aspect ratio: " + aspectRatio);
      break;
  }

  return (
    <Stack
      direction="column"
      sx={{
        height: "100%",
      }}
    >
      <Alert severity="info">
        <strong>Note: </strong>Updating the crop dimensions will update this
        image anywhere it is used.
      </Alert>
      <div
        style={{
          position: "relative",
          minHeight: "300px",
          height: "100%",
        }}
      >
        <Cropper
          image={imgURL}
          crop={crop}
          zoom={zoom}
          aspect={aspectRatio}
          initialCroppedAreaPixels={initialCroppedAreaPixels}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropAreaChange={(croppedArea, croppedAreaPixels) => {
            setCroppedAreaPixels(croppedAreaPixels);
            onCropUpdate && onCropUpdate(croppedAreaPixels);

            // If the dimensions are too small display an error
            // Square: 250 x 250
            // Vertical: 720 x 1280
            if (aspectRatio === CROP_SQUARE_1_1) {
              if (croppedAreaPixels.width < 250) {
                setError(
                  "For best results, the image should be least 250 x 250"
                );
              } else {
                setError(null);
              }
            } else {
              if (croppedAreaPixels.width < 720) {
                setError(
                  "For best results, the image should be least 720 x 1280"
                );
              } else {
                setError(null);
              }
            }
          }}
          showGrid={true}
          cropShape={shape}
        />
      </div>{" "}
      <div>
        <Slider
          value={zoom}
          min={1}
          max={3}
          step={0.01}
          aria-labelledby="Zoom"
          onChange={(e, zoom) => setZoom(zoom)}
        />
      </div>
      {error && (
        <Alert severity="warning" sx={{ mt: 2 }}>
          {error}
          <Divider />
          Current dimensions: {croppedAreaPixels?.width} x{" "}
          {croppedAreaPixels?.height}
        </Alert>
      )}
    </Stack>
  );
};

export const AssetCropperDialog = ({
  asset,
  aspectRatio,
  open = false,
  onClose,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState();

  const save = () => {
    setLoading(true);
    saveCrop(asset, aspectRatio, croppedAreaPixels)
      .then((asset) => {
        enqueueSnackbar("Asset cropped", { variant: "success" });
        onClose(asset);
      })
      .catch((err) => {
        enqueueSnackbar("Error cropping asset: " + err.message, {
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog
      TransitionComponent={SlideUpFromBottom}
      open={open}
      onClose={() => onClose(asset)}
      aria-labelledby="cropper-dialog-title"
      maxWidth={"md"}
      fullWidth={true}
    >
      <DialogTitle id="cropper-dialog-title">
        Media Crop
        <RightCloseIcon onClick={() => onClose(asset)} />{" "}
      </DialogTitle>
      <DialogContent
        sx={{
          height: "70vh",
        }}
      >
        <AssetCropper
          onCropUpdate={setCroppedAreaPixels}
          asset={asset}
          aspectRatio={aspectRatio}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => onClose(asset)}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          startIcon={loading ? <CircularProgress size={20} /> : <SaveIcon />}
          onClick={save}
          color="primary"
          disabled={loading}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

/*
Finds the largest rectangle that fits within the given width and height

Example usage:
const largestRectangle = findLargestRectangle(100, 200);
console.log(largestRectangle);
// Output: { x: 0, y: 47, width: 100, height: 56 }
*/
function findLargestRectangle(width, height) {
  const aspectRatio = 16 / 9; // aspect ratio of 9:16
  let rectWidth = width;
  let rectHeight = Math.floor(width * aspectRatio);

  if (rectHeight > height) {
    rectHeight = height;
    rectWidth = Math.floor(height / aspectRatio);
  }

  const x = Math.floor((width - rectWidth) / 2);
  const y = Math.floor((height - rectHeight) / 2);

  return {
    x: x,
    y: y,
    width: rectWidth,
    height: rectHeight,
  };
}
