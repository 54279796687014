import { useQuery } from "@tanstack/react-query";
import find from "lodash/find";

import { microshopURLs } from "../constants/apiURLs";
import { API_QUERY_KEYS } from "../constants/textMappings";
import request from "../utilities/request";

export const queryKey = API_QUERY_KEYS.MICROSHOP_REQUEST;

export const useMicroshops = () => {
  const { data, ...meta } = useQuery([queryKey], () => {
    return request().get(microshopURLs.list());
  });

  return {
    microshops: data?.microshops ?? [],
    ...meta,
  };
};

export const useMicroshop = (microshopID) => {
  const { microshops, ...meta } = useMicroshops();
  const microshop = find(microshops, { id: microshopID });

  return {
    microshop: microshop ?? {},
    ...meta,
  };
};
