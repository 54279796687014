import React from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";

import Page from "../components/Page";

import {
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Alert,
  AlertTitle,
  Stack,
  Button,
} from "@mui/material";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import ClearIcon from "@mui/icons-material/Clear";

import { useAccountDetails } from "../api/fetchAccountData";

const displayIcon = (value) =>
  value ? <CheckCircleTwoToneIcon color="secondary" /> : <ClearIcon />;

const displayShopifyIcon = (value) =>
  value ? (
    <img
      style={{
        height: "30px",
      }}
      src="/img/shopify_logo_lg.png"
      alt="Shopify"
    />
  ) : (
    <ClearIcon />
  );

const formatBillingPlan = (plan) => {
  if (!plan?.id) {
    return "No plan information available.";
  }

  const titles = [
    { title: "ID", value: plan?.id },
    { title: "Name", value: plan?.name },
    { title: "Base Price", value: plan?.base_price },
    { title: "Interval", value: plan?.interval },
    { title: "Trial Period Days", value: plan?.trial_period_days },
  ];

  return (
    <Table>
      <TableBody>
        {titles.map((i, index) => (
          <TableRow
            key={`${i.title}${index}`}
            sx={{
              "&:last-child .MuiTableCell-root": {
                border: "none",
              },
              "& .MuiTableCell-root": {
                padding: "0",
              },
            }}
          >
            <TableCell sx={{ fontWeight: "bold" }}>{i.title}:</TableCell>
            <TableCell>{i.value}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const formatData = (data) => {
  return [
    { name: "Name", value: data.name },
    { name: "ID", value: data.id },
    { name: "Admin Account", value: displayIcon(data.admin) },
    { name: "Active Plan", value: displayIcon(data.has_active_billing_plan) },
    {
      name: "Shopify Account",
      value: displayShopifyIcon(data.is_shopify_account),
    },
    {
      name: "Plan Information",
      value: formatBillingPlan(data.active_billing_plan),
    },
  ];
};

export default function AccountDetails() {
  const navigate = useNavigate();
  const { accountID } = useParams();
  const { error, isLoading, isFetching, account } =
    useAccountDetails(accountID);

  return (
    <>
      <Page title="Account Details" isLoading={isLoading}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Page.Title>
              <Stack
                direction="row"
                justifyItems="space-between"
                alignItems="center"
              >
                <>Account Details</>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    navigate("/admin");
                  }}
                  sx={{
                    marginLeft: "auto",
                  }}
                  id="create-collection-button"
                >
                  Back
                </Button>
              </Stack>
            </Page.Title>
            <Table>
              <TableBody>
                {!isLoading &&
                  !isFetching &&
                  formatData(account).map((i, index) => (
                    <TableRow
                      key={`${i.name}${index}`}
                      sx={{
                        "&:last-child .MuiTableCell-root": { border: "none" },
                      }}
                    >
                      <TableCell>{i.name}</TableCell>
                      <TableCell>{i.value}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Page>
      {error && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {error}
        </Alert>
      )}
    </>
  );
}
