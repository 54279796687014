import { useQuery } from "react-query";

import { API_QUERY_KEYS } from "../constants/textMappings";
import queryClient from "../constants/queryClient";
import request from "../utilities/request";
import { shopifyDataURLs } from "../constants/apiURLs";

export const queryKey = API_QUERY_KEYS.SHOPIFY_COLLECTIONS_REQUEST;

export const useShopifyCollections = () => {
  const { data, ...meta } = useQuery(queryKey, () => {
    return request().get(shopifyDataURLs.shopifyCollectionList());
  });

  return {
    shopifyCollections: data ?? [],
    ...meta,
  };
}

export const invalidateShopifyCollectionsCache = () => {
  queryClient.invalidateQueries(queryKey);
};
