import React from "react";

import { Button, IconButton, Grid, TextField, Stack } from "@mui/material";
import { useCollection } from "../../api/fetchCollectionData";

import AddIcon from "@mui/icons-material/Add";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { rulesetsUtils } from "../../utilities/pageTargeting";

/*
    This component maintains a list of URLs to test. Users can add/remove/edit the URLs in the list and re-run the API test at any time
*/
const storageTestURLsKey = "pageTargetingURLs";
const defaultList = [""];
const PageTargetingURLTest = ({ rulesets }) => {
  // On load, check if URLs are saved in local storage
  const previousURLs = localStorage.getItem(storageTestURLsKey);
  const [URLs, setURLs] = React.useState(
    previousURLs ? JSON.parse(previousURLs) : defaultList
  );

  // When URL list changes, save to local storage
  React.useEffect(() => {
    localStorage.setItem(storageTestURLsKey, JSON.stringify(URLs));
  }, [URLs]);

  const handleURLAdd = () => {
    setURLs((oldURLs) => {
      const newURLs = Array.from(oldURLs);
      newURLs.push("");

      return newURLs;
    });
  };

  const handleURLChange = (index, value) => {
    // setURLs(URLs.map((url, i) => (i === index ? value : url)));

    setURLs((oldURLs) => {
      const newURLs = Array.from(oldURLs);
      newURLs[index] = value;
      return newURLs;
    });
  };

  const handleURLDelete = (index) => {
    setURLs((oldURLs) => {
      let newURLs = Array.from(oldURLs);
      newURLs.splice(index, 1);

      // If length is now 0, use the default list
      if (newURLs.length === 0) {
        return defaultList;
      }

      return newURLs;
    });
  };

  const handleClear = () => {
    setURLs(defaultList);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Stack
          direction="row"
          justifyContent="space-around"
          alignItems="center"
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleURLAdd()}
            startIcon={<AddIcon />}
          >
            Add
          </Button>
          <Button
            variant="contained"
            color="warning"
            onClick={() => handleClear()}
            startIcon={<RemoveCircleOutlineIcon />}
          >
            Clear
          </Button>
        </Stack>
      </Grid>
      {URLs.map((URL, index) => (
        <URLField
          key={index}
          rulesets={rulesets}
          url={URL}
          onChange={(value) => handleURLChange(index, value)}
          onDelete={() => handleURLDelete(index)}
        />
      ))}
    </Grid>
  );
};

const URLField = React.memo(
  ({ url, rulesets, onChange, onDelete }) => {
    // @todo longer term, this should come from the API, not by duplicate matching logic here
    const match = rulesetsUtils.RulesetListMatch(rulesets, url);
    const collection_id = match ? match.collection_id : "";

    const { collection } = useCollection(collection_id);

    const color = collection_id === "" ? "error" : "success";

    const label =
      collection_id === ""
        ? "No Story Block will display"
        : "Page will display: " + collection.internal_name;

    return (
      <Grid item xs={12}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={2}
        >
          <TextField
            size="small"
            type="text"
            value={url}
            onChange={(e) => onChange(e.target.value)}
            color={color}
            helperText={label}
            placeholder="https://example.com/landingPageFB?utm_source=facebook&utm_medium=social&utm_campaign=landingPage"
            focused={true}
            fullWidth
          />
          <IconButton onClick={onDelete} aria-label="delete" color="error">
            <RemoveCircleOutlineIcon />
          </IconButton>
        </Stack>
      </Grid>
    );
  },
  (prev, next) => {
    // Update if url, collection ID, or rulesets change
    return (
      prev.url === next.url &&
      prev.resultCollectionID === next.resultCollectionID &&
      rulesetsUtils.listEqual(prev.rulesets, next.rulesets)
    );
  }
);

export default PageTargetingURLTest;
