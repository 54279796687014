import React, { useState } from "react";
import map from "lodash/map";
import { Droppable, Draggable } from "react-beautiful-dnd";

import {
  Avatar,
  Grid,
  Dialog,
  Button,
  Typography,
  IconButton,
  Stack,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  DialogActions,
} from "@mui/material";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import AddBoxIcon from "@mui/icons-material/AddBox";

import ChapterEditor from "../../pages/story-blocks/chapters/ChapterEditor";
import ChapterEditTile from "../chapterEditor/ChapterEditTile";

import LoadingBackdrop from "../../components/LoadingBackdrop";
import StoryEditorForm from "../StoryEditor.jsx/StoryEditor";
import RightCloseIcon from "../assets/RightCloseIcon";
import { SlideUpFromBottom } from "../../utilities/transitions";

const CollectionEditorStory = ({
  story,
  isFirst,
  isLast,
  collection,
  onContentUpdated,
  isLoading,
  handleChapterMove,
  handleStoryMove,
  isDraggingOver,
}) => {
  const chapterCount = story.chapters?.length ?? 0;

  const [storyEditorModalOpen, setStoryEditorModalOpen] = useState(false);
  const [storyEditorModalStoryID, setStoryEditorModalStoryID] = useState(null);

  const [chapterEditModalOpen, setChapterEditModalOpen] = useState(false);
  const [chapterEditModalChapter, setChapterEditModalChapter] = useState(null);
  const [chapterEditModalIsNew, setChapterEditModalIsNew] = useState(false);
  const [
    chapterEditModalDestinationIndex,
    setChapterEditModalDestinationIndex,
  ] = useState(null);
  const addChapterAtIndex = (destinationIndex) => {
    setChapterEditModalChapter(null);
    setChapterEditModalIsNew(true);
    setChapterEditModalDestinationIndex(destinationIndex);
    setChapterEditModalOpen(true);
  };

  const [storyDeleteModalOpen, setStoryDeleteModalOpen] = useState(false);

  const setDragStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "rgb(238 248 238 / 76%)" : "transparent",
    display: "flex",
  });

  return (
    <Grid container spacing={1}>
      <LoadingBackdrop loading={isLoading} />

      {/* Chapter edit dialog */}
      <Dialog
        open={chapterEditModalOpen}
        onClose={() => setChapterEditModalOpen(false)}
        TransitionComponent={SlideUpFromBottom}
        fullWidth={true}
        maxWidth={"xl"}
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5">Edit Chapter</Typography>
          <RightCloseIcon onClick={() => setChapterEditModalOpen(false)} />
        </DialogTitle>
        <DialogContent>
          <ChapterEditor
            collectionID={collection.id}
            storyID={story.id}
            chapter={chapterEditModalChapter}
            isNew={chapterEditModalIsNew}
            destinationIndex={chapterEditModalDestinationIndex}
            onDone={() => {
              setChapterEditModalOpen(false);
              onContentUpdated();
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setChapterEditModalOpen(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Story edit dialog */}
      <Dialog
        open={storyEditorModalOpen}
        onClose={() => setStoryEditorModalOpen(false)}
        TransitionComponent={SlideUpFromBottom}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogContent>
          <StoryEditorForm
            collectionID={collection.id}
            storyID={storyEditorModalStoryID}
            isNew={false}
            setEditStoryModalOpen={setStoryEditorModalOpen}
            onDone={() => {
              setStoryEditorModalOpen(false);
              onContentUpdated();
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setStoryEditorModalOpen(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Grid item xs={8}>
        {/* Header on same line as image */}
        <Stack direction="row" spacing={2} justify="flex-end" align="center">
          <Avatar
            src={story.thumbnail_url}
            variant="circular"
            className="CollectionEditorStory-thumbnail"
            style={{
              border: "2px solid #ccc",
            }}
          />
          <Typography variant="h4">{story.thumbnail_text}</Typography>
          <Typography variant="overline">
            {story.type === "multimedia" && "Multimedia"}
            {story.type === "link" && "Link"}
          </Typography>
        </Stack>
      </Grid>

      <Grid
        item
        xs={4}
        sx={{
          // Align content to the right
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <IconButton
          onClick={() => {
            // open story modal
            setStoryEditorModalStoryID(story.id);
            setStoryEditorModalOpen(true);
          }}
          aria-label="Edit Story"
        >
          <EditIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            // open delete confirmation modal
            setStoryDeleteModalOpen(true);
          }}
          aria-label="Delete Story"
        >
          <DeleteIcon />
        </IconButton>
        <DeleteDialog
          open={storyDeleteModalOpen}
          onClose={() => setStoryDeleteModalOpen(false)}
          onDelete={() => {
            // open story delete modal
            handleStoryMove(story, "delete");
          }}
        />
        {/* Only display up button if this is not already the 1st story */}
        {!isFirst && (
          <IconButton
            onClick={() => {
              // move story up
              handleStoryMove(story, "up");
            }}
          >
            <ArrowUpwardIcon />
          </IconButton>
        )}
        {/* Only display down button if this is not already the last story */}
        {!isLast && (
          <IconButton
            onClick={() => {
              // move story down
              handleStoryMove(story, "down");
            }}
          >
            <ArrowDownwardIcon />
          </IconButton>
        )}
      </Grid>

      {/* Link type: Display the external link */}
      {story.type === "link" && (
        <Grid item xs={12}>
          <Typography variant="h6">
            Link:&nbsp;
            <a
              href={story.link_url}
              color="primary"
              style={{
                textDecoration: "none",
              }}
            >
              {story.link_url}
            </a>
          </Typography>
        </Grid>
      )}

      {/* Chapter list: Give a box that goes full width and allows horizontal scrolling */}
      {story.type === "multimedia" && (
        <Grid item xs={12} key={story.id}>
          <Droppable
            direction="horizontal"
            droppableId={story.id}
            key={story.id}
          >
            {(provided, snapshot) => {
              return (
                <Box
                  sx={{
                    overflowX: "auto",
                    overflowY: "hidden",
                    maxHeight: "100%",
                    width: "100%",
                  }}
                  ref={provided.innerRef}
                  style={setDragStyle(snapshot.isDraggingOver)}
                  {...provided.droppableProps}
                >
                  <Stack
                    sx={{
                      marginBottom: "10px",
                    }}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                  >
                    {/* If there are no chapters, give us a button to add our first one */}
                    {chapterCount === 0 && (
                      <Stack spacing={1} sx={{ pt: 1 }}>
                        <Typography variant="body1">
                          This story contains no chapters.
                        </Typography>
                        <Button
                          sx={{
                            opacity: isDraggingOver ? 0 : 1,
                          }}
                          type="button"
                          onClick={() => {
                            addChapterAtIndex(0);
                          }}
                          variant="outlined"
                          color="primary"
                          fullWidth={true}
                          startIcon={<AddIcon />}
                        >
                          Add Chapter
                        </Button>
                      </Stack>
                    )}

                    {map(story.chapters, (chapter, chapterIndex) => {
                      return (
                        <React.Fragment key={chapter.id}>
                          {/* Button with vertical text */}
                          <Button
                            className="ButtonVerticalText"
                            // add chapter at end of list
                            onClick={() => addChapterAtIndex(chapterIndex)}
                            startIcon={<AddBoxIcon />}
                            variant="outlined"
                            color="primary"
                            style={{
                              transform: "rotate(-90deg)",
                              marginRight: "-2.6rem",
                              marginLeft: "-2rem",
                              minWidth: "7rem",
                              opacity: isDraggingOver ? 0 : 1,
                            }}
                            size="small"
                          >
                            Chapter
                          </Button>
                          <Draggable
                            key={chapter.id}
                            draggableId={chapter.id}
                            index={chapterIndex}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                key={chapter.id}
                              >
                                <ChapterEditTile
                                  isFirst={chapterIndex === 0}
                                  isLast={chapterIndex === chapterCount - 1}
                                  onEdit={() => {
                                    // Open chapter editor
                                    setChapterEditModalChapter(chapter);
                                    setChapterEditModalIsNew(false);
                                    setChapterEditModalDestinationIndex(null);
                                    setChapterEditModalOpen(true);
                                  }}
                                  onDelete={() => {
                                    // delete chapter
                                    handleChapterMove(chapter, "delete", story);
                                  }}
                                  chapter={chapter}
                                />
                              </div>
                            )}
                          </Draggable>

                          {/* If this is the last chapter, then add an extra +1 chapter button */}
                          {chapterIndex === chapterCount - 1 && (
                            <Button
                              className="ButtonVerticalText"
                              // add chapter at end of list
                              onClick={() =>
                                addChapterAtIndex(chapterIndex + 1)
                              }
                              startIcon={<AddBoxIcon />}
                              variant="outlined"
                              color="primary"
                              style={{
                                transform: "rotate(-90deg)",
                                marginRight: "-2.6rem",
                                marginLeft: "-2rem",
                                minWidth: "7rem",
                                opacity: isDraggingOver ? 0 : 1,
                              }}
                              size="small"
                            >
                              Chapter
                            </Button>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </Stack>
                  {provided.placeholder}
                </Box>
              );
            }}
          </Droppable>
        </Grid>
      )}
    </Grid>
  );
};

export default CollectionEditorStory;

function DeleteDialog({ open, onClose, onDelete }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={SlideUpFromBottom}
      aria-labelledby="delete-dialog-title"
      aria-describedby="delete-dialog-description"
    >
      <DialogTitle id="delete-dialog-title">Delete this story?</DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-dialog-description">
          You will not be able to recover the stories or chapters after this!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button color="error" variant="contained" onClick={onDelete} autoFocus>
          Yes, Delete It
        </Button>
      </DialogActions>
    </Dialog>
  );
}
