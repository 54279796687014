import storage from "redux-persist/lib/storage";

import { adminSlice } from "../redux/slices/adminSlice";
import { assetSlice } from "../redux/slices/assetSlice";
import { authSlice } from "../redux/slices/authSlice";
import { ctaSlice } from "../redux/slices/ctaSlice";
import { pageTargetingSlice } from "../redux/slices/pageTargetingSlice";
import { settingsSlice } from "../redux/slices/settingsSlice";
import { shopifyDataSlice } from "../redux/slices/shopifyDataSlice";
import { storyBlockSlice } from "../redux/slices/storyBlockSlice";
import { viewSlice } from "../redux/slices/viewSlice";

export const persistConfig = {
  key: "root",
  version: 1,
  storage,
  migrate: () => {
    return Promise.resolve({
      admin: adminSlice.getInitialState(),
      assets: assetSlice.getInitialState(),
      auth: authSlice.getInitialState(),
      ctas: ctaSlice.getInitialState(),
      pageTargeting: pageTargetingSlice.getInitialState(),
      settings: settingsSlice.getInitialState(),
      storyBlocks: storyBlockSlice.getInitialState(),
      shopifyData: shopifyDataSlice.getInitialState(),
      view: viewSlice.getInitialState(),
    });
  },
};
