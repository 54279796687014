import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  authenticated: 0,
  status: "idle",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
});

export default authSlice.reducer;
