import { useReducer } from "react";

import { generateSetters, generateInitialState, reducer } from "./baseModel";

const settableKeys = {
  thumbnail: "thumbnail_url",
  name: "thumbnail_text",
  altText: "alt_text",
  bounce: "bounce",
  internalDescription: "internal_description",
  internalName: "internal_name",
  asset: "asset",
  type: "type",
  link_url: "link_url",
};

const initialState = {
  thumbnail_url: "",
  thumbnail_text: "",
  alt_text: "",
  bounce: false,
  internal_description: "",
  internal_name: "",
  type: "multimedia",
  link_url: "",
};

const useStoryDetails = (story) => {
  const [state, dispatch] = useReducer(
    reducer,
    generateInitialState({
      ...initialState,
      ...story,
    })
  );
  const setters = generateSetters(settableKeys, dispatch);

  const returnValue = {
    ...setters,
    ...state,
  };

  return returnValue;
};

export default useStoryDetails;
