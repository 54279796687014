import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";

import {
  usePageTargetingRulesets,
  invalidatePageTargetingCache,
} from "../api/fetchPageTargetingRulesets";

import Page from "../components/Page";

import Rulesets from "../modules/pageTargeting/Rulesets";
import PageTargetingURLTest from "../modules/pageTargeting/URLTest";
import { rulesetsUtils } from "../utilities/pageTargeting";
import { apiCall } from "../utilities/request";
import { accountURLs } from "../constants/apiURLs";
import { requestAccountID } from "../context/auth";
import PlanValidationStatus from "../components/PlanValidationStatus";
import PlanUpgradeBanner from "../components/PlanUpgradeBanner";

import { getRulesets } from "../redux/slices/pageTargetingSlice";

import {
  Grid,
  Alert,
  AlertTitle,
  Button,
  Tooltip,
  Typography,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";

const PageTargeting = () => {
  const {
    error,
    isLoading,
    rulesets: originalRulesets,
  } = usePageTargetingRulesets();

  const dispatch = useDispatch();

  useEffect(() => {
    // @todo move all rulesets state and api calls into redux
    dispatch(getRulesets());
  }, [dispatch]);

  const { enqueueSnackbar } = useSnackbar();

  const [updatedRulesets, setUpdatedRulesets] = useState(originalRulesets);

  useState(() => {
    if (originalRulesets) {
      setUpdatedRulesets(originalRulesets);
    }
  }, [originalRulesets]);

  const handleSave = () => {
    console.info("HandleSave");

    // save updated rulesets
    apiCall
      .put(accountURLs.pageTargeting(), {
        rulesets: updatedRulesets,
      })
      .then((res) => {
        console.log("Page targeting rulesets saved");

        // Invalidate cache
        invalidatePageTargetingCache().then(() => {
          console.log("cache invalidated successfully");
        });

        // Update the segment records for if this customer has rulesets or not
        const hasPageRules = updatedRulesets.length > 0;
        window.analytics.identify(requestAccountID(), {
          hasPageRules: hasPageRules,
        });
      })
      .catch((err) => {
        console.group("Error saving page targeting rulesets");
        console.error(err);
        console.groupEnd();

        // Get response code
        const responseCode = err.response.status;

        // Get error code from response body
        const errorCode = err.response.data?.error_code;

        console.log(err.response.data);

        let message = `Error saving page targeting rulesets: ${responseCode} -  ${errorCode} -- Contact Support with these codes`;
        switch (errorCode) {
          case 3008:
            message = "Every ruleset must have a Story Block selected";
            break;
          default:
            break;
        }

        // display error
        enqueueSnackbar(message, {
          variant: "error",
        });
      });
  };

  const [unsavedChanges, setUnsavedChanges] = useState(false);
  useEffect(() => {
    // Deep compare updatedRulesets and originalRulesets
    if (rulesetsUtils.listEqual(updatedRulesets, originalRulesets)) {
      setUnsavedChanges(false);
    } else {
      setUnsavedChanges(true);
    }
  }, [updatedRulesets, originalRulesets]);

  return (
    <Page title="Publish Rules" isLoading={isLoading}>
      <Grid container>
        <Grid item xs={12} md={12} style={{ position: "relative" }}>
          <PlanValidationStatus />
          <PlanUpgradeBanner />
          <Page.Title id="publish-rules-heading">Publish Rules</Page.Title>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              {!error && (
                <Rulesets
                  onChange={(rulesets) => {
                    setUpdatedRulesets(rulesets);
                  }}
                />
              )}
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" sx={{ marginBottom: "1rem" }}>
                Publish rules define which <strong>Kahani Story Block</strong>{" "}
                appears on each site page. Rules are processed from top to
                bottom. The first ruleset that matches defines which Story Block
                displays.
              </Typography>
              <Tooltip
                title={
                  unsavedChanges ? "Save changes" : "Make some changes first"
                }
                placement="top"
                arrow
              >
                <span>
                  <Button
                    variant="contained"
                    startIcon={<SaveIcon />}
                    onClick={handleSave}
                    fullWidth
                    disabled={!unsavedChanges}
                  >
                    Save Changes
                  </Button>
                </span>
              </Tooltip>
              <Typography
                variant="h5"
                sx={{
                  marginTop: "1rem",
                }}
                id="test-urls-heading"
              >
                Test URLs
              </Typography>

              <Typography
                variant="subtitle2"
                sx={{
                  marginBottom: "1rem",
                }}
              >
                Paste complete page URLs here to see which Story Block would
                display on that pge
              </Typography>

              <PageTargetingURLTest rulesets={updatedRulesets} />
            </Grid>
          </Grid>

          {error && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {error}
            </Alert>
          )}
        </Grid>
      </Grid>
    </Page>
  );
};

export default PageTargeting;
