import React from "react";

// State Management
import {
  hasOverrideAccountID,
  overrideAccountName,
  clearRequestAccountID,
} from "../context/auth";

// Styling
import { Alert, AlertTitle, Button, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

export default function AccountOverrideAlert() {
  const accountOverride = hasOverrideAccountID();

  return (
    <Alert severity={accountOverride ? "success" : "warning"}>
      <AlertTitle>Customer Selected:</AlertTitle>
      <Typography variant="body1">
        {accountOverride ? overrideAccountName() : "None selected yet"}
      </Typography>
      {accountOverride && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            clearRequestAccountID();
            window.location.href = "/admin";
          }}
          startIcon={<CancelIcon />}
          size="normal"
          style={{ margin: 30 }}
        >
          Log Out
        </Button>
      )}
    </Alert>
  );
}
