import React from "react";
import {useMicroshop} from "../../api/fetchMicroshopData";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Chip, Button, CircularProgress } from "@mui/material";
import { apiCall } from "../../utilities/request";
import { microshopURLs } from "../../constants/apiURLs";
import { useSnackbar } from "notistack";

const MicroshopPublishButton = ({ microshopID, creatingMicroshops }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { error, isLoading, microshop, refetch } = useMicroshop(microshopID);
  const published = microshop?.published;

  const [saving, setSaving] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeStatus = (newstatus) => {
    setAnchorEl(null);
    setSaving(true);
    apiCall
      .patch(microshopURLs.details(microshop.id), { published: newstatus })
      .then((response) => {
        enqueueSnackbar("Microshop status updated", { variant: "success" });
        refetch();
      })
      .catch((error) => {
        enqueueSnackbar("Error updating microshop status", {
          variant: "error",
        });
      })
      .finally(() => {
        setSaving(false);
      });
  };

  if (error) {
    return null;
  }

  if (isLoading) {
    return null;
  }

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        disabled={saving}
        startIcon={saving ? <CircularProgress size={20} /> : null}
      >
        {published && !creatingMicroshops ? (
          <Chip label="Published" color="success" sx={{ cursor: "pointer" }} />
        ) : (
          <Chip
            label="Unpublished"
            color="warning"
            variant="outlined"
            sx={{ cursor: "pointer" }}
          />
        )}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {published ? (
          <MenuItem
            disabled={saving}
            onClick={() => {
              changeStatus(false);
            }}
          >
            Unpublish
          </MenuItem>
        ) : (
          <MenuItem
            disabled={saving}
            onClick={() => {
              changeStatus(true);
            }}
          >
            Publish
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default MicroshopPublishButton;
