import React, { useMemo } from "react";
import {
  Alert,
  AlertTitle,
  Avatar,
  ButtonGroup,
  Card,
  CardActions,
  CardHeader,
  Grid,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import { useMicroshops } from "../../api/fetchMicroshopData";
// import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TimedProgressBar from "../../components/TimedProgressBar";
import LaunchIcon from "@mui/icons-material/Launch";
import MicroshopDeleteDialog from "./MicroshopDeleteDialog";
// import { useNavigate } from "react-router-dom";
import MicroshopPublishButton from "./MicroshopPublish";

export const MicroshopsList = ({creatingMicroshops}) => {
  const { error, isLoading, microshops } = useMicroshops();

  const sortedMicroshops = useMemo(() => microshops.sort((a, b) => {
    return b.created > a.created ? 1 : -1;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [JSON.stringify(microshops)]);

  // If error or loading do nothing
  if (error) {
    return (
      <Alert severity="error">
        <AlertTitle>Error loading Microshops</AlertTitle>
        {error}
      </Alert>
    );
  }

  if (isLoading) {
    return <TimedProgressBar duration={10} type="linear" />;
  }

  return (
    <>
      <Grid container spacing={3}>
        {sortedMicroshops.map((microshop) => (
          <Grid item xs={12} sm={6} md={4} lg={4} key={microshop.id} sx={{display: 'flex', alignItems: 'stretch'}}>
            <MicroshopListCard microshop={microshop} creatingMicroshops={creatingMicroshops} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

const MicroshopListCard = ({ creatingMicroshops, microshop }) => {
  // const navigate = useNavigate();
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

  const openDelete = () => {
    setDeleteDialogOpen(true);
  };

  // const screenshot =
  //   microshop?.screenshot_url ||
  //   "https://via.placeholder.com/100x177?text=No+Screenshot+Yet";

  const previewURL = microshop?.url;

  // Short URL that cuts off everything before "/pages"
  let shortURL = microshop?.url?.split("/pages")?.[1];
  if (shortURL?.startsWith("/")) {
    shortURL = "/pages" + shortURL;
  } else {
    // Just use the original
    shortURL = microshop?.url;
  }

  // Avatar URL if we have one
  const avatarURL = microshop?.influencer?.profile_picture_url || "";

  return (
    <Card sx={{height: '100%', width: '100%'}}>
      <MicroshopDeleteDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        microshop={microshop}
      />
      <CardHeader
        title={microshop.name}
        subheader={shortURL}
        avatar={<Avatar src={avatarURL} alt={microshop?.influencer?.name} />}
        action={
          <>
            <Tooltip
              title={microshop?.published ? "View Microshop" : "View Preview"}
              aria-label="view microshop"
              placement="top"
            >
              <IconButton
                aria-label="launch"
                onClick={(e) => {
                  e.preventDefault();
                  e.preventDefault();
                  window.open(previewURL, "_blank");
                }}
              >
                <LaunchIcon />
              </IconButton>
            </Tooltip>
          </>
        }
      />

      {/* <CardContent>
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="center"
        >
          <img
            src={screenshot}
            alt="Screenshot of the microshop"
            width={300 / 1.775}
            height={300}
            style={{
              borderRadius: 20,
              border: "1px solid #e0e0e0",
              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
            }}
          />
        </Stack>
      </CardContent> */}
      <CardActions>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <MicroshopPublishButton creatingMicroshops={creatingMicroshops} microshopID={microshop.id} />
          <ButtonGroup
            variant="outlined"
            aria-label="outlined primary button group"
          >
            {/* <IconButton
              aria-label="edit"
              onClick={() => {
                navigate(`/microshops/${microshop.id}`);
              }}
            >
              <EditIcon />
            </IconButton> */}
            <IconButton aria-label="delete" onClick={() => openDelete()}>
              <DeleteIcon />
            </IconButton>
          </ButtonGroup>
        </Stack>
      </CardActions>
    </Card>
  );
};
