import React from "react";

import {
  Box,
  Switch,
  Typography,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { accountURLs } from "../../constants/apiURLs";
import { apiCall } from "../../utilities/request";
import { invalidateAccountCache } from "../../api/fetchAccountData";
import { useAccount } from "../../../src/api/fetchAccountData";
import { useSnackbar } from "notistack";

export default function GA4OptInSwitch() {
  const { enqueueSnackbar } = useSnackbar();
  const {
    account: { ga4_opt_in },
  } = useAccount();

  // function to save changes
  const save = () => {
    apiCall
      .put(accountURLs.setGA4OptIn(), {
        ga4_opt_in: !ga4_opt_in,
      })
      .then((res) => {
        console.info("Saved GA4 opt in", res);
        console.debug(JSON.stringify(res));

        invalidateAccountCache();

        enqueueSnackbar("Saved GA4 opt in", {
          variant: "success",
        });
      })
      .catch((error) => {
        console.error("Error saving GA4 opt in");
        console.error(error.response);

        enqueueSnackbar("Error saving GA4 opt in", {
          variant: "error",
        });
      });
  };

  const message = ga4_opt_in
    ? "Using the Kahani GA4 integration, all events will automatically be added to the data layer on your site."
    : "Our GA4 integration is currently in beta. Contact us if you are  interested in switching from Universal Analytics to GA4.";

  return (
    <>
      <Typography variant="body1" gutterBottom>
        {message}
      </Typography>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FormGroup>
          <FormControlLabel
            value="ga4_opt_in"
            control={<Switch color="primary" />}
            label="GA4 Enabled"
            labelPlacement="start"
            checked={ga4_opt_in === true}
            onChange={(e) => {
              e.preventDefault();
              save();
            }}
            disabled={true}
          />
        </FormGroup>
      </Box>
    </>
  );
}
