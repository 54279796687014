import React, { useState } from "react";
import map from "lodash/map";
import { useSnackbar } from "notistack";
import { QRCodeSVG } from "qrcode.react";
import { DragDropContext } from "react-beautiful-dnd";

import {
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  Button,
  Grid,
  Stack,
  Popover,
  Typography,
  Paper,
  Link,
} from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import AddIcon from "@mui/icons-material/Add";
import PreviewIcon from "@mui/icons-material/Preview";

import CollectionEditorForm from "../collectionMetadataForm/CollectionMetadataForm";
import CollectionEditorStory from "./CollectionEditorStory";
import StoryEditorForm from "../StoryEditor.jsx/StoryEditor";

import { apiCall } from "../../utilities/request";
import { useLivePreviewHost } from "../../utilities/livePreview";

import {
  chapterURLs,
  collectionURLs,
  storyURLs,
} from "../../constants/apiURLs";

import { collectionHasChanged } from "../../api/fetchCollectionData";
import { SlideUpFromBottom } from "../../utilities/transitions";

const CollectionEditor = ({
  collection,
  setEditCollectionMetadataModalOpen,
  editCollectionMetadataModalOpen,
  setLivePreviewDialog,
  livePreviewDialog,
  setLivePreviewDialogAnchorEl,
  livePreviewDialogAnchorEl,
}) => {
  // @todo Can use params (useParams) or path (useMatch) to indicate if a specific chapter is open right now so we can jump to that dialog when we open the page
  const { enqueueSnackbar } = useSnackbar();

  const { stories } = collection;
  const storiesCount = stories ? stories.length : 0;
  const [isLoading, setIsLoading] = React.useState(false);

  const [isDraggingOver, setIsDraggingOver] = React.useState(false);

  // Story edit logic
  const [editStoryModalOpen, setEditStoryModalOpen] = useState(false);
  const [storyEditIndex, setStoryEditIndex] = useState(null);
  const addStoryAtIndex = (index) => {
    // Set index
    setStoryEditIndex(index);

    // Open modal
    setEditStoryModalOpen(true);
  };

  // Start the live preview host
  const previewHost = useLivePreviewHost(collection.id, collection.account_id);

  const updateLivePreview = () => {
    previewHost.triggerUpdate();
  };

  // One handler to rule them all for moving or deleting a story
  const handleStoryMove = (story, motion) => {
    console.debug("handleStoryMove", story, motion);
    let currentStoryIDs = collection.stories.map((story) => story.id);
    let newStoryIDs = [...currentStoryIDs];
    let currentIndex = currentStoryIDs.indexOf(story.id);
    switch (motion) {
      case "down":
        // Return immediately if we're already at the end
        if (currentStoryIDs.indexOf(story.id) === currentStoryIDs.length - 1)
          return;

        // Swap the current story with the next one
        let nextIndex = currentIndex + 1;
        let nextStoryID = currentStoryIDs[nextIndex];
        newStoryIDs[currentIndex] = nextStoryID;
        newStoryIDs[nextIndex] = story.id;

        break;
      case "up":
        // Return immediately if we're already at the beginning
        if (currentStoryIDs.indexOf(story.id) === 0) return;

        // Swap the current story with the previous one
        let previousIndex = currentIndex - 1;
        let previousStoryID = currentStoryIDs[previousIndex];
        newStoryIDs[currentIndex] = previousStoryID;
        newStoryIDs[previousIndex] = story.id;

        break;
      case "delete":
        // Remove the story from the collection
        newStoryIDs = currentStoryIDs.filter((storyID) => {
          return storyID !== story.id;
        });

        break;
      default:
        console.error(
          "You supplied an invalid motion to handleStoryMove:",
          motion
        );
        return;
    }

    // Update the collection stories
    setIsLoading(true);
    apiCall
      .put(collectionURLs.storyOrder(collection.id), {
        stories: newStoryIDs,
      })
      .then((res) => {
        console.info(res);

        // Invalidate the current collection list
        collectionHasChanged(collection.id);
        updateLivePreview();

        // If deleting, then also delete the story object
        if (motion === "delete") {
          setIsLoading(true);
          apiCall
            .delete(storyURLs.details(story.id))
            .then((res) => {
              console.info(res);

              // Notify success
              enqueueSnackbar("Deleted Story", {
                variant: "success",
              });
            })
            .catch((err) => {
              console.error(err);
              enqueueSnackbar("Could not delete story", {
                variant: "error",
              });
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar("Could not update story order", {
          variant: "error",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const reOrderChapter = async (story, newChapterIDs) => {
    return apiCall.put(storyURLs.chapterOrder(story.id), {
      chapters: newChapterIDs,
    });
  };
  // One handler to rule them all for moving or deleting a chapter
  const handleChapterMove = (chapter, motion, story, dragControl) => {
    console.debug("handleChapterMove", chapter, motion);
    let currentChapterIDs = story.chapters.map((chapter) => chapter.id);
    let newChapterIDs = [...currentChapterIDs];
    let sourceStoryChapterIds = [];

    switch (motion) {
      case "drag":
        const sourceIndex = dragControl.sourceIndex;
        const destinationIndex = dragControl.destinationIndex;
        sourceStoryChapterIds = [
          ...dragControl.sourceStory.chapters.map((chapter) => chapter.id),
        ];

        if (dragControl.destinationStoryId !== dragControl.sourceStoryId) {
          // Delete the chapter from it's original story
          sourceStoryChapterIds.splice(sourceIndex, 1);

          // Add the chapter to the destination story
          newChapterIDs.splice(destinationIndex, 0, dragControl.draggableId);
        } else {
          if (sourceIndex === destinationIndex) return;

          // Delete the chapterId with the dragged index
          newChapterIDs.splice(sourceIndex, 1);
          // Update the newChapterId array with the
          // the dragged chapterId
          newChapterIDs.splice(destinationIndex, 0, dragControl.draggableId);
        }
        setIsLoading(true);

        break;
      case "delete":
        // Remove the chapter from the story
        newChapterIDs = currentChapterIDs.filter((chapterID) => {
          return chapterID !== chapter.id;
        });

        break;
      default:
        console.error(
          "You supplied an invalid motion to handleChapterMove:",
          motion
        );
        return;
    }

    // Update the story chapters
    setIsLoading(true);

    reOrderChapter(story, newChapterIDs)
      .then(async (res) => {
        console.info(res);

        /**
         * If we drag a chapter to a different story
         */
        if (
          motion === "drag" &&
          dragControl.destinationStoryId !== dragControl.sourceStoryId
        ) {
          await reOrderChapter(dragControl.sourceStory, sourceStoryChapterIds);
          collectionHasChanged(collection.id);
          updateLivePreview();
        }
        // If deleting, then also delete the story object
        if (motion === "delete") {
          setIsLoading(true);
          apiCall
            .delete(chapterURLs.details(chapter.id))
            .then((res) => {
              console.info(res);

              // Notify success
              enqueueSnackbar("Deleted Chapter", {
                variant: "success",
              });
            })
            .catch((err) => {
              console.error("error deleting chapter");
              console.error(err);
              enqueueSnackbar("Could not delete chapter", {
                variant: "error",
              });
            })
            .finally(() => {
              setIsLoading(false);
            });
        } else {
          // Not deleting, so we're done!

          // Notify success
          enqueueSnackbar("Updated Chapter Order", {
            variant: "success",
          });
        }

        // Invalidate the current collection list
        collectionHasChanged(collection.id);
        updateLivePreview();
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar("Could not update chapter order", {
          variant: "error",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <DragDropContext
      onDragStart={() => setIsDraggingOver(true)}
      onDragEnd={(event) => {
        setIsDraggingOver(false);
        if (event.destination) {
          const destinationStory = stories.find(
            (story) => story.id === event.destination.droppableId
          );
          const sourceStory = stories.find(
            (story) => story.id === event.source?.droppableId
          );
          const draggedChapter = destinationStory.chapters[event.source.index];

          handleChapterMove(draggedChapter, "drag", destinationStory, {
            sourceIndex: event.source.index,
            destinationIndex: event.destination.index,
            draggableId: event.draggableId,
            destinationStoryId: event.destination.droppableId,
            sourceStoryId: event.source.droppableId,
            sourceStory,
          });
        }
      }}
    >
      <Grid
        container
        spacing={1}
        sx={{ margin: "auto", width: "-webkit-fill-available" }}
      >
        {/* Modal containing collection edit form */}
        <Dialog
          open={editCollectionMetadataModalOpen}
          onClose={() => setEditCollectionMetadataModalOpen(false)}
          TransitionComponent={SlideUpFromBottom}
          fullWidth
          maxWidth="md"
        >
          <DialogContent>
            <CollectionEditorForm
              collectionID={collection.id}
              onDone={() => {
                setEditCollectionMetadataModalOpen(false);
                updateLivePreview();
              }}
              isNew={false}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setEditCollectionMetadataModalOpen(false);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {/* Modal containing story edit form */}
        <Dialog
          open={editStoryModalOpen}
          fullWidth
          maxWidth="md"
          onClose={() => {
            setEditStoryModalOpen(false);
            updateLivePreview();
          }}
          TransitionComponent={SlideUpFromBottom}
        >
          <DialogContent>
            <StoryEditorForm
              collectionID={collection.id}
              destinationIndex={storyEditIndex}
              setEditStoryModalOpen={setEditStoryModalOpen}
              onDone={() => {
                setEditStoryModalOpen(false);
              }}
              isNew={true} // A different dialog is used to edit the story
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setEditStoryModalOpen(false);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          style={{
            width: "100%",
          }}
        >
          {storiesCount !== 0 && (
            <Button
              variant="outlined"
              color="primary"
              startIcon={<AddBoxIcon />}
              onClick={() => addStoryAtIndex(0)}
              style={{ marginBottom: "1rem" }}
            >
              Add story here
            </Button>
          )}
          <Stack spacing={2} direction="row" alignItems="center">
            <Popover
              open={livePreviewDialog}
              anchorEl={livePreviewDialogAnchorEl}
              onClose={() => {
                setLivePreviewDialog(false);
                setLivePreviewDialogAnchorEl(null);
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Paper
                style={{
                  padding: "1rem 2rem",
                  width: "100%",
                  maxWidth: "400px",
                }}
              >
                <Typography variant="h6" sx={{ pb: 2 }}>
                  Live Preview
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<PreviewIcon />}
                  fullWidth
                  onClick={() => {
                    // Open preview window in a new tab
                    previewHost.openPreview();

                    // Close the dialog
                    setLivePreviewDialog(false);
                    setLivePreviewDialogAnchorEl(null);
                  }}
                >
                  Open in new tab
                </Button>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1rem",
                  }}
                >
                  Or scan this code on your mobile device
                </Typography>
                {/* Placeholder image for QR code */}
                <QRCodeSVG
                  value={previewHost.previewURL()}
                  size={250}
                  includeMargin={true}
                  style={{
                    width: "100%",
                  }}
                />
              </Paper>
            </Popover>
          </Stack>
        </Stack>

        {/* If there are no stories display an alert */}
        {storiesCount === 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              margin: "24px auto",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "24px",
              }}
            >
              <img
                src="/img/empty_collections_icon.svg"
                alt="empty collections icon"
              />
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => addStoryAtIndex(0)}
                startIcon={<AddIcon />}
                id="create-collection-button"
              >
                Add the first story
              </Button>
            </div>
            <div style={{ color: "rgba(0, 0, 0, .54)" }}>
              <div>Looking for inspiration or guidance?</div>
              <div>
                Check out our expertly-curated{" "}
                <Link
                  target="_blank"
                  sx={{ fontWeight: "bold", textDecoration: "none" }}
                  href="https://help.kahaniapp.com/article/20-launching-your-first-stories"
                >
                  story best practices
                </Link>
              </div>
            </div>
          </div>
        )}

        {map(stories, (story, index) => {
          return (
            <Box
              key={story.id}
              style={{
                width: "100%",
                borderRadius: "0.5rem",
                marginBottom: "1rem",
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  borderRadius: "10px",
                  backgroundColor: "rgba(0,0,0,0.03)",
                  padding: "1rem",
                }}
              >
                <CollectionEditorStory
                  index={index}
                  isFirst={index === 0}
                  isLast={index === storiesCount - 1}
                  story={story}
                  collection={collection}
                  onContentUpdated={() => {
                    updateLivePreview();
                  }}
                  isLoading={isLoading}
                  handleStoryMove={handleStoryMove}
                  handleChapterMove={handleChapterMove}
                  isDraggingOver={isDraggingOver}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<AddBoxIcon />}
                  onClick={() => addStoryAtIndex(index + 1)}
                  style={{
                    marginTop: "1rem",
                  }}
                >
                  Add Story Here
                </Button>
              </Grid>
            </Box>
          );
        })}
      </Grid>
    </DragDropContext>
  );
};

export default CollectionEditor;
