import React from "react";
import classNames from "classnames";

import "./styles/Image.css";

const Image = ({ className, ratio, src }) => {
  return (
    <div
      className={classNames("Image", className)}
      style={{
        height: "100%",
        minHeight: "300px",
      }}
    >
      {ratio ? (
        <div
          className="Image__ratioSizer"
          style={{ paddingTop: `${ratio * 100}%` }}
        >
          <div className="img" style={{ backgroundImage: `url(${src})` }} />
        </div>
      ) : (
        <img src={src} alt="" />
      )}
    </div>
  );
};

export default Image;
