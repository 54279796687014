import React from "react";

import CodeEditor from "@uiw/react-textarea-code-editor";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { Button, Typography, Alert, Stack, Fade } from "@mui/material";
import copy from "copy-to-clipboard";
import { requestAccountID } from "../../context/auth";
const WidgetCode = () => {
  const snippet = `<div id="kahani-target"></div>

<script src="https://cdn.prd.kahaniapp.com/kahani/kahani.js" async></script>
<script>
    window.addEventListener('kahaniReady', function () {
        KahaniModule.init({
            account_id: "${requestAccountID()}",
            targetId: "kahani-target"
        })
    });
</script>`;

  const [justCopied, setJustCopied] = React.useState(false);
  // When justCopied goes true, set a timer to hide the dialog in 5 seconds
  React.useEffect(() => {
    if (justCopied) {
      const timer = setTimeout(() => {
        setJustCopied(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  });

  return (
    <>
      <Typography variant="h5">Kahani Snippet</Typography>
      <Typography variant="body1">
        Paste this snippet where you would like Kahani Story Blocks to appear in
        your theme. It may be included anywhere that accepts HTML, even outside
        of Shopify themes.
      </Typography>
      <CodeEditor
        language="html"
        padding={15}
        value={snippet}
        style={{
          fontSize: 12,
          backgroundColor: "#f5f5f5",
          fontFamily:
            "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
        }}
        readOnly
      />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        spacing={5}
        sx={{
          marginTop: "1rem",
        }}
      >
        <Button
          variant="contained"
          color="warning"
          onClick={() => {
            copy(snippet);
            setJustCopied(true);
          }}
          startIcon={<ContentPasteIcon />}
        >
          Copy to Clipboard
        </Button>
        <Fade
          in={justCopied}
          timeout={{
            appear: 300,
            enter: 300,
            exit: 1000,
          }}
        >
          <Alert severity="info">Snippet copied to clipboard</Alert>
        </Fade>
      </Stack>
    </>
  );
};

export default WidgetCode;
