import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { SlideUpFromBottom } from "../../utilities/transitions";
import { apiCall } from "../../utilities/request";
import { microshopURLs } from "../../constants/apiURLs";
import { useSnackbar } from "notistack";
import { useMicroshops } from "../../api/fetchMicroshopData";

const MicroshopDeleteDialog = ({ open, onClose, microshop }) => {
  const [deleting, setDeleting] = useState(false);
  const {refetch} = useMicroshops();

  const { enqueueSnackbar } = useSnackbar();
  const deleteMicroshop = () => {
    setDeleting(true);
    apiCall
      .delete(microshopURLs.details(microshop.id))
      .then((response) => {
        console.info("Delete microshop response OK:");
        console.info(response);

        // Notify
        enqueueSnackbar("Microshop deleted", {
          variant: "success",
        });

        // Update the microshops cache
        refetch();

        // Close the dialog
        onClose();
      })
      .catch((error) => {
        console.error("Delete microshop response ERROR:");
        console.error(error);

        // Notify
        enqueueSnackbar("Error deleting microshop", {
          variant: "error",
        });
      })
      .finally(() => {
        setDeleting(false);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={SlideUpFromBottom}
      aria-labelledby="delete-dialog-title"
      aria-describedby="delete-dialog-description"
    >
      <DialogTitle id="delete-dialog-title">
        Delete {microshop?.name}?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-dialog-description">
          <Typography variant="subtitle">{microshop?.url}</Typography>
          <br />
          <br />
          Are you sure you want to delete this microshop? This action cannot be
          undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={deleting} variant="outlined" onClick={onClose}>
          {" "}
          Cancel
        </Button>
        <Button
          disabled={deleting}
          variant="contained"
          color="error"
          onClick={deleteMicroshop}
        >
          {" "}
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MicroshopDeleteDialog;
