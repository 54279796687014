import { useQuery } from "react-query";

import queryClient from "../constants/queryClient";
import { accountURLs } from "../constants/apiURLs";
import { API_QUERY_KEYS } from "../constants/textMappings";
import request from "../utilities/request";

export const useKlaviyoLists = () => {
  const { data, ...meta } = useQuery(
    API_QUERY_KEYS.KLAVIYO_LISTS_REQUEST,
    () => {
      return request().get(accountURLs.klaviyoLists());
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    klaviyoLists: data?.lists ?? [],
    ...meta,
  };
};

export const invalidateKlaviyoCache = () => {
  queryClient.invalidateQueries(API_QUERY_KEYS.KLAVIYO_LISTS_REQUEST);
};