import { IconButton } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

function RightCloseIcon({ onClick }) {
  return (
    // Rotate icon 90° on hover

    <IconButton
      sx={{
        float: "right",
      }}
      onClick={onClick}
      aria-label="Close dialog"
    >
      <CloseIcon
        sx={{
          "&:hover": {
            transform: "rotate(90deg)",
            transition: "transform 0.2s ease-in-out",
          },
        }}
      />
    </IconButton>
  );
}

export default RightCloseIcon;
