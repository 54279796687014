import React from "react";

import {
  Typography,
  Tooltip,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Popper,
} from "@mui/material";

import ImageIcon from "@mui/icons-material/Image";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VideocamIcon from "@mui/icons-material/Videocam";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import SmartButtonIcon from "@mui/icons-material/SmartButton";
import { assetIsVideo, assetPreviewLink } from "../../api/fetchAssetData";
import { SlideUpFromBottom } from "../../utilities/transitions";

// @todo this design is complicated but could likely be simplified by using <Card /> instead
// https://mui.com/material-ui/react-card/

const ChapterEditTile = ({ chapter, onEdit, onDelete }) => {
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);

  const topGradient = `linear-gradient(to bottom, rgb(0 0 0 / 58%), rgb(25 25 25 / 68%))`;
  const previewImage = assetPreviewLink(chapter.asset);

  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  // Cut internal name ot 15 characters and add ellipsis
  let chapterName = chapter.internal_name;
  if (chapterName.length > 10) {
    chapterName = chapterName.substring(0, 10) + "...";
  }

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const hasCTA = chapter.ctas?.length > 0;
  return (
    <>
      {/* Delete confirmation dialog */}
      <DeleteDialog
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(false);
        }}
        onDelete={() => {
          setDeleteDialogOpen(false);
          onDelete();
        }}
      />
      <Card
        sx={{
          width: 180,
          height: 320,
          cursor: "pointer",
          // boxShadow: '5px 5px 5px 0px rgb(0 0 0 / 75%)'
        }}
      >
        <CardHeader
          sx={{
            background: open ? `${topGradient}` : "#fff",
            p: "10px 16px",
          }}
          avatar={
            <Avatar
              sx={{
                width: 30,
                height: 30,
              }}
            >
              {assetIsVideo(chapter.asset) ? (
                <Tooltip title="Video chapter" placement="top" arrow>
                  <VideocamIcon />
                </Tooltip>
              ) : (
                <Tooltip title="Photo chapter" placement="top" arrow>
                  <ImageIcon />
                </Tooltip>
              )}
            </Avatar>
          }
          action={
            <CardActions>
              <Tooltip title="More settings" placement="top" arrow>
                <div>
                  <Button
                    sx={{
                      padding: 0,
                      minWidth: 0,
                      color: "#000",
                    }}
                    ref={anchorRef}
                    onClick={handleToggle}
                  >
                    <MoreVertIcon />
                  </Button>
                  <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    placement="bottom-start"
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom-start"
                              ? "left top"
                              : "left bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              autoFocusItem={open}
                              id="composition-menu"
                              aria-labelledby="composition-button"
                              onKeyDown={handleListKeyDown}
                            >
                              <MenuItem onClick={() => onEdit()}>Edit</MenuItem>
                              <MenuItem
                                onClick={() => setDeleteDialogOpen(true)}
                              >
                                Delete
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </div>
              </Tooltip>
            </CardActions>
          }
        />

        <div
          onClick={onEdit}
          style={{
            backgroundImage: open
              ? `${topGradient}, url(${previewImage})`
              : `url(${previewImage})`,
            height: 222,
            width: 180,
            minWidth: 153,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            cursor: "pointer",
          }}
        ></div>
        <CardContent
          onClick={onEdit}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            p: "10px 16px",
          }}
        >
          <Typography variant="body6">{chapterName}</Typography>
          {hasCTA && (
            <Tooltip
              sx={{
                float: "right",
              }}
              title="This chapter has a CTA"
              placement="top"
              arrow
              enterDelay={500}
            >
              <SmartButtonIcon />
            </Tooltip>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default ChapterEditTile;

function DeleteDialog({ open, onClose, onDelete }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={SlideUpFromBottom}
      aria-labelledby="delete-dialog-title"
      aria-describedby="delete-dialog-description"
    >
      <DialogTitle id="delete-dialog-title">Delete this chapter?</DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-dialog-description">
          You will not be able to recover this chapter after it is deleted. This
          will not delete the media or actions associated with it.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          color="error"
          variant="contained"
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
        >
          Yes, Delete It
        </Button>
      </DialogActions>
    </Dialog>
  );
}
