import React, { useRef, useState } from "react";
import { Button, Grow, MenuList, MenuItem, Paper, Popper } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

export default function ChapterPopover({ onDeleteClick, onEditClick }) {
  const anchorRef = useRef(null);
  const [showOptionsMenu, setShowOptionsMenu] = useState(false);

  const handleToggle = () => setShowOptionsMenu((prevOpen) => !prevOpen);

  return (
    <div
      style={{
        position: "relative",
        zIndex: 10,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          height: "100%",
          width: "100%",
          position: "absolute",
        }}
      >
        <Button
          sx={{
            marginTop: "5px",
            marginRight: "0px",
            position: "absolute",
            "& .MuiSvgIcon-root": {
              height: "40px",
              width: "40px",
              color: "white",
            },
          }}
          ref={anchorRef}
          onClick={handleToggle}
        >
          <MoreHorizIcon 
            id="chapter-popover" 
            fontSize="medium" 
            style={{
              padding: 10 
            }}
          />
        </Button>
        <Popper
          open={showOptionsMenu}
          anchorEl={anchorRef.current}
          placement="bottom"
          transition
          disablePortal
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Paper>
                <MenuList autoFocusItem={showOptionsMenu} id="composition-menu">
                  <MenuItem onClick={onEditClick}>
                    Edit
                  </MenuItem>
                  <MenuItem disabled>Duplicate</MenuItem>
                  <MenuItem onClick={onDeleteClick}>
                    Delete
                  </MenuItem>
                </MenuList>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}
