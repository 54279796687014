import { useQuery } from "react-query";
import queryClient from "../constants/queryClient";
import { API_QUERY_KEYS } from "../constants/textMappings";

import { accountURLs, shopifyURLs } from "../constants/apiURLs";
import request from "../utilities/request";

export const queryKey = API_QUERY_KEYS.ACCOUNT_REQUEST;
export const detailsQueryKey = API_QUERY_KEYS.ACCOUNT_DETAILS_REQUEST;

export const useAccount = (accountID = "") => {
  const { data, ...meta } = useQuery(queryKey, () => {
    return request().get(accountURLs.account(accountID));
  });

  return {
    account: data?.account ?? [],
    ...meta,
  };
};

export const useAccountDetails = (accountID) => {
  const { data, ...meta } = useQuery(detailsQueryKey, () => {
    return request().get(accountURLs.account(accountID));
  });

  return {
    account: data?.account ?? [],
    ...meta,
  };
};

export const useAccountKVStore = () => {
  const { account, ...meta } = useAccount();

  return {
    kv_store: account?.kv_store ?? [],
    ...meta,
  };
};

export const invalidateAccountCache = () => {
  queryClient.invalidateQueries(queryKey);
};

export const useShopifyStore = () => {
  const { data, ...meta } = useQuery(
    API_QUERY_KEYS.SHOPIFY_STORE_REQUEST,
    () => {
      return request().get(shopifyURLs.shopifyStore());
    }
  );

  return {
    shopifyStore: data?.shopify_store ?? [],
    ...meta,
  };
};
