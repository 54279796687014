import { useQuery } from "react-query";

import queryClient from "../constants/queryClient";
import { adminURLs } from "../constants/apiURLs";
import { API_QUERY_KEYS } from "../constants/textMappings";
import request from "../utilities/request";

export const useAccounts = () => {
  const { data, ...meta } = useQuery(API_QUERY_KEYS.ACCOUNTS_REQUEST, () => {
    return request().get(adminURLs.list());
  });

  return {
    accounts: data?.accounts ?? [],
    ...meta,
  };
};

export const invalidateAccountsCache = () => {
  queryClient.invalidateQueries(API_QUERY_KEYS.ACCOUNTS_REQUEST);
};