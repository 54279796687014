// libraries
import React, { useState } from "react";

// UI components
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Grid, Divider } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

// app components
import AssetList from "../modules/assets/AssetList";
import Page from "../components/Page";
import PlanUpgradeBanner from "../components/PlanUpgradeBanner";
import CollectionList from "../modules/collections/CollectionList";

// helpers
import {
  chapterURLs,
  collectionURLs,
  ctaURLs,
  storyURLs,
} from "../constants/apiURLs";
import { apiCall } from "../utilities/request";
import { getUser } from "../utilities/parseCookie";
import { isValidUrl } from "../utilities/helpers";
// import shortenURL from "../utilities/shortenURL";
import { uploadAsset } from "../utilities/assets";
import { useAssets } from "../api/fetchAssetData";
import { useCollections } from "../api/fetchCollectionData";
import { hasOverrideAccountID } from "../context/auth";
const kahaniLogo = require("../assets/watermark.png");

const steps = [
  {
    id: 1,
    label: "How It Works",
    description: `Turn any media asset(s) you have into mobile-friendly story landing pages in seconds.`,
  },
  {
    id: 2,
    label: "Page Info",
    description: "Please complete all required fields below.",
  },
  {
    id: 3,
    label: "Media",
    description: `We recommend uploading at least 3 images or videos of your product. These assets will appear on your landing page as stories. Upload or choose from the media library below.`,
  },
];

const currentUser = getUser();
let acctId = currentUser?.id;

if (currentUser?.admin) {
  acctId = localStorage.getItem("apc_user_id");
}

const LandingPages = () => {
  const [activeStep, setActiveStep] = useState(0);
  // const [assets, setAssets] = useState([]);
  const [ctaUrl, setCTAUrl] = useState("");
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [landingPageUrl, setLandingPageUrl] = useState("");
  const [pageName, setPageName] = useState("");
  const [storyThumbnailId, setStoryThumbnailId] = useState("");
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [selectedAssetIDs, setSelectedAssetIDs] = useState([]);
  // const [uploadType, setUploadType] = useState("image");
  const userAssets = useAssets();

  const { collections, refetch } = useCollections();

  // When the selected assets changes, update the selected asset IDs
  React.useEffect(() => {
    if (!selectedAssets) return;
    const assetIDs = selectedAssets.map((asset) => asset.id);
    console.log("selectedAssets", selectedAssets);
    console.log("assetIDs", assetIDs);
    setSelectedAssetIDs(assetIDs);
  }, [selectedAssets]);

  /**
   * Uploads a default thumbnail
   */
  const uploadDefaultThumbnail = async () => {
    const base64Response = await fetch(`${kahaniLogo}`);
    const blob = await base64Response.blob();
    const file = new File([blob], "default thumbnail");

    uploadAsset({
      file: { file, internal_name: "default kahani thumbnail" },
      name: "kahani_logo",
      thumbnail: true,
      tags: [],
      onProcessingComplete: (res) => {
        setStoryThumbnailId(res.id);
      },
    });
  };

  /**
   * Reset LP builder form
   */
  const resetForm = () => {
    setActiveStep(0);
    setPageName("");
    setCTAUrl("");
    // setAssets([]);
    // setUploadType('image');
    setLandingPageUrl("");
  };

  /**
   * Creates a new landing page
   */
  const createLandingPage = async () => {
    // show loading indicator
    setIsLoading(true);

    // appending to CTA url for tracking
    const url = new URL(ctaUrl);
    const params = [
      { key: "utm_source", value: "kahani" },
      { key: "utm_medium", value: "lp-alpha" },
      { key: "utm_campaign", value: pageName },
    ];
    params.forEach((p) => url.searchParams.append(p.key, p.value));

    const newCTA = {
      discount_text: "",
      internal_description: "",
      internal_name: pageName,
      klaviyo_list_id: "",
      link_text: "Buy Now",
      link_url: url.href,
      type: "link",
    };

    // create new CTA
    const cta = await createCTA(newCTA);

    let createChapterPromises = [];
    let newChapters = [];

    // iterate through assets
    selectedAssets.forEach((asset) => {
      // for each asset, create a promise to create a chapter
      createChapterPromises.push(
        new Promise(async (resolve) => {
          let payload = {
            type: determineChapterType(asset.type),
            asset_id: asset.id,
            dwell: 7,
            internal_name: pageName,
            internal_description: "",
          };

          // create new chapter
          const newChapter = await createChapter(payload);

          newChapters.push(newChapter.data.id);

          payload.cta_id = cta.data.id;

          // set CTA on chapter
          await setCTA(newChapter.data.id, payload);
          resolve();
        })
      );
    });

    // run create chapter promises
    Promise.all(createChapterPromises).then(() => {
      let createStoryPromises = [];
      let newStories = [];

      // create one story
      createStoryPromises.push(
        new Promise(async (resolve) => {
          // create story
          const newStory = await createStory({
            thumbnail_text: pageName,
            internal_name: pageName,
            internal_description: "",
            alt_text: pageName,
            asset_id: storyThumbnailId,
            type: "multimedia",
            link_url: "",
          });

          newStories.push(newStory.data.id);
          resolve();
        })
      );

      // run create story promises
      Promise.all(createStoryPromises).then(() => {
        let setChaptersPromises = [];

        // iterate through new stories
        newStories.forEach((storyId, index) => {
          setChaptersPromises.push(
            new Promise(async (resolve) => {
              // set chapters
              await setChapters({
                storyId: storyId,
                chapters: newChapters,
              });

              resolve();
            })
          );
        });

        // run set chapter promises
        Promise.all(setChaptersPromises).then(async () => {
          const data = {
            internal_name: pageName,
            internal_description: "||LP||",
            custom_css: "",
            custom_js: "",
            style: {
              primary_color: "",
              accent_color_1: "",
              accent_color_2: "",
              heading_font: "",
              body_font: "",
            },
          };

          // create new collection
          const newCollection = await createCollection(data);

          // create tracking event
          window.analytics.track("collection-created", {
            name: pageName,
            id: newCollection.data.id,
            admin_action: hasOverrideAccountID(),
          });

          // set stories on collection
          await setStories({
            collectionId: newCollection.data.id,
            stories: newStories,
          });

          const url = `https://cdn.kahaniapp.com/landingpage.html?collectionID=${newCollection.data.id}&accountID=${acctId}`;
          // const shortenedUrl = await shortenURL(url);

          // update UI to show landing page URL
          setLandingPageUrl(url);

          // hide loading indicator
          setIsLoading(false);
          refetch();
          setSelectedAssets([]);
        });
      });
    });
  };

  /**
   * Determines chapter type
   * @param {string} assetType asset type
   */
  const determineChapterType = (assetType) => {
    switch (assetType) {
      case "image":
        return "photo";
      case "cloudflare_stream":
        return "cloudflare_stream";
      case "mux_stream":
        return "mux_stream";
      default:
        return "photo";
    }
  };

  /**
   * Throws an error, renders error UI
   * @param {object} err error info
   */
  const saveError = (err) => {
    console.error("Save failed");
    console.error(err.response?.data);
    console.error(err);

    setIsLoading(false);
    setError(err.response?.statusText);
    setError(err.response?.data?.error + err.response?.data?.details);
    throw new Error(err.response?.statusText);
  };

  /**
   * Creates a new CTA
   * @param {object} data CTA info
   */
  const createCTA = async (data) => {
    try {
      const newCTA = await apiCall.post(ctaURLs.list(), data);
      return newCTA;
    } catch (err) {
      saveError(err);
    }
  };

  /**
   * Sets a CTA on a chapter
   * @param {string} chapterId chapter id
   * @param {object} data CTA info
   */
  const setCTA = async (chapterId, data) => {
    try {
      await apiCall.put(chapterURLs.details(chapterId), data);
    } catch (err) {
      saveError(err);
    }
  };

  /**
   * Creats a new story
   * @param {object} data story info
   */
  const createStory = async (data) => {
    try {
      const newStory = await apiCall.post(storyURLs.list(), data);
      return newStory;
    } catch (err) {
      saveError(err);
    }
  };

  /**
   * Creats a new chapter
   * @param {object} data story info
   */
  const createChapter = async (data) => {
    try {
      const newChapter = await apiCall.post(chapterURLs.list(), data);
      return newChapter;
    } catch (err) {
      saveError(err);
    }
  };

  /**
   * Sets chapters on a story
   * @param {object} data chapters info
   */
  const setChapters = async (data) => {
    try {
      await apiCall.put(storyURLs.chapterOrder(data.storyId), {
        chapters: data.chapters,
      });
    } catch (err) {
      saveError(err);
    }
  };

  /**
   * Creats a new collection
   * @param {object} data collection info
   */
  const createCollection = async (data) => {
    try {
      const newCollection = await apiCall.post(collectionURLs.list(), data);
      return newCollection;
    } catch (err) {
      saveError(err);
    }
  };

  /**
   * Sets stories on a collection
   * @param {object} data collection info
   */
  const setStories = async (data) => {
    try {
      await apiCall.put(collectionURLs.storyOrder(data.collectionId), {
        stories: data.stories,
      });
    } catch (err) {
      saveError(err);
    }
  };

  /**
   * Handler for "Next" button in stepper
   */
  const handleNext = async () => {
    if (activeStep === 0) {
      // if user has assets {...}
      if (userAssets.assets.length > 0) {
        // iterate through assets
        userAssets.assets.forEach((asset, index) => {
          // if kahani logo is found {...}
          if (asset.internal_name === "kahani_logo") {
            // set story thumbnail
            setStoryThumbnailId(asset.id);
          }

          // if last iteration {...}
          if (index === userAssets.assets.length - 1) {
            // if no story thumbnail
            if (!storyThumbnailId) {
              // set story thumbnail
              uploadDefaultThumbnail();
            }
          }
        });
      } else {
        uploadDefaultThumbnail();
      }
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  /**
   * Handler for "Back" button in stepper
   */
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  /**
   * Determines if the "Next" button in the stepper should be disabled
   * @param {number} activeStep current active step
   */
  const disableButton = (activeStep) => {
    let disabled = false;

    if (activeStep === 1) {
      if (!pageName || !ctaUrl || (ctaUrl && !isValidUrl(ctaUrl))) {
        disabled = true;
      }
    }

    return disabled;
  };

  /**
   * Renders content for a step
   * @param {number} id step id
   */
  const renderStepContent = (id) => {
    switch (id) {
      case 2:
        return (
          <div style={{ marginTop: 15 }}>
            <h4 style={{ marginBottom: 0 }}>Name</h4>
            <p style={{ fontSize: 12 }}>
              The internal name of your landing page, typically relating to the
              product being sold - for example "Yeezy Slides - page 1"
            </p>
            <TextField
              fullWidth
              value={pageName}
              label='Landing Page Name (i.e "Super Shoe")'
              variant="outlined"
              onChange={(e) => setPageName(e.target.value)}
            />
            <h4 style={{ marginBottom: 0 }}>CTA Url</h4>
            <p style={{ fontSize: 12 }}>
              Add a call-to-action for your landing page, usually pointed
              towards a product page. For example
              "https://www.yoursite.com/product1"
            </p>
            <TextField
              fullWidth
              value={ctaUrl}
              label='CTA Url (i.e "https://www.yoursite.com/checkout")'
              variant="outlined"
              onChange={(e) => setCTAUrl(e.target.value)}
            />
          </div>
        );
      case 3:
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "20px",
            }}
          >
            <div style={{ overflow: "scroll", height: "750px" }}>
              <AssetList
                onSelect={(asset) => {
                  console.debug("selected asset", asset.internal_name);
                  if (!selectedAssets.includes(asset)) {
                    setSelectedAssets([...selectedAssets, asset]);
                  } else {
                    const removed = selectedAssets.filter((s) => s !== asset);
                    setSelectedAssets(removed);
                  }
                }}
                selectedAssetIDs={selectedAssetIDs}
                assets={userAssets.assets}
                uploaderAllowMultiple={true}
                filter={{
                  not_type: "thumbnails",
                }}
              />
            </div>
          </div>
        );
      default:
        return <div></div>;
    }
  };

  return (
    <Page title="Landing Pages" isLoading={isLoading}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <PlanUpgradeBanner />
          <Page.Title>Landing Page Builder</Page.Title>

          {error ? (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {error}
            </Alert>
          ) : (
            <div>
              {!landingPageUrl ? (
                <Stepper activeStep={activeStep} orientation="vertical">
                  {steps.map((step, index) => (
                    <Step key={step.label}>
                      <StepLabel
                        optional={
                          index === 2 ? (
                            <Typography variant="caption">Last step</Typography>
                          ) : null
                        }
                      >
                        {step.label}
                      </StepLabel>
                      <StepContent>
                        <Typography>{step.description}</Typography>
                        <div>
                          {step.id !== 3 ? renderStepContent(step.id) : null}
                        </div>
                        <Box sx={{ mb: 2 }}>
                          <div>
                            {index !== steps.length - 1 && (
                              <Button
                                disabled={disableButton(activeStep)}
                                variant="contained"
                                onClick={handleNext}
                                sx={{ mt: 1, mr: 1 }}
                              >
                                Continue
                              </Button>
                            )}
                            <Button
                              disabled={index === 0}
                              onClick={handleBack}
                              sx={{ mt: 1, mr: 1 }}
                            >
                              Back
                            </Button>
                            {step.id === 3 ? (
                              <Button
                                onClick={createLandingPage}
                                disabled={selectedAssets.length === 0}
                                variant="contained"
                                sx={{ mt: 1, mr: 1 }}
                                startIcon={<CheckIcon />}
                              >
                                Finish
                              </Button>
                            ) : null}
                          </div>
                        </Box>
                        <div>
                          {step.id === 3 ? renderStepContent(step.id) : null}
                        </div>
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: 25,
                    paddingBottom: 50,
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <h1>Good Job!</h1>
                    <p>
                      Your new landing page is live! Copy the url and paste it
                      into your browser.
                    </p>
                    <a
                      style={{
                        padding: 10,
                        borderWidth: 1,
                        borderColor: "#CCCCCC",
                        borderRadius: 5,
                      }}
                      target="_blank"
                      rel="noreferrer"
                      href={landingPageUrl}
                    >
                      {landingPageUrl}
                    </a>
                    <div>
                      <Button onClick={resetForm} sx={{ mt: 1, mr: 1 }}>
                        Reset
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          <Grid item xs={12}>
            <Divider
              sx={{
                borderStyle: "dashed",
                marginTop: 5,
                marginBottom: 1,
              }}
            />
            <Typography
              id="unpublished-title"
              variant="h6"
              color="primary"
              sx={{ marginBottom: 2 }}
            >
              Landing Pages
            </Typography>
          </Grid>
          <CollectionList
            collections={collections}
            collectionType="landing pages"
          />
        </Grid>
      </Grid>
    </Page>
  );
};

export default LandingPages;
