import React from "react";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import { Paper, Typography, InputAdornment, Stack, Box } from "@mui/material";
import { shopifyOAuthRedirect } from "../../utilities/shopifyKey";

const ShopifyLoginForm = () => {
  const [shopifyShop, setShopifyShop] = React.useState("");
  const [shopifyShopError, setShopifyShopError] = React.useState(null);
  function handleShopifySubmit(e) {
    e.preventDefault();
    setShopifyShopError(null);

    let shop = shopifyShop;

    // If empty, error
    if (shop.length === 0) {
      setShopifyShopError("You must enter your store URL");
      return;
    }

    // If name includes https or http, remove
    if (shop.includes("https://")) {
      shop = shop.replace("https://", "");
    } else if (shop.includes("http://")) {
      shop = shop.replace("http://", "");
    }

    // If name includes .myshopify.com, remove
    if (shop.includes(".myshopify.com")) {
      shop = shop.replace(".myshopify.com", "");
    }

    shopifyOAuthRedirect(shop + ".myshopify.com");
  }

  return (
    <Paper sx={{ p: 4, mt: 4 }}>
      <form onSubmit={handleShopifySubmit}>
        <Stack spacing={2}>
          <Typography variant="h5" color="primary">
            Log in with Shopify
          </Typography>
          <Typography variant="body1">
            To log in with your shopify account, enter the Kahani app through
            your Shopify Dashboard.
          </Typography>
          <Box sx={{ display: { xs: "none" } }}>
            <TextField
              fullWidth
              label="Shopify Store Name"
              value={shopifyShop}
              onChange={(e) => {
                setShopifyShop(e.target.value);
                setShopifyShopError(null);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">.myshopify.com</InputAdornment>
                ),
              }}
              helperText={
                shopifyShopError !== null
                  ? shopifyShopError
                  : "Enter the name of your Shopify store"
              }
              error={shopifyShopError !== null}
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              sx={{
                mt: 2,
              }}
            >
              Log In
            </Button>
          </Box>
        </Stack>
      </form>
    </Paper>
  );
};

export default ShopifyLoginForm;
