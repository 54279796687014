import React from "react";

import {
  Card,
  CardHeader,
  CardContent,
  Button,
  Avatar,
  IconButton,
  CardActions,
  Stack,
  Typography,
  Switch,
  FormGroup,
  FormControlLabel,
  Tooltip,
  Grid,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { usePageTargetingRulesets } from "../../api/fetchPageTargetingRulesets";
import AddIcon from "@mui/icons-material/Add";
import { blue } from "@mui/material/colors";
import DeleteIcon from "@mui/icons-material/Delete";

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CollectionDropdown from "../../components/CollectionDropdown";
import RuleList from "./Rules";
import { rulesetsUtils } from "../../utilities/pageTargeting";

const Rulesets = ({ onChange }) => {
  const { rulesets } = usePageTargetingRulesets();

  const [localRulesets, setLocalRulesets] = React.useState(rulesets);
  // When rulesets change, update localRulesets
  React.useEffect(() => {
    setLocalRulesets(rulesets);
  }, [rulesets]);

  // When local rulesets change, print
  React.useEffect(() => {
    onChange(localRulesets);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localRulesets]);

  const setPriorities = (rulesets) => {
    return rulesets.map((ruleset, index) => {
      const newRuleset = { ...ruleset };
      newRuleset.priority = index;
      return newRuleset;
    });
  };

  const handleRulesetAdd = (index) => {
    const newRuleset = {
      id: uuidv4(),
      rules: [],
      mode: "any",
      collection_id: "",
      active: true,
    };

    let newRulesets = [];

    if (index === 0) {
      newRulesets = [newRuleset, ...localRulesets];
    } else {
      newRulesets = [
        ...localRulesets.slice(0, index),
        newRuleset,
        ...localRulesets.slice(index),
      ];
    }

    // Set priorities
    setLocalRulesets(setPriorities(newRulesets));
  };

  const handleRulesetDelete = (index) => {
    let newRulesets = [...localRulesets];
    newRulesets.splice(index, 1);
    setLocalRulesets(setPriorities(newRulesets));
  };

  const handleRulesetChange = (newRuleset, index) => {
    // Make a local copy of that ruleset
    const localRuleset = { ...localRulesets[index] };
    // Update the local copy with the new rules
    localRuleset.rules = newRuleset.rules;
    localRuleset.mode = newRuleset.mode;
    localRuleset.collection_id = newRuleset.collection_id;

    // Update the local copy with the new ruleset
    // Note: don't need to set priorities because no order changed
    setLocalRulesets([
      ...localRulesets.slice(0, index),
      localRuleset,
      ...localRulesets.slice(index + 1),
    ]);
  };

  return (
    <>
      {/* One card for each ruleset */}
      {localRulesets.map((ruleset, i) => (
        <React.Fragment key={ruleset.id}>
          <RulesetAddButton
            label="before"
            index={i + 1}
            onClick={() => handleRulesetAdd(i)}
          />
          <RulesetCard
            ruleset={ruleset}
            isFirst={i === 0}
            isLast={i === localRulesets.length - 1}
            even={i % 2 === 0}
            onChange={(newRuleset) => handleRulesetChange(newRuleset, i)}
            onDelete={() => {
              handleRulesetDelete(i);
            }}
          />
        </React.Fragment>
      ))}

      {/* Button to add a new set */}
      <RulesetAddButton
        label="after"
        index={localRulesets.length}
        onClick={() => handleRulesetAdd(localRulesets.length)}
      />
    </>
  );
};

export default Rulesets;

const RulesetCard = React.memo(
  ({ ruleset, isFirst, isLast, even, onChange, onDelete }) => {
    return (
      <WrappedRulesetCard
        ruleset={ruleset}
        isFirst={isFirst}
        isLast={isLast}
        even={even}
        onChange={onChange}
        onDelete={onDelete}
      />
    );
  },
  (prev, next) => {
    const equal = rulesetsUtils.oneEqual(prev.ruleset, next.ruleset);

    // Compare rulesets
    // console.log("Should re-render: " + !equal);
    return equal;
  }
);

const WrappedRulesetCard = ({
  isFirst,
  isLast,
  ruleset,
  onChange,
  onDelete,
  even,
}) => {
  const [mode, setMode] = React.useState("any");
  const displayMode = (mode) => {
    switch (mode) {
      case "any":
        return "Any";
      case "all":
        return "All";
      default:
        return "Unknown";
    }
  };
  const displayModeTooltip = (mode) => {
    switch (mode) {
      case "any":
        return "Only one of the conditions must be met to display this Story Block";
      case "all":
        return "All of the conditions must be met to display this Story Block";
      default:
        return "Unknown";
    }
  };

  const displayPriority = isNaN(ruleset.priority) ? "?" : ruleset.priority + 1;

  // When mode changes, update parent
  React.useEffect(() => {
    const _ruleset = { ...ruleset };
    _ruleset.mode = mode;
    onChange(_ruleset);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  return (
    <Card
      sx={{
        marginBottom: "1rem",
      }}
      elevation={1}
    >
      <CardHeader
        avatar={
          <Avatar
            sx={{ bgcolor: blue[200], color: "black" }}
            aria-label={"Rule Set #" + displayPriority}
          >
            {displayPriority}
          </Avatar>
        }
        action={
          <IconButton
            aria-label="Delete ruleset"
            color="error"
            onClick={() => {
              // Remove this ruleset from the list
              onDelete(ruleset);
            }}
          >
            <DeleteIcon />
          </IconButton>
        }
        title={"Rule Set #" + displayPriority}
      />

      <CardContent
        sx={{
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <Typography
              variant="h6"
              sx={{
                textAlign: "right",
              }}
            >
              Use this collection:
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <CollectionDropdown
              onSelect={(collection) => {
                // Update this specific ruleset
                let newRuleset = { ...ruleset };
                newRuleset.collection_id = collection.id;

                onChange(newRuleset);
              }}
              selected={ruleset.collection_id}
              size="small"
            />
          </Grid>
        </Grid>
        <Typography variant="subtitle1" gutterBottom>
          When {mode} of the following conditions are met:
        </Typography>
        <RuleList
          rules={ruleset.rules}
          onChange={(rules) => {
            // Update this specific ruleset
            let newRuleset = { ...ruleset };
            newRuleset.rules = rules;
            onChange(newRuleset);
          }}
        />
      </CardContent>
      <CardActions sx={{ width: "100%" }}>
        {/* Center and space evenly the butons horizontally */}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{
            width: "100%",
          }}
        >
          {/* Dropdown to select mode */}
          <Tooltip
            title={displayModeTooltip(mode)}
            placement="top"
            arrow
            enterDelay={500}
            leaveDelay={500}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    value={mode === "any"}
                    onChange={(e) => {
                      setMode(e.target.checked ? "any" : "all");
                    }}
                    defaultChecked
                  />
                }
                label={"Match: " + displayMode(mode)}
              />
            </FormGroup>
          </Tooltip>
          <div>
            <IconButton
              aria-label="Move ruleset up"
              onClick={() => {
                // @todo Move the ruleset up
              }}
              color="primary"
              disabled={isFirst}
            >
              <ArrowUpwardIcon />
            </IconButton>
            <IconButton
              aria-label="Move ruleset down"
              onClick={() => {
                // @todo Move the ruleset down
              }}
              color="primary"
              disabled={isLast}
            >
              <ArrowDownwardIcon />
            </IconButton>
          </div>
        </Stack>
      </CardActions>
    </Card>
  );
};

const RulesetAddButton = ({ onClick, label, index }) => {
  let buttonLabel = "Insert Ruleset " + label + " " + index;
  if (label === "after" && index === 0) {
    buttonLabel = "Create first ruleset";
  }

  return (
    <Button
      variant="contained"
      color="secondary"
      startIcon={<AddIcon />}
      sx={{
        marginBottom: "1rem",
      }}
      onClick={onClick}
    >
      {buttonLabel}
    </Button>
  );
};
