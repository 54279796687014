// @todo get rid of the word cookie here and everywhere else

const parseCookie = () => {
  return {
    user: window.localStorage.getItem("kahani_account"),
    token: window.localStorage.getItem("kahani_token"),
    baseURL: window.localStorage.getItem("kahani_baseURL"),
  };
};

// Wrapper to get account ID from the cookie
const getAccountID = () => {
  const { user } = parseCookie();
  // If undefined, return null
  return user ? JSON.parse(user)?.id : null;
};

// Wrapper to get user from cookie
const getUser = () => {
  const { user } = parseCookie();
  // If undefined, return null
  return user ? JSON.parse(user) : null;
};

// Wrapper to get API URL from cookie
const getBaseURL = () => {
  const { baseURL } = parseCookie();
  // If undefined, return null
  return baseURL ? baseURL : null;
};

// Wrapper to set custom base URL
const setCustomBaseURL = (baseURL) => {
  console.info("set custom base URL: ", baseURL);
  // If the base URL is empty, clear the cookie
  if (baseURL === "" || baseURL === null) {
    console.info("Clear base url cookie");

    window.localStorage.removeItem("kahani_baseURL");
  } else {
    // Save
    window.localStorage.setItem("kahani_baseURL", baseURL);
  }
};

// Wrapper to get the auth token from the cookie
const getAuthToken = () => {
  const { token } = parseCookie();
  // If undefined, return null
  return token ? token : null;
};

// Log out function clears the cookies
const clearCookie = () => {
  // Clear the token and user cookies
  window.localStorage.removeItem("kahani_account");
  window.localStorage.removeItem("kahani_token");
};

// function to set the cookies after a user logs in
// They should never expire
const setCookie = (user, token) => {
  // Turn user into JSON
  const userJSON = JSON.stringify(user);

  document.cookie = `user=${userJSON}`;
  document.cookie = `token=${token}`;

  window.localStorage.setItem("kahani_account", userJSON);
  window.localStorage.setItem("kahani_token", token);
};

export {
  getAccountID,
  getBaseURL,
  getAuthToken,
  getUser,
  clearCookie,
  setCookie,
  setCustomBaseURL,
};
