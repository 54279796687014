import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useSnackbar } from "notistack";
import { Button, Grid, Paper, Stack, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import ChapterPopover from "../../../components/ChapterPopover";
import DeleteChapterModal from "./DeleteChapterModal";
import { invalidateCollectionCache, useChapters, useCollectionStyle, useStory } from "../../../api/fetchCollectionData";
import { reorderChapters } from "../../../redux/api/storyBlockAPI";
import { CreateChapterModal } from "./CreateChapterModal";
import { EditChapterModal } from "./EditChapterModal";

const style = {
  section: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 10,
    marginTop: 10,
  },
  chapterSection: {
    marginTop: 20,
  },
};

export default function ChaptersSection({storyBlockId, storyId, updateLivePreview}) {
  const { enqueueSnackbar } = useSnackbar();
  const [isDragging, setIsDragging] = useState(false);
  const [hover, setHover] = useState(null);
  const [selectedChapter, setSelectedChapter] = useState(null);
  const {style: storyBlockStyle} = useCollectionStyle(storyBlockId);
  const [showCreateChapterModal, setShowCreateChapterModal] = useState(false);
  const [showEditChapterModal, setShowEditChapterModal] = useState(false);
  const [showDeleteChapterModal, setShowDeleteChapterModal] = useState(false);
  const {story} = useStory(storyId);
  const {chapters} = useChapters(storyId);
  const [orderedChapters, setOrderedChapters] = useState(chapters);

  useEffect(() => {
    setOrderedChapters(chapters);
  }, [chapters]);

  const setDragStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "rgb(238 248 238 / 76%)" : "transparent",
    display: "flex",
  });

  const onReorder = async (event) => {
    setIsDragging(false);
    const startingChapterIndex = event.source.index;
    const endingChapterIndex = event.destination.index;
    const newChapters = [...chapters];

    // Chapters without chapter being moved
    newChapters.splice(startingChapterIndex, 1);

    // Chapters with moved chapter inserted into final position
    newChapters.splice(endingChapterIndex, 0, chapters[startingChapterIndex]);

    // Optimistically update the chapter order
    setOrderedChapters(newChapters);

    try {
      await reorderChapters({
        id: storyId,
        newChapterIds: newChapters.map((c) => c.id),
      });
      invalidateCollectionCache();
      updateLivePreview();
      enqueueSnackbar("Chapters reordered successfully!", {variant: "success"});
    } catch (error) {
      // Rollback the chapter order
      setOrderedChapters(chapters);
      enqueueSnackbar("Failed to reorder chapters!", {variant: "error"});
    }
  }

  if (!storyId) return null;

  if (story?.type === "link") {
    return (
      <>
        <Grid
          item
          xs={12}
          style={{ ...style.section, ...style.chapterSection }}
        >
          <Typography variant="h5">
            {story?.internal_name} Navigation Information
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper
            elevation={1}
            sx={{ p: 3, display: "flex", justifyContent: "center" }}
          >
            <Typography variant="h5">
              Link:{" "}
              <a
                href={story?.link_url}
                target="_blank"
                rel="noreferrer"
              >
                {story?.link_url}
              </a>
            </Typography>
          </Paper>
        </Grid>
      </>
    );
  }

  return (
    <>
      <Grid item xs={12} style={{ ...style.section, ...style.chapterSection }}>
        <Typography variant="h5">
          {story?.internal_name} Chapters
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Paper
          elevation={1}
          sx={{ p: 3, display: "flex", justifyContent: "center" }}
        >
          {!orderedChapters?.length && (
            <Button
              variant="outlined"
              onClick={() => setShowCreateChapterModal(true)}
              sx={{
                height: "400px",
                width: "225px",
                display: "flex",
                flexDirection: "column",
                borderRadius: "12px",
              }}
            >
              <AddIcon fontSize="large" />
              <>Add Chapter</>
            </Button>
          )}

          {orderedChapters?.length > 0 && (
            <DragDropContext
              onDragStart={() => setIsDragging(true)}
              onDragEnd={onReorder}
            >
              <Droppable direction="horizontal" droppableId="chapters">
                {(provided, snapshot) => (
                  <Stack
                    direction="row"
                    className="chapters"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={setDragStyle(snapshot.isDraggingOver)}
                    sx={{
                      overflowX: "auto",
                      overflowY: "hidden",
                      maxHeight: "100%",
                      width: "100%",
                    }}
                    spacing={1}
                  >
                    {orderedChapters?.map((c, index) => {
                      let url =
                        c.asset.thumbnail_url ??
                        c.asset.url ??
                        c.asset.image_original_url;
                      if (!url || url === "") {
                        url = c.asset.ImageOriginalURL;
                      }
                      if (!url || url === "" || url.includes("ucarecdn")) {
                        url = c.asset.ThumbnailURL;
                      }
                      return (
                        <Draggable key={c.id} draggableId={c.id} index={index}>
                          {(provided) => (
                            <div
                              key={c.id}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div
                                style={{
                                  height: "400px",
                                  width: "225px",
                                  border: "1px solid grey",
                                  borderRadius: "12px",
                                  background: `url(${url}) 50%/cover no-repeat`,
                                }}
                                onMouseEnter={() => setHover(c.id)}
                                onMouseLeave={() => setHover(null)}
                                onClick={(e) => {
                                  if(e.target.id !== 'chapter-popover') {
                                    setSelectedChapter(c);
                                    setShowEditChapterModal(true);
                                  }
                                }}
                              >
                                {hover === c.id && (
                                  <ChapterPopover
                                    storyBlockId={storyBlockId}
                                    storyId={storyId}
                                    chapter={c}
                                    onEditClick={() => {
                                      setSelectedChapter(c);
                                      setShowEditChapterModal(true);
                                    }}
                                    onDeleteClick={() => {
                                      setSelectedChapter(c);
                                      setShowDeleteChapterModal(true);
                                    }}
                                  />
                                )}
                                <div
                                  style={{
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "end",
                                    paddingBottom: "30px",
                                    backgroundColor:
                                      hover === c.id ? "rgba(0, 0, 0, .5)" : "",
                                    borderRadius: hover === c.id ? "12px" : "",
                                  }}
                                >
                                  {c.ctas.length ? (
                                    <div
                                      style={{
                                        color: "white",
                                        backgroundColor: storyBlockStyle.primary_color,
                                        padding: "5px 30px",
                                        borderRadius: "5px",
                                        filter:
                                          hover === c.id
                                            ? "brightness(50%)"
                                            : "",
                                      }}
                                    >
                                      {c.ctas[0].link_text}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    <Button
                      variant="outlined"
                      onClick={() => setShowCreateChapterModal(true)}
                      sx={{
                        height: "400px",
                        minWidth: "225px",
                        marginLeft: isDragging ? "242px !important" : "",
                        display: "flex",
                        flexDirection: "column",
                        borderRadius: "12px",
                      }}
                    >
                      <AddIcon fontSize="large" />
                      <>Add Chapter</>
                    </Button>
                    {provided.placeholder}
                  </Stack>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </Paper>
        <CreateChapterModal
          open={showCreateChapterModal}
          storyBlockId={storyBlockId}
          storyId={storyId}
          onClose={() => {
            setShowCreateChapterModal(false);
            updateLivePreview();
          }}
        />
        <EditChapterModal
          open={showEditChapterModal}
          storyBlockId={storyBlockId}
          storyId={storyId}
          chapter={selectedChapter}
          onClose={() => {
            setShowEditChapterModal(false);
            updateLivePreview();
          }}
        />
        <DeleteChapterModal
          open={showDeleteChapterModal}
          onClose={() => {
            setShowDeleteChapterModal(false);
            updateLivePreview();
          }}
          chapterId={selectedChapter?.id}
          storyId={storyId}
          storyBlockId={storyBlockId}
        />
      </Grid>
    </>
  );
}
