import React from "react";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";

import {
  useCollection,
  collectionHasChanged,
} from "../../api/fetchCollectionData";
import useCollectionDetails from "../../models/collectionDetails";

import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AlertTitle,
  Button,
  Grow,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Typography,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import DifferenceIcon from "@mui/icons-material/Difference";

import ColorInputField from "../../components/ColorInputField";
import { collectionURLs } from "../../constants/apiURLs";
import { apiCall } from "../../utilities/request";
import { hasOverrideAccountID } from "../../context/auth";
import { SlideUpFromBottom } from "../../utilities/transitions";

const CollectionEditorForm = ({ collectionID, onDone, isNew }) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  // Get existing collection data if we're editing an existing collection
  const { collection } = useCollection(collectionID);
  const details = useCollectionDetails(collection);
  const {
    internal_name,
    internal_description,
    custom_css,
    custom_js,
    style,
    id,
    setInternalName,
  } = details;

  // Set up local vars for the style
  const [primaryColor, setPrimaryColor] = React.useState(style.primary_color);
  const [accentColor1] = React.useState(style.accent_color_1);
  const [accentColor2] = React.useState(style.accent_color_2);
  const [headingFont] = React.useState(style.heading_font);
  const [bodyFont] = React.useState(style.body_font);

  // When style changes, update the local state
  // React.useEffect(() => {
  //   setPrimaryColor(style.primary_color);
  //   setAccentColor1(style.accent_color_1);
  //   setAccentColor2(style.accent_color_2);
  //   setHeadingFont(style.heading_font);
  //   setBodyFont(style.body_font);
  // }, [style]);

  const headerLabel = isNew ? "Create Story Block" : "Story Block Settings";

  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [duplicateDialogOpen, setDuplicateDialogOpen] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(false);
  const [error, _setError] = React.useState(null);
  const errorRef = React.useRef(null);

  const setError = (err) => {
    _setError(err);

    // Only do the rest if err not null
    if (err) {
      enqueueSnackbar("Error saving story", { variant: "error" });

      // Scroll to error alert message
      errorRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleSaveCollection = (e) => {
    // Stop default handler and prevent propagation
    e.preventDefault();
    e.stopPropagation();

    let data = {
      internal_name: internal_name,
      internal_description: internal_description,
      custom_css: custom_css,
      custom_js: custom_js,
      style: {
        primary_color: primaryColor,
        accent_color_1: accentColor1,
        accent_color_2: accentColor2,
        heading_font: headingFont,
        body_font: bodyFont,
      },
    };

    setError(null);

    // Validate form - Require name
    if (!data.internal_name) {
      setError("Please enter a name");
      return;
    }

    const saveSuccess = (res) => {
      console.info("Save successful");
      console.info(res);

      // invalidate the current collection list
      collectionHasChanged(collectionID);

      // Notify success
      enqueueSnackbar("Saved Story Block", {
        variant: "success",
      });

      // Report done if the function exists
      if (onDone) {
        onDone(res.data);
      }

      if (isNew) {
        window.analytics.track("collection-created", {
          name: internal_name,
          id: res.data.id,
          admin_action: hasOverrideAccountID(),
        });
      } else {
        window.analytics.track("collection-edited", {
          name: internal_name,
          id: res.data.id,
          admin_action: hasOverrideAccountID(),
        });
      }
    };

    const saveError = (err) => {
      console.error("Save failed");
      console.error(err.response?.data);
      console.error(err);
      setError(err.response?.statusText);
      setError(err.response?.data?.error + err.response?.data?.details);
    };

    // If new, then  create new collection otherwise use the collection ID page
    setIsLoading(true);
    if (isNew) {
      // Create a new collection
      apiCall
        .post(collectionURLs.list(), data)
        .then(saveSuccess)
        .catch(saveError)
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      // Update the collection
      apiCall
        .put(collectionURLs.details(id), data)
        .then(saveSuccess)
        .catch(saveError)
        .finally(() => {
          setIsLoading(false);
        });
    }
    return false;
  };

  return (
    <form
      onSubmit={handleSaveCollection}
      style={isNew ? { width: "50%", margin: "auto" } : {}}
    >
      {/* Modal containing delete confirmation */}
      <DeleteDialog
        open={deleteDialogOpen}
        onClose={() => {
          // Close the dialog without doing anything
          setDeleteDialogOpen(false);
        }}
        onDelete={() => {
          // run the delete API call
          setIsLoading(true);
          apiCall
            .delete(collectionURLs.details(collection.id))
            .then(() => {
              // invalidate the current collection list
              collectionHasChanged(collection.id);

              // Notify success
              enqueueSnackbar("Deleted Story Block", {
                variant: "success",
              });

              // Return to the collection list or landing pages list
              navigate(
                collection.internal_description === "||LP||"
                  ? "/landing_pages"
                  : "/story_blocks"
              );
            })
            .catch((err) => {
              // Notify failure
              enqueueSnackbar("Failed to delete collection", {
                variant: "error",
              });

              // Log the error
              console.error(err);

              // Close the dialog
              setDeleteDialogOpen(false);
            })
            .finally(() => {
              setIsLoading(false);
            });

          // Close the dialog
          setDeleteDialogOpen(false);
        }}
      />

      {/* Modal containing duplicate confirmation */}
      <DuplicateDialog
        open={duplicateDialogOpen}
        onClose={() => {
          // Close the dialog without doing anything
          setDuplicateDialogOpen(false);
        }}
        onDuplicate={(newName) => {
          // run the duplicate API call
          setIsLoading(true);
          apiCall
            .post(collectionURLs.duplicate(collection.id), {
              name: newName,
            })
            .then((res) => {
              // invalidate the current collection list
              collectionHasChanged(collection.id);

              // Notify success
              enqueueSnackbar(
                "Duplicated Story Block. Redirecting to the new copy...",
                {
                  variant: "success",
                }
              );

              // Go to the collections screen & focus on the new collection
              navigate("/story_blocks?focusCollectionID=" + res.data.id);
            })
            .catch((err) => {
              // Notify failure
              enqueueSnackbar("Failed to duplicate collection", {
                variant: "error",
              });

              // Log the error
              console.error(err);
            })
            .finally(() => {
              setIsLoading(false);
            });

          // Close the dialog
          setDuplicateDialogOpen(false);
        }}
      />

      <Typography
        variant="h5"
        id={isNew ? "new-collection-heading" : "edit-collection-heading"}
        style={{ marginTop: isNew ? "48px" : null }}
      >
        {headerLabel}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography sx={{ pt: 2, fontWeight: "bold" }} variant="h6">
            Name
          </Typography>
          <Typography variant="body2">
            Choose a name that sets this story block apart (e.g. the page where
            it will appear or the type of content it will display)
          </Typography>
          <TextField
            label="Story Block Name"
            value={internal_name}
            onChange={(e) => setInternalName(e.target.value)}
            fullWidth={true}
            margin="normal"
            required={true}
            placeholder="ex: Homepage, Landing page 3, Product Collection Page, ... "
            // Focus this on page load
            autoFocus={true}
          />

          {/* Only show the advanced fields when editing a collection, not when creating it */}
          {!isNew && (
            <>
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ pt: 2, fontWeight: "bold" }}>
                  Styles
                </Typography>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h6">Primary Color</Typography>
                  <Typography variant="body2">
                    This will be the color of CTA buttons and the story
                    thumbnail border
                  </Typography>
                  <ColorInputField
                    value={primaryColor}
                    onChange={setPrimaryColor}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ pt: 2, fontWeight: "bold" }}>
                  Duplicate Story Block
                </Typography>
                <Typography variant="body2">
                  Create a copy of this story block
                </Typography>
                <Button
                  variant="outlined"
                  color="warning"
                  startIcon={<DifferenceIcon />}
                  onClick={() => {
                    setDuplicateDialogOpen(true);
                  }}
                  size="medium"
                  sx={{ mt: 2 }}
                >
                  Duplicate Story Block
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ pt: 2, fontWeight: "bold" }}>
                  Delete Story Block
                </Typography>
                <Typography variant="body2">
                  Deleting a story block will remove all content associated with
                  it. <b>This can't be undone.</b>
                </Typography>
                <Button
                  variant="outlined"
                  color="error"
                  startIcon={<DeleteIcon />}
                  onClick={() => {
                    setDeleteDialogOpen(true);
                  }}
                  size="medium"
                  sx={{ mt: 2 }}
                >
                  Delete Story Block
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <LoadingButton
        loading={isLoading}
        color="primary"
        fullWidth={true}
        loadingPosition="start"
        variant="contained"
        type="submit"
        startIcon={<SaveIcon />}
        sx={{ mt: 2 }}
      >
        Save
      </LoadingButton>
      {error && (
        <Grow in={error ? true : false}>
          <Alert ref={errorRef} severity="error">
            <AlertTitle>Error</AlertTitle>
            {error}
          </Alert>
        </Grow>
      )}
    </form>
  );
};

export default CollectionEditorForm;

function DeleteDialog({ open, onClose, onDelete }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={SlideUpFromBottom}
      aria-labelledby="delete-dialog-title"
      aria-describedby="delete-dialog-description"
    >
      <DialogTitle id="delete-dialog-title">
        Delete this Story Block?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-dialog-description">
          You will not be able to recover the Story Block, stories, or chapters
          after this!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button color="error" variant="contained" onClick={onDelete}>
          Yes, Delete It
        </Button>
      </DialogActions>
    </Dialog>
  );
}

// Dialog for confirming a duplication request. The only field is for the new
// collection's name.
function DuplicateDialog({ open, onClose, onDuplicate }) {
  const [newName, setNewName] = React.useState("");
  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={SlideUpFromBottom}
      aria-labelledby="duplicate-dialog-title"
      aria-describedby="duplicate-dialog-description"
    >
      <DialogTitle id="duplicate-dialog-title">
        Duplicate this Story BLock?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="duplicate-dialog-description">
          You will be able to edit the new Story Block without affecting this
          one.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="New Story Block Name"
          type="text"
          fullWidth
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="warning"
          variant="contained"
          onClick={() => {
            onDuplicate(newName);
          }}
        >
          Yes, Duplicate It
        </Button>
      </DialogActions>
    </Dialog>
  );
}
