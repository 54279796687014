import React, { useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  DialogTitle,
  DialogContent,
  Dialog,
  Typography,
  Button,
  Stack,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SaveIcon from "@mui/icons-material/Save";

import { SlideUpFromBottom } from "../../utilities/transitions";
import RightCloseIcon from "../assets/RightCloseIcon";
import { Product, ShopifyProductSelector } from "../shopify/ProductSelector";
import TimedProgressBar from "../../components/TimedProgressBar";
import { InfluencerList } from "../influencers/InfluencerList";
import { OptionalAddons } from "./optional-addons/OptionalAddons";
import { CreateMicroshopPayload } from "../../pages/microshops/Microshops";

export enum Recipes {
  INFLUENCER_PDP = 'influencer_pdp',
  INFLUENCER_CURATED_SHOP = 'influencer_curated_shop',
  INFLUENCER_CURATED_SHOP_WITH_FEATURED_PRODUCT = 'influencer_curated_shop_with_featured_product',
  INFLUENCER_PDP_WITH_VERTICAL_VIDEO = 'influencer_pdp_with_vertical_video',
  INFLUENCER_CURATED_SHOP_WITH_VERTICAL_VIDEO = 'influencer_curated_shop_with_vertical_video',
}

const recipeTitles: Record<Recipes, string> = {
  [Recipes.INFLUENCER_PDP]: 'PDP',
  [Recipes.INFLUENCER_CURATED_SHOP]: 'Curated Shop',
  [Recipes.INFLUENCER_CURATED_SHOP_WITH_FEATURED_PRODUCT]: 'Curated Shop w/ Featured Product',
  [Recipes.INFLUENCER_PDP_WITH_VERTICAL_VIDEO]: 'PDP w/ Vertical Video',
  [Recipes.INFLUENCER_CURATED_SHOP_WITH_VERTICAL_VIDEO]: 'Curated Shop w/ Vertical Video',
}

const recipeShortCodes: Record<Recipes, string> = {
  [Recipes.INFLUENCER_PDP]: 'pdp',
  [Recipes.INFLUENCER_CURATED_SHOP]: 'cs',
  [Recipes.INFLUENCER_CURATED_SHOP_WITH_FEATURED_PRODUCT]: 'csfp',
  [Recipes.INFLUENCER_PDP_WITH_VERTICAL_VIDEO]: 'pdpvv',
  [Recipes.INFLUENCER_CURATED_SHOP_WITH_VERTICAL_VIDEO]: 'csvv',
}

type Influencer = {
  id: string;
  name: string;
}

export type Asset = {
  id: string;
  internal_name: string;
}

type Props = {
  open: boolean;
  onClose: () => void;
  onCreateMicroshops: (microshopPayloads: CreateMicroshopPayload[]) => void;
};

export const MicroshopCreate = ({ open, onClose, onCreateMicroshops }: Props) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [shopifyProducts, setShopifyProducts] = React.useState<Product[]>([]);
  const [influencer, setInfluencer] = React.useState<Influencer>();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [discountCode, setDiscountCode] = useState<string>("");
  const [enableShopPay, setEnableShopPay] = useState<boolean>(false);
  const [enableTimerBar, setEnableTimerBar] = useState<boolean>(false);
  const [featuredProductHandle, setFeaturedProductHandle] = useState<Product['handle']>('');
  const [collectionsVideoAsset, setCollectionsVideoAsset] = useState<Asset>();
  const steps = ["Influencer", "Products", "Optional Addons"];

  const resetAll = () => {
    setActiveStep(0);
    setShopifyProducts([]);
    setInfluencer(undefined);
    setDiscountCode("");
    setEnableShopPay(false);
    setEnableTimerBar(false);
    setFeaturedProductHandle('');
    setCollectionsVideoAsset(undefined);
    setIsSubmitting(false);
  }

  // Form data validator
  const stepValid = (step: any) => {
    switch (step) {
      case 0:
        // Must have an influencer selected
        return !!influencer?.id;
      case 1:
        // Must have at least one product selected
        return shopifyProducts.length > 0;
      case 2:
        // No optional addons required
        return true;
      default:
        return false;
    }
  };
  
  // Stepper navigation
  const handleNext = () => {
    // Validate before continuing
    if (!stepValid(activeStep)) {
      return;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // After the last step, trigger the save function
  React.useEffect(() => {
    if (activeStep === steps.length) {
      save();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  const formatMicroshopPayload = (
    recipe: Recipes,
    randomId: string,
    partialPayload: Omit<CreateMicroshopPayload, 'name' | 'handle' | 'recipe'>
  ): CreateMicroshopPayload => {
    return {
      ...partialPayload,
      recipe,
      name: `${influencer?.name ?? 'Microshop'} (${recipeTitles[recipe]})`,
      handle: `${influencer?.name?.toLowerCase()?.split(' ')?.[0] ?? 'influencer'}-${recipeShortCodes[recipe]}-${randomId}`,
    };
  }

  const save = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    const randomId = uuidv4().substring(0, 3).toLowerCase();
    const partialPayload: Omit<CreateMicroshopPayload, 'name' | 'handle' | 'recipe'> = {
      influencer_id: influencer?.id || "",
      shopify_product_ids: shopifyProducts.map((product) => product.id),
      ...(discountCode ? { discount_code: discountCode } : {}),
      ...(featuredProductHandle ? { featured_product_id: featuredProductHandle } : {}),
      ...(collectionsVideoAsset ? { featured_asset: collectionsVideoAsset.id } : {}),
      attributes: {
        ...(enableShopPay ? { enable_shop_pay: enableShopPay } : {}),
        ...(enableTimerBar ? { enable_timer_bar: enableTimerBar } : {}),
      }
    }
    const createPayloads: CreateMicroshopPayload[] = [];

    // Influencer PDP
    if (partialPayload.featured_product_id) {
      createPayloads.push(formatMicroshopPayload(Recipes.INFLUENCER_PDP, randomId, partialPayload));
    }

    // Influencer Curated Shop
    if (true) {
      createPayloads.push(formatMicroshopPayload(Recipes.INFLUENCER_CURATED_SHOP, randomId, partialPayload));
    }

    // Influencer Curated Shop with Featured Product
    if (partialPayload.featured_product_id) {
      createPayloads.push(formatMicroshopPayload(Recipes.INFLUENCER_CURATED_SHOP_WITH_FEATURED_PRODUCT, randomId, partialPayload));
    }

    // Influencer PDP with Vertical Video
    if (
      partialPayload.featured_product_id && (
        partialPayload.featured_asset || partialPayload.featured_product_assets?.[partialPayload.featured_product_id]
      )) {
      createPayloads.push(formatMicroshopPayload(Recipes.INFLUENCER_PDP_WITH_VERTICAL_VIDEO, randomId, partialPayload));
    }

    // Influencer Curated Shop with Vertical Video
    if (partialPayload.featured_asset) {
      createPayloads.push(formatMicroshopPayload(Recipes.INFLUENCER_CURATED_SHOP_WITH_VERTICAL_VIDEO, randomId, partialPayload));
    }

    onCreateMicroshops(createPayloads);
    resetAll();
  };

  // Determine what the next step button text should be
  const nextStepButtonText = () => {
    if (activeStep === steps.length - 1) {
      return "Save";
    }

    return "Next";
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xl"
      fullWidth
      TransitionComponent={SlideUpFromBottom as any}
    >
      <DialogTitle>
        Create a new microshop
        <RightCloseIcon onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <Box sx={{ width: "100%", height: "80vh" }}>
          {/* Wizard header */}
          <Stepper
            activeStep={activeStep}
            sx={{
              mb: 2,
            }}
          >
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step sx={{}} key={label} {...stepProps}>
                  <StepLabel {...labelProps} sx={{fontWeight: activeStep === index ? 'bold' : 'regular'}}>
                    {label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === steps.length ? (
            <>
              <TimedProgressBar duration={60} type="linear" />
              <div className="mt-4 text-center">This may take up to a minute to complete</div>
            </>
          ) : (
            <>
              {/* Begin wizard box content */}

              {/* Step 1: Influencer */}
              {activeStep === 0 && (
                <Box>
                  <Typography variant="body2" gutterBottom sx={{mt: 3, mb: 4, textAlign: 'center', fontWeight: 'semibold', fontSize: '18px'}}>
                    Select the influencer you want to link to this microshop
                  </Typography>
                  <InfluencerList
                    onSelect={(influencer: Influencer) => {
                      setInfluencer(influencer);
                    }}
                    mode="select"
                    initialSelectedID={influencer?.id}
                  />
                </Box>
              )}

              {/* Step 2: Products */}
              {activeStep === 1 && (
                <Box>
                  <Typography variant="body2" gutterBottom sx={{mt: 3, mb: 4, textAlign: 'center', fontWeight: 'semibold', fontSize: '18px'}}>
                    Select the products you want to display on this microshop
                  </Typography>
                  <ShopifyProductSelector
                    onChange={setShopifyProducts}
                    initialSelectedProductIds={shopifyProducts.map((product) => product.id)}
                  />
                </Box>
              )}

              {/* Step 3: Optional Addons */}
              {activeStep === 2 && (
                <Box>
                  <Typography variant="body2" gutterBottom sx={{mt: 3, mb: 4, textAlign: 'center', fontWeight: 'semibold', fontSize: '18px'}}>
                    Select any addons you'd like to enable for this microshop
                  </Typography>
                  <OptionalAddons
                    discountCode={discountCode}
                    setDiscountCode={setDiscountCode}
                    enableShopPay={enableShopPay}
                    setEnableShopPay={setEnableShopPay}
                    enableTimerBar={enableTimerBar}
                    setEnableTimerBar={setEnableTimerBar}
                    featuredProductHandle={featuredProductHandle}
                    setFeaturedProductHandle={setFeaturedProductHandle}
                    selectedProducts={shopifyProducts}
                    collectionsVideoAsset={collectionsVideoAsset}
                    setCollectionsVideoAsset={setCollectionsVideoAsset}
                  />
                </Box>
              )}

              {/* End wizard box content */}

              {/* Wizard forward/back navigation */}
              <Stack
                direction="row"
                spacing={2}
                justifyContent="space-between"
                sx={{
                  mt: 2,
                  position: "fixed",
                  bottom: 32,
                  left: 0,
                  width: "95%",
                  transform: "translateX(2.5%)",
                  zIndex: 100,
                  padding: '0 32px',
                  mb: 2,
                }}
              >
                {activeStep > 0 ? (
                  <Button
                    color="warning"
                    disabled={activeStep === 0 || isSubmitting}
                    onClick={handleBack}
                    variant="outlined"
                    startIcon={<ChevronLeftIcon />}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                ) : <span/>}
                <Button
                  variant="contained"
                  disabled={isSubmitting || !stepValid(activeStep)}
                  endIcon={
                    activeStep === steps.length - 1 ? (
                      <SaveIcon />
                    ) : (
                      <ChevronRightIcon />
                    )
                  }
                  onClick={handleNext}
                >
                  {nextStepButtonText()}
                </Button>
              </Stack>
            </>
          )}
      </Box>
      </DialogContent>
    </Dialog>
  );
};