import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { usePageTargetingRulesets } from "../../api/fetchPageTargetingRulesets";

import {
  Alert,
  AlertTitle,
  Button,
  Divider,
  Paper,
  Popover,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckIcon from "@mui/icons-material/Check";

/*
  This module should embed on a collection page to let you know that it is, or is not being used by a ruleset.
*/

export default function PageTargetingCollectionNotice({ collectionID }) {
  const { rulesets, isLoading } = usePageTargetingRulesets();

  // Null if not used, otherwise contains a copy of the ruleset that matches
  const [inUse, setInUse] = useState(false);
  const [detailsExpanded, setDetailsExpanded] = useState(false);
  const [detailsExpandedAnchor, setDetailsExpandedAnchor] = useState(null);

  // When ruleSets or collection ID change, update inUse
  useEffect(() => {
    if (!collectionID || !rulesets) return;

    const matchingRuleSet = rulesets.find(
      (r) => r.collection_id === collectionID
    );
    setInUse(matchingRuleSet);
  }, [collectionID, rulesets]);

  const collectionStatus = (inUse, isLoading) => {
    if (isLoading) return "Checking if collection is in use...";
    if (inUse)
      return (
        <>
          <CheckIcon sx={{ marginRight: "5px" }} /> Published
        </>
      );
    return "Not published yet";
  };

  return (
    <>
      <Button
        variant={inUse ? "contained" : "outlined"}
        onClick={(e) => {
          setDetailsExpanded(!detailsExpanded);
          setDetailsExpandedAnchor(e.currentTarget);
        }}
        endIcon={
          <ExpandMoreIcon
            sx={{
              transform: detailsExpanded ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.2s",
            }}
          />
        }
        sx={{ whiteSpace: "nowrap" }}
      >
        {collectionStatus(inUse, isLoading)}
      </Button>
      <Popover
        open={detailsExpanded}
        anchorEl={detailsExpandedAnchor}
        onClose={() => {
          setDetailsExpanded(false);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <HelperText inUse={inUse} />
      </Popover>
    </>
  );
}

const HelperText = ({ inUse }) => {
  const navigate = useNavigate();
  const message = inUse
    ? "Because this Story Block is published, it may be displayed on your website."
    : "Because this Story Block is not published it will not be displayed on your website.";

  return (
    <Paper
      style={{
        padding: "1rem",
        width: "100%",
        maxWidth: "400px",
      }}
    >
      {message}
      <Divider
        sx={{
          marginTop: "1rem",
          marginBottom: "1rem",
        }}
      />
      <Alert severity="info">
        <AlertTitle>Note</AlertTitle>
        Story Blocks are cached on our global CDN for maximum performance.
        Changes may take up to 10 minutes to be visible. Clear your browser
        cache if you are still not seeing them after that time.
      </Alert>
      <Divider
        sx={{
          marginTop: "1rem",
        }}
      />
      <Button
        variant="text"
        onClick={() => {
          navigate("/publish");
        }}
      >
        Click here
      </Button>{" "}
      to change the publish status for this Story Block.
    </Paper>
  );
};
