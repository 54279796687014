import React from "react";
import { useSnackbar } from "notistack";
import { apiCall } from "../../utilities/request";
import { accountURLs } from "../../constants/apiURLs";
import {
  Avatar,
  Dialog,
  AlertTitle,
  DialogTitle,
  DialogContent,
  Box,
  Button,
  Grid,
  Stack,
  Grow,
  Alert,
  CircularProgress,
  LinearProgress,
} from "@mui/material";
import { SlideUpFromBottom } from "../../utilities/transitions";
import RightCloseIcon from "../assets/RightCloseIcon";
import SaveIcon from "@mui/icons-material/Save";
import { formatBytes } from "../../utilities/helpers";
import { invalidateAccountCache, useAccount } from "../../api/fetchAccountData";

const Form = ({ onSuccess, onFailure }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [submitProgress, setSubmitProgress] = React.useState(0);
  const [submitProgressBuffer, setSubmitProgressBuffer] = React.useState(5);
  React.useEffect(() => {
    if (submitProgress > 100) {
      setSubmitProgressBuffer(10);
    } else {
      const diff = Math.random() * 6;
      const diff2 = Math.random() * 8;
      setSubmitProgressBuffer(submitProgress + diff + diff2);
    }
  }, [submitProgress]);

  // Updatge the buffer every second while uploadProgress > 0
  // @todo @bug this re-renders the whole thing every second??? Also in the influencer upload form too
  React.useEffect(() => {
    const timer = setInterval(() => {
      if (submitProgress <= 0 && submitProgress >= 100) {
        clearInterval(timer);
      } else {
        const diff = Math.random() * 6;
        const diff2 = Math.random() * 8;
        setSubmitProgressBuffer(submitProgress + diff + diff2);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [submitProgress]);

  const [pictureURL, setPictureURL] = React.useState("");
  const [pictureFile, setPictureFile] = React.useState(null);

  // Print picture url on change
  React.useEffect(() => {
    console.group("AccountForm.pictureURL");
    console.info("pictureURL: ", pictureURL);
    console.info("pictureFile: ", pictureFile);
    console.groupEnd();
  }, [pictureURL, pictureFile]);

  const {
    account: { thumbnail_url },
  } = useAccount();

  // When the account is loaded, update the form fields
  // @todo should this be memo instead?
  React.useEffect(() => {
    if (!pictureURL) {
      if (thumbnail_url) {
        setPictureURL(thumbnail_url);
      }
    }
  }, [thumbnail_url, pictureURL]);

  const save = () => {
    let data = new FormData();

    // @todo remove this
    console.group("AccountForm.save");
    console.info("pictureURL: ", pictureURL);
    console.info("pictureFile: ", pictureFile);
    console.groupEnd();

    // If a profile pic file is set, default to that
    if (pictureFile) {
      data.append("file", pictureFile);
    } else {
      if (pictureURL !== thumbnail_url) {
        data.append("url", pictureURL);
      }
    }

    const success = (response) => {
      const message = "Thumbnail updated successfully";

      enqueueSnackbar(message, {
        variant: "success",
      });

      // Unset vars
      setPictureURL("");
      setPictureFile(null);

      invalidateAccountCache();

      if (typeof onSuccess === "function") {
        onSuccess(response.data?.account);
      }
    };

    const fail = (error) => {
      const message = "Failed to update thumbnail";
      enqueueSnackbar(message, {
        variant: "error",
      });
      if (typeof onFailure === "function") {
        onFailure(error);
      }
    };

    setIsSubmitting(true);
    setSubmitProgress(0);

    apiCall
      .put(accountURLs.uploadThumbnail(), data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          setSubmitProgress(percentCompleted * 0.8); // 80% of progress is upload, 20% is online processing
        },
      })
      .then(success)
      .catch(fail)
      .finally(() => {
        setIsSubmitting(false);
        setSubmitProgress(0);
      });
  };

  return (
    <>
      <Grid container spacing={2}>
        {isSubmitting && (
          <Grid item xs={12}>
            <Grow in={true}>
              <Alert severity="info" icon={<SaveIcon />}>
                <AlertTitle>
                  {submitProgress < 75
                    ? "Uploading image"
                    : "Saving influencer persona"}
                </AlertTitle>
                <LinearProgress
                  variant="buffer"
                  valueBuffer={submitProgressBuffer}
                  value={submitProgress}
                  sx={{ mt: 1, width: "300px", height: 15 }}
                />
              </Alert>
            </Grow>
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              border: "1px dashed grey",
              borderRadius: 5,
              height: "100%",
              p: 2,
            }}
          >
            <Stack direction="column" alignItems="center">
              <Avatar src={pictureURL} sx={{ width: 150, height: 150 }} />
              <Button
                variant="outlined"
                component="label"
                sx={{ mt: 2 }}
                disabled={isSubmitting}
                onClick={() => {
                  document.getElementById("account_pic_file").click();
                }}
              >
                Upload new image
              </Button>
              <input
                type="file"
                hidden
                accept="image/*"
                id="account_pic_file"
                onChange={(event) => {
                  if (event.target.files.length > 0) {
                    setPictureFile(event.target.files[0]);
                    setPictureURL(URL.createObjectURL(event.target.files[0]));

                    console.debug(
                      "event.target.files[0]: ",
                      event.target.files[0]
                    );
                  }
                }}
              />
              {pictureFile && (
                <Box
                  sx={{
                    my: 3,
                  }}
                >
                  <strong>Upload File Size: </strong>{" "}
                  {formatBytes(pictureFile?.size)}
                </Box>
              )}
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            variant="contained"
            color="primary"
            onClick={save}
            disabled={
              isSubmitting ||
              (pictureURL === thumbnail_url && pictureURL !== "")
            }
            startIcon={
              isSubmitting ? <CircularProgress size={20} /> : <SaveIcon />
            }
          >
            Save Changes
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

const DialogWithForm = ({ open, onClose }) => {
  const onSuccess = (data) => {
    console.info(`Logo saved succussfully`, data);
    onClose();
  };

  const onFailure = (error) => {
    console.error(`An error occurred while saving the logo`, error);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      TransitionComponent={SlideUpFromBottom}
    >
      <DialogTitle>
        Set Shop Logo
        <RightCloseIcon onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <Form onSuccess={onSuccess} onFailure={onFailure} />
      </DialogContent>
    </Dialog>
  );
};

export const AccountLogo = {
  Form,
  Dialog: DialogWithForm,
};
