import React from "react";
import { LoadingButton } from "@mui/lab";
import AirlineStopsIcon from "@mui/icons-material/AirlineStops";
import {
  Grid,
  Alert,
  AlertTitle,
  Button,
  Grow,
  TextField,
  Stack,
  Typography,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  Table,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAccordion from "@mui/material/Accordion";
import CropIcon from "@mui/icons-material/Crop";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useSnackbar } from "notistack";

import { apiCall } from "../../../utilities/request";
import { storyURLs, chapterURLs } from "../../../constants/apiURLs";
import { useStory, invalidateCollectionCache } from "../../../api/fetchCollectionData";
import chapterDetails from "../../../models/chapterDetails";
import AssetSelectorDialog from "../../../modules/assets/AssetSelectorDialog";
import CTASelectorDialog from "../../../modules/ctas/CTASelectorDialog";
import CTAEditor from "../../../modules/ctas/CTAEditorForm";
import AssetUploader from "../../../modules/assets/AssetUploader";
import ChapterPreview from "./ChapterPreview";
import { assetIsImage, invalidateAssetCache } from "../../../api/fetchAssetData";
import { hasOverrideAccountID } from "../../../context/auth";
import { AssetCropperDialog } from "../../../modules/assets/AssetCrop";
import { CROP_VERTICAL_9_16 } from "../../../constants/assets";

const ChapterEditor = ({
  storyBlockId,
  storyId,
  onDone,
  chapter,
  isNew,
  destinationIndex,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  // Get existing story data
  const { story } = useStory(storyId);
  // Generate an array of the chapter IDs current in this story
  const currentChapterIDs = story.chapters
    ? story.chapters.map((chapter) => chapter.id)
    : [];

  // Accordian for separating out different types of settings
  const [accordianExpanded, setAccordianExpanded] = React.useState("media");
  const handleAccordianChange = (panel) => (event, isExpanded) => {
    setAccordianExpanded(isExpanded ? panel : false);
  };

  const [cropDialogOpen, setCropDialogOpen] = React.useState(false);

  const {
    internal_name,
    internal_description,
    asset,
    dwell,
    ctas,
    id,
    setName,
    setDuration,
    setAsset,
    setCtas,
    hasChanges,
  } = chapterDetails(chapter);

  const [previewAsset, setPreviewAsset] = React.useState(asset);

  // If we are creating a new chapter, default the dwell to 7
  React.useEffect(() => {
    if (isNew) {
      setDuration(7);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNew]);

  const { id: cta_id } = ctas[0] ?? {};
  const { id: asset_id } = asset ?? {};

  const buttonLabel = isNew ? "Create Chapter" : "Save Chapter";
  const disableCreateButton = isNew && (!internal_name || !asset);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, _setError] = React.useState(null);
  const errorRef = React.useRef(null);

  // Resource selectors
  const [assetDialogOpen, setAssetDialogOpen] = React.useState(false);
  const [ctaDialogOpen, setCtaDialogOpen] = React.useState(false);
  const [newCTADialogOpen, setNewCTADialogOpen] = React.useState(false);

  const setError = (err) => {
    _setError(err);

    // Only do the rest if err not null
    if (err) {
      enqueueSnackbar("Error saving story", { variant: "error" });

      // Scroll to error alert message
      errorRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleSave = (e) => {
    e.preventDefault();

    const payload = {
      id,
      asset_id,
      cta_id,
      dwell: Number(dwell),
      internal_name,
      internal_description,
    };

    setError(null);

    // Validate form
    // Dwell
    if (payload.dwell <= 0) {
      setError("Dwell must be greater than 0");
      return;
    }
    // Asset
    if (
      payload.asset_id === null ||
      payload.asset_id === undefined ||
      payload.asset_id === ""
    ) {
      setError("You must select the media for this chapter");
      return;
    }
    // Name
    if (payload.internal_name === null || payload.internal_name === "") {
      setError(
        "You must enter a name for this chapter. This will help you identify the chapter in analytics."
      );
      return;
    }

    const saveSuccess = (res) => {
      console.info("Save successful");
      console.info(res);

      // If this is a new chapter, add it to the story @ the requested index
      let newChapterIDs = [...currentChapterIDs];
      if (isNew) {
        console.info("Inserting new chapter at index", destinationIndex);

        // Insert the new chapter at the requested index
        newChapterIDs.splice(destinationIndex, 0, res.data.id);

        // Update the story with the new chapter IDs
        setIsLoading(true);
        apiCall
          .put(storyURLs.chapterOrder(storyId), {
            chapters: newChapterIDs,
          })
          .then((res) => {
            console.info("Chapter order changed");
            console.info(res);

            // invalidate the current collection list
            invalidateCollectionCache(storyBlockId);

            // Notify success
            enqueueSnackbar("Saved Chapter & inserted into story", {
              variant: "success",
            });

            // Report done if the function exists
            if (onDone) {
              onDone(res.data);
            }
          })
          .catch((err) => {
            console.error("Chapter order change error");
            console.error(err);
            setError(err.response.data.error + err.response.data.details);
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        // Not a new chapter, so the order didn't change

        // invalidate the current collection list
        invalidateCollectionCache(storyBlockId);

        // Notify success
        enqueueSnackbar("Saved Chapter", {
          variant: "success",
        });

        // If onDone is defined, call it
        if (onDone) {
          onDone();
        }

        if (isNew) {
          window.analytics.track("chapter-created", {
            name: internal_name,
            id: res.data.id,
            admin_action: hasOverrideAccountID(),
          });
        } else {
          window.analytics.track("chapter-edited", {
            name: internal_name,
            id: res.data.id,
            admin_action: hasOverrideAccountID(),
          });
        }
      }
    };

    const saveError = (err) => {
      console.error("Save failed");
      console.error(err);
      console.error(err.response.data);
      setError(err.response.data.error + err.response.data.details);
    };

    // Create or update chapter
    if (isNew) {
      // Create a new chapter & associate it with the story
      apiCall
        .post(chapterURLs.list(), payload)
        .then(saveSuccess)
        .catch(saveError)
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      // Update the chapter
      apiCall
        .put(chapterURLs.details(id), payload)
        .then(saveSuccess)
        .catch(saveError)
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const transformAsset = (new_asset_id) => {
    setAsset({
      id: new_asset_id,
    });
  };

  const transformCTA = (cta) => {
    if (cta === null) {
      setCtas([]);
    } else {
      setCtas([{ id: cta.id }]);
    }
  };

  const selectAsset = (asset) => {
    // Detect asset type and set it
    switch (asset.type) {
      case "image":
        break;
      case "cloudflare_stream":
        break;
      case "mux_stream":
        break;
      default:
        // Alert on error
        setError("Unknown asset type - critical error");
        setAssetDialogOpen(false);
        return;
    }

    // Update the asset & close dialog
    setAssetDialogOpen(false);
    transformAsset(asset.id);
    setAsset(asset);

    // If the chapter does not have a name, set it to the asset name
    console.group("Setting name");
    console.info("internal_name", internal_name);
    console.info("asset.internal_name", asset.internal_name);
    console.groupEnd();
    if (
      internal_name === null ||
      internal_name === "" ||
      typeof internal_name === "undefined"
    ) {
      setName(asset.internal_name);
    }
  };

  // On a new upload or if media is selected that's not already 9:16, open the crop dialog
  const checkCropRequired = (asset) => {
    // Only relevant for images
    if (asset.type !== "image") {
      return;
    }

    // If the asset is already 9:16, don't open the crop dialog
    const aspectRatio = asset.width / asset.height;

    if (aspectRatio === CROP_VERTICAL_9_16) {
      // We're good!
      return;
    }

    // If the image already has a vertical crop, we're good!
    if (asset?.image_vertical_crop_url) {
      return;
    }

    // Open the crop dialog
    setCropDialogOpen(true);
    enqueueSnackbar("Please set your desired crop", {
      variant: "info",
    });
  };

  return (
    <>
      {/* Asset selector */}
      <AssetSelectorDialog
        open={assetDialogOpen}
        onClose={() => setAssetDialogOpen(false)}
        onSelect={(asset) => {
          selectAsset(asset);
          setPreviewAsset(asset);
          checkCropRequired(asset);
        }}
        uploaderAllowMultiple={false}
      />
      <AssetCropperDialog
        open={cropDialogOpen}
        asset={previewAsset}
        aspectRatio={CROP_VERTICAL_9_16}
        onClose={(asset) => {
          // If given an asset, update the preview
          if (asset) {
            setPreviewAsset(asset);
          }
          invalidateAssetCache();
          setCropDialogOpen(false);
        }}
      />

      {/* CTA selector */}
      <CTASelectorDialog
        open={ctaDialogOpen}
        onClose={() => setCtaDialogOpen(false)}
        onSelect={(cta) => {
          // Set the CTA
          transformCTA(cta);
          setCtas([cta]);

          // Close dialog
          setCtaDialogOpen(false);
        }}
        buttonLabel="Use CTA"
      />

      {/* New CTA selector */}
      <CTAEditor.Modal
        show={newCTADialogOpen}
        onClose={(cta) => {
          // Test if this is actaully a CTA
          if (cta && cta.id) {
            console.info("New CTA created: ");
            console.info(cta);

            // Set the CTA
            transformCTA(cta);
            setCtas([cta]);
          }
          // Close dialog
          setNewCTADialogOpen(false);
        }}
        cta={null}
      />

      <Grid container spacing={3}>
        {/* Center all content in the grid item */}
        <Grid item xs={12} md={4} alignItems={'center'} justifyContent={'center'}>
          {/* Only have crop button on images */}
          {asset?.type === "image" && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setCropDialogOpen(true);
              }}
              startIcon={<CropIcon />}
              sx={{
                marginBottom: "0.75rem",
                marginLeft: "6rem",
              }}
              disabled={!asset}
            >
              Adjust Crop
            </Button>
          )}
          <div style={{height: '500px', width: '250px', borderRadius: '8px'}} className="rounded-lg mx-auto p-1.5 bg-gray-700">
              <ChapterPreview
                story={story}
                ctas={ctas}
                asset={previewAsset}
                collectionID={storyBlockId}
              />
          </div>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            label="Name"
            value={internal_name}
            onChange={(e) => setName(e.target.value)}
            fullWidth={true}
            margin="normal"
            placeholder="Examples: <Product name>, <Product Collection name>, Bundle, ..."
            required={true}
            helperText="This is how the Chapter will be identified in analytics: <Story Name> - <Chapter Name>"
            style={{ marginBottom: "1.5rem" }}
          />

          {/* Media */}
          <Accordion
            expanded={accordianExpanded === "media"}
            onChange={handleAccordianChange("media")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="media-content"
              id="media-header"
            >
              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                Add Media
              </Typography>
              {/* <Typography sx={{ color: "text.secondary" }}>
                Chapter photo/video
              </Typography> */}
            </AccordionSummary>
            <AccordionDetails>
              <Stack
                direction="row"
                justifyContent="space-around"
                alignItems="center"
              >
                <Button
                  type="button"
                  variant="outlined"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => setAssetDialogOpen(true)}
                  aria-label="set chapter media"
                >
                  Set Chapter Media
                </Button>
                <AssetUploader
                  onDone={(asset) => {
                    if (asset && asset.id) {
                      setPreviewAsset(asset);
                      selectAsset(asset);
                      checkCropRequired(asset);
                    }
                  }}
                  allowMultiple={false}
                />
              </Stack>

              {assetIsImage(asset) && (
                <TextField
                  label="Duration (seconds)"
                  value={dwell}
                  onChange={(e) => setDuration(e.target.value)}
                  fullWidth={true}
                  margin="normal"
                  required={true}
                  id="duration-input"
                  type="number"
                  helperText="How long the photo should be displayed before moving to the next chapter"
                />
              )}
            </AccordionDetails>
          </Accordion>

          {/* Call to actions */}
          <Accordion
            expanded={accordianExpanded === "call-to-actions"}
            onChange={handleAccordianChange("call-to-actions")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="call-to-actions-content"
              id="call-to-actions-header"
            >
              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                Add a CTA
              </Typography>
              <Typography sx={{ color: "text.secondary" }}></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2" sx={{ marginBottom: "1rem" }}>
                Choose your button colors in the Story Block settings
              </Typography>
              {/* If no cta selected, give an info */}
              {ctas.length === 0 && (
                <Alert severity="info">
                  No CTAs have been added to this chapter.
                </Alert>
              )}

              {/* If cta selected, show the cta */}
              {ctas.length > 0 && (
                <TableContainer>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>{ctas[0].internal_name}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Type</TableCell>
                        <TableCell>{ctas[0].type}</TableCell>
                      </TableRow>

                      {/* If CTA is a link, show the destination */}
                      {ctas[0].type === "link" && (
                        <TableRow>
                          <TableCell>URL</TableCell>
                          <TableCell>{ctas[0].link_url}</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}

              <Stack
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                sx={{
                  marginTop: "1rem",
                }}
              >
                {/* Button to add or change the CTA */}
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  startIcon={<AirlineStopsIcon />}
                  onClick={() => setCtaDialogOpen(true)}
                  aria-label="set chapter cta"
                >
                  Use existing CTA
                </Button>

                {/* Create new CTA */}
                <Button
                  type="button"
                  variant="contained"
                  color="secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    setNewCTADialogOpen(true);
                  }}
                  startIcon={<AddIcon />}
                  aria-label="create new cta"
                >
                  Create New CTA
                </Button>

                {/* Button to remove CTA */}
                {/* {ctas.length > 0 && ( */}
                <Button
                  type="button"
                  variant="contained"
                  color="error"
                  startIcon={<DeleteIcon />}
                  onClick={() => {
                    transformCTA(null);
                    setCtas([]);
                  }}
                  disabled={ctas.length === 0}
                  aria-label="remove cta"
                >
                  Remove CTA
                </Button>
                {/* )} */}
              </Stack>
            </AccordionDetails>
          </Accordion>
          <LoadingButton
            loading={isLoading}
            color="primary"
            loadingPosition="start"
            variant="contained"
            fullWidth={true}
            onClick={handleSave}
            disabled={!hasChanges || disableCreateButton}
            startIcon={<SaveIcon />}
            sx={{
              marginTop: "1rem",
            }}
          >
            {buttonLabel}
          </LoadingButton>

          {error && (
            <Grow in={error ? true : false}>
              <Alert
                sx={{
                  marginTop: "1rem",
                }}
                ref={errorRef}
                severity="error"
              >
                <AlertTitle>Error</AlertTitle>
                {error}
              </Alert>
            </Grow>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ChapterEditor;

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  borderBottom: "1px solid rgba(0, 0, 0, .125)",
}));
