import { v4 as uuidv4 } from "uuid";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router";
import {
  Box,
  Button,
  FormGroup,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Modal,
  Select,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import SaveIcon from "@mui/icons-material/Save";

import RightCloseIcon from "../../modules/assets/RightCloseIcon";
import Autocomplete from "../../components/Autocomplete";
import {
  formatShopifyCollectionData,
  formatShopifyProductsData,
  formatStoryBlockInternalName,
  formatStoryBlockInternalDesc,
} from "../../utilities/formatShopifyData";
import {
  createStoryBlock,
  updateStoryBlock,
} from "../../redux/api/storyBlockAPI";
import {
  invalidateCollectionCache,
  useCollection,
} from "../../api/fetchCollectionData";
import { useShopifyCollections } from "../../api/useShopifyCollections";
import { useShopifyProducts } from "../../api/useShopifyProducts";
import {
  invalidatePageTargetingCache,
  usePageTargetingRulesets,
} from "../../api/fetchPageTargetingRulesets";
import { updatePageTargetingRulesets } from "../../redux/api/pageTargetingAPI";

const style = {
  topMargin: {
    marginTop: 10,
  },
  box: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  viewallButton: {
    marginLeft: "5px",
    whiteSpace: "nowrap",
  },
  selectionButton: {
    height: 144,
    display: "flex",
    flexDirection: "column",
    "& .MuiSvgIcon-root": {
      height: "52px",
      width: "52px",
      marginBottom: "5px",
    },
  },
  footer: {
    textAlign: "right",
  },
};

const selections = [
  { title: "Homepage", value: "homepage", icon: <HomeIcon /> },
  {
    title: "Collection Page",
    value: "collection_page",
    icon: <LibraryBooksIcon />,
  },
  { title: "Product Page", value: "product_page", icon: <LocalOfferIcon /> },
];

export default function StoryBlockLocationModal({
  edit = false,
  open = false,
  onClose,
}) {
  const [pageSelection, setPageSelection] = useState("");
  const [dropdownSelection, setDropdownSelection] = useState({});
  const [sort, setSort] = useState("a-z");
  const [activeOnly, setActiveOnly] = useState(false);
  const { shopifyProducts, isLoading: isLoadingShopifyProducts } =
    useShopifyProducts();
  const { shopifyCollections, isLoading: isLoadingShopifyCollections } =
    useShopifyCollections();
  const { storyBlockId } = useParams();
  const { collection: storyBlock } = useCollection(storyBlockId);
  const { rulesets } = usePageTargetingRulesets();
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setPageSelection("");
    setActiveOnly(false);
    setSort("a-z");
    onClose();
  };

  const handlePageSelection = (newPage) => {
    setDropdownSelection({});
    setPageSelection(newPage);
  };

  const handleDropdownSelect = (e) => setDropdownSelection(e || {});

  const getNewRuleset = (newStoryBlockId) => ({
    rules: [
      {
        category: "shopify",
        type: pageSelection, // depends on selection
        value: dropdownSelection.id || "", // either product or collection id
      },
    ],
    mode: "any",
    collection_id: edit ? storyBlockId : newStoryBlockId,
    priority:
      rulesets.reduce(
        (highestPriority, ruleset) =>
          ruleset.priority > highestPriority
            ? ruleset.priority
            : highestPriority,
        0
      ) + 1,
    active: false,
  });

  const onEditClick = async () => {
    const data = {
      ...storyBlock,
      internal_name: formatStoryBlockInternalName(
        pageSelection,
        dropdownSelection?.title
      ),
      internal_description: formatStoryBlockInternalDesc(
        pageSelection,
        dropdownSelection?.id,
        dropdownSelection?.handle
      ),
    };

    try {
      // Update the story block
      await updateStoryBlock(data);
      invalidateCollectionCache();

      // Update the story block ruleset
      const ruleset = rulesets.find(
        (ruleset) => ruleset.collection_id === storyBlockId
      );

      if (ruleset) {
        // Update the existing ruleset and make sure it's not published
        const nonMatchingRulesets = rulesets.filter(
          (ruleset) => ruleset.collection_id !== storyBlockId
        );
        await updatePageTargetingRulesets([
          ...nonMatchingRulesets,
          {
            ...ruleset,
            active: false,
            rules: [
              {
                id: uuidv4(),
                category: "shopify",
                type: pageSelection, // depends on selection
                value: dropdownSelection.id || "", // either product or collection id
              },
            ],
          },
        ]);
      } else {
        // Create a new ruleset
        await updatePageTargetingRulesets([...rulesets, getNewRuleset()]);
      }

      invalidatePageTargetingCache();
      enqueueSnackbar("Publish location updated successfully!", {
        variant: "success",
      });
    } catch (err) {
      console.error("Error updating story block location: ", err);
    }

    handleClose();
  };

  const onCreateClick = async () => {
    const data = {
      internal_name: formatStoryBlockInternalName(
        pageSelection,
        dropdownSelection?.title
      ),
      internal_description: formatStoryBlockInternalDesc(
        pageSelection,
        dropdownSelection?.id,
        dropdownSelection?.handle
      ),
      style: { primary_color: "#000000" }, // Default to black CTA buttons
      custom_css: "",
      custom_js: "",
    };

    try {
      // Create the story block
      const resData = await createStoryBlock(data);

      // Now create the ruleset
      await updatePageTargetingRulesets([
        ...rulesets,
        getNewRuleset(resData.id),
      ]);

      invalidateCollectionCache();

      // Now go to the new story block page to edit it
      navigate(`/story_blocks/${resData.id}`);
    } catch (err) {
      console.error("Error updating story block location: ", err);
    }
  };

  const showOptions =
    pageSelection === "collection_page" || pageSelection === "product_page";
  const displayedCollections = formatShopifyCollectionData(shopifyCollections, {
    sort,
  });
  const displayedProducts = formatShopifyProductsData(shopifyProducts, {
    activeOnly,
    sort,
  });

  const sortOptions = [
    { title: "Alphabetically: A to Z", value: "a-z" },
    { title: "Alphabetically: Z to A", value: "z-a" },
  ];

  if (pageSelection === "product_page") {
    sortOptions.push(
      { title: "Published: Latest First", value: "published-latest" },
      { title: "Published: Earliest First", value: "published-earliest" },
      { title: "Created: Latest First", value: "created-latest" },
      { title: "Created: Earliest First", value: "created-earliest" }
    );
  } else if (pageSelection === "collection_page") {
    sortOptions.push(
      { title: "Updated: Latest First", value: "updated-latest" },
      { title: "Updated: Earliest First", value: "updated-earliest" }
    );
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style.box}>
        <div>
          <Typography id="modal-title" variant="h5" component="h2">
            Where should this story block display?
            <RightCloseIcon onClick={handleClose} />
          </Typography>
          <Grid container spacing={3} style={style.topMargin}>
            {selections.map((s) => (
              <Grid item xs={4} key={s.value}>
                <Button
                  id={s.value}
                  fullWidth
                  variant={pageSelection === s.value ? "contained" : "outlined"}
                  onClick={() => handlePageSelection(s.value)}
                  sx={style.selectionButton}
                >
                  {s.icon}
                  <>{s.title}</>
                </Button>
              </Grid>
            ))}
          </Grid>

          {showOptions && (
            <Grid container style={style.topMargin}>
              {showOptions && (
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography>Sort By:</Typography>
                      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <Select
                          labelId="select-small"
                          id="select-small"
                          value={sort}
                          onChange={(event) => setSort(event.target.value)}
                        >
                          {sortOptions.map((o) => (
                            <MenuItem value={o.value} key={o.value}>
                              {o.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    {pageSelection === "product_page" && (
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={activeOnly}
                              onChange={(e) => setActiveOnly(e.target.checked)}
                            />
                          }
                          label="Active Only"
                        />
                      </FormGroup>
                    )}
                    <Typography>
                      {pageSelection === "collection_page"
                        ? displayedCollections.length
                        : displayedProducts.length}{" "}
                      Items
                    </Typography>
                  </Stack>
                </Grid>
              )}
              <Grid item xs={12}>
                <Autocomplete
                  label={pageSelection}
                  options={
                    pageSelection === "collection_page"
                      ? displayedCollections
                      : displayedProducts
                  }
                  onSelect={handleDropdownSelect}
                  loading={
                    pageSelection === "collection_page"
                      ? isLoadingShopifyCollections
                      : isLoadingShopifyProducts
                  }
                />
              </Grid>
            </Grid>
          )}
        </div>

        <Grid
          container
          spacing={2}
          style={{ ...style.footer, ...style.topMargin }}
        >
          <Grid item xs={12}>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={
                pageSelection === "" ||
                ((pageSelection === "product_page" ||
                  pageSelection === "collection_page") &&
                  Object.keys(dropdownSelection).length === 0)
              }
              sx={{ ml: 1 }}
              onClick={edit ? onEditClick : onCreateClick}
              startIcon={<SaveIcon />}
            >
              {edit ? "Update" : "Create"}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
