import React from "react";

import {
  AlertTitle,
  Alert,
  Typography,
  Divider,
  Tooltip,
  Autocomplete,
} from "@mui/material";
import { useSnackbar } from "notistack";

import { LoadingButton } from "@mui/lab";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import { ctaURLs } from "../../constants/apiURLs";
import { apiCall } from "../../utilities/request";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Grow from "@mui/material/Grow";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
  TextField,
  Stack,
} from "@mui/material";
import { useKlaviyoLists } from "../../api/fetchKlaviyoLists";
import { invalidateCTACache } from "../../api/fetchCTAData";

import RightCloseIcon from "../assets/RightCloseIcon";
import { CTA_LINK_TEXT } from "../../constants/textMappings";
import { hasOverrideAccountID } from "../../context/auth";
import { SlideUpFromBottom } from "../../utilities/transitions";

// A component defining a modal to edit the metadata of an cta
const CTAEditor = ({ isNew, cta, onDone }) => {
  const { enqueueSnackbar } = useSnackbar();

  // Get klaviyo lists
  const { klaviyoLists } = useKlaviyoLists();

  // If we're making a new one, set the state to a good default
  const defaults = {
    internal_name: isNew ? "" : cta.internal_name,
    internal_description: isNew ? "" : cta.internal_description,
    type: isNew ? "" : cta.type,
    link_text: isNew ? "" : cta.link_text,
    link_url: isNew ? "" : cta.link_url,
    discount_text: isNew ? "" : cta.discount_text,
    klaviyo_list_id: isNew ? "" : cta.klaviyo_list_id,
  };

  const [isLoading, setIsLoading] = React.useState(false);
  console.debug("isLoading default: ", isLoading);
  React.useEffect(() => {
    console.debug("isLoading changed: " + isLoading);
  }, [isLoading]);
  const [error, _setError] = React.useState(null);
  const errorRef = React.useRef(null);
  const setError = (err) => {
    _setError(err);

    // Only do the rest if err not null
    if (err) {
      enqueueSnackbar("Error saving story", { variant: "error" });

      // Scroll to error alert message
      errorRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [internal_name, setInternalName] = React.useState(
    defaults.internal_name
  );
  const [type, setType] = React.useState(defaults.type);
  const [link_text, setLinkText] = React.useState(defaults.link_text);
  const [link_url, setLinkUrl] = React.useState(defaults.link_url);
  const [discount_text, setDiscountText] = React.useState(
    defaults.discount_text
  );
  const [klaviyo_list_id, setKlaviyoListId] = React.useState(
    defaults.klaviyo_list_id
  );

  // Handle cta delete
  const deleteCTA = () => {
    // Send the request
    setIsLoading(true);
    setError(null);
    apiCall
      .delete(ctaURLs.cta(cta.id))
      .then((res) => {
        console.info("Delete OK");

        // Invalidate cta data
        invalidateCTACache();

        // Notify success
        enqueueSnackbar("Deleted CTA", {
          variant: "success",
        });

        // All done!
        if (onDone) {
          onDone(res.data);
        }
      })
      .catch((error) => {
        console.error("Error deleting cta");
        console.error(error.response);
        setError(error.response.data.error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Function to upload metadata changes
  const saveMetadata = (e) => {
    e.preventDefault();

    setError(null);

    const requestData = {
      internal_name,
      internal_description: defaults.internal_description,
      type,
      link_text,
      link_url,
      discount_text,
      klaviyo_list_id,
    };

    // Require name
    if (!internal_name) {
      setError("Name is required");
      return;
    }

    // Link text always required
    if (!link_text) {
      setError("Button text is required");
      return;
    }

    // Validate based on type
    switch (type) {
      case "link":
        // Link url also required
        if (!link_url) {
          setError("Link URL is required");
          return;
        }

        break;
      case "klaviyo_email_discount_modal":
        // Klaviyo list id required
        if (!klaviyo_list_id) {
          setError("Klaviyo list ID is required");
          return;
        }

        // Discount text required
        if (!discount_text) {
          setError("Discount text is required");
          return;
        }

        break;
      default:
        setError("Invalid CTA type entered");
        break;
    }
    const onError = (error) => {
      console.error("Error saving metadata");
      console.error(error.response);
      setError(error.response.data.error);
    };

    const onSuccess = (res) => {
      console.info("Saved metadata");
      console.info(res.data);

      console.debug(JSON.stringify(res));

      // Invalidate cta data
      invalidateCTACache();

      // Notify success
      enqueueSnackbar("Saved CTA", {
        variant: "success",
      });

      // All done!
      if (onDone) {
        onDone(res.data);
      }

      if (isNew) {
        window.analytics.track("cta-created", {
          name: internal_name,
          id: res.data.id,
          admin_action: hasOverrideAccountID(),
        });
      } else {
        window.analytics.track("cta-edited", {
          name: internal_name,
          id: res.data.id,
          admin_action: hasOverrideAccountID(),
        });
      }
    };

    // Send the request
    if (isNew) {
      console.info("Creating new cta");
      apiCall
        .post(ctaURLs.list(), requestData)
        .then(onSuccess)
        .catch(onError)
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      console.info("Attempting metadata update");
      setIsLoading(true);
      apiCall
        .put(ctaURLs.cta(cta.id), requestData)
        .then(onSuccess)
        .catch(onError)
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <form onSubmit={saveMetadata}>
      <Grid container spacing={2}>
        {/* @todo add a preview of the CTA here */}
        <Grid item xs={12} md={12}>
          {!isNew && <>ID: {cta.id}</>}
          <TextField
            label="Name"
            value={internal_name}
            onChange={(e) => setInternalName(e.target.value)}
            variant="outlined"
            required={true}
            margin="normal"
            helperText="This is how the CTA will be identified in analytics"
            fullWidth
          />

          {/* Big spacer here */}
          <Divider
            style={{
              marginTop: "2rem",
            }}
          />

          <FormControl margin="normal" fullWidth>
            <InputLabel id="type-label">Type</InputLabel>
            <Select
              labelId="type-label"
              value={type}
              label="Type"
              onChange={(e) => setType(e.target.value)}
              required={true}
            >
              <MenuItem value="link">Link</MenuItem>
              <MenuItem value="klaviyo_email_discount_modal">
                Klaviyo Discount Modal
              </MenuItem>
            </Select>
          </FormControl>
          <Autocomplete
            sx={{ marginTop: "10px" }}
            value={link_text}
            handleHomeEndKeys
            inputValue={link_text}
            fullWidth
            freeSolo
            options={CTA_LINK_TEXT}
            onInputChange={(event, value) => {
              setLinkText(value);
            }}
            onChange={(event, value) => {
              const inputValue = value || "";
              setLinkText(inputValue);
            }}
            renderInput={(params) => (
              <TextField
                variant="outlined"
                placeholder="e.g. Buy Now"
                {...params}
                label="CTA Button Text"
              />
            )}
          />

          {type === "link" && (
            <TextField
              label="Link URL"
              value={link_url}
              onChange={(e) => setLinkUrl(e.target.value)}
              variant="outlined"
              margin="normal"
              fullWidth
              placeholder="e.g. https://example.com/path/to/cart"
            />
          )}
          {type === "klaviyo_email_discount_modal" && (
            <>
              {/* If there are no lists, display an error and @todo show the form to set the key */}
              {klaviyoLists.length === 0 && (
                <Alert severity="error">
                  <AlertTitle>
                    There are no Klaviyo lists to choose from.
                  </AlertTitle>
                  You might need to set the Klaviyo API key.
                </Alert>
              )}
              {/* Dropdown for the avaliable klaviyo lists */}
              {klaviyoLists.length > 0 && (
                <FormControl margin="normal" fullWidth>
                  <InputLabel id="klaviyo-list-label">Klaviyo List</InputLabel>
                  <Select
                    labelId="klaviyo-list-label"
                    value={klaviyo_list_id}
                    label="Klaviyo List"
                    onChange={(e) => setKlaviyoListId(e.target.value)}
                    required={true}
                    placeholder="Select a list"
                  >
                    {klaviyoLists.map((list) => (
                      <MenuItem key={list.id} value={list.id}>
                        <Typography variant="body1">{list.name}</Typography>
                        &nbsp;&ndash;&nbsp;
                        <Typography variant="caption">({list.id})</Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              <TextField
                label="Discount Text"
                value={discount_text}
                onChange={(e) => setDiscountText(e.target.value)}
                variant="outlined"
                margin="normal"
                fullWidth
                placeholder="e.g. $10 off"
              />
            </>
          )}

          {/* Action buttons */}
          <Stack
            direction="row"
            justify="space-evenly"
            align="center"
            spacing={2}
            sx={{
              width: "100%",
              marginTop: "1rem",
            }}
          >
            <LoadingButton
              loading={isLoading}
              color="primary"
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="contained"
              type="submit"
            >
              Save
            </LoadingButton>
            {!isNew && (
              <Tooltip
                title={cta.inUse ? "This CTA is in use" : "Delete CTA"}
                placement="top"
                arrow
              >
                <span>
                  <Button
                    color="error"
                    type="button"
                    variant="outlined"
                    disabled={cta.inUse || isLoading}
                    startIcon={<DeleteIcon />}
                    onClick={(e) => {
                      e.preventDefault();

                      deleteCTA();
                    }}
                  >
                    Delete
                  </Button>
                </span>
              </Tooltip>
            )}
          </Stack>
          <Grow in={error ? true : false}>
            <Alert
              sx={{
                marginTop: "1rem",
              }}
              ref={errorRef}
              severity="error"
            >
              <AlertTitle>Error</AlertTitle>
              {error}
            </Alert>
          </Grow>
        </Grid>
      </Grid>
    </form>
  );
};

//   Modal wrapper for the cta editor
const CTAEditorModal = ({ show, cta, onClose }) => {
  // If a CTA wasn't passed in, then we're saving a new one
  const isNew = !cta || cta.id === undefined;

  const title = isNew ? "New CTA" : "Edit " + cta.internal_name;

  return (
    <Dialog
      TransitionComponent={SlideUpFromBottom}
      open={show}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth={"md"}
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title">
        {title}
        <RightCloseIcon onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <CTAEditor isNew={isNew} cta={cta} onDone={onClose} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CTAEditor.Modal = CTAEditorModal;

export default CTAEditor;
