import React from "react";

import { AssetCropperDialog } from "./AssetCrop";
import Image from "../../components/Image";

import {
  assetPreviewLink,
  assetSquareCropLink,
  assetVerticalCropLink,
  invalidateAssetCache,
} from "../../api/fetchAssetData";
import { requestAccountID } from "../../context/auth";

import MuxPlayer from "@mux/mux-player-react";

import { Grid, Button, Stack, Typography } from "@mui/material";
import CropIcon from "@mui/icons-material/Crop";
import { CROP_SQUARE_1_1, CROP_VERTICAL_9_16 } from "../../constants/assets";

export const AssetFormMedia = ({ asset }) => {
  const previewImage = assetPreviewLink(asset);
  return (
    <>
      {asset.type === "cloudflare_stream" && (
        <Image ratio={16 / 9} src={previewImage} />
      )}
      {asset.type === "image" ? <ImageView asset={asset} /> : null}
      {asset.type === "mux_stream" && <MuxView asset={asset} />}
    </>
  );
};

const ImageView = ({ asset }) => {
  const [verticalCropOpen, setVerticalCropOpen] = React.useState(false);
  const [squareCropOpen, setSquareCropOpen] = React.useState(false);

  const squareURL = assetSquareCropLink(asset);
  const verticalURL = assetVerticalCropLink(asset);

  const previewImage = assetPreviewLink(asset);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Stack
            direction="column"
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="h5">Original</Typography>
            <img
              src={previewImage}
              style={{
                borderRadius: 5,
                boxShadow: "0 0 10px 0 rgba(0,0,0,0.2)",
                border: "1px solid #eee",
                display: "block",
                maxHeight: "300px",
                width: "90%",
                height: "auto",
              }}
              alt="Original"
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={4}>
          <Stack
            direction="column"
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="h5">Card</Typography>
            {/* Display the image in a 9:16 vertical card, centered in the cover mode */}
            <div
              style={{
                borderRadius: 5,
                boxShadow: "0 0 10px 0 rgba(0,0,0,0.2)",
                border: "1px solid #eee",
                backgroundImage: `url('${verticalURL}')`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                height: 275,
                width: 275 * (9 / 16),
              }}
            >
              <br />
              <br />
            </div>

            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setVerticalCropOpen(true);
              }}
              startIcon={<CropIcon />}
            >
              Crop
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4}>
          <Stack
            direction="column"
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="h5">Thumbnail</Typography>
            <img
              src={squareURL}
              style={{
                borderRadius: "50%",
                boxShadow: "0 0 10px 0 rgba(0,0,0,0.2)",
                border: "1px solid #eee",
                height: "200px",
                width: "200px",
              }}
              alt="thumbnail"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setSquareCropOpen(true);
              }}
              startIcon={<CropIcon />}
            >
              Crop
            </Button>
          </Stack>
        </Grid>
      </Grid>

      <AssetCropperDialog
        open={verticalCropOpen}
        asset={asset}
        aspectRatio={CROP_VERTICAL_9_16}
        onClose={() => {
          invalidateAssetCache();
          setVerticalCropOpen(false);
        }}
      />
      <AssetCropperDialog
        open={squareCropOpen}
        asset={asset}
        aspectRatio={CROP_SQUARE_1_1}
        onClose={() => {
          invalidateAssetCache();
          setSquareCropOpen(false);
        }}
      />
    </>
  );
};

const MuxView = ({ asset }) => {
  // Referece to the Mux player
  const muxPlayerRef = React.useRef(null);
  const [videoPlaybackProgress, setVideoPlaybackProgress] = React.useState(0);

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "10px",
          backgroundColor: "black",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: `${videoPlaybackProgress * 100}%`,
            height: "10px",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
          }}
        />
      </div>

      <MuxPlayer
        ref={muxPlayerRef}
        streamType="on-demand"
        playbackId={asset.mux_playback_id}
        metadata={{
          video_id: asset.id,
          video_title: asset.internal_name,
          viewer_user_id: requestAccountID(),
        }}
        loop={true}
        autoPlay={true}
        muted={true}
        onTimeUpdate={() => {
          if (muxPlayerRef?.current) {
            // Get the current time from the player
            const time = muxPlayerRef.current.currentTime;

            // Get progress as a percentage
            const progress = time / asset.duration;
            setVideoPlaybackProgress(progress);
          }
        }}
      />
    </>
  );
};
