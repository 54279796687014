import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchPageTargetingRulesets,
  updatePageTargetingRulesets,
} from "../api/pageTargetingAPI";

const initialState = {
  rulesets: { status: "idle", list: [] },
  updatingStatus: "idle",
};

// Thunks
export const getRulesets = createAsyncThunk(
  "shopifyData/fetchPageTargetingRuleSets",
  async () => {
    const data = await fetchPageTargetingRulesets();
    return data?.response?.status === 400 ? [] : data;
  }
);

export const updateRulesets = createAsyncThunk(
  "shopifyData/updatePageTargetingRulesets",
  async (rulesets) => {
    const data = await updatePageTargetingRulesets(rulesets);
    return data?.response?.status === 400 ? [] : data;
  }
);

// Slice
export const pageTargetingSlice = createSlice({
  name: "pageTargeting",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getRulesets.pending, (state) => {
        state.rulesets.status = "loading";
      })
      .addCase(getRulesets.fulfilled, (state, action) => {
        state.rulesets.status = "idle";
        state.rulesets.list = action.payload.rulesets;
      })
      .addCase(updateRulesets.pending, (state) => {
        state.updatingStatus = "loading";
      })
      .addCase(updateRulesets.fulfilled, (state, action) => {
        state.rulesets.list = action.payload.rulesets;
        state.updatingStatus = "idle";
      });
  },
});

export const selectRuleSets = (state) => state.pageTargeting.rulesets?.list;

export default pageTargetingSlice.reducer;
