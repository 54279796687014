import { useQuery } from "react-query";
import find from "lodash/find";

import queryClient from "../constants/queryClient";
import { assetURLs } from "../constants/apiURLs";
import { API_QUERY_KEYS } from "../constants/textMappings";
import request from "../utilities/request";

export const useAssets = () => {
  const { data, ...meta } = useQuery(API_QUERY_KEYS.ASSET_REQUEST, () => {
    return request().get(assetURLs.list());
  });

  return {
    assets: data?.assets ?? [],
    ...meta,
  };
};

export const useAsset = (assetID) => {
  const { assets, ...meta } = useAssets();
  const asset = find(assets, { id: assetID });

  return {
    asset: asset ?? {},
    ...meta,
  };
};

export const invalidateAssetCache = () => {
  queryClient.invalidateQueries(API_QUERY_KEYS.ASSET_REQUEST);
};

export const assetSquareCropLink = (asset) => {
  if (typeof asset === "undefined" || asset === null) {
    return "/img/no-image.svg";
  }

  let url =
    asset?.image_square_crop_url ?? asset?.image_original_url ?? asset?.url;

  return url;
};

export const assetOriginalLink = (asset) => {
  if (typeof asset === "undefined" || asset === null) {
    return "/img/no-image.svg";
  }

  let url = asset?.image_original_url ?? asset?.url;

  return url;
};

export const assetVerticalCropLink = (asset) => {
  if (typeof asset === "undefined" || asset === null) {
    return "/img/no-image.svg";
  }

  let url =
    asset?.image_vertical_crop_url ?? asset?.image_original_url ?? asset?.url;

  return url;
};

export const assetPreviewLink = (asset) => {
  if (typeof asset === "undefined" || asset === null) {
    return "/img/select-media.svg";
  }

  let previewImage = asset?.thumbnail_url ?? asset?.url;

  // If a mux stream, use the gif instead
  if (asset.type === "mux_stream") {
    previewImage =
      "https://image.mux.com/" + asset.mux_playback_id + "/animated.gif";
  }

  return previewImage;
};

export const assetIsVisual = (asset) => {
  if (typeof asset === "undefined" || asset === null) {
    return false;
  }

  return assetIsImage(asset) || assetIsVideo(asset);
};

export const assetIsImage = (asset) => {
  if (typeof asset === "undefined" || asset === null) {
    return false;
  }

  return asset.type === "image";
};

export const assetIsVideo = (asset) => {
  if (typeof asset === "undefined" || asset === null) {
    return false;
  }

  return asset.type === "mux_stream" || asset.type === "cloudflare_stream";
};

export const assetSourceInstagram = (asset) => {
  if (typeof asset === "undefined" || asset === null) {
    return false;
  }

  if (typeof asset.instagram_media_id === "undefined") {
    return false;
  }

  return asset.instagram_media_id !== "";
};

export const assetSourceUploaded = (asset) => {
  if (typeof asset === "undefined" || asset === null) {
    return false;
  }

  return !assetSourceInstagram(asset);
};
