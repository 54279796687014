import request from "../../utilities/request";
import { shopifyDataURLs } from "../../constants/apiURLs";

export function fetchShopifySyncStatus() {
  return new Promise((resolve) => {
    const response = request().get(shopifyDataURLs.shopifySyncStatus());
    resolve(response);
  });
}

export function fetchShopifyCollections() {
  return new Promise((resolve) => {
    const response = request().get(shopifyDataURLs.shopifyCollectionList());
    resolve(response);
  });
}

export function fetchShopifyProducts() {
  return new Promise((resolve) => {
    const response = request().get(shopifyDataURLs.shopifyProductList());
    resolve(response);
  });
}

export function fetchShopifyProduct(productId) {
  return new Promise((resolve) => {
    const response = request().get(shopifyDataURLs.shopifyProduct(productId));
    resolve(response);
  });
}
