import { useState } from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";
import AddIcon from "@mui/icons-material/Add";
import {
  Grid,
  Button,
  Stack,
  Alert,
  AlertTitle,
  Paper,
  Typography,
  Divider,
} from "@mui/material";

import { useMicroshops } from "../../api/fetchMicroshopData";
import PlanUpgradeBanner from "../../components/PlanUpgradeBanner";
import PlanValidationStatus from "../../components/PlanValidationStatus";
import Page from "../../components/Page";
import { MicroshopCreate } from "../../modules/microshops/MicroshopCreate";
import { MicroshopsList } from "../../modules/microshops/MicroshopList";
import { InfluencerList } from "../../modules/influencers/InfluencerList";
import { InfluencerCreate } from "../../modules/influencers/InfluencerCreate";
import { Product } from "../../modules/shopify/ProductSelector";
import { useSnackbar } from "notistack";
import { apiCall } from "../../utilities/request";
import { microshopURLs } from "../../constants/apiURLs";
import TimedProgressBar from "../../components/TimedProgressBar";

export type CreateMicroshopPayload = {
  name: string;
  handle: string;
  recipe: string;
  discount_code?: string;
  shopify_product_ids: string[];
  influencer_id: string;
  featured_product_id?: string;
  featured_product_assets?: Record<Product['id'], string>; // productId: asset.id
  featured_asset?: string; // Collections level Kahani Media Library Asset ID
  attributes?: {
    enable_shop_pay?: boolean;
    enable_timer_bar?: boolean;
  }
};

export const MicroshopsPage = () => {
  const {isError, isLoading, microshops, refetch} = useMicroshops();
  const [showCreateMicroshopModal, setShowCreateMicroshopModal] = useState<boolean>(false);
  const [showCreateInfluencerModal, setShowCreateInfluencerModal] = useState<boolean>(false);
  const [creatingMicroshops, setCreatingMicroshops] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const createMicroshop = (payload: CreateMicroshopPayload) => {
    return new Promise<void>(async (resolve) => {
      try {
        const res = await apiCall.post(microshopURLs.list(), payload);
        await apiCall.patch(microshopURLs.details(res.data?.microshop.id), { published: true })
        await apiCall.patch(microshopURLs.details(res.data?.microshop.id), { published: false })
        refetch();
        resolve();
      } catch (error) {
        console.error(`error creating ${payload.name} microshop`, error);
        enqueueSnackbar(`An error occurred while creating the ${payload.name} microshop`, {variant: "error"});
        resolve();
      }
    });
  }

  const onCreateMicroshops = async (createPayloads: CreateMicroshopPayload[]) => {
    if (creatingMicroshops) return;

    setCreatingMicroshops(true);
    setShowCreateMicroshopModal(false);

    for (const payload of createPayloads) {
      await createMicroshop(payload);
    }

    setCreatingMicroshops(false);
  }

  return (
    <Page title="Microshops" isLoading={isLoading}>
      <Grid item xs={12}>
        <PlanValidationStatus />
        <PlanUpgradeBanner />
        <Page.Title>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <span id="microshops-page-title">Microshops</span>
            <Button
              variant="contained"
              color="primary"
              id="create-collection-button"
              startIcon={<AddIcon />}
              onClick={() =>
                setShowCreateMicroshopModal(!showCreateMicroshopModal)
              }
              style={{ marginLeft: "18px" }}
            >
              Create Microshop
            </Button>
          </Stack>
          <Alert severity="info" sx={{ marginTop: 2 }}>
            Microshops convert customers <strong>3x</strong> faster than
            traditional online stores
          </Alert>
        </Page.Title>
        {creatingMicroshops && (
          <div className="mt-4">
            <TimedProgressBar duration={60} type="linear" />
            <div className="my-4 text-center text-sm">Creating microshops...</div>
          </div>
        )}
        {isError ? (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            Error loading microshops
          </Alert>
        ) : (
          <>
            {microshops.length === 0 ? (
                <Paper sx={{ p: 8, mt: 4 }}>
                  <Stack direction="column" justifyContent="center" alignItems="center">
                      <Typography component="p">No microshops created yet</Typography>
                      <Button
                        variant="contained"
                        sx={{ mt: 4 }}
                        color="primary"
                        startIcon={<AddBoxIcon />}
                        onClick={() => setShowCreateMicroshopModal(true)}
                      >
                        Create Microshop
                      </Button>
                  </Stack>
                </Paper>
            ) : (
              <MicroshopsList creatingMicroshops={creatingMicroshops} />
            )}
          </>
        )}
      </Grid>
      <Divider
        sx={{
          my: 8,
        }}
      />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h6" gutterBottom>Influencers</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            setShowCreateInfluencerModal(true);
          }}
        >
          Add Influencer
        </Button>
      </Stack>
      <InfluencerList
        onSelect={undefined}
        mode="edit"
      />

      <MicroshopCreate
        open={showCreateMicroshopModal}
        onClose={() => setShowCreateMicroshopModal(false)}
        onCreateMicroshops={onCreateMicroshops}
      />
      <InfluencerCreate.Dialog
        influencerID={undefined}
        open={showCreateInfluencerModal}
        onClose={() => setShowCreateInfluencerModal(false)}
        mode="create"
      />
    </Page>
  );
};

export default MicroshopsPage;
