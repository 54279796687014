import * as React from "react";
import { Link } from "react-router-dom";
import env from "react-dotenv";

import AccountOverrideAlert from "../components/AccountOverrideAlert";
import ActiveMenuLink from "./ActiveMenuLink";

import { hasOverrideAccountID, useAuth } from "../context/auth";
import { useAccount } from "../api/fetchAccountData";

// Styling
import { Box, Divider, List, Typography, ListSubheader } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import StoreIcon from "@mui/icons-material/Store";
import SettingsIcon from "@mui/icons-material/Settings";
import InstallDesktopIcon from "@mui/icons-material/InstallDesktop";
import "./styles/navigation.css";

export default function MainMenu({ open }) {
  const auth = useAuth();
  const theme = useTheme();
  const themeMode = theme.palette.mode;
  const { account } = useAccount();
  const isEnrolled = account.has_active_billing_plan;

  // Get first 8 characters of commit ID (if it exists)
  const commitID = env?.COMMIT_REF
    ? env.COMMIT_REF.substring(0, 8)
    : "live local";

  return (
    <div id="main-menu" className={!open ? "collapsed" : null}>
      {/* Only show the admin menu if logged in as an admin */}
      {auth.isAdmin && (
        <>
          <List>
            <ListSubheader inset>Admin</ListSubheader>
            <ActiveMenuLink
              to="/admin"
              label="Account Selector"
              icon={<PeopleOutlineIcon />}
            />
          </List>
          <Divider />
          <AccountOverrideAlert />
          <Divider />
        </>
      )}
      {/* Only show the other menus if we're not an admin OR we are an admin and have selected an account ID to assume */}
      {(!auth.isAdmin || hasOverrideAccountID()) && (
        <>
          <List>
            <ActiveMenuLink
              className={`storyblocks-tab-link ${themeMode}`}
              to="/story_blocks"
              label="Story Blocks"
            />
            {/* Only show microshops to admins */}
            {hasOverrideAccountID() && (
              <ActiveMenuLink
                className={`microshops-tab-link`}
                to="/microshops"
                label="Microshops"
                icon={<StoreIcon />}
              />
            )}
            <ActiveMenuLink
              to="/landing_pages"
              label="Landing Pages"
              icon={<FlightTakeoffIcon />}
            />
          </List>
          <List>
            <ListSubheader inset>Assets</ListSubheader>
            <ActiveMenuLink
              to="/assets"
              label="Media"
              icon={<PermMediaIcon />}
            />
            <ActiveMenuLink
              to="/ctas"
              label="CTAs"
              icon={<AddShoppingCartIcon />}
            />
          </List>
          <List>
            <ListSubheader inset>Account</ListSubheader>
            <ActiveMenuLink
              to="/publish"
              label="Publishing Rules"
              icon={<DocumentScannerIcon />}
            />
            <ActiveMenuLink
              to="/shopify_installation"
              label="Shopify Installation"
              icon={<InstallDesktopIcon />}
            />
            <ActiveMenuLink
              to="/account"
              label="Settings"
              icon={<SettingsIcon />}
            />
          </List>
          <Box
            sx={{
              bottom: 10,
              left: 10,
              position: "fixed",
              display: "flex",
              justifyContent: "space-between",
              zIndex: 0,
            }}
            className="build-text"
          >
            <Typography variant="body1" color="#b0b0b0">
              Build {commitID}
            </Typography>
          </Box>
        </>
      )}
      {!isEnrolled && (
        <div
          style={{
            padding: "10px 10px 25px 10px",
            width: "100%",
            textAlign: "center",
            textDecoration: "none",
            marginTop: "2rem",
          }}
          className="activate-plan-nav-text"
        >
          <div
            style={{
              whiteSpace: "break-spaces",
              fontSize: "14px",
              color: "grey",
              marginBottom: "5px",
            }}
          >
            You aren't enrolled in an active plan. Redeem your plan code.
          </div>
          <Link
            to="/account"
            style={{ textDecoration: "none", fontWeight: "bold" }}
          >
            Activate Plan
          </Link>
        </div>
      )}
    </div>
  );
}
