import { useReducer } from "react";

import { generateSetters, generateInitialState, reducer } from "./baseModel";

const settableKeys = {
  name: "internal_name",
  description: "internal_description",
  duration: "dwell",
  asset: "asset",
  ctas: "ctas",
};

const initialState = {
  internal_name: "",
  internal_description: "",
  ctas: [],
  dwell: 0,
};

const useChapterDetails = (chapter) => {
  const [state, dispatch] = useReducer(
    reducer,
    generateInitialState({
      ...initialState,
      ...chapter,
    })
  );
  const setters = generateSetters(settableKeys, dispatch);

  return {
    ...setters,
    ...state,
  };
};

export default useChapterDetails;
