import React, {
  useEffect,
  forwardRef,
  useRef,
  createContext,
  useContext,
} from "react";
import { VariableSizeList } from "react-window";

import { styled, useTheme } from "@mui/material/styles";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import { Box, CircularProgress, Popper, TextField } from "@mui/material";

/**
 * @param {string} label Dropdown Label
 * @param {Array.<Object>} options Selection Options
 *  { id, label, url, title, secondaryTitle }
 * @param {function} onSelect Callback that fires when selection is made
 * @param {boolean} loading Loading State of Dropdown Options
 * @param {boolean} disabled Sets Disabled State of Form
 */

const LISTBOX_PADDING = 8; // px

function RenderRow(props) {
  const { data, index, style } = props;
  const dataSet = data[index];

  const theme = useTheme();

  return (
    <Box
      component="li"
      sx={{
        "& img": { mr: 2, flexShrink: 0 },
        "& > div": {
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        },
        "& span": {
          display: "flex",
          alignItems: "center",
        },
      }}
      style={style}
      {...dataSet[0]}
      // key attribute must be after {...props} or it will be overridden
      key={dataSet[1].id}
    >
      <div id={dataSet[1].id} data-value={dataSet[1].title}>
        <span>
          {dataSet[1].url && (
            <img
              loading="lazy"
              width="75"
              height="75"
              style={{ objectFit: "contain" }}
              src={dataSet[1].url}
              alt={dataSet[1].id}
            />
          )}
          <span style={{ display: "none" }}>{dataSet[1].label}</span>
          <span>{dataSet[1].title}</span>
        </span>
        <span style={{ color: theme.palette.text.secondary }}>
          {dataSet[1].id}
        </span>
      </div>
    </Box>
  );
}

const OuterElementContext = createContext({});

const OuterElementType = forwardRef((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = [];
  let itemSize = 36;

  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...(item.children || []));

    if (item[1].url) {
      itemSize = 80;
    }
  });
  const itemCount = itemData.length;

  const getChildSize = () => itemSize;

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {RenderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
});

export default function Virtualize({
  label,
  options,
  onSelect,
  disabled = false,
  loading = false,
}) {
  return (
    <Autocomplete
      id="virtualize-demo"
      disableListWrap
      disabled={disabled}
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      options={options}
      onChange={(_, newValue) => {
        onSelect(newValue);
      }}
      renderInput={(params) => {
        params.inputProps.value = params.inputProps.value.split(" * ")[0];
        return (
          <TextField
            {...params}
            label={label}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        );
      }}
      renderOption={(props, option) => [props, option]}
      renderGroup={(params) => params}
    />
  );
}
