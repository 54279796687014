import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  googleAnalytics: false,
  status: "idle",
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
});

export default settingsSlice.reducer;
