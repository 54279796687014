import { useQuery } from "react-query";

import queryClient from "../constants/queryClient";
import { accountURLs } from "../constants/apiURLs";
import { API_QUERY_KEYS } from "../constants/textMappings";
import request from "../utilities/request";

export const usePageTargetingRulesets = () => {
  const { data, ...meta } = useQuery(
    API_QUERY_KEYS.PAGE_TARGETING_RULESET_REQUEST,
    () => {
      return request().get(accountURLs.pageTargeting());
    }
  );

  return {
    rulesets: data?.rulesets ?? [],
    ...meta,
  };
};

export const invalidatePageTargetingCache = () => {
  return queryClient.invalidateQueries(
    API_QUERY_KEYS.PAGE_TARGETING_RULESET_REQUEST
  );
};
