import React from "react";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";

import RightCloseIcon from "../../../modules/assets/RightCloseIcon";
import { invalidateCollectionCache, useChapters } from "../../../api/fetchCollectionData";
import { apiCall } from "../../../utilities/request";
import { storyURLs } from "../../../constants/apiURLs";

const style = {
  topMargin: {
    marginTop: 1,
  },
  box: {
    bgcolor: "background.paper",
    boxShadow: 24,
    display: "flex",
    flexDirection: "column",
    height: 275,
    justifyContent: "space-between",
    left: "50%",
    p: 3,
    top: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    width: 600,
  },
};

export default function DeleteChapterModal({ open, onClose, chapterId, storyId, storyBlockId }) {
  const { enqueueSnackbar } = useSnackbar();
  const {chapters} = useChapters(storyId);

  const onDelete = async () => {
    try {
      await apiCall.put(storyURLs.chapterOrder(storyId), {
        chapters: chapters.reduce((chapterIds, chapter) => (chapter.id !== chapterId ? [...chapterIds, chapter.id] : chapterIds), []),
      });
      invalidateCollectionCache(storyBlockId);
      enqueueSnackbar("Chapter deleted successfully!", {variant: "success"});
      onClose();
    } catch (error) {
      enqueueSnackbar("Failed to delete chapter", {variant: "error"});
    }
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style.box}>
        <Typography variant="h5">
          Delete Chapter
          <RightCloseIcon onClick={onClose} />
        </Typography>
        <Alert severity="error">
          <AlertTitle>
            <strong>Are you sure you want to delete this chapter?</strong>
          </AlertTitle>
          This cannot be undone.
        </Alert>
        <Stack direction="row" justifyContent="end" sx={style.topMargin}>
          <Button variant="outlined" color="error" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            loading={false}
            loadingPosition="start"
            color="error"
            variant="contained"
            startIcon={<SaveIcon />}
            onClick={onDelete}
            style={{ marginLeft: "10px" }}
          >
            Delete
          </LoadingButton>
        </Stack>
      </Box>
    </Modal>
  );
}
