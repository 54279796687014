import React from "react";

import {
  Alert,
  Dialog,
  DialogActions,
  AlertTitle,
  DialogContent,
  DialogTitle,
  Box,
  Button,
  Typography,
  Grid,
  CircularProgress,
  Card,
  CardMedia,
  CardContent,
  Stack,
} from "@mui/material";
import { instagramOAuthURLs } from "../../constants/apiURLs";
import { apiCall } from "../../utilities/request";
import { invalidateAccountCache } from "../../api/fetchAccountData";
import { useAccount } from "../../../src/api/fetchAccountData";
import { useSnackbar } from "notistack";
import { getInstagramOAuthRedirectURI } from "../../utilities/instagramOAuth";
import InstagramIcon from "@mui/icons-material/Instagram";
import { SlideUpFromBottom } from "../../utilities/transitions";
import RightCloseIcon from "../assets/RightCloseIcon";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

export default function Instagram() {
  const {
    account: { has_instagram_auth, has_instagram_account },
  } = useAccount();

  const [accountSelectorDialogOpen, setAccountSelectorDialogOpen] =
    React.useState(false);

  // When the page loads with the "igsuccess" in the URL params, show the selector dialog
  React.useEffect(() => {
    if (window.location.search.includes("igsuccess")) {
      setAccountSelectorDialogOpen(true);
    }
  }, []);

  // If there is an access token & account ID, just show success
  if (has_instagram_auth && has_instagram_account) {
    return (
      <Alert
        severity="success"
        sx={{
          width: "100%",
          mb: 2,
        }}
      >
        Connected to Instagram
      </Alert>
    );
  }

  // If there isn't an access token, show the button to connect
  if (!has_instagram_auth) {
    return <OAuthButton text="Connect to Instagram" />;
  }
  return (
    <>
      <Alert
        severity="warning"
        sx={{
          width: "100%",
          mb: 2,
        }}
      >
        <AlertTitle>Not connected yet</AlertTitle>
        <Typography variant="body1" gutterBottom>
          Select an Instagram business account to finish the integration
        </Typography>
        <Button
          variant="contained"
          type="button"
          onClick={() => {
            setAccountSelectorDialogOpen(true);
          }}
          startIcon={<InstagramIcon />}
        >
          Select Account
        </Button>
      </Alert>
      <AccountSelectorDialog
        open={accountSelectorDialogOpen}
        onClose={() => setAccountSelectorDialogOpen(false)}
      />
    </>
  );
}

const OAuthButton = ({ text }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Button
        variant="contained"
        type="button"
        startIcon={<InstagramIcon />}
        onClick={() => {
          window.location.assign(getInstagramOAuthRedirectURI());
        }}
      >
        {text}
      </Button>
    </Box>
  );
};

const AccountSelectorDialog = ({ open, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [accounts, setAccounts] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  // When the dialog opens, fetch the list of accounts
  React.useEffect(() => {
    if (open) {
      setLoading(true);
      setError(null);
      setAccounts(null);
      apiCall
        .get(instagramOAuthURLs.getBusinessAccounts())
        .then((res) => {
          console.info("Got biz accounts", res);
          if (res?.data?.ig_accounts?.length > 0) {
            setAccounts(res?.data?.ig_accounts);
          } else {
            setError("No Instagram business accounts found");
          }
        })
        .catch((error) => {
          console.error("Error getting IG accounts");
          console.error(error.response);
          setError("Could not load your Instagram business accounts");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [open]);

  // function to save changes
  const select = (instagram_business_account_id) => {
    apiCall
      .put(instagramOAuthURLs.setBusinessAccountID(), {
        instagram_business_account_id: instagram_business_account_id,
      })
      .then((res) => {
        console.info("Set biz account ID", res);

        invalidateAccountCache();

        enqueueSnackbar("Your IG media will be imported in the background", {
          variant: "success",
        });

        onClose();
      })
      .catch((error) => {
        console.error("Error saving IG account ID");
        console.error(error.response);

        enqueueSnackbar("Error selecting account", {
          variant: "error",
        });
      });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={SlideUpFromBottom}
      fullWidth={true}
      maxWidth={"md"}
    >
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        Select Instagram Business Account
        <RightCloseIcon onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        {error && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {error}
          </Alert>
        )}
        {loading && (
          <>
            <Typography variant="h6">
              <CircularProgress size={24} /> Loading...
            </Typography>
          </>
        )}
        {accounts && accounts.length > 0 && (
          <>
            <Alert severity="info" sx={{ mb: 2 }}>
              Select the Instagram business account you want to connect to
              Kahani.
            </Alert>
            <Grid container spacing={2}>
              {accounts.map((account) => (
                <Grid item xs={6} key={account.id}>
                  <Card
                    sx={{
                      backgroundColor: "rgba(0,0,0,0.02)",
                    }}
                  >
                    <CardMedia
                      component="img"
                      height="140"
                      image={account.profile_picture_url}
                      alt={account.name}
                    />
                    <CardContent>
                      <Grid container justifyContent="space-between">
                        <Grid item xs={6}>
                          <Typography variant="h5" gutterBottom>
                            {account.name}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            @{account.username}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Stack
                            direction="column"
                            spacing={1}
                            sx={{ textAlign: "right" }}
                          >
                            <Button
                              variant="text"
                              href={`https://www.instagram.com/${account.username}`}
                              target="_blank"
                              rel="noopener"
                              endIcon={<OpenInNewIcon />}
                            >
                              View Account
                            </Button>
                            <Button
                              variant="contained"
                              type="button"
                              onClick={() => select(account.id)}
                              startIcon={<InstagramIcon />}
                            >
                              Select Account
                            </Button>
                          </Stack>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </>
        )}
        {accounts && accounts.length === 0 && (
          <Typography variant="body1">
            You don't have any Instagram business accounts connected to this
            Facebook account.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};
