import React from "react";
import { useNavigate } from "react-router";

import {
  Alert,
  AlertTitle,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import PasswordField from "../../components/PasswordField";
import { useAuth } from "../../context/auth";
import queryClient from "../../constants/queryClient";
import { getEnvironment } from "../../utilities/environment";

const KahaniLoginForm = ({ from }) => {
  let navigate = useNavigate();
  let auth = useAuth();
  // Logout success message
  const [loginError, setLoginError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  // Credentials
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  function handleSubmit(e) {
    e.preventDefault();

    setLoginError(null);
    setLoading(true);

    auth.signin(
      username,
      password,
      (resp) => {
        setLoading(false);

        // Invalidate all existing objects
        queryClient.invalidateQueries();

        // Identify the user to segment
        window.analytics.identify(resp.user.id, {
          name: resp.user.name,
          environment: getEnvironment(),
          admin: resp.isAdmin,
        });

        window.analytics.track("login", {
          environment: getEnvironment(),
        });

        // Check if we're an admin
        if (resp.isAdmin) {
          navigate("/admin");
          return;
        }

        // Send them back to the page they tried to visit when they were
        // redirected to the login page. Use { replace: true } so we don't create
        // another entry in the history stack for the login page.  This means that
        // when they get to the protected page and click the back button, they
        // won't end up back on the login page, which is also really nice for the
        // user experience.
        navigate(from, { replace: true });
      },
      (error) => {
        // Check if the error is a network error. THis should be the case if there is no error response
        // from the server.
        console.error("error message: ", error.message);
        if (error.response === undefined) {
          setLoginError("Network error. Please try again later.");
          setLoading(false);
          return;
        }

        setLoading(false);
        console.error("login error: ", error);
        console.error(error);
        console.error(error.response);
        setLoginError("Invalid credentials");
      }
    );
  }

  return (
    <Paper sx={{ p: 4, marginTop: "2rem" }}>
      <form onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <Typography variant="h5" color="primary">
            Log in with Kahani Account
          </Typography>
          <TextField
            label="Username"
            value={username}
            disabled={loading}
            fullWidth={true}
            onChange={(e) => setUsername(e.target.value)}
          />
          <PasswordField
            label="Password"
            type="password"
            value={password}
            fullWidth={true}
            onChange={(e) => setPassword(e.target.value)}
            helperText="To reset your password, contact support@kahaniapp.com"
          />
          <LoadingButton
            loading={loading}
            color="primary"
            loadingPosition="start"
            variant="contained"
            type="submit"
          >
            Log In
          </LoadingButton>
          {loginError && (
            <Alert
              onClose={() => {
                setLoginError(null);
              }}
              severity="error"
            >
              <AlertTitle>Error</AlertTitle>
              {loginError}
            </Alert>
          )}
        </Stack>
      </form>
    </Paper>
  );
};

export default KahaniLoginForm;
