import React from "react";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { ThemeProvider, CssBaseline } from "@mui/material";

// Admin Settings Listener
import { useKonamiCode } from "./utilities/konamiCode";

// State Management
import { AuthProvider } from "./context/auth";
import queryClient from "./constants/queryClient";
import { MainContext, useMainContext } from "./context/main";
import { ThemeContext, useThemeContext } from "./context/theme";

// Pages
import AdminSettingsDialog from "./modules/adminSettings.jsx/adminSettingsDialog";
import { RouteList } from "./RouteList";

export const App = () => {
  const konamiActivated = useKonamiCode();

  const value = useMainContext();
  const { theme, themeValues } = useThemeContext();

  return (
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      >
        <AuthProvider>
          <AdminSettingsDialog open={konamiActivated} />
          <BrowserRouter>
            <ThemeContext.Provider value={themeValues}>
              <MainContext.Provider value={value}>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <RouteList />
                </ThemeProvider>
              </MainContext.Provider>
            </ThemeContext.Provider>
          </BrowserRouter>
        </AuthProvider>
      </SnackbarProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}
