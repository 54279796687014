import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import {
  Avatar,
  Dialog,
  AlertTitle,
  Typography,
  DialogTitle,
  DialogContent,
  Box,
  Button,
  Grid,
  TextField,
  Stack,
  Grow,
  Alert,
  CircularProgress,
  LinearProgress,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
// import InstagramIcon from "@mui/icons-material/Instagram";

import { apiCall } from "../../utilities/request";
import { influencerURLs } from "../../constants/apiURLs";
import { SlideUpFromBottom } from "../../utilities/transitions";
import RightCloseIcon from "../assets/RightCloseIcon";
import { invalidateInfluencerCache } from "../../api/fetchInfluencerData";
import { useInfluencer } from "../../api/fetchInfluencerData";
import { formatBytes } from "../../utilities/helpers";

const Form = ({ onSuccess, onFailure, mode = "create", influencerID }) => {
  // Error out if mode is not "create" or "edit"
  if (!["create", "edit"].includes(mode)) {
    throw new Error(`Invalid influencer form mode: ${mode}`);
  }

  const { enqueueSnackbar } = useSnackbar();

  // const [avatarConfirmDialogOpen, setAvatarConfirmDialogOpen] = useState(false);
  // const [tempAvatarURL, setTempAvatarURL] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitProgress, setSubmitProgress] = useState(0);
  const [submitProgressBuffer, setSubmitProgressBuffer] = useState(5);
  const [name, setName] = useState("");
  const [profilePictureURL, setProfilePictureURL] = useState("");
  const [profilePictureFile, setProfilePictureFile] = useState(null);
  const [heroPictureURL, setHeroPictureURL] = useState("");
  const [heroPictureFile, setHeroPictureFile] = useState(null);
  const [instagramHandle, setInstagramHandle] = useState("");
  const [tiktokHandle, setTiktokHandle] = useState("");
  const [brandReview, setBrandReview] = useState("");
  // const [avatarLoading, setAvatarLoading] = useState(false);
  const { influencer } = useInfluencer(influencerID);
  const [hasMissingFields, setHasMissingFields] = useState(false);
  
  useEffect(() => {
    if (submitProgress > 100) {
      setSubmitProgressBuffer(10);
    } else {
      const diff = Math.random() * 6;
      const diff2 = Math.random() * 8;
      setSubmitProgressBuffer(submitProgress + diff + diff2);
    }
  }, [submitProgress]);

  // Updatge the buffer every second while uploadProgress > 0
  useEffect(() => {
    const timer = setInterval(() => {
      if (submitProgress <= 0 && submitProgress >= 100) {
        clearInterval(timer);
      } else {
        const diff = Math.random() * 6;
        const diff2 = Math.random() * 8;
        setSubmitProgressBuffer(submitProgress + diff + diff2);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [submitProgress]);

  // When the influencer is loaded, update the form fields
  useEffect(() => {
    if (influencer) {
      if (influencer?.name) {
        setName(influencer.name);
      }
      if (influencer?.profile_picture_url) {
        setProfilePictureURL(influencer.profile_picture_url);
      }
      if (influencer?.hero_image_url) {
        setHeroPictureURL(influencer.hero_image_url);
      }
      if (influencer?.instagram_handle) {
        setInstagramHandle(influencer.instagram_handle);
      }
      if (influencer?.tiktok_handle) {
        setTiktokHandle(influencer.tiktok_handle);
      }
      if (influencer?.brand_review) {
        setBrandReview(influencer.brand_review);
      }
    }
  }, [influencer]);

  const validateForm = () => {
    if (
      !name ||
      !brandReview ||
      !profilePictureURL
    ) {
      setHasMissingFields(true);
      return false;
    }

    setHasMissingFields(false);
    return true;  
  }

  const save = () => {
    let data = new FormData();
    let url = "";
    const isFormValid = validateForm();

    // Make sure all required fields have been filled out
    if (!isFormValid) {
      return;
    }

    if (mode === "create") {
      url = influencerURLs.list();

      data.append("name", name);
      data.append("instagram_handle", instagramHandle);
      data.append("tiktok_handle", tiktokHandle);
      data.append("brand_review", brandReview);

      // If a profile pic file is set, default to that
      if (profilePictureFile) {
        data.append("profile_picture_file", profilePictureFile);
      } else {
        data.append("profile_picture_url", profilePictureURL);
      }

      // Append optional hero image if it exists
      if (heroPictureFile) {
        data.append('hero_image_file', heroPictureFile);
      } else if (heroPictureURL) {
        data.append('hero_image_url', heroPictureURL);
      }
    } else if (mode === "edit") {
      url = influencerURLs.details(influencerID);
      // If fields have changed, send them up
      if (name !== influencer?.name) {
        data.append("name", name);
      }
      if (instagramHandle !== influencer?.instagram_handle) {
        data.append("instagram_handle", instagramHandle);
      }
      if (tiktokHandle !== influencer?.tiktok_handle) {
        data.append("tiktok_handle", tiktokHandle);
      }
      if (brandReview !== influencer?.brand_review) {
        data.append("brand_review", brandReview);
      }

      // If a profile pic file is set, default to that
      if (profilePictureFile) {
        data.append("profile_picture_file", profilePictureFile);
      } else {
        if (profilePictureURL !== influencer?.profile_picture_url) {
          data.append("profile_picture_url", profilePictureURL);
        }
      }

      // Append optional hero image if it exists
      if (heroPictureFile) {
        data.append('hero_image_file', heroPictureFile);
      } else if (heroPictureURL) {
        data.append('hero_image_url', heroPictureURL);
      }
    }

    const success = (response) => {
      const message =
        mode === "create"
          ? "Influencer created successfully"
          : "Influencer updated successfully";

      enqueueSnackbar(message, {
        variant: "success",
      });

      invalidateInfluencerCache();

      if (typeof onSuccess === "function") {
        onSuccess(response.data?.influencer);
      }
    };

    const fail = (error) => {
      const message =
        mode === "create"
          ? "An error occurred while creating the influencer"
          : "An error occurred while updating the influencer";
      enqueueSnackbar(message, {
        variant: "error",
      });
      if (typeof onFailure === "function") {
        onFailure(error);
      }
    };

    setIsSubmitting(true);
    setSubmitProgress(0);

    if (mode === "create") {
      apiCall
        .post(url, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );

            setSubmitProgress(percentCompleted * 0.8); // 80% of progress is upload, 20% is online processing
          },
        })
        .then(success)
        .catch(fail)
        .finally(() => {
          setIsSubmitting(false);
          setSubmitProgress(0);
        });
    } else if (mode === "edit") {
      apiCall
        .put(url, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );

            setSubmitProgress(percentCompleted * 0.8); // 80% of progress is upload, 20% is online processing
          },
        })
        .then(success)
        .catch(fail)
        .finally(() => {
          setIsSubmitting(false);
          setSubmitProgress(0);
        });
    }
  };

  // Get Instagram Profile Picture
  // const socialAvatar = (service) => {
  //   if (service === "instagram") {
  //     // handle must be set
  //     if (instagramHandle === "") {
  //       enqueueSnackbar("Please enter an Instagram handle", {
  //         variant: "warning",
  //       });
  //       return;
  //     }

  //     // get profile picture
  //     setAvatarLoading(true);

  //     apiCall
  //       .get(influencerURLs.scrapeIGProfilePicture(instagramHandle))
  //       .then((response) => {
  //         console.debug(response.data);
  //         if (response.data?.profile_picture_url) {
  //           setTempAvatarURL(response.data.profile_picture_url);
  //           setAvatarConfirmDialogOpen(true);
  //         } else {
  //           enqueueSnackbar("Unable to find Instagram profile picture", {
  //             variant: "warning",
  //           });
  //         }
  //       })
  //       .catch((error) => {
  //         enqueueSnackbar("Unable to find Instagram profile picture", {
  //           variant: "warning",
  //         });
  //       })
  //       .finally(() => {
  //         setAvatarLoading(false);
  //       });
  //   } else if (service === "tiktok") {
  //     // @todo
  //     alert("Not implemented");
  //   }
  // };

  return (
    <>
      <Grid container spacing={2}>
        {isSubmitting && (
          <Grid item xs={12}>
            <Grow in={true}>
              <Alert severity="info" icon={<SaveIcon />}>
                <AlertTitle>
                  {submitProgress < 75
                    ? "Uploading image"
                    : "Saving influencer persona"}
                </AlertTitle>
                <LinearProgress
                  variant="buffer"
                  valueBuffer={submitProgressBuffer}
                  value={submitProgress}
                  sx={{ mt: 1, width: "300px", height: 15 }}
                />
              </Alert>
            </Grow>
          </Grid>
        )}
        <Grid item xs={12} md={3}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              borderRadius: 5,
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px dashed grey",
                borderRadius: 5,
                p: 2,
              }}
            >
              <Stack direction="column" alignItems="center">
                <img
                  src={heroPictureURL ? heroPictureURL : 'https://placehold.co/400x400?text=Upload+Image'}
                  alt="Influencer Hero"
                  className="rounded-xl cursor-pointer object-cover inline-block"
                  style={{ width: 200, height: 200 }}
                  onClick={() => {
                    document
                      .getElementById("influencer_hero_image_file")
                      .click();
                  }}
                />
                <Button
                  variant="outlined"
                  component="label"
                  sx={{ mt: 2 }}
                  disabled={isSubmitting}
                  onClick={() => {
                    document.getElementById("influencer_hero_image_file").click();
                  }}
                >
                  {heroPictureURL ? 'Edit' : 'Set'} Hero Image
                </Button>
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  id="influencer_hero_image_file"
                  onChange={(event) => {
                    if (event.target.files.length > 0) {
                      setHeroPictureFile(event.target.files[0]);
                      setHeroPictureURL(
                        URL.createObjectURL(event.target.files[0])
                      );
                    }
                  }}
                />
                {heroPictureFile && (
                  <Box
                    sx={{
                      my: 3,
                    }}
                  >
                    <strong>Upload File Size: </strong>{" "}
                    {formatBytes(heroPictureFile?.size)}
                  </Box>
                )}
              </Stack>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={9}>
          <div className="flex flex-row items-center gap-2 mb-4">
            <Avatar
              src={profilePictureURL}
              sx={{ width: 100, height: 100 }}
            />
            <div className="flex flex-col gap-2">
              <Button
                variant={profilePictureURL ? "text" : "outlined"}
                component="label"
                sx={{
                  height: 35
                }}
                disabled={isSubmitting}
                onClick={() => {
                  document.getElementById("influencer_profile_pic_file").click();
                }}
              >
                {profilePictureURL ? 'Edit' : 'Set'} Profile Image
              </Button>
              {hasMissingFields && !profilePictureURL && (
                <div className="text-red-500 text-xs">
                  Please upload a profile picture
                </div>
              )}
            </div>
            <input
              type="file"
              hidden
              accept="image/*"
              id="influencer_profile_pic_file"
              onChange={(event) => {
                if (event.target.files.length > 0) {
                  setProfilePictureFile(event.target.files[0]);
                  setProfilePictureURL(
                    URL.createObjectURL(event.target.files[0])
                  );
                }
              }}
            />
          </div>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            value={name}
            required
            error={hasMissingFields && !name}
            onChange={(event) => setName(event.target.value)}
            disabled={isSubmitting}
            sx={{
              mb: 2,
              mt: 1,
            }}
          />
          {/* <Grid container spacing={2}> */}
            {/* <Grid item xs={8}> */}
              {/* <TextField
                label="Instagram Handle"
                variant="outlined"
                fullWidth
                value={instagramHandle}
                onChange={(event) => setInstagramHandle(event.target.value)}
                disabled={isSubmitting}
                sx={{
                  mb: 2,
                  mt: 1,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">@</InputAdornment>
                  ),
                }}
              /> */}
            {/* </Grid> */}
            {/* <Grid item xs={4}>
              <Grow in={instagramHandle !== ""}>
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => socialAvatar("instagram")}
                  disabled={isSubmitting || avatarLoading}
                  startIcon={
                    avatarLoading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <InstagramIcon />
                    )
                  }
                >
                  Use Avatar
                </Button>
              </Grow>
            </Grid> */}

            {/* <Grid item xs={8}> */}
              {/* <TextField
                label="TikTok Handle"
                variant="outlined"
                fullWidth
                value={tiktokHandle}
                onChange={(event) => setTiktokHandle(event.target.value)}
                disabled={isSubmitting}
                sx={{
                  mb: 2,
                  mt: 1,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">@</InputAdornment>
                  ),
                }}
              /> */}
            {/* </Grid> */}
            {/* <Grid item xs={4}>
              <Grow in={tiktokHandle !== ""}>
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => socialAvatar("tiktok")}
                  disabled={isSubmitting || avatarLoading}
                >
                  Use Avatar
                </Button>
              </Grow>
            </Grid> */}
          {/* </Grid> */}
          <TextField
            label="Brand Review"
            variant="outlined"
            fullWidth
            multiline={true}
            required
            error={hasMissingFields && !brandReview}
            value={brandReview}
            onChange={(event) => setBrandReview(event.target.value)}
            disabled={isSubmitting}
            sx={{
              mb: 2,
              mt: 1,
            }}
          />
          {hasMissingFields && (
            <Alert severity="error" sx={{ mb: 2 }}>Please fill out all required fields</Alert>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={save}
            disabled={isSubmitting}
            startIcon={
              isSubmitting ? <CircularProgress size={20} /> : <SaveIcon />
            }
          >
            Save
          </Button>
          {mode === "edit" && (
            <Alert
              severity="info"
              sx={{
                mt: 2,
              }}
            >
              <AlertTitle>Info</AlertTitle>
              <Typography variant="body2">
                Updating this influencer will immediately change the microshops it's used in.
              </Typography>
            </Alert>
          )}
        </Grid>
      </Grid>
      {/* <Dialog
        open={avatarConfirmDialogOpen}
        onClose={() => setAvatarConfirmDialogOpen(false)}
        fullWidth
        TransitionComponent={SlideUpFromBottom}
      >
        <DialogTitle>Use This Avatar?</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              border: "1px dashed grey",
              borderRadius: 5,
              height: "100%",
              p: 2,
            }}
          >
            <Avatar
              src={tempAvatarURL}
              sx={{
                width: 150,
                height: 150,
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setAvatarConfirmDialogOpen(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setProfilePictureURL(tempAvatarURL);

              // Empty the file upload if one was set because we want to use the URL instead
              setProfilePictureFile(null);

              setAvatarConfirmDialogOpen(false);
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog> */}
    </>
  );
};

const DialogWithForm = ({ open, onClose, mode, influencerID }) => {
  const onSuccess = (data) => {
    console.info(`Influencer saved (${mode}) successfully`, data);
    onClose();
  };

  const onFailure = (error) => {
    console.error(
      `An error occurred while saving (${mode}) the influencer`,
      error
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      TransitionComponent={SlideUpFromBottom}
    >
      <DialogTitle>
        {mode === "edit" ? "Edit" : "Create"} Influencer
        <RightCloseIcon onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <Form
          onSuccess={onSuccess}
          onFailure={onFailure}
          mode={mode}
          influencerID={influencerID}
        />
      </DialogContent>
    </Dialog>
  );
};

export const InfluencerCreate = {
  Form,
  Dialog: DialogWithForm,
};
