import React from "react";

import "./CollectionCard.css";
import { Paper, Box, Typography, Stack, Divider, Button } from "@mui/material";
import StoryBubbles from "./stories/StoryBubbles";
import CheckIcon from "@mui/icons-material/Check";
import { useNavigate, useLocation } from "react-router";
import { useSnackbar } from "notistack";
import { useTheme } from "@mui/material/styles";
import PreviewIcon from "@mui/icons-material/Preview";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
// import HomeIcon from "@mui/icons-material/Home";
// import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
// import LocalOfferIcon from "@mui/icons-material/LocalOffer";

import { formatShopifyHandle } from "../../utilities/formatShopifyData";

const CollectionCard = ({ collection }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const {
    account_id,
    internal_name,
    id,
    stories,
    published,
    internal_description,
  } = collection;
  const name = internal_name?.replace("/", "/ ").replace(".", ". ");

  // Color focus this chapter
  let location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const focus =
    searchParams.get("focusCollectionID") !== null &&
    id === searchParams.get("focusCollectionID");

  // If this collection is focused, scroll to it on load
  React.useEffect(() => {
    if (focus) {
      const element = document.getElementById("collection-" + id);
      // Scroll slowly
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [focus, id]);

  // Count all chapters
  let chapterCount = 0;
  stories.forEach((story) => {
    chapterCount += story.chapters.length;
  });

  const landingPageUrl = `https://cdn.kahaniapp.com/landingpage.html?collectionID=${id}&accountID=${account_id}`;

  return (
    <div style={{ position: "relative", height: "100%" }}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          cursor: "pointer",
          height: "100%",
          overflow: 'hidden',
          // backgroundColor: focus ? "#E8F5E9" : "#fff",
          "&:hover": {
            boxShadow:
              "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
          },
        }}
        role="button"
        onClick={() => {
          // navigate to the collection page
          navigate(`/story_blocks/${id}`);
        }}
        elevation={focus ? 8 : 1}
        id={"collection-" + id}
      >
        <Box
          sx={{
            p: 3,
            m: "auto",
          }}
        >
          <StoryBubbles stories={stories} collectionID={id} />
        </Box>
        <Divider />
        <Box sx={{ px: 3, py: 2 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography variant="h6" component="h2">
                {name}
              </Typography>

              {formatShopifyHandle(collection).page === "" ? (
                <Typography variant="body2">
                  {stories.length} stories • {chapterCount} chapters
                </Typography>
              ) : (
                <Typography
                  variant="body2"
                  color={theme.palette.text.secondary}
                  sx={{ display: "flex", alignItems: "center", marginTop: 1 }}
                >
                  {formatShopifyHandle(collection).icon}
                  <span style={{ marginLeft: 10 }}>
                    {formatShopifyHandle(collection).page}
                  </span>
                </Typography>
              )}
            </Box>
            <Box
              component="span"
              sx={{
                backgroundColor: published ? "#E8F5E9" : "#ECEFF1",
                color: published ? "#1B5E20" : "rgba(0,0,0,0.6)",
                fontSize: 14,
                paddingTop: published ? 0.5 : 1,
                paddingBottom: 1,
                px: 2,
                borderRadius: 6,
                position: "relative",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                marginLeft: "10px",
                height: "fit-content",
              }}
            >
              {published ? (
                <>
                  <CheckIcon
                    sx={{
                      height: "20px",
                      top: "4px",
                      position: "relative",
                    }}
                    color="grey"
                  />
                  &nbsp; Published
                </>
              ) : (
                "Not Published"
              )}
            </Box>
          </Stack>
        </Box>
      </Paper>
      {internal_description === "||LP||" && (
        <div style={{ position: "absolute", bottom: "10px", right: "10px" }}>
          <Button
            variant="outlined"
            disabled={!stories.length}
            onClick={() => {
              enqueueSnackbar("URL Copied!", {
                variant: "success",
              });
              navigator.clipboard.writeText(landingPageUrl);
            }}
            startIcon={<ContentCopyIcon />}
          >
            Copy URL
          </Button>
          <Button
            sx={{ marginLeft: "5px" }}
            variant="contained"
            onClick={() => window.open(landingPageUrl)}
            disabled={!stories.length}
            startIcon={<PreviewIcon />}
          >
            Preview
          </Button>
        </div>
      )}
    </div>
  );
};

export default CollectionCard;
