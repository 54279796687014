import { Button, Checkbox, MenuItem, TextField } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

import { Product } from "../../shopify/ProductSelector";
import { useState } from "react";
import AssetSelectorDialog from "../../assets/AssetSelectorDialog";
import { Asset } from "../MicroshopCreate";

type Props = {
    discountCode: string;
    enableShopPay: boolean;
    enableTimerBar: boolean;
    featuredProductHandle: Product['handle'];
    selectedProducts: Product[];
    collectionsVideoAsset?: Asset;
    setDiscountCode: (discountCode: string) => void;
    setEnableShopPay: (enableShopPay: boolean) => void;
    setEnableTimerBar: (enableTimerBar: boolean) => void;
    setFeaturedProductHandle: (featuredProductHandle: Product['handle']) => void;
    setCollectionsVideoAsset: (collectionsVideoAsset: Asset) => void;
}

export const OptionalAddons = ({
    discountCode, 
    enableShopPay, 
    enableTimerBar, 
    featuredProductHandle,
    selectedProducts,
    collectionsVideoAsset,
    setDiscountCode,
    setEnableShopPay,
    setEnableTimerBar,
    setFeaturedProductHandle,
    setCollectionsVideoAsset,
}: Props) => {
    const [videoModalOpen, setVideoModalOpen] = useState(false);

    return (
        <div className="mt-12 mb-12 flex flex-col w-3/6 mx-auto">
            <AssetSelectorDialog
                filter={{type: 'videos'}}
                open={videoModalOpen}
                onClose={() => setVideoModalOpen(false)}
                onSelect={(asset: Asset) => {
                    console.log('asset: ', asset);
                    setCollectionsVideoAsset(asset);
                    setVideoModalOpen(false);
                }}
                uploaderAllowMultiple={false}
            />
            <div className="w-full">
                <TextField
                    select
                    label="Select a Featured Product"
                    value={featuredProductHandle}
                    fullWidth
                    required
                    error={false}
                    onChange={(event) => setFeaturedProductHandle(event.target.value)}
                    sx={{mb: 2}}
                >
                    {selectedProducts.map((product: Product) => (
                        <MenuItem key={product.title} value={product.handle}>
                            {product.title}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
            <div className="w-full mt-4">
                <div className="">Do you have a discount code?</div>
                <TextField
                    label="Discount Code"
                    variant="outlined"
                    value={discountCode}
                    fullWidth
                    required
                    error={false}
                    onChange={(event) => setDiscountCode(event.target.value)}
                    sx={{
                        mb: 2,
                        mt: 2,
                    }}
                />
            </div>
            <div className="w-full mt-4 flex flex-row items-center">
                <div className="mr-4">Do you want to add a video at the collection level?</div>
                <Button
                  type="button"
                  variant="outlined"
                  color="primary"
                  startIcon={collectionsVideoAsset ? <EditIcon /> : <AddIcon />}
                  onClick={() => setVideoModalOpen(true)}
                  aria-label="Select Video"
                >
                  {collectionsVideoAsset ? 'Change' : 'Choose'} Video
                </Button>
                {collectionsVideoAsset?.internal_name && (
                    <p className="ml-4 text-xs">({collectionsVideoAsset.internal_name})</p>
                )}
            </div>
            <div className="w-full mt-2">
                <div className="flex flex-row items-center">
                    <Checkbox
                        checked={enableTimerBar}
                        onChange={(event) => setEnableTimerBar(event.target.checked)}
                        size="medium"
                        title="Enable Countdown Timer"
                        disabled={!discountCode}
                    />
                    <div className={!discountCode ? "text-gray-400" : ""}>Enable Countdown Timer?</div>
                </div>
            </div>
            <div className="w-full mb-12">
                <div className="flex flex-row items-center">
                    <Checkbox
                        checked={enableShopPay}
                        onChange={(event) => setEnableShopPay(event.target.checked)}
                        size="medium"
                        title="Enable Shop Pay"
                    />
                    <div>Enable Shop Pay?</div>
                </div>
            </div>
        </div>
    )
}