import request from "../../utilities/request";
import {
  collectionURLs,
  storyURLs,
  chapterURLs,
} from "../../constants/apiURLs";

export function fetchStoryBlocks() {
  return new Promise((resolve) => {
    const response = request().get(collectionURLs.list());
    resolve(response);
  });
}

export function createChapter(data) {
  return new Promise((resolve) => {
    const response = request().post(chapterURLs.list(), data);
    resolve(response);
  });
}

export function createStory(data) {
  return new Promise((resolve) => {
    const response = request().post(storyURLs.list(), data);
    resolve(response);
  });
}

export function updateStory(data) {
  return new Promise((resolve) => {
    const response = request().put(storyURLs.details(data.id), data);
    resolve(response);
  });
}

export function createStoryBlock(data) {
  return new Promise((resolve) => {
    const response = request().post(collectionURLs.list(), data);
    resolve(response);
  });
}

export function updateStoryBlock(data) {
  return new Promise((resolve) => {
    const response = request().put(collectionURLs.details(data.id), data);
    resolve(response);
  });
}

export function updateStoryBlockWithNewStory(data) {
  return new Promise((resolve) => {
    const response = request().put(collectionURLs.storyOrder(data.id), {
      stories: data.newStoryIds,
    });
    resolve(response);
  });
}

export function updateChapter(data) {
  return new Promise((resolve) => {
    const response = request().put(chapterURLs.details(data.id), data);
    resolve(response);
  });
}

export function deleteChapter(id) {
  return new Promise((resolve) => {
    const response = request().delete(chapterURLs.details(id));
    resolve(response);
  });
}

export function deleteStory(id) {
  return new Promise((resolve) => {
    const response = request().delete(storyURLs.details(id));
    resolve(response);
  });
}

export function deleteStoryBlock(id) {
  return new Promise((resolve) => {
    const response = request().delete(collectionURLs.details(id));
    resolve(response);
  });
}

export function duplicateStoryBlock(data) {
  return new Promise((resolve) => {
    const response = request().post(collectionURLs.duplicate(data.id), {
      name: data.newName,
    });
    resolve(response);
  });
}

export function reorderChapters(data) {
  return new Promise((resolve) => {
    const response = request().put(storyURLs.chapterOrder(data.id), {
      chapters: data.newChapterIds,
    });
    resolve(response);
  });
}

export function reorderStories(data) {
  return new Promise((resolve) => {
    const response = request().put(collectionURLs.storyOrder(data.id), {
      stories: data.newStoryIDs,
    });
    resolve(response);
  });
}
