import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchShopifySyncStatus,
  fetchShopifyCollections,
  fetchShopifyProducts,
  fetchShopifyProduct,
} from "../api/shopifyDataAPI";

const initialState = {
  syncStatus: { status: "idle", isComplete: null },
  collections: { status: "idle", list: [] },
  products: { status: "idle", list: [] },
  product: { status: "idle", list: [] },
};

// Thunks
export const getShopifySyncStatus = createAsyncThunk(
  "shopifyData/fetchShopifySyncStatus",
  async () => {
    const data = await fetchShopifySyncStatus();
    return data?.response?.status === 400 ? { Status: "Not Found" } : data;
  }
);

export const getShopifyCollections = createAsyncThunk(
  "shopifyData/fetchShopifyCollections",
  async () => {
    const data = await fetchShopifyCollections();
    return data?.response?.status === 400 ? [] : data;
  }
);

export const getShopifyProducts = createAsyncThunk(
  "shopifyData/fetchShopifyProducts",
  async () => {
    const data = await fetchShopifyProducts();
    return data?.response?.status === 400 ? [] : data;
  }
);

export const getShopifyProduct = createAsyncThunk(
  "shopifyData/fetchShopifyProduct",
  async (productId) => {
    const data = await fetchShopifyProduct(productId);
    return data?.response?.status === 400 ? [] : data;
  }
);

// Slice
export const shopifyDataSlice = createSlice({
  name: "shopifyData",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getShopifySyncStatus.pending, (state) => {
        state.syncStatus.status = "loading";
      })
      .addCase(getShopifySyncStatus.fulfilled, (state, action) => {
        state.syncStatus.status = "idle";
        state.syncStatus.isComplete = action.payload.Status;
      })
      .addCase(getShopifyCollections.pending, (state) => {
        state.collections.status = "loading";
      })
      .addCase(getShopifyCollections.fulfilled, (state, action) => {
        state.collections.status = "idle";
        state.collections.list = action.payload;
      })
      .addCase(getShopifyProducts.pending, (state) => {
        state.products.status = "loading";
      })
      .addCase(getShopifyProducts.fulfilled, (state, action) => {
        state.products.status = "idle";
        state.products.list = action.payload;
      })
      .addCase(getShopifyProduct.pending, (state) => {
        state.product.status = "loading";
      })
      .addCase(getShopifyProduct.fulfilled, (state, action) => {
        state.product.status = "idle";
        state.product.list = action.payload;
      });
  },
});

// Selectors
export const selectSyncStatus = (state) => state.shopifyData.syncStatus;
export const selectCollections = (state) => state.shopifyData.collections;
export const selectProducts = (state) => state.shopifyData.products;
export const selectProduct = (state) => state.shopifyData.product;

export default shopifyDataSlice.reducer;
