import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  assets: [],
  status: "idle",
};

export const assetSlice = createSlice({
  name: "assets",
  initialState,
});

export default assetSlice.reducer;
