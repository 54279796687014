import { useReducer } from "react";
import reduce from "lodash/reduce";
import upperFirst from "lodash/upperFirst";

import generateAction from "../utilities/generateAction";

export const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    default:
      return {
        ...state,
        [type]: payload,
        hasChanges: true,
      };
  }
};

export const generateInitialState = (data, settableKeys, readOnlyKeys) => {
  return {
    ...data,
    hasChanges: false,
  };
};

export const generateSetters = (settableKeys, dispatcher) => {
  return reduce(
    settableKeys,
    (acc, settableKey, key) => {
      const functionName = `set${upperFirst(key)}`;
      const setter = (value) => dispatcher(generateAction(settableKey, value));

      acc[functionName] = setter;

      return acc;
    },
    {}
  );
};

const useBaseModel = (data, settableKeys, readOnlyKeys) => {
  const initialState = generateInitialState(data, settableKeys, readOnlyKeys);
  const [state, dispatch] = useReducer(reducer, initialState);
  const setters = generateSetters(settableKeys, dispatch);

  return {
    ...setters,
    ...state,
  };
};

export default useBaseModel;
