import React from "react";
import { useSnackbar } from "notistack";

import useStoryDetails from "../../models/storyDetails";
import {
  useStory,
  invalidateCollectionCache,
  collectionHasChanged,
} from "../../api/fetchCollectionData";
import { useCollection } from "../../api/fetchCollectionData";

import { collectionURLs, storyURLs } from "../../constants/apiURLs";
import { apiCall } from "../../utilities/request";

import {
  assetSquareCropLink,
  invalidateAssetCache,
  useAssets,
} from "../../api/fetchAssetData";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AlertTitle,
  Divider,
  Grid,
  Grow,
  Paper,
  Stack,
  CircularProgress,
  TextField,
  Button,
  Tabs,
  Tab,
  Typography,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import LinkIcon from "@mui/icons-material/Link";
import CropIcon from "@mui/icons-material/Crop";
import TheatersIcon from "@mui/icons-material/Theaters";

import RightCloseIcon from "../assets/RightCloseIcon";
import { hasOverrideAccountID } from "../../context/auth";
import AssetList from "../assets/AssetList";
import { CROP_SQUARE_1_1 } from "../../constants/assets";
import { AssetCropperDialog } from "../assets/AssetCrop";

const StoryEditorForm = ({
  collectionID,
  destinationIndex,
  storyID,
  onDone,
  isNew,
  setEditStoryModalOpen,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  // Get existing story data if we're editing an existing story
  const { story } = useStory(storyID);
  const details = useStoryDetails(story);
  const {
    thumbnail_text,
    internal_description,
    alt_text,
    id,
    asset,
    type,
    link_url,
    setName,
    setAsset,
    setType,
    setLink_url,
  } = details;

  // Save initial value of the story type
  const [initialType] = React.useState(type);

  const thumbnailURLGen = (asset) => {
    // Set thumb
    let initialThumbnailURL = assetSquareCropLink(asset);
    if (
      initialThumbnailURL === null ||
      initialThumbnailURL === undefined ||
      initialThumbnailURL === ""
    ) {
      // Default to a placeholder image
      initialThumbnailURL =
        "https://via.placeholder.com/300x300?text=None+Selected";
    }
    return initialThumbnailURL;
  };
  const [thumbnailURL, setThumbnailURL] = React.useState(
    thumbnailURLGen(asset)
  );

  const [thumbnailAsset, setThumbnailAsset] = React.useState(asset);

  // When the thumbnail asset changes, update the url too
  React.useEffect(() => {
    setThumbnailURL(thumbnailURLGen(thumbnailAsset));
  }, [thumbnailAsset]);

  // Get existing collection data
  const { collection } = useCollection(collectionID);

  // Generate an array of the current story IDs in the collection
  const currentStoryIDs = collection.stories.map((story) => story.id);

  const headerLabel = isNew ? "Add Story" : "Edit Story";

  const [cropDialogOpen, setCropDialogOpen] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(false);
  const [error, _setError] = React.useState(null);

  const setError = (err) => {
    _setError(err);

    console.error(err);

    // Only do the rest if err not null
    if (err) {
      enqueueSnackbar("Error saving story", { variant: "error" });
    }
  };

  const handleSaveStory = (e) => {
    e.preventDefault();

    // If thumbnail text is set but alt text isn't, copy it over
    let data = {
      thumbnail_text: thumbnail_text,
      internal_name: thumbnail_text,
      internal_description: internal_description,
      alt_text: thumbnail_text && !alt_text ? thumbnail_text : alt_text,
      asset_id: asset.id,
      type: type,
      link_url: link_url,
    };

    setError(null);

    // Validate form - Require name, internal name, thumbnail
    if (!data.internal_name || !data.asset_id || !data.thumbnail_text) {
      setError("Please enter a name and select a thumbnail");
      return;
    }

    // If story is link type, make sure URL is set
    if (type === "link" && !link_url) {
      setError("Please enter a link URL");
      return;
    }

    // If story is multimedia, then clear out the link
    if (type === "multimedia") {
      data.link_url = "";
    }

    const saveSuccess = (res) => {
      console.info("Save successful");
      console.info(res);

      // invalidate the current story list
      invalidateCollectionCache();
      // invalidate the current collection list
      collectionHasChanged(collectionID);

      // Insert the story into the collection at the specified index
      let newStoryIDs = [...currentStoryIDs];
      // Update is required if this is a new story
      if (isNew) {
        console.info("Inserting new story at index", destinationIndex);

        // Insert the new story at the requested index
        newStoryIDs.splice(destinationIndex, 0, res.data.id);

        // Update the collection with the new story IDs
        setIsLoading(true);
        apiCall
          .put(collectionURLs.storyOrder(collectionID), {
            stories: newStoryIDs,
          })
          .then((res) => {
            console.info(res);

            // invalidate the current collection list
            collectionHasChanged(collectionID);

            // Notify success
            enqueueSnackbar("Saved Story & inserted into collection", {
              variant: "success",
            });

            // Report done if the function exists
            if (onDone) {
              onDone(res.data);
            }
          })
          .catch((err) => {
            console.error(err);
            setError(
              err.response.data.error + " - " + err.response.data.details
            );
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        // Not a new story so the order didn't change
        // Notify success
        enqueueSnackbar("Saved Story", {
          variant: "success",
        });

        // Report done if the function exists
        if (onDone) {
          onDone(res.data);
        }
      }

      // Track event
      if (isNew) {
        window.analytics.track("story-created", {
          name: data.internal_name,
          id: res.data.id,
          admin_action: hasOverrideAccountID(),
        });
      } else {
        window.analytics.track("story-edited", {
          name: data.internal_name,
          id: res.data.id,
          admin_action: hasOverrideAccountID(),
        });
      }
    };

    const saveError = (err) => {
      console.error("Save failed");
      console.error(err);
      setError(err.response.data.error + " - " + err.response.data.details);
    };

    // If new, create a new story otherwise use the story ID URL
    setIsLoading(true);
    if (isNew) {
      apiCall
        .post(storyURLs.list(), data)
        .then(saveSuccess)
        .catch(saveError)
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      apiCall
        .put(storyURLs.details(id), data)
        .then(saveSuccess)
        .catch(saveError)
        .finally(() => {
          setIsLoading(false);
        });
    }
    return false;
  };

  const selectAsset = (asset) => {
    // Clear any existing error
    setError(null);

    // Detect asset type and set it
    switch (asset.type) {
      case "thumbnail":
        break;
      case "image":
        break;
      default:
        // Alert on error
        setError("You must select a thumbnail asset");
        return;
    }

    // Update the asset
    setAsset(asset);
  };

  const showChapterDeletionWarning = initialType === "multimedia" && !isNew;

  return (
    <>
      <AssetCropperDialog
        open={cropDialogOpen}
        asset={thumbnailAsset}
        aspectRatio={CROP_SQUARE_1_1}
        onClose={(newAsset) => {
          invalidateAssetCache();
          // If an asset was set, update the display image
          if (newAsset) {
            console.debug("New asset", newAsset);
            setThumbnailAsset(newAsset);
          }
          setCropDialogOpen(false);
        }}
      />
      <Typography variant="h5">
        {headerLabel}
        <RightCloseIcon onClick={() => setEditStoryModalOpen(false)} />
      </Typography>
      <TextField
        label="Story Title"
        value={thumbnail_text}
        onChange={(e) => setName(e.target.value)}
        fullWidth={true}
        margin="normal"
        required={true}
        helperText="Keep this to 1-2 words and about 15 characters. It is the text that will be displayed below the thumbnail in the widget."
      />
      <Grid container spacing={2} sx={{ marginTop: 1, marginBottom: 1 }}>
        <Grid item xs={12} sm={4}>
          {/* If no thumbnail, then display a placeholder */}
          <Paper
            elevation={1}
            style={{
              borderRadius: "10px",
              height: "100%",
              border: "1px solid #ccc",
            }}
          >
            <Stack
              direction="column"
              spacing={2}
              justifyItems="center"
              alignItems="center"
            >
              <Divider />
              <div
                style={{
                  width: "160px",
                  height: "160px",
                  backgroundColor: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "50%",
                  backgroundImage: `url(${thumbnailURL})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  border: "2px solid #ccc",
                  margin: "auto",
                }}
              >
                {!thumbnailURL && (
                  <Typography variant="h6">No Thumbnail Selected</Typography>
                )}
              </div>
              <Typography variant="h6">{thumbnail_text}</Typography>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setCropDialogOpen(true);
                }}
                startIcon={<CropIcon />}
                disabled={!asset}
              >
                Adjust Crop
              </Button>
              <br />
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Paper
            elevation={1}
            sx={{
              padding: "1rem",
              borderRadius: "0.5rem",
              border: "1px solid #ccc",
            }}
          >
            <Typography variant="h6" color="primary">
              Story Type
            </Typography>
            <Tabs
              value={type}
              onChange={(e, newValue) => {
                // Prevent default action & bubbling
                e.preventDefault();

                setType(newValue);
              }}
              aria-label="select story type"
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
              centered={true}
              style={{
                marginBottom: "1rem",
              }}
            >
              <Tab
                value="multimedia"
                icon={<TheatersIcon />}
                label="Multimedia"
              />
              <Tab value="link" icon={<LinkIcon />} label="Link Only" />
            </Tabs>
            {type === "link" && (
              <>
                <Typography variant="body1" color="">
                  Link stories act as Navbar elements. Clicking on the thumbnail
                  will take the customer to the specified URL.
                </Typography>
                <TextField
                  label="Link"
                  value={link_url}
                  onChange={(e) => setLink_url(e.target.value)}
                  fullWidth={true}
                  margin="normal"
                  helperText="Enter the URL of the page you want to link to"
                  placeholder="https://example.com/product/link/here"
                />

                {/* Alert window if someone is switching from multimedia to link */}
                {showChapterDeletionWarning && (
                  <Alert severity="warning">
                    <AlertTitle>Warning</AlertTitle>
                    Changing this story from multimedia to link will delete any
                    associated chapters.
                  </Alert>
                )}
              </>
            )}
            {type === "multimedia" && (
              <>
                <Typography variant="body1" color="">
                  Multimedia stories act as a slideshow. Clicking on the
                  thumbnail will open the Kahani module and begin playing the
                  story's chapters.
                </Typography>
              </>
            )}
          </Paper>

          {/* Additional save button here */}
          <LoadingButton
            fullWidth={true}
            loading={isLoading}
            color="primary"
            loadingPosition="start"
            variant="contained"
            startIcon={<SaveIcon />}
            onClick={handleSaveStory}
            sx={{
              marginTop: "1em",
            }}
          >
            Save
          </LoadingButton>
        </Grid>
      </Grid>
      {error && (
        <Grow in={error ? true : false}>
          <Alert
            severity="error"
            style={{
              marginTop: "1rem",
            }}
          >
            <AlertTitle>Error</AlertTitle>
            {error}
          </Alert>
        </Grow>
      )}
      <Typography variant="h6" color="primary">
        Select Thumbnail
      </Typography>
      <ThumbnailSelector
        filter={{
          type: "images",
        }}
        onSelect={(asset) => {
          console.log("selecting asset: ");
          console.log(asset);

          if (asset) {
            setThumbnailAsset(asset);
            console.debug("thumbnail url: " + assetSquareCropLink(asset));
            selectAsset(asset);
          }
        }}
        uploaderRequireThumbnail={true}
        currentAsset={asset}
        onCropRequired={(asset) => {
          // The asset does not have a square crop set, so we need to open the cropper
          enqueueSnackbar("Please set the thumbnail crop", {
            variant: "info",
            preventDuplicate: true,
          });
          setCropDialogOpen(true);
        }}
      />
    </>
  );
};

export default StoryEditorForm;

const ThumbnailSelector = ({
  onSelect,
  filter,
  currentAsset,
  onCropRequired,
}) => {
  const {
    error: assetsError,
    isLoading: assetsAreLoading,
    assets,
  } = useAssets();

  return (
    <>
      {assetsError && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {assetsError}
        </Alert>
      )}

      {!assetsError && (
        <AssetList
          filter={filter}
          onSelect={(asset) => {
            // If the image doesn't have a square crop, we need to open the cropper
            if (!asset?.image_square_crop) {
              onCropRequired(asset);
            }
            onSelect(asset);
          }}
          assets={assets}
          uploaderAllowMultiple={false}
          onUpload={(asset) => {
            console.debug("Upload complete. Asset info: ", asset);
            onSelect(asset);

            // Fresh image, so it must be cropped
            onCropRequired(asset);
          }}
          compressed={true}
          selectedAssetIDs={[currentAsset?.id]}
          cropMode={CROP_SQUARE_1_1}
        />
      )}
      {assetsAreLoading && (
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={80} />
        </Grid>
      )}
    </>
  );
};
