import React from "react";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";

import RightCloseIcon from "../../../modules/assets/RightCloseIcon";
import { deleteStory, reorderStories } from "../../../redux/api/storyBlockAPI";
import { invalidateCollectionCache, useCollection } from "../../../api/fetchCollectionData";

const style = {
  topMargin: {
    marginTop: 1,
  },
  box: {
    bgcolor: "background.paper",
    boxShadow: 24,
    display: "flex",
    flexDirection: "column",
    height: 275,
    justifyContent: "space-between",
    left: "50%",
    p: 3,
    top: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    width: 600,
  },
};

export function DeleteStoryModal({ selectedStory, onClose, open }) {
  const { storyBlockId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { collection: storyBlock } = useCollection(storyBlockId);

  const onDelete = async () => {
    const newStoryIds = storyBlock?.stories?.reduce((storyIds, story) => {
      if (story.id !== selectedStory.id) {
        storyIds.push(story.id);
      }

      return storyIds;
    }, []);

    try {
      await reorderStories({id: storyBlock.id, newStoryIDs: newStoryIds});
      await deleteStory(selectedStory.id);
      invalidateCollectionCache();
      enqueueSnackbar("Story deleted successfully!", {variant: "success"});
    } catch (error) {
      enqueueSnackbar("Failed to delete story", {variant: "error"});
    }

    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style.box}>
        <Typography variant="h5">
          Delete Story
          <RightCloseIcon onClick={onClose} />
        </Typography>
        <Alert severity="error">
          <AlertTitle>
            <strong>Are you sure you want to delete this story</strong>
          </AlertTitle>
          This cannot be undone.
        </Alert>
        <Stack direction="row" justifyContent="end" sx={style.topMargin}>
          <Button variant="outlined" color="error" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            loading={false}
            loadingPosition="start"
            color="error"
            variant="contained"
            startIcon={<SaveIcon />}
            onClick={onDelete}
            style={{ marginLeft: "10px" }}
          >
            Delete
          </LoadingButton>
        </Stack>
      </Box>
    </Modal>
  );
}
