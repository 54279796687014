import { useReducer } from "react";

import { generateSetters, generateInitialState, reducer } from "./baseModel";

const settableKeys = {
  name: "name",
};

const initialState = {
  name: "",
  has_klaviyo_api_key: false,
  ga_ua_tracking_id: "",
  is_shopify_account: false,
  thumbnail_url: "",
  has_instagram_auth: false,
  has_instagram_account: false,
};

const useAccountDetails = (account) => {
  const [state, dispatch] = useReducer(
    reducer,
    generateInitialState({
      ...initialState,
      ...account,
    })
  );
  const setters = generateSetters(settableKeys, dispatch);

  return {
    ...setters,
    ...state,
  };
};

export default useAccountDetails;
