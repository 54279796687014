import React from "react";
import env from "react-dotenv";

import { useAuth } from "../context/auth";

import { getEnvironment, getTimeSinceBuild } from "../utilities/environment";
import { determineBaseURL } from "../constants/apiURLs";

// Styling
import { Card, Divider, Link, Typography } from "@mui/material";

export default function Copyright(props) {
  const auth = useAuth();

  // Get first 8 characters of commit ID (if it exists)
  const commitID = env?.COMMIT_REF
    ? env.COMMIT_REF.substring(0, 8)
    : "live local";

  return (
    <>
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
      >
        Copyright ©{" "}
        <Link color="inherit" href="https://kahaniapp.com">
          Kahani
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>

      {/* If not production, print build info */}
      {getEnvironment() !== "production" && (
        <Card
          severity="info"
          title="Test"
          style={{
            margin: "10px",
            width: "25%",
            minWidth: "250px",
            maxWidth: "500px",
            marginLeft: "auto",
            marginRight: "auto",
            padding: "10px",
            textAlign: "center",
            borderRadius: "5px",
          }}
          elevation={1}
        >
          <Typography variant="h6" color="text.secondary" align="center">
            Build Information
          </Typography>
          <Divider style={{ maxWidth: "200px", margin: "auto" }} />
          <Typography variant="body2" align="center">
            {commitID}
            {/* Show build date only if not on shopify */}
            {!auth.isShopifyAccount && <> from {getTimeSinceBuild()}</>}
          </Typography>
          <Typography variant="body2" align="center">
            <b>Env:</b> {getEnvironment()}
          </Typography>
          <Typography variant="body2" align="center">
            <b>API:</b> {determineBaseURL()}
          </Typography>
        </Card>
      )}
    </>
  );
}
