import React from "react";
import map from "lodash/map";

import Grid from "@mui/material/Grid";
import { Alert, AlertTitle, Button, Divider, Typography } from "@mui/material";
import CollectionCard from "../../pages/story-blocks/CollectionCard";
import { useNavigate } from "react-router";

const CollectionList = ({ collections, collectionType }) => {
  const navigate = useNavigate();

  // Create a copy of collections that is sorted by the last_updated field
  collections.sort((a, b) => {
    return new Date(b.last_updated) - new Date(a.last_updated);
  });

  // Filter to make 2 lists: published and not
  const published = collections.filter(
    (collection) =>
      collection.published && collection.internal_description !== "||LP||"
  );
  const notPublished = collections.filter(
    (collection) =>
      !collection.published && collection.internal_description !== "||LP||"
  );

  /**
   * filters collection by type
   * @param {string} collection collection
   */
  const filter = (collection) => {
    if (collectionType === "landing pages") {
      return collection.internal_description === "||LP||";
    } else {
      return collection.internal_description !== "||LP||";
    }
  };

  /**
   * determines if a list should be rendered
   */
  const renderList = () => {
    let renderedCollections = 0;
    collections.forEach((collection) => {
      if (filter(collection)) {
        renderedCollections += 1;
      }
    });

    return renderedCollections;
  };

  const renderStoryBlocks = () => {
    return collections.length > 0 ? (
      <>
        <Grid item xs={12}>
          <Typography id="published-title" variant="h6" color="primary">
            Live
          </Typography>
        </Grid>
        {map(published, (collection) => (
          <Grid item xs={12} lg={6} key={"cc" + collection.id}>
            <CollectionCard collection={collection} />
          </Grid>
        ))}
        {published.length === 0 && (
          <Grid item xs={12}>
            <Alert severity="info">
              <AlertTitle>
                All of your Story Blocks are currently private
              </AlertTitle>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  navigate("/publish");
                }}
              >
                Go to Publish page
              </Button>
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <Divider
            sx={{
              borderStyle: "dashed",
              mt: 1,
              mb: 2,
            }}
          />
          <Typography id="unpublished-title" variant="h6" color="primary">
            Private
          </Typography>
        </Grid>
        {map(notPublished, (collection) => (
          <Grid item xs={12} lg={6} key={"cc" + collection.id}>
            <CollectionCard collection={collection} />
          </Grid>
        ))}
        {notPublished.length === 0 && (
          <Grid item xs={12}>
            <Alert severity="success">
              <AlertTitle>
                All of your Story Blocks are currently published
              </AlertTitle>
            </Alert>
          </Grid>
        )}
      </>
    ) : (
      <Grid item xs={12}>
        <Alert severity="info">
          <AlertTitle>No Story Blocks found</AlertTitle>
          You don't have any Story Blocks yet.
          <br />
          <br />
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              navigate("/story_blocks");
            }}
          >
            Create your first Story Block
          </Button>
        </Alert>
      </Grid>
    );
  };

  const renderLandingPages = () => {
    return renderList() ? (
      map(collections, (collection) => {
        if (filter(collection)) {
          return (
            <Grid item xs={12} lg={6} key={"cc" + collection.id}>
              <CollectionCard
                collection={collection}
                collectionType="landing pages"
              />
            </Grid>
          );
        }
      })
    ) : (
      <Grid
        item
        xs={12}
        style={{ textAlign: "center", marginTop: 50, marginBottom: 50 }}
      >
        <h4>No results found</h4>
        <p>No landing pages were found.</p>
      </Grid>
    );
  };

  return (
    <Grid container spacing={3}>
      {/* If there are no collections, display an alert and a link to make your first one */}
      {collectionType === "regular"
        ? renderStoryBlocks()
        : renderLandingPages()}
    </Grid>
  );
};

export default CollectionList;
