import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AssetCropperDialog } from "../modules/assets/AssetCrop";
import AssetList from "../modules/assets/AssetList";
import { Box, Button, Modal, Stack, Typography } from "@mui/material";

import {
  selectMediaModalOpen,
  toggleMediaModal,
  setSelectedMedia,
  selectSelectedMedia,
  selectMediaModalFilter,
} from "../redux/slices/viewSlice";

import { useAssets } from "../api/fetchAssetData";

import RightCloseIcon from "../modules/assets/RightCloseIcon";
import { CROP_SQUARE_1_1, CROP_VERTICAL_9_16 } from "../constants/assets";

const style = {
  topMargin: {
    marginTop: 4,
  },
  box: {
    bgcolor: "background.paper",
    boxShadow: 24,
    display: "flex",
    flexDirection: "column",
    height: "90%",
    justifyContent: "space-between",
    left: "50%",
    p: 3,
    top: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    width: "90%",
  },
};

export default function MediaModal() {
  const dispatch = useDispatch();
  const { assets } = useAssets();
  const [cropDialogOpen, setCropDialogOpen] = useState(false);

  const mediaModalOpen = useSelector(selectMediaModalOpen);
  const selectedMedia = useSelector(selectSelectedMedia);
  const mediaModalFilter = useSelector(selectMediaModalFilter);

  const handleClose = () => dispatch(toggleMediaModal({}));

  return (
    <Modal open={mediaModalOpen} onClose={handleClose}>
      <Box sx={style.box}>
        <Typography variant="h5">
          Select Media
          <RightCloseIcon onClick={handleClose} />
        </Typography>

        <AssetCropperDialog
          open={cropDialogOpen}
          asset={selectedMedia}
          aspectRatio={CROP_SQUARE_1_1}
          onClose={(newAsset) => {
            // If an asset was set, update the display image
            if (newAsset) {
              dispatch(setSelectedMedia(newAsset));
            }
            setCropDialogOpen(false);
            handleClose();
          }}
        />

        <Box sx={{ overflow: "scroll" }}>
          <AssetList
            filter={mediaModalFilter}
            onSelect={(asset) => {
              dispatch(setSelectedMedia(asset));
              // If the image doesn't have a square crop, we need to open the cropper
              if (
                mediaModalFilter?.type !== "all" &&
                !asset?.image_square_crop
              ) {
                setCropDialogOpen(asset);
              } else {
                handleClose();
              }
            }}
            assets={assets}
            uploaderAllowMultiple={false}
            onUpload={(asset) => {
              dispatch(setSelectedMedia(asset));
              // // Fresh image, so it must be cropped
              setCropDialogOpen(asset);
            }}
            compressed={true}
            selectedAssetIDs={[selectedMedia?.id]}
            cropMode={
              mediaModalFilter?.type === "all"
                ? CROP_VERTICAL_9_16
                : CROP_SQUARE_1_1
            }
          />
        </Box>
        <Stack direction="row" justifyContent="end" sx={style.topMargin}>
          <Button variant="outlined" onClick={handleClose}>
            Back
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}
