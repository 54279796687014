import React, { useState } from "react";
import { useSnackbar } from "notistack";

import { PlanRedemptionFormInner } from "../../components/PlanRedemptionForm";

import { useAccount } from "../../api/fetchAccountData";
import { invalidateAccountCache } from "../../api/fetchAccountData";
import { apiCall } from "../../utilities/request";

import { shopifyURLs } from "../../constants/apiURLs";

import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { SlideUpFromBottom } from "../../utilities/transitions";

export default function BillingPlanEditor() {
  const {
    account: { has_active_billing_plan, active_billing_plan },
  } = useAccount();

  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);

  return (
    <Grid container spacing={2}>
      <CancelDialog
        open={cancelDialogOpen}
        onClose={() => setCancelDialogOpen(false)}
      />
      {!has_active_billing_plan ||
      active_billing_plan?.id === "BEEFBEEF-18FF-4E56-9654-ABD5CCAD35F1" ? (
        <Grid item xs={12}>
          <PlanRedemptionFormInner />
        </Grid>
      ) : null}
      {has_active_billing_plan && (
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom color="primary">
            Current Billing Plan
          </Typography>
          <Stack
            spacing={2}
            direction="row"
            justifyContent="space-around"
            alignItems="center"
          >
            <Typography variant="body1">{active_billing_plan.name}</Typography>
            <Chip
              label={`$${active_billing_plan.base_price}/month`}
              color="success"
              variant="outlined"
            />
            <Button
              variant="text"
              color="error"
              onClick={() => {
                setCancelDialogOpen(true);
              }}
            >
              cancel
            </Button>
          </Stack>
        </Grid>
      )}
    </Grid>
  );
}

const CancelDialog = ({ open, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={SlideUpFromBottom}
      aria-labelledby="cancel-dialog-title"
      aria-describedby="cancel-dialog-description"
    >
      <DialogTitle id="cancel-dialog-title">Cancel Kahani Plan?</DialogTitle>
      <DialogContent>
        <DialogContentText id="cancel-dialog-description">
          Kahani will no longer appear on your site and the legacy subscription
          you are currently on may not be avaliable to you in the future.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          variant="contained"
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
        >
          Keep Kahani
        </Button>
        <Button
          color="error"
          variant="outlined"
          onClick={(e) => {
            e.stopPropagation();
            apiCall
              .delete(shopifyURLs.redeemPlanCode())
              .then((res) => {
                if (res?.data?.success) {
                  invalidateAccountCache();
                  enqueueSnackbar("Plan cancelled", { variant: "success" });
                  onClose();
                } else {
                  enqueueSnackbar(
                    "Error cancelling plan. Our support team will be happy to help you cancel",
                    { variant: "error" }
                  );
                }
              })
              .catch((err) => {
                enqueueSnackbar(
                  "Error cancelling plan. Our support team will be happy to help you cancel",
                  { variant: "error" }
                );
              });
          }}
        >
          Yes, cancel my account
        </Button>
      </DialogActions>
    </Dialog>
  );
};
