import { useMutation } from "react-query";

import { shopifyURLs } from "../constants/apiURLs";
import request from "../utilities/request";

const redeemCode = (redemption_code) => {
  const return_url = `${window.location.origin}/shopify/payment_return`;
  const payload = { return_url, redemption_code };

  return request().post(
    // shopifyURLs.redeemPlanCode() + "?dangerTest=true",
    shopifyURLs.redeemPlanCode(),
    payload,
    {},
    true
  );
};

export const usePlanRedeem = () => useMutation(redeemCode);
