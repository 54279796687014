import { useQuery } from "react-query";
import find from "lodash/find";

import queryClient from "../constants/queryClient";
import { ctaURLs } from "../constants/apiURLs";
import { API_QUERY_KEYS } from "../constants/textMappings";
import request from "../utilities/request";

export const useCTAs = () => {
  const { data, ...meta } = useQuery(API_QUERY_KEYS.CTA_REQUEST, () => {
    return request().get(ctaURLs.list());
  });

  return {
    ctas: data?.ctas ?? [],
    ...meta,
  };
};

export const useCTA = (ctaID) => {
  const { ctas, ...meta } = useCTAs();
  const cta = find(ctas, { id: ctaID });

  return {
    cta: cta ?? {},
    ...meta,
  };
};

export const invalidateCTACache = () => {
  queryClient.invalidateQueries(API_QUERY_KEYS.CTA_REQUEST);
};