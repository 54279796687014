import MuxPlayer from "@mux/mux-player-react";
import React from "react";
import {
  assetPreviewLink,
  assetVerticalCropLink,
} from "../../../api/fetchAssetData";
import { useCollectionStyle } from "../../../api/fetchCollectionData";
import Gradient from "../../../components/Gradient";
import { requestAccountID } from "../../../context/auth";
import "./chapter.css";

export default function ChapterPreview({ ctas, asset, story, collectionID }) {
  const { style } = useCollectionStyle(collectionID);

  let previewImage = assetPreviewLink(asset);
  // If image type, then use the crop mode to define which image we show
  if (asset?.type === "image") {
    previewImage = assetVerticalCropLink(asset);
  }

  const bgStyle =
    asset?.type === "mux_stream"
      ? {}
      : {
          backgroundImage: `url(${previewImage})`,
        };

  return (
    <div className="chapter_preview-container">
      <div className="kahani-modal-container-overlay">
        <div className="kahani-modal-slider-placer">
          <div className="kahani-modal-chapter">
            <div className="kahani-modal-chapter-view" style={bgStyle}>
              {asset?.type === "mux_stream" && (
                <MuxPlayer
                  streamType="on-demand"
                  playbackId={asset.mux_playback_id}
                  metadata={{
                    video_id: asset.id,
                    video_title: asset.internal_name,
                    viewer_user_id: requestAccountID(),
                  }}
                  loop={true}
                  autoPlay={true}
                  muted={true}
                  style={{
                    height: "100%",
                  }}
                />
              )}
            </div>
            <div className="kahani-modal-story-title-container">
              <img
                style={{
                  borderColor: style.primary_color,
                }}
                className="kahani-modal-story-title-image"
                src={story.thumbnail_url}
                alt="Thumbnail"
              />
              <div className="kahani-modal-story-title">
                {story.thumbnail_text}
              </div>
            </div>

            <div className="kahani-modal-icon-share">
              <img
                className="share-image"
                src="https://cdn.beta.kahaniapp.com/module_assets/share.svg"
                alt="Share"
              />
            </div>
            <div>
              <img
                src="https://cdn.beta.kahaniapp.com/module_assets/muted.svg"
                alt="muted"
                className="kahani-modal-icon-muted"
              />
              <button className="kahani-modal-close-overlay">
                <img
                  width="30"
                  src="https://cdn.beta.kahaniapp.com/module_assets/x.svg"
                  alt="close"
                />
              </button>
            </div>
            <Gradient />
            {ctas?.length ? (
              <div className="kahani-modal-cta-button-container">
                <button
                  className="kahani-modal-cta-button-link"
                  style={{
                    width: "100%",
                    backgroundColor: style.primary_color,
                  }}
                >
                  {ctas[0]?.link_text}
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
