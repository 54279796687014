import React, { useRef, useState } from "react";
import { Button, Grow, MenuItem, MenuList, Paper, Popper } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { EditStoryModal } from "./stories/EditStoryModal";
import { DeleteStoryModal } from "./stories/DeleteStoryModal";
import { useStory } from "../../api/fetchCollectionData";

export default function StoryOptions({selectedStoryId, updateLivePreview}) {
  const anchorRef = useRef(null);
  const [showOptionsMenu, setShowOptionsMenu] = useState(false);
  const [showEditStoryModal, setShowEditStoryModal] = useState(false);
  const [showDeleteStoryModal, setShowDeleteStoryModal] = useState(false);
  const {story} = useStory(selectedStoryId);

  return (
    <>
      <Button
        onClick={() => setShowOptionsMenu(true)}
        ref={anchorRef}
        sx={{
          color: "black",
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
      >
        <MoreHorizIcon />
      </Button>
      <Popper
        open={showOptionsMenu}
        anchorEl={anchorRef.current}
        placement="bottom"
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <MenuList autoFocusItem={showOptionsMenu} id="composition-menu">
                <MenuItem onClick={() => setShowEditStoryModal(true)}>
                  Edit Story
                </MenuItem>
                {/* <MenuItem disabled>Duplicate</MenuItem> */}
                <MenuItem onClick={() => setShowDeleteStoryModal(true)}>
                  Delete
                </MenuItem>
              </MenuList>
            </Paper>
          </Grow>
        )}
      </Popper>
      <EditStoryModal
        open={showEditStoryModal}
        selectedStory={story}
        onClose={() => {
          setShowEditStoryModal(false);
          setShowOptionsMenu(false);
          updateLivePreview();
        }}
      />
      <DeleteStoryModal
        open={showDeleteStoryModal}
        selectedStory={story}
        onClose={() => {
          setShowDeleteStoryModal(false);
          setShowOptionsMenu(false);
          updateLivePreview();
        }}
      />
    </>
  );
}
