import { assetURLs } from "../constants/apiURLs";
import { hasOverrideAccountID } from "../context/auth";
import { apiCall } from "./request";

/* Function to upload an individual asset file
    The upload stages are:
        1. Uploading (onProgress will be called continuously)
        2. Upload complete (onUploadComplete will be called)
        3. Processing the image on the server for a bit (no timer)
        4. Image processing complete (onProcessingComplete will be called)



    You should pass in:
        - file: the file to upload
        - name: the name of the file (human readable or filename)
        - thumbnail: bool indicating if this is the thumbnail
        - tags: array of text tags to add to the asset
        - onProgress: function to call when the upload progress changes (passes percentage complete, like 85.21)
        - onUploadComplete: function to call when the upload is complete
        - onProcessingComplete: function to call when the image processing is complete (passes the asset object that was just created)
        - onError: function to call when an error occurs (passes error response from API as an object)
*/
export function uploadAsset({
  file,
  thumbnail = false,
  tags = [],
  urlUpload = false,
  onProgress = () => {},
  onUploadComplete = () => {},
  onProcessingComplete = () => {},
  onError = () => {},
  always = () => {},
} = {}) {
  // All fields are required
  if (!file.internal_name) {
    always();
    throw new Error("All fields are required");
  }

  // Set up request body (form encoded)
  const formData = new FormData();
  let reqBody = {};

  let url;
  if (urlUpload) {
    // If debug_use_mux is set, then we're using the Mux API
    if (window.localStorage.getItem("debug_use_mux")) {
      url = assetURLs.urlUpload(true);
      console.debug("Using Mux API: " + url);
    } else {
      url = assetURLs.urlUpload(false);
      console.debug("Using Cloudflare API: " + url);
    }

    reqBody = {
      mime_type: file.mime_type,
      type: file.type,
      width: file.width,
      height: file.height,
      filename: file.filename,
      internal_description: file.internal_description,
      url: file.url,
      internal_name: file.internal_name,
      tags: tags.join(","),
    };
  } else {
    url = assetURLs.upload();

    formData.append("file", file.file);
    formData.append("internal_name", file.internal_name);
    formData.append("tags", tags.join(","));
  }

  console.info("Uploading asset...");
  console.debug(JSON.stringify(formData));

  // use axios to do a multipart upload
  apiCall
    .post(url, urlUpload ? reqBody : formData, {
      onUploadProgress: (progressEvent) => {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        console.debug(
          progressEvent.loaded +
            " of " +
            progressEvent.total +
            " - " +
            percentCompleted +
            "%"
        );

        // If progress is right at 100%, then we're processing
        if (percentCompleted >= 99.9) {
          onUploadComplete();
          return;
        }

        // Call the onProgress callback
        onProgress(percentCompleted);
      },
    })
    .then((res) => {
      try {
        // Upload and processing are complete
        console.debug("Upload and processing complete");
        console.debug(res);
        onProcessingComplete(res.data);

        window.analytics.track("media-uploaded", {
          name: file.internal_name,
          filename: file.internal_name,
          tags: tags.join(","),
          sizeKB: (file.size / 1024).toFixed(2),
          admin_action: hasOverrideAccountID(),
        });
      } catch (e) {
        console.error("Error uploading file (after success)");
        console.error(e);
      }
    })
    .catch((error) => {
      // Error uploading or processing
      console.error("Error uploading file");
      console.error(error.response);
      onError(error.response.data);
    })
    .finally(() => {
      always();
    });
}

// Function to decide if an asset is a video
export function isVideo(asset) {
  switch (asset.type) {
    case "cloudflare_stream":
    case "mux_stream":
      return true;
    default:
      return false;
  }
}

// Function to decide if an asset is an image
export function isImage(asset) {
  switch (asset.type) {
    case "image":
    case "thumbnail":
      return true;
    default:
      return false;
  }
}
