import React, { useState } from "react";
import map from "lodash/map";
import Grid from "@mui/material/Grid";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Paper,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";

import CollectionCard from "./CollectionCard";

export const StoryBlockList = ({ storyBlocks, storyBlockType, onCreateStoryBlockClick }) => {
  let displayedStoryBlocks = storyBlocks;

  const [searchValue, setSearchValue] = useState("");
  if (searchValue) {
    displayedStoryBlocks = storyBlocks.filter((c) =>
      c.internal_name.toLowerCase().includes(searchValue.toLowerCase())
    );
  }
  // Create a copy of collections that is sorted by the last_updated field
  displayedStoryBlocks.sort((a, b) => {
    return new Date(b.last_updated) - new Date(a.last_updated);
  });

  // Filter to make 2 lists: published and not
  const published = displayedStoryBlocks.filter(
    (collection) =>
      collection.published && collection.internal_description !== "||LP||"
  );
  const notPublished = displayedStoryBlocks.filter(
    (collection) =>
      !collection.published && collection.internal_description !== "||LP||"
  );
  const nonLandingPages = displayedStoryBlocks.filter(
    (collection) => collection.internal_description !== "||LP||"
  );

  /**
   * filters storyBlock by type
   * @param {string} storyBlock storyBlock
   */
  const filter = (storyBlock) => {
    if (storyBlockType === "landing pages") {
      return storyBlock.internal_description === "||LP||";
    } else {
      return storyBlock.internal_description !== "||LP||";
    }
  };

  /**
   * determines if a list should be rendered
   */
  const renderList = () => {
    let renderedStoryBlocks = 0;
    storyBlocks.forEach((storyBlock) => {
      if (filter(storyBlock)) {
        renderedStoryBlocks += 1;
      }
    });

    return renderedStoryBlocks;
  };

  const [value, setValue] = useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const handleTabDisplay = (value) => {
    switch (value) {
      case 0:
        return nonLandingPages;
      case 1:
        return published;
      case 2:
        return notPublished;
      default:
        break;
    }
  };

  const renderEmptyDisplay = (value) => {
    switch (value) {
      case 0:
        return (
          <Button
            variant="contained"
            onClick={() => onCreateStoryBlockClick()}
          >
            Create First Story Block
          </Button>
        );
      case 1:
        return (
          <>
            <Typography variant="h6">
              No Published Story Blocks found
            </Typography>
            <Button onClick={() => setValue(0)}>View All Story Blocks</Button>
          </>
        );
      case 2:
        return (
          <>
            <Typography variant="h6">
              No Unpublished Story Blocks found
            </Typography>
            <Button onClick={() => setValue(0)}>View All Story Blocks</Button>
          </>
        );
      default:
        break;
    }
  };

  const renderStoryBlocks = () => {
    return storyBlocks.length > 0 ? (
      <>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between">
            <Box>
              <Tabs value={value} onChange={handleChange}>
                <Tab label="All" />
                <Tab label="Published" />
                <Tab label="Not Published" />
              </Tabs>
            </Box>
            <TextField
              label="Search Story Blocks"
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
              sx={{ width: "400px" }}
            />
          </Stack>
        </Grid>
        {map(handleTabDisplay(value), (storyBlock) => (
          <Grid item xs={12} lg={6} key={"cc" + storyBlock.id}>
            <CollectionCard collection={storyBlock} />
          </Grid>
        ))}
        {handleTabDisplay(value).length === 0 && (
          <Grid item xs={12}>
            <div
              style={{
                position: "relative",
                margin: "auto",
                width: "min-content",
              }}
            >
              <img
                src={require("../../assets/empty-bg.png")}
                alt="background icon"
              />
              <Paper sx={{ position: "absolute", right: "0px", top: "50px" }}>
                <img
                  src={require("../../assets/blockCard.png")}
                  alt="background icon"
                />
              </Paper>
            </div>

            <Stack direction="column" alignItems="center" sx={{ mt: 3 }}>
              {renderEmptyDisplay(value)}
            </Stack>
          </Grid>
        )}
      </>
    ) : (
      <Grid item xs={12}>
        <Alert severity="info">
          <AlertTitle>No Story Blocks found</AlertTitle>
          You don't have any Story Blocks yet.
          <br />
          <br />
          <Button
            variant="contained"
            color="primary"
            onClick={() => onCreateStoryBlockClick()}
          >
            Create your first Story Block
          </Button>
        </Alert>
      </Grid>
    );
  };

  const renderLandingPages = () => {
    return renderList() ? (
      map(storyBlocks, (storyBlock) => {
        if (filter(storyBlock)) {
          return (
            <Grid item xs={12} lg={6} key={"cc" + storyBlock.id}>
              <CollectionCard
                collection={storyBlock}
                collectionType="landing pages"
              />
            </Grid>
          );
        }
      })
    ) : (
      <Grid
        item
        xs={12}
        style={{ textAlign: "center", marginTop: 50, marginBottom: 50 }}
      >
        <h4>No results found</h4>
        <p>No landing pages were found.</p>
      </Grid>
    );
  };

  return (
    <Grid container spacing={3}>
      {/* If there are no collections, display an alert and a link to make your first one */}
      {storyBlockType === "regular"
        ? renderStoryBlocks()
        : renderLandingPages()}
    </Grid>
  );
};