import Pusher from "pusher-js";
import { collectionURLs } from "../constants/apiURLs";
import { apiCall } from "./request";
import { getEnvironment } from "./environment";

const pusher = new Pusher("72ae039a8d6c4e8f8eb1", {
  cluster: "us2",
  // secret: "SECRET_KEY",
  useTLS: true, // optional, defaults to false
});

export const buildPreviewURL = (collectionID, accountID) => {
  // Generate a random token
  const token =
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15);

  // URI encode this URL
  let collectionURL = encodeURIComponent(
    collectionURLs.publicCollection(collectionID, accountID)
  );

  const relativePreviewURL =
    "/accounts/" +
    accountID +
    "/story_blocks/" +
    collectionID +
    "/livePreview?token=" +
    token +
    "&collectionURL=" +
    collectionURL;

  // Get the current protocol and hostname
  const host = window.location.host;
  let previewURL = "";
  switch (getEnvironment()) {
    case "production":
    case "development":
    case "pull_request":
      previewURL = "https://" + host + relativePreviewURL;
      break;
    default:
      const protocol = window.location.protocol;

      previewURL = protocol + "//" + host + relativePreviewURL;
  }

  return {
    token,
    previewURL,
    relativePreviewURL,
  };
};

export const useLivePreviewHost = (collectionID, accountID) => {
  const { token, previewURL, relativePreviewURL } = buildPreviewURL(
    collectionID,
    accountID
  );

  return {
    previewURL: () => {
      return previewURL;
    },
    openPreview: (target) => {
      // If target is not set, use _blank
      if (!target) {
        target = "_blank";
      }

      // Open the preview window
      window.open(relativePreviewURL, target);
    },
    triggerUpdate: () => {
      // Send API request to notify of an update
      apiCall
        .put(collectionURLs.livePreviewUpdate(token), {
          collection_id: collectionID,
        })
        .then((response) => {
          console.log("Live preview update triggered");
          console.log(response);
        })
        .catch((error) => {
          console.error("Error triggering live preview update");
          console.error(error);
        });
    },
  };
};

export const setupLivePreviewClient = (collectionID, callback) => {
  // Subscribe to the preview channel
  const previewChannel = pusher.subscribe("live-preview-" + collectionID);

  // On error, set the error state
  previewChannel.bind("error", (errorObj) => {
    console.error(errorObj);
  });

  // On update, call the callback
  previewChannel.bind("preview-updated", (data) => {
    // If set, call the callback
    if (callback && typeof callback === "function") {
      callback(data);
    }
  });
};
