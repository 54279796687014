import React from "react";

import { AlertTitle, Alert, Grow } from "@mui/material";
import { useSnackbar } from "notistack";

import SaveIcon from "@mui/icons-material/Save";
import { accountURLs } from "../../constants/apiURLs";
import { useAccount } from "../../api/fetchAccountData";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import { apiCall } from "../../utilities/request";
import { invalidateAccountCache } from "../../api/fetchAccountData";
import {
  useKlaviyoLists,
  invalidateKlaviyoCache,
} from "../../api/fetchKlaviyoLists";
import { Button, Chip, TextField, Stack } from "@mui/material";

function KlaviyoAPIKey({ onSuccess }) {
  const {
    account: { has_klaviyo_api_key },
  } = useAccount();

  const { enqueueSnackbar } = useSnackbar();

  const [klaviyoAPIKey, setKlaviyoAPIKey] = React.useState("KEYKEYKEYKEY");
  const [klaviyoKeyChanged, setKlaviyoKeyChanged] = React.useState(false);

  const [error, _setError] = React.useState(null);
  const errorRef = React.useRef(null);
  const setError = (err) => {
    _setError(err);

    // Only do the rest if err not null
    if (err) {
      enqueueSnackbar("Error saving account information", { variant: "error" });

      // Scroll to error alert message
      errorRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Monitor hasKlaviyoAPIKey to display the mask or not
  React.useEffect(() => {
    if (has_klaviyo_api_key) {
      setKlaviyoAPIKey("KEYKEYKEYKEY");
    } else {
      setKlaviyoAPIKey("");
    }
  }, [has_klaviyo_api_key]);

  // Monitor the klaviyoAPIKey to see if it has changed
  React.useEffect(() => {
    if (klaviyoAPIKey !== "KEYKEYKEYKEY") {
      setKlaviyoKeyChanged(true);
    } else {
      setKlaviyoKeyChanged(false);
    }
  }, [klaviyoAPIKey]);

  // Also get klaviyo lists so we can see if we're authed OK or not
  const { isLoading: klaviyoListsLoading, error: klaviyoListsError } =
    useKlaviyoLists();

  // Watch for list changes to know if we're good or not
  const [klaviyoOK, setKlaviyoOK] = React.useState(false);
  React.useEffect(() => {
    if (klaviyoListsError || klaviyoListsLoading) {
      setKlaviyoOK(false);
    } else {
      setKlaviyoOK(true);
    }
  }, [klaviyoListsError, klaviyoListsLoading]);

  // Function to upload metadata changes
  const saveKlaviyoAPIKey = (e) => {
    e.preventDefault();
    if (klaviyoAPIKey === "KEYKEYKEYKEY") {
      return;
    }

    setError(null);
    console.log("setting API key to", klaviyoAPIKey);

    // Send the request
    apiCall
      .put(accountURLs.setKlaviyoAPIKey(), {
        klaviyo_api_key: klaviyoAPIKey,
      })
      .then((res) => {
        console.info("Saved klaviyo api key", res);
        console.debug(JSON.stringify(res));

        // Invalidate assets data
        invalidateAccountCache();
        invalidateKlaviyoCache();

        // Notify success
        enqueueSnackbar("Saved klaviyo key", {
          variant: "success",
        });

        // Update the UI
        setKlaviyoKeyChanged(false);
        setKlaviyoAPIKey("KEYKEYKEYKEY");

        // All done!
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        console.error("Error saving metadata");
        console.error(error.response);
        setError(error.response.data.error);
      });
  };

  return (
    <>
      <Stack
        direction="column"
        spacing={2}
        justifyItems="center"
        alignItems="center"
        alignContent="center"
      >
        <TextField
          label="Klaviyo API Key"
          helperText="Leave blank to disable Klaviyo integration"
          value={klaviyoAPIKey}
          onChange={(e) => setKlaviyoAPIKey(e.target.value)}
          variant="outlined"
          type={klaviyoKeyChanged ? "text" : "password"}
          margin="normal"
          fullWidth
          onFocus={() => {
            // If still set to default, then clear it
            if (klaviyoAPIKey === "KEYKEYKEYKEY") {
              setKlaviyoAPIKey("");
            }
          }}
        />
        <Button
          variant="contained"
          startIcon={<SaveIcon />}
          onClick={saveKlaviyoAPIKey}
          fullWidth
        >
          Save
        </Button>
        {/* If no key set, give a chip for that */}
        {!has_klaviyo_api_key && !klaviyoOK && (
          <Chip
            label="No Klaviyo API Key Set"
            color="secondary"
            variant="outlined"
            size="small"
            style={{ marginLeft: "1rem" }}
          />
        )}
        {error === null && !klaviyoKeyChanged && (
          <Grow in={error === null}>
            <Chip
              label={
                klaviyoOK
                  ? "Klaviyo API Key is valid"
                  : "Klaviyo API Key is invalid"
              }
              color={klaviyoOK ? "success" : "warning"}
              variant="outlined"
              icon={klaviyoOK ? <CheckIcon /> : <ErrorIcon />}
            />
          </Grow>
        )}
      </Stack>
      {error && (
        <Grow in={error ? true : false}>
          <Alert
            sx={{
              marginTop: "1rem",
            }}
            ref={errorRef}
            severity="error"
          >
            <AlertTitle>Error</AlertTitle>
            {error}
          </Alert>
        </Grow>
      )}
    </>
  );
}

export default KlaviyoAPIKey;
