import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";

import StoriesSection from "./stories/StoriesSection";
import ChaptersSection from "./chapters/ChaptersSection";
import EditChapterModal from "../../redux/api/EditChapterModal";
import { useCollection, useStory } from "../../api/fetchCollectionData";

const style = {
  section: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 10,
    marginTop: 10,
  },
};

export default function StoryBlockEditor({updateLivePreview}) {
  const { storyBlockId } = useParams();
  const {collection: storyBlock} = useCollection(storyBlockId);
  const [selectedStoryId, setSelectedStoryId] = useState(storyBlock?.stories?.[0]?.id);
  const { story } = useStory(selectedStoryId);

  useEffect(() => {
    // If the selected story is not in the story block, select the first story
    if (!storyBlock?.stories?.some(({id}) => id === selectedStoryId)) {
      setSelectedStoryId(storyBlock?.stories?.[0]?.id);
    }
  }, [storyBlockId, storyBlock?.stories, selectedStoryId]);

  return (
    <Grid container>
      <Grid item xs={12} style={style.section}>
        <Typography variant="h5">Stories</Typography>
      </Grid>

      <StoriesSection
        storyBlock={storyBlock}
        selectedStoryId={selectedStoryId}
        setSelectedStoryId={(id) => setSelectedStoryId(id)}
        updateLivePreview={updateLivePreview}
      />

      <ChaptersSection
        storyBlockId={storyBlockId}
        storyId={selectedStoryId}
        updateLivePreview={updateLivePreview}
      />
      <EditChapterModal collection={storyBlock} selectedStory={story} />
    </Grid>
  );
}
