import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Typography } from "@mui/material";
import React from "react";
import { useChapters } from "../../../api/fetchCollectionData";
import RightCloseIcon from "../../../modules/assets/RightCloseIcon";
import ChapterEditor from "./ChapterEditor";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const CreateChapterModal = ({open, onClose, storyBlockId, storyId}) => {
  const {chapters} = useChapters(storyId);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth={"xl"}
    >
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">Add Chapter</Typography>
        <RightCloseIcon onClick={onClose} />
        </DialogTitle>
        <DialogContent>
        <ChapterEditor
          storyBlockId={storyBlockId}
          storyId={storyId}
          chapter={null}
          isNew={true}
          destinationIndex={chapters.length}
          onDone={onClose}
        />
        </DialogContent>
        <DialogActions>
        <Button onClick={onClose}>
          Cancel
        </Button>
        </DialogActions>
    </Dialog>
  )
}