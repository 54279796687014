/**
 * Verifies string is a valid url
 * @param {string} url input
 * @returns boolean
 */
export const isValidUrl = (string) => {
  try {
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
};

/**
 * Convert a bytes int into a readable file size
 */
export function formatBytes(bytes) {
  if (bytes === 0) {
    return "0 Bytes";
  }

  const units = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const base = 1024;
  const decimalPlaces = 2;

  const magnitude = Math.floor(Math.log(bytes) / Math.log(base));
  const size = (bytes / Math.pow(base, magnitude)).toFixed(decimalPlaces);
  const unit = units[magnitude];

  return `${size} ${unit}`;
}
