import React from "react";
import { assetURLs } from "../../constants/apiURLs";
import { apiCall } from "../../utilities/request";

import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import {
  CircularProgress,
  Button,
  Alert,
  Typography,
  Link,
  Divider,
} from "@mui/material";
import { useSnackbar } from "notistack";

export const AssetDownloader = ({ asset }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = React.useState(false);

  const [downloadURL, setDownloadURL] = React.useState(null);
  const [expiresAt, setExpiresAt] = React.useState(null);

  // Handle asset delete
  const generateLink = () => {
    setLoading(true);
    setDownloadURL(null);
    setExpiresAt(null);

    // Send the request
    apiCall
      .get(assetURLs.downloadURL(asset.id))
      .then((res) => {
        if (res?.data?.url) {
          setDownloadURL(res.data.url);

          // Is there an exipration date?
          if (res?.data?.expires_at) {
            // parse the date
            let date = new Date(res.data.expires_at);
            // Format the date
            date = date.toLocaleString("en-US", {
              timeZone: "America/New_York",
              hour: "numeric",
              minute: "numeric",
              hour12: true,
              day: "numeric",
              month: "short",
              year: "numeric",
            });

            setExpiresAt(date);
          }
          // Notify success
          enqueueSnackbar("Download ready", {
            variant: "success",
          });
        } else {
          enqueueSnackbar("Error generating download link", {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        console.error("Error getting download link", error.response);
        enqueueSnackbar("Error generating download link", {
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {!downloadURL && (
        <Button
          variant="text"
          color="primary"
          onClick={generateLink}
          disabled={loading}
          startIcon={
            loading ? <CircularProgress size={20} /> : <CloudDownloadIcon />
          }
        >
          Click here to download the original file
        </Button>
      )}
      {downloadURL && (
        <Alert severity="info" icon={<CloudDownloadIcon />}>
          <Typography variant="body2">
            Your download link is ready:
            <br />
            <Link target="_blank" href={downloadURL} rel="noopener noreferrer">
              Click here to download
            </Link>
          </Typography>
          {expiresAt && (
            <>
              <Divider
                sx={{
                  my: 1,
                }}
              />
              <Typography variant="body2">
                This link will expire at {expiresAt}
              </Typography>
            </>
          )}
        </Alert>
      )}
    </>
  );
};
