import React from "react";

import Paper from "@mui/material/Paper";
import { shopifyOAuthURLs } from "../constants/apiURLs";
import { apiCall } from "../utilities/request";
import {
  Alert,
  AlertTitle,
  Grid,
  Button,
  Grow,
  Typography,
  Stack,
} from "@mui/material";
import TimedProgressBar from "../components/TimedProgressBar";
import LaunchIcon from "@mui/icons-material/Launch";
import { getEnvironment } from "../utilities/environment";
import { hasOverrideAccountID, useAuth } from "../context/auth";
import { shopifyOAuthRedirect } from "../utilities/shopifyKey";
import { useNavigate } from "react-router";
import { getShopifyURLParams } from "../utilities/shopifyURLParams";

const Shopify = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [success, setSuccess] = React.useState(false);

  // Get params from URL
  const { shop } = getShopifyURLParams();

  const performOAuthRedirect = () => {
    // Redirect to Shopify using app bridge
    shopifyOAuthRedirect(shop);
  };

  // Run the authorize request on mount
  React.useEffect(() => {
    // Try to authorize w/ the API
    setError(null);
    apiCall
      .get(shopifyOAuthURLs.authorize() + window.location.search)
      .then((response) => {
        console.log("Authorize success");
        setSuccess(true);
        console.log("OAuth status data: ");
        console.log(response.data);

        switch (response.data?.status) {
          case "account_exists":
            console.log("Account exists - log in");
            console.log(response.data);

            // if the app has been uninstalled, we need to redirect to the app store instead of logging in
            const currentlyInstalled = response?.data?.shopify_store?.installed;

            console.log("App currently installed: " + currentlyInstalled);

            // log in if the app is already installed
            if (currentlyInstalled) {
              console.log("Local log in");

              auth.logInWithCredentials(
                response.data.account,
                response.data.token,
                false
              );

              window.analytics.identify(response.data.account.id, {
                name: response.data.account.name,
                environment: getEnvironment(),
                admin: false,
                shopify_shop: shop,
                website: "https://" + shop,
                createdAt: response.data.shopify_store.created,
              });

              // If not admin, log segment event for login
              if (!hasOverrideAccountID()) {
                window.analytics.track("logged_in", {
                  account_id: response.data.account.id,
                  account_name: response.data.account.name,
                  environment: getEnvironment(),
                  admin: false,
                  shopify_shop: shop,
                  website: "https://" + shop,
                  createdAt: response.data.shopify_store.created,
                });
              }

              console.log("Redirecting to dashboard");

              // Redirect to collections page
              navigate("/story_blocks");
            } else {
              console.log(
                "App not installed - redirect to app store for OAuth"
              );
              console.log("After that, we should take over that account");

              performOAuthRedirect();
            }

            break;
          case "account_does_not_exist":
            console.log("Account does not exist - head into the oauth flow");

            performOAuthRedirect();
            break;
          default:
            setError(
              "Unknown status response from the server. Please contact support."
            );
        }
      })
      .catch((error) => {
        console.error("Authorize error");
        console.error(error.response?.data);
        switch (error.response?.data?.error_code) {
          case 3006:
            setError(
              "There was a problem verifiying your shopify account (3006). Please report this to the support team."
            );
            // @todo open support ticket
            break;
          case 3007:
            setError(
              "There was a problem verifiying your shopify account (3007). Please report this to the support team."
            );
            break;
          default:
            console.error("No response code given");
            console.error(error.response.data);
            if (typeof error?.response?.data?.error != "undefined") {
              setError(error.response.data.error);
            } else {
              setError("An unexpected error occured. Please contact support.");
            }
        }
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  document.title = "Connecting to Shopify | Kahani";

  return (
    <Grid
      container
      spacing={6}
      sx={{
        backgroundColor: "#f5f5f5",
        height: "100vh",
      }}
    >
      <Grid item sm={false} md={2} lg={4}></Grid>
      <Grid item sm={12} md={8} lg={4}>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            marginTop: 20,
          }}
          elevation={1}
        >
          <KahaniShopifyLinkAnimation />
          {loading && (
            <>
              <Typography variant="h6" sx={{ marginTop: 3 }}>
                Please wait while we authorize your account...
              </Typography>
              <TimedProgressBar duration={10} type="linear" />
            </>
          )}
          {success && (
            <Alert severity="success">
              <AlertTitle>Success</AlertTitle>
              Redirecting to Shopify for verification.{" "}
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  performOAuthRedirect();
                }}
                variant="text"
                color="primary"
                size="small"
                startIcon={<LaunchIcon />}
              >
                Click here{" "}
              </Button>
              if you are not redirected automatically.
            </Alert>
          )}
          <Grow in={error !== null}>
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {error}
            </Alert>
          </Grow>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Shopify;

export const KahaniShopifyLinkAnimation = () => {
  return (
    <>
      <Typography
        variant="h5"
        gutterBottom
        color="rgba(122, 87, 209)"
        sx={{
          textAlign: "center",
        }}
      >
        Connecting Kahani to Shopify
      </Typography>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="center"
        sx={{
          marginTop: 3,
          marginBottom: 3,
        }}
      >
        <img
          src="/img/kahani-circle-logo.png"
          alt="Kahani"
          style={{
            borderRadius: "20px",
          }}
          width="100"
        />
        <TimedProgressBar duration={10} type="linear" />
        <img src="/img/shopify_logo_lg.png" alt="Shopify" width="100" />
      </Stack>
    </>
  );
};
