import React, { useContext } from "react";
import { useResolvedPath, useMatch, Link } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import { ListItem, ListItemIcon, ListItemText, Tooltip } from "@mui/material";
import { MainContext } from "../context/main";

export default function ActiveMenuLink({ children, to, ...props }) {
  const mainContext = useContext(MainContext);
  const theme = useTheme();
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  const isActive = match ? true : false;

  // ID is the label with dashes instead of spaces & lower case
  const id = props.id
    ? props.id
    : "drawer-nav-" + props.label.replace(/ /g, "-").toLowerCase();

  return (
    <div>
      <Tooltip
        title={props.label}
        placement="right"
        arrow
        disableHoverListener={mainContext.isNavOpen}
      >
        <Link
          style={{
            fontWeight: isActive ? "bold" : "",
            textDecoration: "none",
            color: theme.palette.text.primary,
          }}
          aria-label={props.label}
          to={to}
          {...props}
          id={id}
        >
          <ListItem button selected={isActive}>
            <ListItemIcon>{props.icon}</ListItemIcon>
            <ListItemText primary={props.label} />
          </ListItem>
        </Link>
      </Tooltip>
    </div>
  );
}
