import { useQuery } from "react-query";
import find from "lodash/find";

import { influencerURLs } from "../constants/apiURLs";
import { API_QUERY_KEYS } from "../constants/textMappings";
import queryClient from "../constants/queryClient";
import request from "../utilities/request";

export const queryKey = API_QUERY_KEYS.INFLUENCER_REQUEST;

export const useInfluencers = () => {
  const { data, refetch, ...meta } = useQuery(queryKey, () => {
    return request().get(influencerURLs.list());
  });

  return {
    influencers: data?.influencers ?? [],
    refetch,
    ...meta,
  };
};

export const useInfluencer = (influencerID) => {
  const { influencers, ...meta } = useInfluencers();
  const influencer = find(influencers, { id: influencerID });

  return {
    influencer: influencer ?? {},
    ...meta,
  };
};

export const invalidateInfluencerCache = async () => {
  await queryClient.invalidateQueries(queryKey);
};
