import React from "react";
import map from "lodash/map";

import AssetCard from "./AssetCard";

import ImageList from "@mui/material/ImageList";
import { useTheme } from "@mui/material/styles";

import {
  FormControl,
  FormControlLabel,
  Radio,
  Grid,
  RadioGroup,
  TextField,
  useMediaQuery,
  Grow,
  Alert,
  Paper,
  Checkbox,
  FormGroup,
} from "@mui/material";

import Fuse from "fuse.js";
import AssetUploader from "./AssetUploader";
import { CROP_VERTICAL_9_16 } from "../../constants/assets";
import {
  assetSourceInstagram,
  assetSourceUploaded,
} from "../../api/fetchAssetData";

const AssetList = ({
  assets,
  onSelect,
  filter,
  onUpload,
  uploaderAllowMultiple,
  selectedAssetIDs,
  compressed = false,
  cropMode = CROP_VERTICAL_9_16,
}) => {
  const [filteredAssets, setFilteredAssets] = React.useState(assets);
  const [selectedAsset, setSelectedAsset] = React.useState("");
  const [searchTerm, setSearchTerm] = React.useState("");
  const [typeFilter, setTypeFilter] = React.useState("all");
  const typeFilterChange = (e) => {
    setTypeFilter(e.target.value);
  };

  const [sourceSelectorUploaded, setSourceSelectorUploaded] =
    React.useState(true);
  const [sourceSelectorInstagram, setSourceSelectorInstagram] =
    React.useState(true);

  // On little screens, show fewer columns
  const theme = useTheme();
  const numCols = useMediaQuery(theme.breakpoints.up("md")) ? 6 : 2;

  // Check if any filters were set in the props
  const staticTypeFilterSet = filter && filter.type;

  // Onload, run assets w/ the default filter
  // When type filter changes, re-filter assets
  // When search term changes, re-filter assets
  React.useEffect(() => {
    filterAssets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    assets,
    searchTerm,
    typeFilter,
    filter,
    sourceSelectorInstagram,
    sourceSelectorUploaded,
  ]);

  // Function to filter the assets
  const filterAssets = () => {
    // Don't run if there are no assets
    if (!assets || assets.length === 0) return;

    // Copy assets
    let filteredAssets = [...assets];

    // Filter assets by currently selected type
    filteredAssets = assets.filter((asset) => {
      let tempTypeFilter = typeFilter;

      if (filter && filter.type) {
        tempTypeFilter = filter.type;
      }

      if (tempTypeFilter === "all") {
        return true;
      } else {
        switch (tempTypeFilter) {
          case "images":
            return asset.type === "image";
          case "videos":
            return (
              asset.type === "cloudflare_stream" || asset.type === "mux_stream"
            );
          default:
            return false;
        }
      }
    });

    // Filter by source
    filteredAssets = filteredAssets.filter((asset) => {
      if (sourceSelectorInstagram && assetSourceInstagram(asset)) return true;
      if (sourceSelectorUploaded && assetSourceUploaded(asset)) return true;
      return false;
    });

    // Filter assets by search term using fuse.js if search term is set
    if (searchTerm) {
      const fuse = new Fuse(filteredAssets, {
        keys: ["internal_name", "internal_description", "tags"],
        threshold: 0.5,
      });

      // Run search
      const results = fuse.search(searchTerm);

      //  Convert results back into the original array type
      filteredAssets = results.map((result) => result.item);
    }

    // Set assets
    setFilteredAssets(filteredAssets);
  };

  return (
    <Grid container spacing={3}>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <TextField
            label="Search"
            variant="outlined"
            margin="normal"
            placeholder="Search media tags or metadata"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={(e) => e.stopPropagation()}
            sx={{ width: "350px" }}
            size={compressed ? "small" : "medium"}
          />
          {!staticTypeFilterSet && (
            <>
              <VerticalDivider />
              <FormControl>
                <RadioGroup
                  aria-label="type-filter"
                  name="type-filter"
                  value={typeFilter}
                  onChange={typeFilterChange}
                  sx={{ flexDirection: "row", pl: 5 }}
                >
                  <FormControlLabel
                    value="all"
                    control={<Radio size="small" />}
                    label="All"
                  />
                  <FormControlLabel
                    value="images"
                    control={<Radio size="small" />}
                    label="Images"
                  />
                  <FormControlLabel
                    value="videos"
                    control={<Radio size="small" />}
                    label="Videos"
                  />
                </RadioGroup>
              </FormControl>
            </>
          )}
          <VerticalDivider />
          {/* Source selector */}
          <FormGroup row>
            <FormControlLabel
              control={<Checkbox size="small" />}
              label="Uploaded"
              checked={sourceSelectorUploaded}
              onChange={(e) => setSourceSelectorUploaded(e.target.checked)}
            />
            <FormControlLabel
              control={<Checkbox size="small" />}
              label="Instagram"
              checked={sourceSelectorInstagram}
              onChange={(e) => setSourceSelectorInstagram(e.target.checked)}
            />
          </FormGroup>
        </div>
        <AssetUploader
          allowMultiple={uploaderAllowMultiple}
          onDone={onUpload}
          selectedAsset={selectedAsset}
          setSelectedAsset={setSelectedAsset}
        />
      </Grid>

      <Grid item xs={12}>
        <Paper sx={{ px: 3, py: 2 }}>
          {/* On small screens, use 2 cols instad of 6 */}
          <ImageList cols={numCols} sx={{ width: "100%" }} gap={8}>
            {map(filteredAssets, (asset) => (
              <AssetCard
                onSelect={() => {
                  onSelect(asset);
                }}
                asset={asset}
                key={asset.id}
                selectedAssetIDs={selectedAssetIDs}
                cropMode={cropMode}
              />
            ))}
          </ImageList>

          <Grow
            in={
              (!filteredAssets.length && searchTerm.length > 0) ||
              !assets.length
            }
          >
            <Grid item xs={12}>
              {searchTerm.length ? (
                <Alert severity="info">
                  There are no photos/videos that match your search query
                </Alert>
              ) : (
                <Alert severity="info">
                  You have not uploaded any media yet
                </Alert>
              )}
            </Grid>
          </Grow>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default AssetList;

const VerticalDivider = () => {
  return (
    <div
      style={{
        height: "40px",
        width: "1px",
        backgroundColor: "#ccc",
        margin: "0 15px",
      }}
    />
  );
};
