import React from "react";

import {
  Paper,
  Grid,
  Alert,
  AlertTitle,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { shopifyOAuthURLs } from "../constants/apiURLs";
import { useAuth } from "../context/auth";
import { apiCall } from "../utilities/request";
import { getEnvironment } from "../utilities/environment";
import { useNavigate } from "react-router";
import { getShopifyURLParams } from "../utilities/shopifyURLParams";
import { KahaniShopifyLinkAnimation } from "./Shopify";

const ShopifyCallback = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(false);

  const auth = useAuth();
  // Get params from URL
  const { code, hmac, shop, timestamp, host, nonce } = getShopifyURLParams();

  // OAuth complete. Run the callback endpoint to create the account and then log in
  React.useEffect(() => {
    console.info("Shopify embedded app");

    setError(null);
    //  Create account
    apiCall
      .get(shopifyOAuthURLs.callback() + window.location.search)
      .then((response) => {
        console.log("Callback success. Account created");

        // log in
        auth.logInWithCredentials(
          response.data.account,
          response.data.token,
          false
        );

        window.analytics.identify(response.data.account.id, {
          name: response.data.account.name,
          environment: getEnvironment(),
          admin: false,
          shopify_shop: shop,
          website: "https://" + shop,
          createdAt: response.data.shopify_store.created,
        });

        setSuccess(true);

        // Redirect to the homepage
        navigate("/");
      })
      .catch((error) => {
        console.error("Authorize error");
        console.error(error.response?.data);

        switch (error.response?.data?.error_code) {
          case 3006:
            setError(
              "There was a problem verifiying your shopify account (3006). Please report this to the support team."
            );
            // @todo open support ticket
            break;
          case 3007:
            setError(
              "There was a problem verifiying your shopify account (3007). Please report this to the support team."
            );
            break;
          default:
            console.error("No response code given");
            console.error(error.response.data);
            setError(error.response.data.error);
        }
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // @docs https://shopify.dev/apps/auth/oauth/getting-started

  document.title = "Connecting to Shopify | Kahani";
  return (
    <Grid
      container
      spacing={6}
      sx={{
        backgroundColor: "#f5f5f5",
        height: "100vh",
      }}
    >
      <Grid item sm={false} md={2} lg={3}></Grid>
      <Grid item sm={12} md={8} lg={6}>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            marginTop: 10,
          }}
          elevation={1}
        >
          <KahaniShopifyLinkAnimation />
          {loading && (
            <Alert severity="info">
              <AlertTitle>Loading account...</AlertTitle>
              We are loading your Kahani account. Please wait
            </Alert>
          )}
          {error && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {error}
              <Divider
                sx={{
                  marginTop: 3,
                  marginBottom: 3,
                }}
              />
              Please share this information with our support team:
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell align="right">HMAC</TableCell>
                    <TableCell>{hmac}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right">Shop</TableCell>
                    <TableCell>{shop}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right">Timestamp</TableCell>
                    <TableCell>{timestamp}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right">Host</TableCell>
                    <TableCell>{host}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right">Nonce</TableCell>
                    <TableCell>{nonce}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right">Code</TableCell>
                    <TableCell>{code}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Alert>
          )}
          {success && (
            <Alert severity="success">
              <AlertTitle>Success</AlertTitle>
              Your account has been created. Taking you to the dashboard now...
            </Alert>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ShopifyCallback;
