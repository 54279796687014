import React from "react";

import "./styles/gradient.css";

const Gradients = () => {
  return (
    <>
      <div className="kahani-story-gradient-top"></div>
      <div className="kahani-story-gradient-bottom"></div>
    </>
  );
};

export default Gradients;
