import { createTheme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

const darkTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
        },
      },
    },
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#90CAF9",
      light: "#E3F2FD",
      dark: "#42A5F5",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    secondary: {
      main: "#CE93D8",
      dark: "#AB47BC",
      light: "#F3E5F5",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    "background-color": grey[800],
  },
});

export default darkTheme;
