import React, { useState } from "react";

import { useCollections } from "../api/fetchCollectionData";

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";

export default function CollectionDropdown({
  onSelect,
  size = "medium",
  error,
  selected = "",
}) {
  // Get collections list
  const { isLoading, collections } = useCollections();

  const [collectionID, setCollectionID] = useState(selected);

  const handleChange = (event) => {
    setCollectionID(event.target.value);

    // find that collection in the list by matching the ID
    const collection = collections.find(
      (collection) => collection.id === event.target.value
    );

    // call the callback with the collection
    onSelect(collection);
  };

  const label = isLoading ? "Loading Story Blocks..." : "Select a Story Block";

  const storiesLabel = (count) => (count === 1 ? "story" : "stories");

  return (
    <Tooltip title={label}>
      <FormControl fullWidth disabled={isLoading}>
        <InputLabel id="collection-picker-label">Select Story Block</InputLabel>
        {isLoading && <FormHelperText>Loading Story Blocks...</FormHelperText>}
        {!isLoading && (
          <Select
            labelId="collection-picker-label"
            value={collectionID}
            label={label}
            onChange={handleChange}
            size={size}
            error={error}
          >
            {/* One item for each collection */}
            {collections.map((collection) => (
              <MenuItem key={collection.id} value={collection.id}>
                {collection.internal_name} ({collection.stories.length}{" "}
                {storiesLabel(collection.stories.length)})
              </MenuItem>
            ))}
          </Select>
        )}
        <FormHelperText error={error}>{error}</FormHelperText>
      </FormControl>
    </Tooltip>
  );
}
