import { useState, useMemo, createContext } from "react";
import { createTheme } from "@mui/material/styles";
import lightTheme from "../themes/light";
import darkTheme from "../themes/dark";

export const ThemeContext = createContext();

export const useThemeContext = () => {
  const [mode, setMode] = useState("light");

  // Values for Theme Context
  const themeValues = useMemo(
    () => ({
      toggleThemeMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  // Creates theme for MUI Theme Provider
  const theme = useMemo(
    () => createTheme(mode === "light" ? lightTheme : darkTheme),
    [mode]
  );

  return { theme, themeValues };
};
