import React, { useEffect, useState } from "react";

import { useShopifyStore, useAccount } from "../../api/fetchAccountData";

import { shopifyOAuthRedirect } from "../../utilities/shopifyKey";
import { SHOPIFY_SCOPES } from "../../constants/textMappings";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Backdrop,
  Divider,
  Typography,
} from "@mui/material";
import { hasOverrideAccountID } from "../../context/auth";
import { useSnackbar } from "notistack";

/*

  If a regular user doesn't have the proper scopes, we need to give them a mean dialog to get them to reauthenticate

  If an admin is logged in as a user, we don't want to show this dialog, but instead show a little notification

*/

export default function ScopeUpdateDialog() {
  const { account } = useAccount();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const { shopifyStore } = useShopifyStore();

  useEffect(() => {
    if (shopifyStore?.shop_name) {
      // compare the given scopes with the new scopes
      // if they don't match then we need to reauthenticate
      if (account.id === "875c35ec-a91c-4ac8-9518-819d24b2f2e0") {
        return;
      }

      if (SHOPIFY_SCOPES !== shopifyStore.scopes) {
        console.warn("Shopify scope mismatch. Redirecting to upgrade");
        console.log("Old: " + shopifyStore.scopes);
        console.log("New: " + SHOPIFY_SCOPES);
        setOpen(true);
        // @todo give a dialog instead of this
        // @todo move the whole shopify and callback page their own pages, outside of the <Page /> component

        // If admin, just give a nice notification and don't allo
        if (hasOverrideAccountID()) {
          enqueueSnackbar(
            "This customer needs to reauthenticate with Shopify.",
            {
              variant: "warning",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
              preventDuplicate: true,
            }
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopifyStore.shop_name, shopifyStore.scopes, account.id]);

  // Check if an admin is logged in
  // Disable popup if admin is logged in as a user account
  if (hasOverrideAccountID()) {
    return null;
  }

  return (
    <>
      <Backdrop
        open={open}
        sx={{
          filter: "blur(2px)",
        }}
      />
      <Dialog
        open={open && !hasOverrideAccountID()}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Kahani needs additional permissions
        </DialogTitle>
        <DialogContent>
          Kahani is constantly building new features and sometimes that means we
          need to request additional permissions on your shopify account. Please
          authorize the new permissions to continue using Kahani.
          <Divider sx={{ my: 2 }} />
          <Typography variant="caption" color="primary">
            You can dismiss this alert, but Kahani may not function properly.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => shopifyOAuthRedirect(shopifyStore.shop_name)}
          >
            Authorize
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
