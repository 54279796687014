import React, { useState, memo } from "react";
import { v4 as uuidv4 } from "uuid";

import LoadingBackdrop from "../components/LoadingBackdrop";
import { apiCall } from "../utilities/request";
import { buildPreviewURL } from "../utilities/livePreview";

// Constants
import { adminURLs } from "../constants/apiURLs";

// Styling
import {
  Alert,
  AlertTitle,
  Button,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

export default function SalesDemos() {
  document.title = "Admin - Sales Demos | Kahani";

  return (
    <Grid
      container
      spacing={6}
      sx={{
        backgroundColor: "#f5f5f5",
        height: "100vh",
      }}
    >
      <Grid item sm={false} md={1} lg={2}></Grid>
      <Grid item sm={12} md={10} lg={8}>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            marginTop: 5,
          }}
          elevation={1}
        >
          <Stack
            spacing={2}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5" color="primary">
              Sales Demo Generator
            </Typography>
          </Stack>
          <RawForm />
        </Paper>
      </Grid>
    </Grid>
  );
}

const RawForm = () => {
  const targetAccountID = "af143e2c-479d-4941-b767-48cf99b4f399";

  const [stories, setStories] = useState([
    {
      id: uuidv4(),
      name: "",
      url: "",
    },
  ]);

  const updateStory = (id, name, url) => {
    setStories((stories) => {
      const newStories = stories.map((story) => {
        if (story.id === id) {
          return {
            id: id,
            name: name,
            url: url,
          };
        }
        return story;
      });
      return newStories;
    });
  };

  const addStory = () => {
    setStories((stories) => {
      const newStories = stories.concat({
        id: uuidv4(),
        name: "",
        url: "",
      });
      return newStories;
    });
  };

  const removeStory = (id) => {
    setStories((stories) => {
      const newStories = stories.filter((story) => story.id !== id);
      return newStories;
    });
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [previewURL, setPreviewURL] = useState(null);
  const [outputText, setOutputText] = useState(null);
  const generate = () => {
    setLoading(true);
    setError(null);
    setOutputText(null);
    setPreviewURL(null);

    const data = {
      collections: stories,
      accountID: targetAccountID,
    };
    apiCall
      .put(adminURLs.salesDemoGenerateCollection(), data)
      .then((response) => {
        console.log(response);
        if (response.data?.success) {
          const collectionID = response.data.collectionID;

          const { previewURL } = buildPreviewURL(collectionID, targetAccountID);
          setPreviewURL(previewURL);

          setOutputText(response.data.textOutput);
        }
      })
      .catch((error) => {
        console.error(error);
        setError(JSON.stringify(error.response));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Grid container spacing={3}>
      <LoadingBackdrop loading={loading} timer={30} />
      <Grid item xs={12}>
        {stories.map((story) => (
          <StoryInput
            key={story.id}
            story={story}
            onChange={(story) => updateStory(story.id, story.name, story.url)}
            onDelete={() => removeStory(story.id)}
          />
        ))}
      </Grid>
      <Grid item xs={12}>
        <Stack
          spacing={2}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Button variant="contained" color="primary" onClick={addStory}>
            Add Story
          </Button>
          <Button variant="contained" color="secondary" onClick={generate}>
            Generate
          </Button>
        </Stack>
      </Grid>
      {previewURL && (
        <Grid item xs={12}>
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            <Typography>
              Preview:{" "}
              <a href={previewURL} target="_blank" rel="noreferrer">
                {previewURL}
              </a>
            </Typography>
            <Typography variant="h6">Generated Stories</Typography>
            <pre>{outputText}</pre>
          </Alert>
        </Grid>
      )}
      {error && (
        <Grid item xs={12}>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            <Typography>{error}</Typography>
          </Alert>
        </Grid>
      )}
    </Grid>
  );
};

const StoryInput = memo(
  ({ story, onChange, onDelete }) => {
    return (
      <Grid container spacing={3} sx={{ my: 0.5 }}>
        <Grid item xs={12} sm={5}>
          <TextField
            label="Story Name"
            value={story.name}
            placeholder="Back To School"
            onChange={(e) => onChange({ ...story, name: e.target.value })}
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            label="products.json URL"
            value={story.url}
            placeholder="https://allbirds.com/collection-name/products.json"
            onChange={(e) => onChange({ ...story, url: e.target.value })}
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <IconButton color="error" onClick={onDelete}>
            <CancelIcon />
          </IconButton>
        </Grid>
      </Grid>
    );
  },
  (prevProps, nextProps) => {
    // name or url different
    return (
      prevProps.story.name === nextProps.story.name &&
      prevProps.story.url === nextProps.story.url
    );
  }
);
