import DeleteIcon from "@mui/icons-material/Delete";

export const adminApiUrls = [
  { url: "https://api.dev.kahaniapp.com" },
  { url: "http://localhost:5001" },
  { url: "https://api.prd.kahaniapp.com" },
  {
    url: "/api-dev",
    title: "Netlify proxy for https://api.dev.kahaniapp.com (/api-dev)",
  },
  {
    url: "/api",
    title: "Netlify proxy for https://api.prd.kahaniapp.com (/api)",
  },
  {
    url: "",
    title: "Clear",
    buttonColor: "warning",
    startIcon: <DeleteIcon />,
  },
];
