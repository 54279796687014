import React, { useEffect, useState, useContext } from "react";

import MainMenu from "../components/DrawerMenu";
import Copyright from "./Copyright";
import { getEnvironment } from "../utilities/environment";
import ScopeUpdateDialog from "../modules/shopify/ScopeUpdate";

// State Management
import { useAuth } from "../context/auth";
import { MainContext } from "../context/main";

// Styling
import { useTheme, styled } from "@mui/material/styles";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import { AccountCircle } from "@mui/icons-material";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => !["open", "isAuthenticated"].includes(prop),
})(({ theme, open, isAuthenticated }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${isAuthenticated ? drawerWidth : 0}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "fixed",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const Page = (props) => {
  const theme = useTheme();
  const auth = useAuth();
  const env = getEnvironment();
  const mainContext = useContext(MainContext);

  // const [open, setOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const { width: windowWidth } = useWindowSize();

  useEffect(() => {
    document.title = props.title ? `${props.title} | Kahani` : "Kahani";
  });

  useEffect(() => {
    // app bar starts closed if the screen is smaller than 400 px wide
    if (windowWidth < 700) {
      mainContext.setIsNavOpen(false);
    } else {
      mainContext.setIsNavOpen(true);
    }
  }, [mainContext, windowWidth]);

  const pullRequestLink = (
    <>
      &mdash;
      <Button
        href={
          "https://github.com/gatewayx/kahani-portal/pull/" + env?.REVIEW_ID
        }
        target="github-pr"
        color="primary"
        variant="contained"
      >
        Open PR on Github
      </Button>
    </>
  );

  return (
    <Box sx={{ backgroundColor: theme.palette["background-color"] }}>
      <ScopeUpdateDialog />
      <AppBar
        isAuthenticated={auth.isAuthenticated}
        position="absolute"
        open={mainContext.isNavOpen}
      >
        <Toolbar sx={{ pr: "24px", backgroundColor: "#000" }}>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            <img
              src={require("../assets/logo.png")}
              alt="invenstory icon"
              style={{
                marginRight: "8px",
                verticalAlign: "middle",
                height: "32px",
              }}
            />
            {env !== "production"
              ? `${env.charAt(0).toUpperCase() + env.slice(1)} Build`
              : null}

            {/* If pull request, then show the link too */}
            {getEnvironment() === "pull_request" && pullRequestLink}
          </Typography>
          {auth.isAuthenticated && (
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={(event) => setAnchorEl(event.currentTarget)}
                color="inherit"
              >
                <AccountCircle />
                <span style={{ fontSize: "1rem", marginLeft: "7px" }}>
                  {auth.user.name}
                </span>
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem onClick={auth.signout}>Logout</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
      {auth.isAuthenticated && (
        <Drawer variant="permanent" open={mainContext.isNavOpen}>
          <Divider />
          <MainMenu open={mainContext.isNavOpen} />
        </Drawer>
      )}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100%",
          overflow: "auto",
          width: `calc(100% - ${mainContext.isNavOpen ? drawerWidth : 0}px)`,
          marginLeft: `${mainContext.isNavOpen ? drawerWidth : 60}px`,
          minHeight: "100vh",
        }}
      >
        <Toolbar />
        <Container maxWidth="xl" sx={{ mb: 4 }}>
          {props.isLoading ? (
            <Grid
              item
              xs={12}
              sx={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                top: "-64px",
              }}
            >
              <CircularProgress size={80} />
            </Grid>
          ) : (
            props.children
          )}
          <Copyright sx={{ pt: 4 }} />
        </Container>
      </Box>
    </Box>
  );
};

function Title(props) {
  const theme = useTheme();
  const id = props?.id ? props.id : null;
  const color = props?.color ? props.color : theme.palette.text.primary;
  const variant = props?.variant ? props.variant : "h4";
  const pageTitleStyle = {
    paddingTop: "48px",
    paddingBottom: "24px",
  };

  return (
    <Typography
      id={id}
      component="h2"
      variant={variant}
      color={color}
      sx={pageTitleStyle}
    >
      {props.children}
    </Typography>
  );
}

Page.Title = Title;

export default Page;

function useWindowSize() {
  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
  React.useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return {
    width: size[0],
    height: size[1],
  };
}
