import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { apiCall } from "../../utilities/request";
import { shopifyURLs } from "../../constants/apiURLs";
import { useSnackbar } from "notistack";
import {
  Alert,
  AlertTitle,
  Card,
  CardMedia,
  Divider,
  Grid,
  Stack,
} from "@mui/material";
import TimedProgressBar from "../../components/TimedProgressBar";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CircularProgress from "@mui/material/CircularProgress";
import { requestAccountID } from "../../context/auth";
import { useShopifyStore } from "../../api/fetchAccountData";
import WidgetCode from "./WidgetCode";

const requiredTimeBetweenVerifications = 45; // in seconds

export default function AppBlockWizard() {
  const { enqueueSnackbar } = useSnackbar();

  // @todo handle loading & errors on this.
  const { shopifyStore } = useShopifyStore();

  const [verified, setVerified] = React.useState(false);
  const [verifiedError, setVerifiedError] = React.useState(null);
  const [verifyLoading, setVerifyLoading] = React.useState(false);
  const [verifyAgainIn, setVerifyAgainIn] = React.useState(null);

  // If verify again has time remaining, update it every second
  React.useEffect(() => {
    if (verifyAgainIn && verifyAgainIn > 0) {
      const timeout = setTimeout(() => {
        setVerifyAgainIn(verifyAgainIn - 1);
      }, 1000);

      return () => {
        clearInterval(timeout);
      };
    } else {
      setVerifyAgainIn(null);
    }
  }, [verifyAgainIn]);

  function verifyInstallation(showFailureMessage = false) {
    setVerifyLoading(true);
    setVerifiedError(null);
    setVerifyAgainIn(null);
    apiCall
      .get(shopifyURLs.verifyAppBlockInstallation())
      .then((res) => {
        // Check response data for status
        if (res?.data?.installed) {
          setVerified(true);

          // re-identify as having the shopify app block installed
          window.analytics.identify(requestAccountID(), {
            shopifyModuleCurrentlyInstalled: true,
          });

          console.log("App block type detected: " + res?.data?.type);
          return;
        }

        setVerifiedError(
          "Kahani was not found in your theme. Follow the instructions below to install it."
        );

        // App block not installed
        enqueueSnackbar("Kahani not found in your theme", {
          variant: "warning",
        });
        if (showFailureMessage) {
        }

        // re-identify as having the shopify app block not installed
        window.analytics.identify(requestAccountID(), {
          shopifyModuleCurrentlyInstalled: false,
        });
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar("There was a problem checking your theme for Kahani", {
          variant: "error",
        });
        setVerifiedError(
          "There was a problem checking your theme for Kahani. Please try again or contact support."
        );
      })
      .finally(() => {
        setVerifyLoading(false);
        setVerifyAgainIn(requiredTimeBetweenVerifications); // restart the counter
      });
  }

  // Run verifyInstallation() on mount
  React.useEffect(() => {
    verifyInstallation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Detect theme type
  const [themeType, setThemeType] = React.useState("");
  const [themeTypeLoading, setThemeTypeLoading] = React.useState(false);
  React.useEffect(() => {
    setThemeTypeLoading(true);
    apiCall
      .get(shopifyURLs.verifyAppBlockSupport())
      .then((res) => {
        const appEmbedBlockSupported = res?.data?.app_embed_block_supported
          ? true
          : false;
        const appBlockSupported = res?.data?.app_block_supported ? true : false;

        // re-identify so we know which block types are supported
        window.analytics.identify(requestAccountID(), {
          shopifyAppEmbedBlockSupported: appEmbedBlockSupported,
          shopifyAppBlockSupported: appBlockSupported,
        });

        // Check response data for status
        if (appBlockSupported) {
          // 2.0 theme
          setThemeType("2.0");
          return;
        }
        if (appEmbedBlockSupported) {
          // 1.0 theme
          setThemeType("1.0");
          return;
        }

        // No theme detected
        setThemeType("unknown");
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar("Could not detect theme type", {
          variant: "warning",
        });
      })
      .finally(() => {
        setThemeTypeLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Install links
  const themeEditor = `https://${shopifyStore?.shop_name}/admin/themes`;

  // @demo
  React.useEffect(() => {
    window.setThemeType = (type) => {
      console.log("call this function with `1.0`, `2.0`, or `unknown`");
      setThemeType(type);
    };
  }, []);

  let videoLink = "";
  switch (themeType) {
    case "1.0":
      videoLink = "https://www.loom.com/share/24f1e3d0aad44ca7b7e99da995c85de0";
      break;
    case "2.0":
      videoLink = "https://www.loom.com/share/014847366df1468883b744ea5253d366";
      break;
    default:
      videoLink = "https://www.loom.com/share/169ad637f9b1462e8837193c10012619";
      break;
  }

  return (
    <>
      <Stack spacing={2} direction="column">
        {!verifyLoading && verified && (
          <Alert severity="success">
            <AlertTitle>Kahani is Installed</AlertTitle>
            We have detected that Kahani is installed on your theme.
          </Alert>
        )}
        {verifyLoading && (
          <Alert severity="info">
            <AlertTitle>Verifying Installation...</AlertTitle>
            Please wait while we verify your installation. This can take up to a
            minute for themes with many files.
            <TimedProgressBar duration={60} type="linear" />
          </Alert>
        )}

        {verifiedError && themeType !== "unknown" && (
          <Alert severity="warning">
            <AlertTitle>Kahani Not Installed</AlertTitle>
            {verifiedError}
          </Alert>
        )}

        <Divider />

        {themeTypeLoading && (
          <Alert severity="info">
            <AlertTitle>Detecting Theme Type...</AlertTitle>
            Please wait while we detect your theme type.
            <TimedProgressBar duration={30} type="linear" />
          </Alert>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12} lg={6} sx={{ paddingRight: 10 }}>
            {!themeTypeLoading && themeType === "2.0" && (
              <Box>
                <Typography variant="h4" color="inherit">
                  2.0 Theme Detected
                </Typography>
                <Typography variant="body1" color="inherit">
                  We have detected that your theme is a 2.0 theme. You may
                  install Kahani by adding an App Block section to your theme in
                  the visual editor.
                  <Button
                    variant="text"
                    color="primary"
                    startIcon={<OpenInNewIcon />}
                    href={themeEditor}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Click here
                  </Button>
                  to open the theme editor.
                  <br />
                  <br />
                  After you add the App Block section, come back here to verify
                  your installation.
                  <br />
                  Please visit the{" "}
                  <a href="https://help.kahaniapp.com/article/14-shopify-2-0-kahani-app-block-installation">
                    Kahani documentation
                  </a>{" "}
                  for instructions on installing Kahani on your theme.
                </Typography>
              </Box>
            )}
            {!themeTypeLoading && themeType === "1.0" && (
              <Box>
                <Typography variant="h4" color="inherit">
                  Legacy Theme Detected
                </Typography>
                <Typography variant="body1" color="inherit">
                  We have detected a 1.0 theme on your online store.
                  <Button
                    variant="text"
                    color="primary"
                    startIcon={<OpenInNewIcon />}
                    href={themeEditor}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Click here
                  </Button>
                  to open the theme editor and enable the Kahani App Embed
                  Block. You may need a developer's help to do this.
                  <br />
                  <br />
                  After enabling the Kahani App Embed Block, come back here to
                  verify your installation.
                  <br />
                  Please visit the{" "}
                  <a href="https://help.kahaniapp.com/article/13-shopify-1-0-theme-app-block-embed">
                    Kahani documentation
                  </a>{" "}
                  for instructions on installing Kahani on your theme.
                </Typography>
              </Box>
            )}
            {/* On an unknown theme type OR when we couldn't detect the theme type, give the custom widget */}
            {!themeTypeLoading &&
              (themeType === "unknown" ||
                (verifiedError && themeType === "")) && (
                <Box>
                  <Typography variant="h4" color="inherit">
                    Unknown Theme Detected
                  </Typography>
                  <Typography variant="body1" color="inherit">
                    We could not detect your theme type. This means you are
                    likely not using a theme based on one of the Shopify example
                    themes. That's OK, but you will have to copy the Kahani code
                    into your theme manually.
                    <br />
                    <br />
                    You will have to manually/visually verify that Kahani
                    installed correctly in your custom theme.
                    <br />
                    <br />
                    <WidgetCode />
                    <br />
                    Please visit the{" "}
                    <a href="https://help.kahaniapp.com/article/15-unknown-theme-or-non-shopify-site-kahani-app-block-installation">
                      Kahani documentation
                    </a>{" "}
                    for instructions on installing Kahani on your theme.
                  </Typography>
                </Box>
              )}
          </Grid>
          <Grid item xs={12} lg={5}>
            {!themeTypeLoading && (
              <Card elevation={1} sx={{ paddingLeft: 3 }}>
                <Stack
                  spacing={2}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack spacing={2} direction="column" sx={{ paddingTop: 2 }}>
                    <Typography variant="h5" color="primary">
                      Video Guide
                    </Typography>
                    <Typography variant="body1" color="inherit">
                      Follow along to install Kahani
                    </Typography>
                  </Stack>
                  <CardMedia
                    component="img"
                    sx={{ width: 300, cursor: "pointer" }}
                    image="https://cdn.loom.com/sessions/thumbnails/169ad637f9b1462e8837193c10012619-with-play.gif"
                    alt="Video Guide"
                    onClick={() => {
                      window.open(videoLink, "_blank");
                    }}
                  />
                </Stack>
              </Card>
            )}
          </Grid>
        </Grid>

        <Divider />

        <Grid container spacing={2}>
          <Grid item xs={false} sm={3}></Grid>
          <Grid item xs={12} sm={6}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                verifyInstallation(true);
              }}
              disabled={verifyLoading || (verifyAgainIn && verifyAgainIn > 0)}
              startIcon={verifyLoading ? <CircularProgress size={24} /> : null}
            >
              Verify Installation
            </Button>
            {verifyAgainIn && verifyAgainIn > 0 && (
              <Typography variant="subtitle2" color="primary">
                Please wait {verifyAgainIn} seconds to try again.
              </Typography>
            )}
          </Grid>
        </Grid>
      </Stack>
    </>
  );
}
