import React, { useState } from "react";
import { useSnackbar } from "notistack";

import { getBaseURL, setCustomBaseURL } from "../../utilities/parseCookie";

// Constants
import { adminApiUrls } from "../../constants/adminApiUrls";

// Styling
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
  TextField,
  Stack,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import LinkIcon from "@mui/icons-material/Link";

export default function AdminSettingsDialog({ open }) {
  const { enqueueSnackbar } = useSnackbar();

  // Check cookie for existing custom URL
  const [customApiUrl, setCustomApiUrl] = useState(getBaseURL() ?? "");

  // Reloading window on close
  const handleClose = () => {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const saveCustomUrl = (e, url) => {
    e.preventDefault();

    // If URL is passed in, use that instead of the current state
    const newURL = typeof url === "string" ? url : customApiUrl;

    // Write to the cookie
    setCustomBaseURL(newURL);

    // Notify success
    enqueueSnackbar("Custom API URL saved", { variant: "success" });

    // Close the dialog
    handleClose();
  };

  const usingMux = window.localStorage.getItem("debug_use_mux") ? true : false;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">Admin Settings</DialogTitle>
      <DialogContent>
        <Typography variant="h6">Use Mux</Typography>
        <Typography variant="body2">
          {usingMux ? "You are currently using Mux" : "You are not using Mux"}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          type="button"
          startIcon={<SaveIcon />}
          onClick={() => {
            if (usingMux) {
              window.localStorage.removeItem("debug_use_mux");
            } else {
              window.localStorage.setItem("debug_use_mux", true);
            }

            handleClose();
          }}
        >
          {usingMux ? "Disable Mux" : "Enable Mux"}
        </Button>
        <Divider sx={{ my: 2 }} />

        <Typography variant="h6">API URL</Typography>
        <TextField
          id="api-url"
          label="Custom API URL (no trailing slash)"
          type="text"
          fullWidth
          margin="normal"
          variant="outlined"
          placeholder="https://api.example.com"
          value={customApiUrl}
          onChange={(e) => setCustomApiUrl(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          startIcon={<SaveIcon />}
          onClick={saveCustomUrl}
        >
          Save custom API URL
        </Button>
        <Divider sx={{ my: 2 }} />
        <Stack
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          {adminApiUrls.map((apiURL, index) => {
            return (
              <Button
                key={apiURL.url + index}
                variant="contained"
                color={apiURL.buttonColor ?? "primary"}
                startIcon={apiURL.startIcon ?? <LinkIcon />}
                onClick={(e) => {
                  setCustomApiUrl(apiURL.url);
                  saveCustomUrl(e, apiURL.url);
                }}
                sx={{ mb: 1 }}
              >
                {apiURL.title ?? apiURL.url}
              </Button>
            );
          })}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Done</Button>
      </DialogActions>
    </Dialog>
  );
}
