import React from "react";

import { Stack, TextField } from "@mui/material";

export default function ColorInputField({ onChange, value, helperText }) {
  const handleInputChange = (value) => {
    let colorValue = value;
    if (value[0] !== "#") {
      colorValue = `#${value}`;
    }
    if (value === "") {
      colorValue = "";
    }
    onChange(colorValue);
  };

  return (
    <Stack
      spacing={2}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <TextField
        value={value}
        onChange={(e) => handleInputChange(e.target.value)}
        fullWidth={true}
        margin="normal"
        placeholder="e.g #59983B"
        helperText={helperText}
        sx={{ width: "100%", mt: "10px" }}
      />

      <div
        style={{
          background: value || "black",
          height: 55,
          width: 55,
          borderRadius: 6,
        }}
      ></div>
    </Stack>
  );
}
