import React from "react";

import Image from "../../components/Image";

import ImageIcon from "@mui/icons-material/Image";
import TheatersIcon from "@mui/icons-material/Theaters";

import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  assetPreviewLink,
  assetSourceInstagram,
  assetSquareCropLink,
  assetVerticalCropLink,
} from "../../api/fetchAssetData";
import { CROP_SQUARE_1_1, CROP_VERTICAL_9_16 } from "../../constants/assets";

const AssetCard = ({ asset, cropMode, onSelect, selectedAssetIDs = [] }) => {
  let imageRatio = CROP_VERTICAL_9_16; // Normally vertical
  // If using the 1:1 crop, use a 1:1 ratio
  if (cropMode === CROP_SQUARE_1_1) {
    imageRatio = 1 / 1;
  }

  const selected = selectedAssetIDs.includes(asset.id);

  let previewImage = assetPreviewLink(asset);
  // If image type, then use the crop mode to define which image we show
  if (asset.type === "image") {
    switch (cropMode) {
      case CROP_SQUARE_1_1:
        previewImage = assetSquareCropLink(asset);
        break;
      case CROP_VERTICAL_9_16:
        previewImage = assetVerticalCropLink(asset);
        break;
      default:
        previewImage = assetPreviewLink(asset);
    }
  }

  const selectedStyle = {
    position: "absolute",
    top: "5px",
    right: "5px",
    width: "30px",
    height: "30px",
    color: "rgba(122, 87, 209)",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    zIndex: "1",
    borderRadius: "5px",
  };

  return (
    <ImageListItem
      onClick={() => {
        onSelect(asset);
      }}
      sx={{
        cursor: "pointer",
      }}
      key={asset.id}
    >
      {/* Little box in the upper right corner */}
      <div
        sx={{
          position: "absolute",
          top: "0",
          right: "0",
          width: "30px",
          height: "30px",
        }}
      >
        {selected ? <CheckBoxIcon sx={selectedStyle} /> : null}
      </div>
      {/* Little floating box in the upper left corner to identify source */}
      <div
        sx={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "30px",
          height: "30px",
          float: "left",
        }}
      >
        {assetSourceInstagram(asset) ? (
          <img src="/img/instagram_logo.svg" alt="Instagram" width="30" />
        ) : null}
      </div>
      {/* Icon based on asset type */}
      {asset.type === "image" ? (
        <ImageView asset={asset} cropMode={cropMode} />
      ) : (
        <Image ratio={imageRatio} src={previewImage} />
      )}
      <ImageListItemBar
        title={asset.internal_name ? asset.internal_name : "untitled"}
        subtitle={asset.internal_description}
        actionIcon={
          <IconButton
            sx={{ color: "rgba(255, 255, 255, 0.54)" }}
            aria-label={`Info about ${asset.internal_name}`}
          >
            {asset.type === "image" && <ImageIcon />}
            {asset.type === "cloudflare_stream" && <TheatersIcon />}
            {asset.type === "mux_stream" && <TheatersIcon />}
          </IconButton>
        }
        sx={{
          background: "rgba(0, 0, 0, 0.2)",
        }}
      />
    </ImageListItem>
  );
};

export default AssetCard;

const ImageView = ({ asset, cropMode }) => {
  const squareURL = assetSquareCropLink(asset);
  const verticalURL = assetVerticalCropLink(asset);
  switch (cropMode) {
    case CROP_SQUARE_1_1:
      return (
        <img
          src={squareURL}
          style={{
            borderRadius: "50%",
            boxShadow: "0 0 10px 0 rgba(0,0,0,0.2)",
            border: "1px solid #eee",
            height: 120,
            width: 120,
          }}
          alt="thumbnail"
        />
      );
    case CROP_VERTICAL_9_16:
      return (
        <div
          style={{
            borderRadius: 5,
            boxShadow: "0 0 10px 0 rgba(0,0,0,0.2)",
            border: "1px solid #eee",
            backgroundImage: `url('${verticalURL}')`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            height: 275,
            width: 275 * (9 / 16),
          }}
        >
          <br />
          <br />
        </div>
      );
    default:
      return <>Instruction not understood</>;
  }
};
