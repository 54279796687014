import React from "react";
export const Ago = ({ date, updateEvery }) => {
  // If updateEvery was not set, default to 5 seconds
  if (!updateEvery) {
    updateEvery = 5;
  }

  const [time, setTime] = React.useState(compareTimes(date));

  // If date changes, run right now
  React.useEffect(() => {
    setTime(compareTimes(date));
  }, [date]);

  // Every updateEvery seconds, update the time
  React.useEffect(() => {
    const interval = setInterval(() => {
      setTime(compareTimes(date));
    }, updateEvery * 1000);
    return () => clearInterval(interval);
  }, [updateEvery, date]);

  return <>{time}</>;
};

function compareTimes(sinceTime) {
  const now = new Date();

  // Calculate D, H, and M
  const D = Math.floor(
    (now.getTime() - sinceTime.getTime()) / (1000 * 60 * 60 * 24)
  );
  const H = Math.floor(
    (now.getTime() - sinceTime.getTime()) / (1000 * 60 * 60)
  );
  const M = Math.floor((now.getTime() - sinceTime.getTime()) / (1000 * 60));

  // Return the time since the build was made
  if (D === 1) {
    return `${D} day ago`;
  } else if (D > 0) {
    return `${D} days ago`;
  }

  if (H === 1) {
    return `${H} hour ago`;
  } else if (H > 0) {
    return `${H} hours ago`;
  }

  if (M === 1) {
    return `${M} minute ago`;
  } else if (M > 0) {
    return `${M} minutes ago`;
  }

  return "just now";
}
