import { createTheme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

const lightTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "24px",
          textTransform: "capitalize",
        },
      },
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: "#7A57D1",
      light: "#9C7EE7",
      dark: "#5D35C0",
    },
    secondary: {
      main: "#5BE7C4",
      light: "#8CF2D9",
      dark: "#20C59C",
      contrastText: "#FFF",
    },
    "background-color": grey[50],
  },
});

export default lightTheme;
