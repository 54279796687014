import React from "react";

import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from "@mui/material";
import AssetList from "../assets/AssetList";
import { useAssets } from "../../api/fetchAssetData";
import RightCloseIcon from "./RightCloseIcon";
import CircularProgress from "@mui/material/CircularProgress";

function AssetSelectorDialog({
  open,
  onSelect,
  onClose,
  filter,
  uploaderAllowMultiple,
}) {
  const {
    error: assetsError,
    isLoading: assetsAreLoading,
    assets,
  } = useAssets();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth="lg"
    >
      <DialogTitle id="form-dialog-title">
        Select Media
        <RightCloseIcon onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        {assetsError && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {assetsError}
          </Alert>
        )}

        {!assetsError && (
          <AssetList
            filter={filter}
            onSelect={onSelect}
            assets={assets}
            uploaderAllowMultiple={uploaderAllowMultiple}
            onUpload={onSelect}
          />
        )}
        {assetsAreLoading && (
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={80} />
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AssetSelectorDialog;
