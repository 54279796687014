import React, { useState, useEffect } from "react";
import Fuse from "fuse.js";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

import { requestAccountID, setRequestAccount } from "../../context/auth";

// Styling
import {
  Alert,
  AlertTitle,
  Button,
  Grid,
  Paper,
  Stack,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CropFreeIcon from "@mui/icons-material/CropFree";

export default function AccountList({ error, accounts }) {
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredAccounts, setFilteredAccounts] = useState(accounts);
  const [sortedAccounts, setSortedAccounts] = useState(filteredAccounts);

  // Grab the account list when it or any of the filters change
  useEffect(() => {
    filterAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, accounts]);

  const filterAccounts = () => {
    // Return nothing if no accounts
    if (!accounts || accounts.length === 0) {
      setFilteredAccounts([]);
      return;
    }

    // Copy accounts
    let _filteredAccounts = [...accounts];

    // Filter the accounts by search term
    if (searchTerm) {
      const fuse = new Fuse(_filteredAccounts, {
        keys: ["name", "id", "ga_ua_tracking_id"],
        threshold: 0.5,
      });

      // Run search
      const results = fuse.search(searchTerm);

      // Convert results back into original array type
      _filteredAccounts = results.map((result) => result.item);
    }

    // Set filtered accounts
    setFilteredAccounts(_filteredAccounts);
  };

  // When the filtered accounts or sort params change, re-sort
  useEffect(() => {
    sortAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredAccounts]);

  const sortAccounts = () => {
    // Return nothing if no accounts
    if (!filteredAccounts || filteredAccounts.length === 0) {
      setSortedAccounts([]);
      return;
    }

    // Copy filtered accounts
    let _sortedAccounts = [...filteredAccounts];

    // Sort the accounts by name
    _sortedAccounts = _sortedAccounts.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    // Set sorted accounts
    setSortedAccounts(_sortedAccounts);
  };

  const selectAccount = (account) => {
    // Set the account in local storage
    setRequestAccount(account);

    // Redirect to the collections page
    navigate("/story_blocks");
  };

  return (
    <>
      {/* A table showing all of the accounts */}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <TextField
              label="Search"
              variant="outlined"
              margin="normal"
              placeholder="Search media tags or metadata"
              fullWidth
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{ m: 0 }}
            />
            <Typography
              variant="h6"
              sx={{ whiteSpace: "nowrap", pl: 3, pr: 2 }}
            >
              {accounts.length} Accounts
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            {/* Table of each account */}
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell style={{ whiteSpace: "nowrap" }}>
                    Account Type
                  </TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedAccounts.map((account) => (
                  <TableRow
                    key={account.id}
                    sx={{
                      "&:last-child .MuiTableCell-root": { border: "none" },
                    }}
                  >
                    <TableCell style={{ width: "100%" }}>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: "bold" }}
                      >
                        {account.name}
                      </Typography>
                      <Typography variant="subtitle2">{account.id}</Typography>
                    </TableCell>

                    <TableCell
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {account.is_shopify_account ? (
                        <img
                          style={{
                            height: "30px",
                          }}
                          src="/img/shopify_logo_lg.png"
                          alt="Shopify"
                        />
                      ) : (
                        <CropFreeIcon />
                      )}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {account.admin ? (
                        "Admin Account"
                      ) : (
                        // If we are already logged in as this user, then don't allow them to select it

                        <Button
                          variant="contained"
                          color="secondary"
                          disabled={account.id === requestAccountID()}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            selectAccount(account);
                          }}
                        >
                          Select
                        </Button>
                      )}
                    </TableCell>
                    <TableCell sx={{ textAlign: "right" }}>
                      <Link
                        to={`/account_details/${account.id}`}
                        style={{ textDecoration: "none" }}
                      >
                        <Button style={{ whiteSpace: "nowrap" }}>
                          View Details
                        </Button>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>

      {error && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {error}
        </Alert>
      )}
    </>
  );
}
