import { assetURLs } from "../constants/apiURLs";
import { CROP_SQUARE_1_1, CROP_VERTICAL_9_16 } from "../constants/assets";
import { apiCall } from "../utilities/request";
import { invalidateAssetCache } from "./fetchAssetData";

// Take in an asset and a cropped area and save the cropped asset to the server
// Return a promise that resolves to the new asset or passes an error
export function saveCrop(asset, aspectRatio, croppedAreaPixels) {
  return new Promise((resolve, reject) => {
    const crop = {
      x: croppedAreaPixels.x,
      y: croppedAreaPixels.y,
      w: croppedAreaPixels.width,
      h: croppedAreaPixels.height,
    };

    let data = {};

    // If vertical crop, set that
    switch (aspectRatio) {
      case CROP_VERTICAL_9_16:
        data = {
          vertical_crop: crop,
        };
        break;
      case CROP_SQUARE_1_1:
        data = {
          square_crop: crop,
        };
        break;
      default:
        reject("Invalid aspect ratio: " + aspectRatio);
    }

    //  Update the asset
    apiCall
      .put(assetURLs.crop(asset.id), data)
      .then((response) => {
        // Invalidate asset list
        invalidateAssetCache();

        // Resolve the promise w/ the resulting asset
        resolve(response?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
