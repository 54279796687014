import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

// State Management
import {
  RequireAuth,
  requestAccountID,
  hasOverrideAccountID,
} from "./context/auth";

// Pages
import AccountDetails from "./pages/AccountDetails";
import AccountSettings from "./pages/AccountSettings";
import Admin from "./pages/Admin";
import Assets from "./pages/Assets";
import { StoryBlocks } from "./pages/story-blocks/StoryBlocks";
import CTAs from "./pages/CTAs";
import EditCollection from "./pages/EditCollection";
import { StoryBlock } from "./pages/story-blocks/StoryBlock";
import LandingPages from "./pages/LandingPages";
import LivePreview from "./pages/LivePreview";
import Login from "./pages/Login";
import NetErrorPage from "./pages/NetErrorPage";
import NotFoundPage from "./pages/NotFoundPage";
import PageTargeting from "./pages/PageTargeting";
import PaymentReturn from "./pages/PaymentReturn";
import SalesDemos from "./pages/SalesDemoGenerator";
import Shopify from "./pages/Shopify";
import ShopifyAppBlock from "./pages/ShopifyAppBlock";
import ShopifyCallback from "./pages/ShopifyCallback";
import MicroshopPage from "./pages/microshops/Microshop";
import MicroshopsPage from "./pages/microshops/Microshops";

type AuthRequiredRouteProps = {
  route: JSX.Element;
  adminOnly?: boolean;
};

const AuthRequiredRoute = ({ route, adminOnly = false }: AuthRequiredRouteProps) => (
  <RequireAuth adminOnly={adminOnly}>{route}</RequireAuth>
);

export const RouteList = () => {
  const location = useLocation();

  useEffect(() => {
    if (requestAccountID()) {
      // Only re-identify if not an admin
      if (!hasOverrideAccountID()) {
        (window as any).analytics.identify(requestAccountID());
      }
    }
  }, [location.pathname]);

  return (
    <Routes>
      {/* Admin pages */}
      <Route
        path="/admin"
        element={<AuthRequiredRoute route={<Admin />} adminOnly={true} />}
      />
      <Route
        path="/admin/salesDemos"
        element={<AuthRequiredRoute route={<SalesDemos />} adminOnly={true} />}
      />

      {/* Protected Pages */}
      <Route
        path="/admin"
        element={<AuthRequiredRoute route={<Admin />} adminOnly={true} />}
      />
      <Route path="/" element={<AuthRequiredRoute route={<StoryBlocks />} />} />
      <Route
        path="/account"
        element={<AuthRequiredRoute route={<AccountSettings />} />}
      />
      <Route
        path="/account_details/:accountID"
        element={<AuthRequiredRoute route={<AccountDetails />} />}
      />
      <Route
        path="/microshops"
        element={<AuthRequiredRoute route={<MicroshopsPage />} />}
      />
      <Route
        path="/microshops/:microshopID"
        element={<AuthRequiredRoute route={<MicroshopPage />} />}
      />
      <Route
        path="/story_blocks"
        element={<AuthRequiredRoute route={<StoryBlocks />} />}
      />
      <Route
        path="/story_blocks/:storyBlockId"
        element={<AuthRequiredRoute route={<StoryBlock />} />}
      />
      <Route
        path="/accounts/:accountID/story_blocks/:collectionID/livePreview"
        element={<LivePreview />}
      />
      <Route
        path="/story_blocks/:collectionID/stories/:storyID"
        element={<AuthRequiredRoute route={<EditCollection />} />}
      />
      <Route
        path="/story_blocks/:collectionID/stories/:storyID/chapters/:chapterID"
        element={<AuthRequiredRoute route={<EditCollection />} />}
      />
      <Route
        path="/assets"
        element={<AuthRequiredRoute route={<Assets />} />}
      />
      <Route path="/ctas" element={<AuthRequiredRoute route={<CTAs />} />} />
      <Route
        path="/publish"
        element={<AuthRequiredRoute route={<PageTargeting />} />}
      />
      <Route
        path="/shopify_installation"
        element={<AuthRequiredRoute route={<ShopifyAppBlock />} />}
      />
      <Route
        path="/shopify/payment_return"
        element={<AuthRequiredRoute route={<PaymentReturn />} />}
      />
      <Route
        path="/landing_pages"
        element={<AuthRequiredRoute route={<LandingPages />} />}
      />

      {/* Unprotected Pages */}
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<NotFoundPage />} />

      {/* Shopify OAuth */}
      <Route path="/shopify" element={<Shopify />} />
      <Route path="/auth/callback" element={<ShopifyCallback />} />
      <Route path="/error/network" element={<NetErrorPage />} />
    </Routes>
  );
};
