import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";

import { useAccount } from "../api/fetchAccountData";

// Styling
import { Alert, AlertTitle, Collapse, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function PlanUpgradeBanner() {
  const { account, isFetching, isFetched } = useAccount();
  const [enrollmentWarning, setEnrollmentWarning] = useState(false);

  const update = useCallback(() => {
    setEnrollmentWarning(!account.has_active_billing_plan);
  }, [account.has_active_billing_plan]);

  useEffect(() => {
    if (isFetched) {
      update();
    }
  }, [isFetching, isFetched, update]);

  return (
    <Collapse
      in={enrollmentWarning}
      style={{ marginTop: enrollmentWarning ? "14px" : null }}
    >
      <Alert
        severity="warning"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => setEnrollmentWarning(false)}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        <AlertTitle>
          You aren't enrolled in a plan. Redeem your plan code{" "}
          <Link to="/account" style={{ textDecoration: "none" }}>
            here.
          </Link>
        </AlertTitle>
        A member of the Kahani team will provide your paid plan code based on
        your monthly sessions. Email{" "}
        <a href="mailto:support@kahaniapp.com">support@kahaniapp.com</a> if
        needed.
      </Alert>
    </Collapse>
  );
}
