import React from "react";

import Page from "../components/Page";

import { Grid } from "@mui/material";

export default function NotFoundPage() {
  return (
    <Page title="Not Found">
      <Grid container spacing={6}>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <Page.Title>Page Not Found</Page.Title>
        </Grid>
      </Grid>
    </Page>
  );
}
