import React, { useState } from "react";
import TimedProgressBar from "../../components/TimedProgressBar";
import {
  Alert,
  AlertTitle,
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import {
  invalidateInfluencerCache,
  useInfluencers,
} from "../../api/fetchInfluencerData";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import AddBoxIcon from "@mui/icons-material/AddBox";

import { InfluencerCreate } from "./InfluencerCreate";
import { Cancel } from "@mui/icons-material";
import { apiCall } from "../../utilities/request";
import { influencerURLs } from "../../constants/apiURLs";
import { useSnackbar } from "notistack";

// InfluencerList is a React component that displays a list of influencers.
// It accepts a mode prop that determines what type of list to display:
// - "edit": displays a list of influencers with edit controls
// - "select": displays a list of influencers with checkboxes

export const InfluencerList = ({ mode, onSelect, initialSelectedID = "" }) => {
  // mode must be "edit" or "select"
  if (mode !== "edit" && mode !== "select") {
    throw new Error("Invalid mode for influencer list");
  }

  const { error, isLoading, influencers } = useInfluencers();

  const [showCreateInfluencerModal, setShowCreateInfluencerModal] = useState(false);
  const [selected, setSelected] = React.useState(initialSelectedID);

  const select = (influencer) => {
    setSelected(influencer.id);
    onSelect(influencer);
  };

  // If error or loading do nothing
  if (error) {
    return (
      <Alert severity="error">
        <AlertTitle>Error loading Influencers</AlertTitle>
        {error}
      </Alert>
    );
  }

  if (isLoading) {
    return <TimedProgressBar duration={10} type="linear" />;
  }

  return (
    <>
      <InfluencerCreate.Dialog
          open={showCreateInfluencerModal}
          onClose={() => setShowCreateInfluencerModal(false)}
          mode="create"
      />
      {influencers.length < 1 && (
        <Paper sx={{ p: 8, mt: 4 }}>
          <Stack direction="column" justifyContent="center" alignItems="center">
              <Typography variant="p">No influencers created yet</Typography>
              <Button
                variant="contained"
                sx={{ mt: 4 }}
                color="primary"
                startIcon={<AddBoxIcon />}
                onClick={() => setShowCreateInfluencerModal(true)}
              >
                Add Influencer
              </Button>
          </Stack>
        </Paper>
      )}
      {influencers.length > 0 && (
        <Grid container spacing={3} marginTop={4} marginBottom={8}>
          {influencers.map((influencer) => (
            <Grid item xs={6} md={4} key={influencer.id}>
              <InfluencerListCard
                influencer={influencer}
                mode={mode}
                onSelect={select}
                selected={influencer.id === selected}
              />
            </Grid>
          ))}
          <Grid item xs={6} md={4} key="create">
            {/* Full height and center the content */}
            <Card
              sx={{
                border: "1px dashed #e0e0e0",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CardContent>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<AddBoxIcon />}
                  onClick={() => setShowCreateInfluencerModal(true)}
                >
                  Add Influencer
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const InfluencerListCard = ({ influencer, mode, selected, onSelect }) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);

  return (
    <Card>
      <CardActionArea
        onClick={() => {
          if (mode === "select") {
            onSelect(influencer);
          } else if (mode === "edit") {
            setEditDialogOpen(true);
          }
        }}
      >
        <CardContent>
          <Stack
            direction="row"
            justifyContent="left"
            alignItems="center"
            spacing={3}
          >
            <Avatar src={influencer.profile_picture_url} />
            <Typography variant="h6">{influencer.name}</Typography>
          </Stack>
        </CardContent>
      </CardActionArea>
      <CardActions>
        {mode === "edit" && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              width: "100%",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              startIcon={<EditIcon />}
              onClick={() => setEditDialogOpen(true)}
            >
              Edit
            </Button>
            <Button
              variant="outlined"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => setDeleteDialogOpen(true)}
            >
              Delete
            </Button>
          </Stack>
        )}
        {mode === "select" && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button
              variant={selected ? "contained" : "outlined"}
              fullWidth
              startIcon={selected ? <CheckBoxIcon /> : <CheckBoxOutlinedIcon />}
              onClick={() => onSelect(influencer)}
            >
              {selected ? "Selected" : "Select"}
            </Button>
          </Stack>
        )}
      </CardActions>
      <InfluencerCreate.Dialog
        influencerID={influencer?.id}
        open={editDialogOpen}
        mode="edit"
        onClose={() => setEditDialogOpen(false)}
      />
      <DeleteDialog
        influencer={influencer}
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      />
    </Card>
  );
};

const DeleteDialog = ({ influencer, open, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deleting, setDeleting] = React.useState(false);

  const deleteInfluencer = () => {
    setDeleting(true);
    apiCall
      .delete(influencerURLs.details(influencer.id))
      .then(() => {
        enqueueSnackbar(`${influencer.name} deleted`, { variant: "success" });
        invalidateInfluencerCache();
        onClose();
      })
      .catch((error) => {
        enqueueSnackbar(
          `Error deleting ${influencer.name} - ${error?.response?.data?.error}`,
          { variant: "error" }
        );
        console.error(error);
        onClose();
      })
      .finally(() => {
        setDeleting(false);
      });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Delete {influencer?.name}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Are you sure you want to delete {influencer?.name}?
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Typography variant="body1">
          If this influencer is associated with any campaigns, you will need to
          unlink them first.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="inherit"
          startIcon={<Cancel />}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="error"
          startIcon={<DeleteIcon />}
          onClick={deleteInfluencer}
          disabled={deleting}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
