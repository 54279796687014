import React from "react";
import { useParams } from "react-router-dom";
import {
  Alert,
  AlertTitle,
  Divider,
  LinearProgress,
  Paper,
  Typography,
} from "@mui/material";
import { setupLivePreviewClient } from "../utilities/livePreview";
import { useAuth } from "../context/auth";
import { Ago } from "../utilities/time";
import KahaniModule from "@kahani/kahani-module2";
import "./LivePreview.css";

const LivePreview = () => {
  // Get collection ID from url
  const { collectionID } = useParams();

  const auth = useAuth();

  // Parse search query
  const searchParams = new URLSearchParams(window.location.search);
  // const token = searchParams.get("token");
  const collectionURL = searchParams.get("collectionURL");

  const [showModule, setShowModule] = React.useState(true);

  const [previewLastUpdated, setPreviewLastUpdated] = React.useState(
    new Date()
  );
  const updatedCallback = (data) => {
    console.log("Live preview update triggered");
    console.log(data);

    setPreviewLastUpdated(new Date());

    // Hide the module for 500ms then show it again
    setTimeout(() => {
      setShowModule(false);
    }, 500);
    setTimeout(() => {
      setShowModule(true);
    }, 1000);
  };

  // Ensure we do this only once
  React.useEffect(() => {
    console.log("Listening for live preview updates");
    setupLivePreviewClient(collectionID, updatedCallback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  document.title = "Kahani Live Preview";
  window.Beacon("destroy"); // Kill helpscout

  return (
    <Paper
      sx={{
        p: 3,
        display: "flex",
        flexDirection: "column",
        marginTop: 10,
        marginX: 3,
        maxWidth: "100%",
      }}
      elevation={1}
    >
      <Typography variant="h6" color="primary">
        Live Kahani Preview
      </Typography>
      {showModule && <KahaniModule storyUrl={collectionURL} />}
      {!showModule && (
        <Alert
          severity="info"
          style={{
            marginTop: "1rem",
            marginBottom: "1rem",
            width: "100%",
            maxWidth: "400px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <AlertTitle>
            Updating content
            <LinearProgress />
          </AlertTitle>
          Please wait one moment...
        </Alert>
      )}
      <Divider />
      {auth.isAuthenticated && (
        <Alert severity="info">
          <AlertTitle>Note</AlertTitle>Share this link with anyone and they will
          be able to view (but not edit) this collection.
        </Alert>
      )}
      Last Updated: <Ago date={previewLastUpdated} updateEvery={5} />
    </Paper>
  );
};

export default LivePreview;
