export const CTA_LINK_TEXT = [
  "Buy Now",
  "Learn More",
  "Sign Up",
  "Subscribe",
  "Shop Now",
  "Get Discount",
];

export const ERROR_MESSAGE_MAPPING = {
  2003: "Current password is incorrect",
};

export const API_QUERY_KEYS = {
  ACCOUNT_REQUEST: "ACCOUNT_REQUEST",
  ACCOUNT_DETAILS_REQUEST: "ACCOUNT_DETAILS_REQUEST",
  ACCOUNTS_REQUEST: "ACCOUNTS_REQUEST",
  SHOPIFY_STORE_REQUEST: "SHOPIFY_STORE_REQUEST",
  ASSET_REQUEST: "ASSET_REQUEST",
  ASSET_TAG_REQUEST: "ASSET_TAG_REQUEST",
  CHARGE_VALIDATION_REQUEST: "CHARGE_VALIDATION_REQUEST",
  COLLECTION_REQUEST: "COLLECTION_REQUEST",
  MICROSHOP_REQUEST: "MICROSHOP_REQUEST",
  INFLUENCER_REQUEST: "INFLUENCER_REQUEST",
  CTA_REQUEST: "CTA_REQUEST",
  KLAVIYO_LISTS_REQUEST: "KLAVIYO_LISTS_REQUEST",
  PAGE_TARGETING_RULESET_REQUEST: "PAGE_TARGETING_RULESET_REQUEST",
  SHOPIFY_COLLECTIONS_REQUEST: "SHOPIFY_COLLECTIONS_REQUEST",
  SHOPIFY_PRODUCTS_REQUEST: "SHOPIFY_PRODUCTS_REQUEST",
  SHOPIFY_SYNC_STATUS_REQUEST: "SHOPIFY_SYNC_STATUS_REQUEST",
};

// For our dev site, we don't have the "read_all_orders" permission
// @todo request read_all_orders permission for the dev app
// let scope =
//   // "read_content,read_products,read_customers,write_discounts,read_files,read_inventory,write_script_tags,write_themes,read_orders";
// if (getEnvironment() === "production") {
//   scope += ",read_all_orders";
// }

export const SHOPIFY_SCOPES =
  "write_themes,read_products,read_inventory,read_orders,write_content";
