import React, { useState, useMemo, useEffect } from "react";
import {MaterialReactTable, MRT_RowSelectionState, type MRT_ColumnDef} from 'material-react-table';
import {TextField, Grid, Alert, AlertTitle} from "@mui/material";
import Fuse from "fuse.js";

import { useShopifyProducts } from "../../api/useShopifyProducts";

type Image = {
  width: number;
  height: number;
  url: string;
  altText: string;
}

type VariantOption = {
  name: string;
  values: string[];
}

export type Product = {
  collections: {
    handle: string;
    title: string;
  }[];
  createdAt: string;
  featuredImage: Image;
  handle: string;
  id: string;
  images: Image[];
  legacyResourceID: string;
  onlineStoreUrl: string | null;
  options: VariantOption[]
  publishedAt: string;
  status: string;
  title: string;
  variants: any[];
  vendor: string;
}

type Props = {
  initialSelectedProductIds?: string[];
  onChange: (selectedProducts: Product[]) => void;
}

/*
  - initialSelectedProductIds: array - Array of Shopify product IDs to be selected on load
  - onChange: function - Callback function that is called when the selected products change so you can do something with the new selections
*/
export const ShopifyProductSelector = ({initialSelectedProductIds = [], onChange}: Props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>(initialSelectedProductIds.reduce((acc, productId) => {
    acc[productId] = true;
    return acc;
  }, {} as MRT_RowSelectionState));
  const {
    shopifyProducts: allProducts,
    isLoading: isLoadingShopifyProducts,
    error: shopifyProductsError,
    isError: isShopifyProductsError,
  } = useShopifyProducts(true);

  useEffect(() => {
    const selectedProductIds = Object.keys(rowSelection).filter((rowId) => rowSelection[rowId]);
    const selectedProducts = allProducts.filter((product: Product) => selectedProductIds.includes(product.id));

    onChange(selectedProducts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowSelection]);

  // Update the filtered products based on all products
  const filteredProducts = React.useMemo(() => {
    if (isShopifyProductsError) return;

    if (searchTerm) {
      const fuse = new Fuse(allProducts, {
        keys: ["title", "id"],
        threshold: 0.3,
      });

      const results = fuse.search(searchTerm);

      // Convert results to array of products
      // Use the opportunity to parse the publish date
      const newFilteredProducts = results.map((result) => {
        return result.item;
      });

      return newFilteredProducts;
    } else {
      return allProducts;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(allProducts), searchTerm, isShopifyProductsError]);

  const columns = useMemo<MRT_ColumnDef<Product>[]>(() => [
    {
      id: 'image',
      header: 'Image',
      maxSize: 100,
      enableSorting: false,
      accessorFn: (product: Product) => {
        // Use the featured image or the 1st image
        const image = product?.featuredImage || product?.images?.[0];

        // Resize down product images to fit inside an 80x80 box but keep the aspect ratio
        const aspectRatio = image?.width / image?.height;
        // If the image is wider than it is tall
        const width = aspectRatio > 1 ? 80 : 80 * aspectRatio;
        // If the image is taller than it is wide
        const height = aspectRatio > 1 ? 80 / aspectRatio : 80;

        return image?.url ? (
          <img
            src={image?.url}
            alt={"Product image for" + product.title}
            width={width}
            height={height}
            style={{
              objectFit: "contain",
              borderRadius: "20%",
            }}
          />
        ) : null;
      },
    },
    {
      accessorKey: 'title', //access nested data with dot notation
      header: 'Title',
    },
    {
      id: 'variants', //access nested data with dot notation
      header: 'Variants',
      enableSorting: false,
      accessorFn: (product: Product) => {
        // Date in the local date format
        return `${product?.variants?.length ?? 0} Variant${product?.variants?.length === 1 ? '' : 's'}`;
      }
    },
    {
      id: 'publishedAt', //access nested data with dot notation
      header: 'Published On',
      accessorFn: (product: Product) => {
        if (!product.publishedAt) return null;

        return new Date(product.publishedAt).toDateString();
      }
    },
  ], []);

  if (isLoadingShopifyProducts) {
    return <p>Loading...</p>;
  } else if (isShopifyProductsError) {
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        Could not load your products from Shopify. Please try again later or
        contact support.
        <br />
        <strong>
          {shopifyProductsError?.response?.status}{" "}
          {shopifyProductsError?.response?.statusText}
        </strong>
        <pre>
          {JSON.stringify(shopifyProductsError?.response?.data, null, 2)}
        </pre>
      </Alert>
    );
  }

  return (
    <Grid item xs={12} sm={6} paddingBottom={12}>
        <TextField
          label="Search Products"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          fullWidth
          size="small"
        />
        <MaterialReactTable
          initialState={{
            pagination: { pageSize: 100, pageIndex: 0 }
          }}
          enableStickyHeader={true}
          enableGlobalFilter={false}
          enableColumnFilters={false}
          enableDensityToggle={false}
          enableHiding={false}
          enableColumnOrdering={false}
          enableFullScreenToggle={false}
          enableColumnActions={false}
          enableRowSelection={true}
          getRowId={(product) => product.id} // Row selection will use the product ID as the row ID
          onRowSelectionChange={setRowSelection}
          columns={columns}
          data={filteredProducts}
          state={{
            rowSelection
          }}
        />
    </Grid>
  );
}
