import React from "react";
import { useNavigate } from "react-router";
import { useSearchParams, Navigate } from "react-router-dom";
import Page from "../components/Page";

import { useChargeValidation } from "../api/fetchChargeValidation";

import {
  Paper,
  Grid,
  Stack,
  Button,
  Typography,
  LinearProgress,
} from "@mui/material";

const PaymentReturn = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const chargeId = searchParams.get("charge_id");

  const { error, isLoading, data, isFetched } = useChargeValidation(chargeId);
  const isActive = data?.active;

  if (isFetched) {
    return (
      <Navigate
        to={`/story_blocks?payment_status=${isActive ? "success" : "error"}`}
      />
    );
  }

  return (
    <Page title="Payment Return">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Page.Title>
              <Stack
                direction="row"
                justifyItems="space-between"
                alignItems="center"
              >
                <>Payment Return</>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    navigate("/story_blocks");
                  }}
                  sx={{ marginLeft: "auto" }}
                  id="create-collection-button"
                >
                  Go to Story Blocks
                </Button>
              </Stack>
            </Page.Title>
            {isActive && (
              <Typography variant="body1">
                Plan Activation Successful! Redirecting...
              </Typography>
            )}
            {isLoading && (
              <Typography variant="body1">
                We're activating your account right now. Please wait...
                <span
                  style={{
                    display: "block",
                    textAlign: "center",
                    margin: "20px 0",
                  }}
                >
                  <LinearProgress />
                </span>
              </Typography>
            )}
            {error && <Typography variant="body1">Error.</Typography>}
          </Paper>
        </Grid>
      </Grid>
    </Page>
  );
};

export default PaymentReturn;
