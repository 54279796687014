import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ctas: [],
  status: "idle",
};

export const ctaSlice = createSlice({
  name: "ctas",
  initialState,
});

export default ctaSlice.reducer;
