import React, { useState } from "react";

import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export default function PasswordField({
  label,
  value,
  onChange,
  onFocus,
  helperText,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <FormControl sx={{ mt: 2, mb: 2, width: "100%" }} variant="outlined">
      <InputLabel htmlFor="password-field">{label}</InputLabel>
      <OutlinedInput
        type={showPassword ? "text" : "password"}
        value={value}
        label={label}
        onChange={onChange}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        onFocus={onFocus}
      />
      <Typography variant="caption">{helperText}</Typography>
    </FormControl>
  );
}
