import React from "react";

import Page from "../components/Page";

import { Alert, Button, Divider, Typography, Grid, Paper } from "@mui/material";

export default function NetErrorPage() {
  const online = navigator.onLine;

  return (
    <Page title="Network Error">
      <Grid container spacing={6}>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <Paper
            sx={{
              p: 4,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Page.Title>Could not connect to Kahani</Page.Title>
            We're sorry, but we were unable to connect to Kahani right now.
            <Divider
              sx={{
                marginTop: "1em",
                marginBottom: "1em",
              }}
            />
            <Alert severity="warning">
              {online ? (
                <>
                  <Typography variant="body1">
                    This may be a problem with your internet connection, or with
                    Kahani's servers. Please try again in a few minutes. If you
                    need help right away, please
                    <Button
                      variant="outlined"
                      color="primary"
                      type="button"
                      size="small"
                      sx={{
                        marginLeft: "0.5em",
                        marginRight: "0.5em",
                      }}
                      onClick={() => {
                        window.Beacon("prefill", {
                          name: "",
                          subject: "Can't connect to Kahani",
                          text: "I'm not able to connect to Kahani right now. Please help me!",
                        });
                        window.Beacon("open");
                      }}
                    >
                      create a support ticket
                    </Button>
                    and we'll get back to you as soon as we are able.
                  </Typography>
                </>
              ) : (
                <Typography variant="body1">
                  Your browser is reporting that you are not connected to the
                  internet. Please check your internet connection and try again.
                </Typography>
              )}
            </Alert>
          </Paper>
        </Grid>
      </Grid>
    </Page>
  );
}
