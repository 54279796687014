import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import PublishIcon from "@mui/icons-material/Publish";

import RightCloseIcon from "../../modules/assets/RightCloseIcon";
import { formatShopifyHandle } from "../../utilities/formatShopifyData";
import { useCollection } from "../../api/fetchCollectionData";
import { updatePageTargetingRulesets } from "../../redux/api/pageTargetingAPI";
import { invalidatePageTargetingCache, usePageTargetingRulesets } from "../../api/fetchPageTargetingRulesets";

export default function PublishModal({ open, onClose, storyBlockId }) {
  const { enqueueSnackbar } = useSnackbar();
  const [success, setSuccess] = useState(false);
  const {collection: storyBlock} = useCollection(storyBlockId);
  const {rulesets} = usePageTargetingRulesets();
  const { type: selectedPageType, page, id } = formatShopifyHandle(storyBlock);
  const selectedRuleSet = rulesets.find(ruleset => ruleset?.collection_id === storyBlockId);

  const handleClose = () => {
    setSuccess(false);
    onClose();
  };

  /**
   * publishes story block
   */
  const onPublishClick = async () => {
    const newRuleSets = rulesets.map(ruleset => {
      if (ruleset?.collection_id === storyBlockId) {
        // This is the ruleset being published
        return { ...ruleset, active: true };
      } else if (ruleset?.rules?.find(rule => rule.type === selectedPageType)) {
        // This ruleset has the same page type as the ruleset being published
        if (selectedPageType === 'homepage') {
          // This is another homepage ruleset that needs to be overridden
          return { ...ruleset, active: false };
        } else {
          if (ruleset?.rules?.find(rule => rule.value === id)) {
            // This is another collection or product page ruleset that needs to be overridden
            return { ...ruleset, active: false };
          } else {
            // this is another collection or product page ruleset that does not need to be overridden
            return ruleset;
          }
        }
      } else {
        return ruleset;
      }
    });

    try {
      await updatePageTargetingRulesets(newRuleSets);
      invalidatePageTargetingCache();
      setSuccess(true);
    } catch (error) {
      enqueueSnackbar("Story block failed to publish", {variant: "error"});
      handleClose();
    }
  }

  const onUnpublishClick = async () => {
    const newRuleSets = rulesets.map((ruleset) => (
      ruleset?.collection_id === storyBlockId ? { ...ruleset, active: false } : ruleset
    ));

    try {
      await updatePageTargetingRulesets(newRuleSets);
      invalidatePageTargetingCache();
      enqueueSnackbar("Story block unpublished successfully!", {variant: "success"});
    } catch (error) {
      enqueueSnackbar("Story block failed to unpublish", {variant: "error"});
    }

    handleClose();
  }

  const style = {
    topMargin: {
      marginTop: 1,
    },
    box: {
      bgcolor: "background.paper",
      boxShadow: 24,
      display: "flex",
      flexDirection: "column",
      left: "50%",
      p: 3,
      top: "50%",
      transform: "translate(-50%, -50%)",
      position: "absolute",
      justifyContent: "space-between",
      width: success ? 400 : 600,
    }
  };

  if (selectedRuleSet?.active && !success) {
    return (
      <Modal open={open} onClose={handleClose}>
        <Box sx={style.box}>
          <Typography variant="h5">
            Unpublish Story Block
            <RightCloseIcon onClick={handleClose} />
          </Typography>
          <Typography variant="body1">
            Are you sure you want to unpublish the {storyBlock?.internal_name}{" "}
            Story Block.
          </Typography>
          <Stack direction="row" justifyContent="end" sx={style.topMargin}>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton
              loadingPosition="start"
              color="error"
              variant="contained"
              startIcon={<PublishIcon />}
              onClick={onUnpublishClick}
              style={{ marginLeft: "10px" }}
            >
              Unpublish
            </LoadingButton>
          </Stack>
        </Box>
      </Modal>
    );
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style.box}>
        {success ? (
          <>
            <Typography variant="h5">
              Story Block Published
              <RightCloseIcon onClick={handleClose} />
            </Typography>
            <Stack alignItems="center">
              <img
                src={require("../../assets/published.png")}
                alt="published icon"
                style={{ maxHeight: "140px", maxWidth: "120px", mt: 2 }}
              />
              <Typography sx={{ mt: 2 }}>
                Success! The story block is published!
              </Typography>
              <Stack alignSelf="end" sx={{ mt: 2 }}>
                <Button variant="contained" onClick={handleClose}>
                  Okay
                </Button>
              </Stack>
            </Stack>
          </>
        ) : (
          <>
            <Typography variant="h5">
              Publish Story Block
              <RightCloseIcon onClick={handleClose} />
            </Typography>
            <Typography variant="body1">
              The {storyBlock?.internal_name} story block will be published to{" "}
              <strong>...{page}</strong>
            </Typography>
            <Stack direction="row" justifyContent="end" sx={style.topMargin}>
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
              <LoadingButton
                disabled={!page}
                loadingPosition="start"
                variant="contained"
                startIcon={<PublishIcon />}
                onClick={onPublishClick}
                style={{ marginLeft: "10px" }}
              >
                Publish
              </LoadingButton>
            </Stack>
          </>
        )}
      </Box>
    </Modal>
  );
}
