import React, { useState } from "react";
import {useMicroshop} from "../../api/fetchMicroshopData";
import Page from "../../components/Page";
import {
  Grid,
  Stack,
  Alert,
  AlertTitle,
  Breadcrumbs,
  Typography,
  Paper,
  TextField,
  Button,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import { useParams, Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import MicroshopPublishButton from "../../modules/microshops/MicroshopPublish";
import CodeEditor from "@uiw/react-textarea-code-editor";
import { apiCall } from "../../utilities/request";
import { determineBaseURL, microshopURLs } from "../../constants/apiURLs";
import { useSnackbar } from "notistack";
import SaveIcon from "@mui/icons-material/Save";
import { useShopifyStore } from "../../api/fetchAccountData";
import { InfluencerList } from "../../modules/influencers/InfluencerList";
import { ShopifyProductSelector } from "../../modules/shopify/ProductSelector";

const MicroshopPage = () => {
  const { microshopID } = useParams();
  const { error, isLoading, microshop } = useMicroshop(microshopID);

  const { shopifyStore } = useShopifyStore();
  const pagesBaseURL = `https://${
    shopifyStore?.shop_name ? shopifyStore.shop_name : "yoursite.com"
  }/pages/`;

  // Get currently selected products from microshop collection
  const initialSelectedShopifyProductIDs = getSelectedShopifyProductIDs(microshop);

  // The microshop is just a big form to update the microshop
  const [name, setName] = useState("");
  const [handle, setHandle] = useState("");
  const [selectedShopifyProductIDs, setSelectedShopifyProductIDs] = useState>(initialSelectedShopifyProductIDs);
  const [influencerID, setInfluencerID] = useState("");

  // When the microshop changes, update the defaults
  React.useEffect(() => {
    if (microshop) {
      if (microshop?.name) {
        setName(microshop?.name);
      }
      if (microshop?.handle) {
        setHandle(microshop?.handle);
      }
      if (microshop?.influencer_id) {
        setInfluencerID(microshop?.influencer_id);
      }
      setSelectedShopifyProductIDs(getSelectedShopifyProductIDs(microshop));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [microshop]);

  // Wrapper for handle setter.
  //    - Ensures it is URL friendly and lowercase.
  //    - Any spaces are replaced with -
  //    - Any non-alphanumeric characters are removed
  const setMicroshopHandleWrapper = (value) => {
    const handle = value
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^a-z0-9-]/g, "");
    setHandle(handle);
  };

  // Just for dev times, show the URl to request this microshop publically
  const publicURL = `${determineBaseURL()}/v0/public/matchMicroshop?shop=${
    shopifyStore?.shop_name
  }&handle=${microshop?.handle}`;

  return (
    <Page title="Microshop" isLoading={isLoading}>
      <Page.Title>
        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
          <Link
            style={{
              textDecoration: "none",
              color: "inherit",
            }}
            color="inherit"
            to="/microshops"
          >
            Microshops
          </Link>
          <Typography color="text.primary">{microshop.name}</Typography>
        </Breadcrumbs>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <span id="microshops-page-title">Edit</span>
          <SaveButton
            microshop={microshop}
            name={name}
            handle={handle}
            influencerID={influencerID}
            selectedShopifyProductIDs={selectedShopifyProductIDs}
          />
        </Stack>
      </Page.Title>
      <Alert severity="info" sx={{ my: 3 }}>
        <AlertTitle>API Call</AlertTitle>
        <a href={publicURL} target="_blank" rel="noreferrer">
          {publicURL}
        </a>
      </Alert>
      {error ? (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {error}
        </Alert>
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <Typography variant="h6" gutterBottom>
                Setup
              </Typography>
              <Paper
                sx={{
                  padding: 2,
                }}
              >
                <TextField
                  id="microshop-name"
                  label="Name"
                  variant="outlined"
                  fullWidth
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  sx={{
                    marginBottom: 2,
                  }}
                />
                <TextField
                  id="microshop-handle"
                  label="URL"
                  variant="outlined"
                  fullWidth
                  value={handle}
                  onChange={(e) => {
                    setMicroshopHandleWrapper(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {pagesBaseURL}
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    marginBottom: 2,
                  }}
                />
                <MicroshopPublishButton microshopID={microshop.id} />
              </Paper>
            </Grid>
            <Grid item xs={12} md={7}>
              <Typography variant="h6" gutterBottom>
                Influencer Persona
              </Typography>
              <Paper
                sx={{
                  padding: 2,
                }}
              >
                <Typography variant="body2" gutterBottom>
                  Select the influencer persona that will be used in this
                  microshop.
                </Typography>
                <InfluencerList
                  mode="select"
                  initialSelectedID={microshop?.influencer?.id}
                  onSelect={(influencer) => {
                    setInfluencerID(influencer.id);
                  }}
                />
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Products
              </Typography>
              <ShopifyProductSelector
                onChange={(ids) => {
                  setSelectedShopifyProductIDs(ids);
                }}
                initialSelectedProductIds={initialSelectedShopifyProductIDs}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Data here!
              </Typography>
              <Paper
                sx={{
                  padding: 2,
                }}
              >
                <br />
                <CodeEditor value={JSON.stringify(microshop)} language="json" />
              </Paper>
            </Grid>
          </Grid>
        </>
      )}
    </Page>
  );
};

export default MicroshopPage;

const SaveButton = ({
  microshop,
  name,
  handle,
  influencerID,
  selectedShopifyProductIDs,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const initialSelectedShopifyProductIDs = getSelectedShopifyProductIDs(microshop);
  const {refetch} = useMicroshop(microshop.id);

  const hasChanges = () => {
    if (
      name !== microshop?.name ||
      handle !== microshop?.handle ||
      influencerID !== microshop?.influencer?.id
    ) {
      return true;
    }

    // Deep compare selected products, regardless of the order
    if (
      !arraySame(selectedShopifyProductIDs, initialSelectedShopifyProductIDs)
    ) {
      return true;
    }

    return false;
  };

  const [saving, setSaving] = React.useState(false);
  const save = () => {
    // Only overwite necessary fields
    let payload = {};
    if (name !== microshop?.name) {
      payload.name = name;
    }
    if (handle !== microshop?.handle) {
      payload.handle = handle;
    }
    if (influencerID !== microshop?.influencer?.id) {
      payload.influencer_id = influencerID;
    }
    if (
      !arraySame(selectedShopifyProductIDs, initialSelectedShopifyProductIDs)
    ) {
      payload.shopify_product_ids = selectedShopifyProductIDs;
    }

    setSaving(true);
    apiCall
      .patch(microshopURLs.details(microshop.id), payload)
      .then((response) => {
        console.info(response);
        enqueueSnackbar("Saved!", { variant: "success" });

        refetch();
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar("Error saving", { variant: "error" });
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <Button
      variant={hasChanges() ? "contained" : "outlined"}
      color={hasChanges() ? "primary" : "inherit"}
      disabled={!hasChanges() || saving}
      startIcon={saving ? <CircularProgress size={16} /> : <SaveIcon />}
      onClick={() => {
        save();
      }}
    >
      Save Changes
    </Button>
  );
};

const getSelectedShopifyProductIDs = (microshop) => {
  // For now, we're only using the 1st collection in the microshop. Statically set that
  const collection = microshop?.collections?.[0];

  // Get currently selected products from microshop collection
  let initialSelectedShopifyProductIDs = [];

  if (collection?.products) {
    initialSelectedShopifyProductIDs = collection.products.map((product) => {
      return product.shopify_product_id;
    });
  }

  return initialSelectedShopifyProductIDs;
};

const arraySame = (arrayA, arrayB) => {
  if (arrayA.length !== arrayB.length) {
    return false;
  }

  // Every item in A must be in B
  for (const itemA of arrayA) {
    if (!arrayB.includes(itemA)) {
      return false;
    }
  }

  // Every item in B must be in A
  // Check both to make sure we're accounting for possible duplicates
  for (const itemB of arrayB) {
    if (!arrayA.includes(itemB)) {
      return false;
    }
  }

  return true;
};
