import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  createChapter,
  createStory,
  updateChapter,
  createStoryBlock,
  updateStory,
  fetchStoryBlocks,
  deleteChapter,
  deleteStory,
  deleteStoryBlock,
  duplicateStoryBlock,
  reorderChapters,
  reorderStories,
  updateStoryBlock,
  updateStoryBlockWithNewStory,
} from "../api/storyBlockAPI";

const initialState = {
  storyBlocks: { status: "idle", list: [] },
  createStoryStatus: "idle",
  createStatus: "idle",
  deleteStatus: "idle",
  updateChapterStatus: "idle",
  deleteChapterStatus: "idle",
  duplicateStatus: "idle",
  reorderStatus: "idle",
  reorderStoriesStatus: "idle",
  updateStatus: "idle",
  updateStoryStatus: "idle",
  updateStoryBlockWithNewStoryStatus: "idle",
};

// Thunks
export const getStoryBlocks = createAsyncThunk(
  "storyBlocks/fetchStoryBlocks",
  async () => {
    const data = await fetchStoryBlocks();
    return data?.response?.status === 400 ? [] : data;
  }
);

export const createStoryRequest = createAsyncThunk(
  "storyBlocks/createStory",
  async (storyData) => {
    const data = await createStory(storyData);
    return data?.response?.status === 400 ? [] : data;
  }
);

export const createChapterRequest = createAsyncThunk(
  "storyBlocks/createChapter",
  async (chapterData) => {
    const data = await createChapter(chapterData);
    return data?.response?.status === 400 ? [] : data;
  }
);

export const updateChapterRequest = createAsyncThunk(
  "storyBlocks/updateChapter",
  async (chapterData) => {
    const data = await updateChapter(chapterData);
    return data?.response?.status === 400 ? [] : data;
  }
);

export const updateStoryRequest = createAsyncThunk(
  "storyBlocks/updateStory",
  async (storyData) => {
    const data = await updateStory(storyData);
    return data?.response?.status === 400 ? [] : data;
  }
);

export const createStoryBlockRequest = createAsyncThunk(
  "storyBlocks/createStoryBlock",
  async (storyBlockData) => {
    const data = await createStoryBlock(storyBlockData);
    return data?.response?.status === 400 ? [] : data;
  }
);

export const deleteChapterRequest = createAsyncThunk(
  "storyBlocks/deleteChapter",
  async (id) => {
    const data = await deleteChapter(id);
    return data?.response?.status === 400 ? [] : data;
  }
);

export const deleteStoryRequest = createAsyncThunk(
  "storyBlocks/deleteStory",
  async (id) => {
    const data = await deleteStory(id);
    return data?.response?.status === 400 ? [] : data;
  }
);

export const deleteStoryBlockRequest = createAsyncThunk(
  "storyBlocks/deleteStoryBlock",
  async (id) => {
    const data = await deleteStoryBlock(id);
    return data?.response?.status === 400 ? [] : data;
  }
);

export const duplicateStoryBlockRequest = createAsyncThunk(
  "storyBlocks/duplicateStoryBlock",
  async (storyBlockData) => {
    const data = await duplicateStoryBlock(storyBlockData);
    return data?.response?.status === 400 ? [] : data;
  }
);

export const reorderChaptersRequest = createAsyncThunk(
  "storyBlocks/reorderChapters",
  async (storyBlockData) => {
    const data = await reorderChapters(storyBlockData);
    return data?.response?.status === 400 ? [] : data;
  }
);

export const reorderStoriesRequest = createAsyncThunk(
  "storyBlocks/reorderStories",
  async (storyBlockData) => {
    const data = await reorderStories(storyBlockData);
    return data?.response?.status === 400 ? [] : data;
  }
);

export const updateStoryBlockRequest = createAsyncThunk(
  "storyBlocks/updateStoryBlock",
  async (storyBlockData) => {
    const data = await updateStoryBlock(storyBlockData);
    return data?.response?.status === 400 ? [] : data;
  }
);

export const updateStoryBlockWithNewStoryRequest = createAsyncThunk(
  "storyBlocks/updateStoryBlockWithNewStory",
  async (storyBlockData) => {
    const data = await updateStoryBlockWithNewStory(storyBlockData);
    return data?.response?.status === 400 ? [] : data;
  }
);

// Slice
export const storyBlockSlice = createSlice({
  name: "storyBlocks",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getStoryBlocks.pending, (state) => {
        state.storyBlocks.status = "loading";
      })
      .addCase(getStoryBlocks.fulfilled, (state, action) => {
        state.storyBlocks.status = "idle";
        state.storyBlocks.list = action.payload.collections;
      })
      .addCase(createStoryRequest.pending, (state) => {
        state.createStoryStatus = "loading";
      })
      .addCase(createStoryRequest.fulfilled, (state) => {
        state.createStoryStatus = "idle";
      })
      .addCase(updateStoryRequest.pending, (state) => {
        state.updateStoryStatus = "loading";
      })
      .addCase(updateStoryRequest.fulfilled, (state) => {
        state.updateStoryStatus = "idle";
      })
      .addCase(createStoryBlockRequest.pending, (state) => {
        state.createStatus = "loading";
      })
      .addCase(createStoryBlockRequest.fulfilled, (state, action) => {
        state.storyBlocks.list.push(action.payload);
        state.createStatus = "idle";
      })
      .addCase(updateChapterRequest.pending, (state) => {
        state.updateChapterStatus = "loading";
      })
      .addCase(updateChapterRequest.fulfilled, (state) => {
        state.updateChapterStatus = "idle";
      })
      .addCase(deleteChapterRequest.pending, (state) => {
        state.deleteChapterStatus = "loading";
      })
      .addCase(deleteChapterRequest.fulfilled, (state) => {
        state.deleteChapterStatus = "idle";
      })
      .addCase(deleteStoryBlockRequest.pending, (state) => {
        state.deleteStatus = "loading";
      })
      .addCase(deleteStoryBlockRequest.fulfilled, (state, action) => {
        state.storyBlocks.list = state.storyBlocks.list.filter(
          (s) => s.id !== action.payload.id
        );
        state.deleteStatus = "idle";
      })
      .addCase(duplicateStoryBlockRequest.pending, (state) => {
        state.duplicateStatus = "loading";
      })
      .addCase(duplicateStoryBlockRequest.fulfilled, (state, action) => {
        state.storyBlocks.list.push(action.payload);
        state.duplicateStatus = "idle";
      })
      .addCase(reorderChaptersRequest.pending, (state) => {
        state.reorderStatus = "loading";
      })
      .addCase(reorderChaptersRequest.fulfilled, (state, action) => {
        let blockIndex = "";
        state.storyBlocks.list.forEach((block, index) => {
          block.stories.forEach((story) => {
            if (story.id === action.payload.id) {
              blockIndex = index;
            }
          });
        });

        const chapterIndex = state.storyBlocks.list[
          blockIndex
        ].stories.findIndex((s) => s.id === action.payload.id);

        state.storyBlocks.list[blockIndex].stories[chapterIndex] = {
          ...action.payload,
          thumbnail_url: action.payload.asset.ImageOriginalURL,
        };
        state.reorderStatus = "idle";
      })
      .addCase(reorderStoriesRequest.pending, (state) => {
        state.reorderStoriesStatus = "loading";
      })
      .addCase(reorderStoriesRequest.fulfilled, (state, action) => {
        const index = state.storyBlocks.list.findIndex(
          (s) => s.id === action.payload.id
        );
        if (index >= 0) {
          state.storyBlocks.list[index] = action.payload;
        }
        state.reorderStoriesStatus = "idle";
      })
      .addCase(updateStoryBlockRequest.pending, (state) => {
        state.updateStatus = "loading";
      })
      .addCase(updateStoryBlockRequest.fulfilled, (state, action) => {
        const index = state.storyBlocks.list.findIndex(
          (s) => s.id === action.payload.id
        );
        if (index >= 0) {
          state.storyBlocks.list[index] = action.payload;
        }
        state.updateStatus = "idle";
      })
      .addCase(updateStoryBlockWithNewStoryRequest.pending, (state) => {
        state.updateStoryBlockWithNewStoryStatus = "loading";
      })
      .addCase(updateStoryBlockWithNewStoryRequest.fulfilled, (state) => {
        state.updateStoryBlockWithNewStoryStatus = "idle";
      });
  },
});

// Selectors
export const selectStoryBlocks = (state) => state.storyBlocks.storyBlocks;
export const selectStoryBlockById = (id) =>
  createSelector(selectStoryBlocks, (state) =>
    state?.list?.find((block) => block.id === id)
  );
export const selectStoryCreateStatus = (state) =>
  state.storyBlocks.createStoryStatus;
export const selectStoryBlockCreateStatus = (state) =>
  state.storyBlocks.createStatus;
export const selectStoryBlockDeleteStatus = (state) =>
  state.storyBlocks.deleteStatus;
export const selectChapterDeleteStatus = (state) =>
  state.storyBlocks.deleteChapterStatus;
export const selectChapterUpdateStatus = (state) =>
  state.storyBlocks.updateChapterStatus;
export const selectStoryBlockDuplicateStatus = (state) =>
  state.storyBlocks.duplicateStatus;
export const selectStoryBlockReorderStatus = (state) =>
  state.storyBlocks.reorderStatus;
export const selectStoryBlockUpdateStatus = (state) =>
  state.storyBlocks.updateStatus;
export const selectStoryUpdateStatus = (state) =>
  state.storyBlocks.updateStoryStatus;
export const selectStoryBlockWithNewStoryRequestUpdateStatus = (state) =>
  state.storyBlocks.updateStoryBlockWithNewStoryStatus;

export default storyBlockSlice.reducer;
