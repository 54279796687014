import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

import adminReducer from "../redux/slices/adminSlice";
import assetReducer from "../redux/slices/assetSlice";
import authReducer from "../redux/slices/authSlice";
import ctaReducer from "../redux/slices/ctaSlice";
import pageTargetingReducer from "../redux/slices/pageTargetingSlice";
import settingsReducer from "../redux/slices/settingsSlice";
import shopifyDataReducer from "../redux/slices/shopifyDataSlice";
import storyBlockReducer from "../redux/slices/storyBlockSlice";
import viewReducer from "../redux/slices/viewSlice";

import { listenerMiddleware } from "./listenerMiddleware";
import { persistConfig } from "./persistConfig";

const reducers = combineReducers({
  admin: adminReducer,
  assets: assetReducer,
  auth: authReducer,
  ctas: ctaReducer,
  pageTargeting: pageTargetingReducer,
  settings: settingsReducer,
  storyBlocks: storyBlockReducer,
  shopifyData: shopifyDataReducer,
  view: viewReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).prepend(listenerMiddleware.middleware),
});

export const persistor = persistStore(store);
