import { useQuery } from "react-query";

import { API_QUERY_KEYS } from "../constants/textMappings";
import queryClient from "../constants/queryClient";
import request from "../utilities/request";
import { shopifyDataURLs } from "../constants/apiURLs";
import { find } from "lodash";

export const queryKey = API_QUERY_KEYS.SHOPIFY_PRODUCTS_REQUEST;

export const useShopifyProducts = (deep = false) => {
  const { data, ...meta } = useQuery(queryKey, () => {
    return request().get(shopifyDataURLs.shopifyProductList(deep));
  });

  // Check if "isError" is present & true in the data response
  const isError = data?.isError ?? false;
  // If error, return empty array & error data as error
  if (isError) {
    return {
      ...meta,
      shopifyProducts: [],
      isError: true,
      error: data,
    };
  }

  return {
    shopifyProducts: data ?? [],
    ...meta,
  };
};

export const useShopifyProduct = (productId) => {
  const { shopifyProducts, ...meta } = useShopifyProducts();
  const shopifyProduct = find(shopifyProducts, { id: productId });

  return {
    shopifyProduct: shopifyProduct ?? {},
    ...meta,
  };
};

export const invalidateShopifyProductsDataCache = () => {
  queryClient.invalidateQueries(queryKey);
};
