import { useQuery } from "react-query";

import queryClient from "../constants/queryClient";
import { assetURLs } from "../constants/apiURLs";
import { API_QUERY_KEYS } from "../constants/textMappings";
import request from "../utilities/request";

export const useAssetTags = () => {
  const { data, ...meta } = useQuery(API_QUERY_KEYS.ASSET_TAG_REQUEST, () => {
    return request().get(assetURLs.tags());
  });

  return {
    tags: data?.tags ?? [],
    ...meta,
  };
};

export const invalidateAssetTagCache = () => {
  queryClient.invalidateQueries(API_QUERY_KEYS.ASSET_TAG_REQUEST);
};