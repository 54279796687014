import React, { useState } from "react";
import { useSnackbar } from "notistack";
import {
  Box,
  Button,
  Grid,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";

import RightCloseIcon from "../../modules/assets/RightCloseIcon";
import { updateStoryBlock } from "../../redux/api/storyBlockAPI";
import { collectionHasChanged } from "../../api/fetchCollectionData";

const style = {
  topMargin: {
    marginTop: 1,
  },
  box: {
    bgcolor: "background.paper",
    boxShadow: 24,
    display: "flex",
    flexDirection: "column",
    height: 300,
    justifyContent: "space-between",
    left: "50%",
    p: 3,
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
  },
  colorPreview: {
    backgroundColor: "red",
    borderRadius: "5px",
    height: "56px",
    marginLeft: "10px",
    width: "56px",
  },
};

export default function StyleSettingsModal({storyBlock, open, onClose}) {
  const { enqueueSnackbar } = useSnackbar();
  const [updating, setUpdating] = useState(false);

  const [primaryColor, setPrimaryColor] = useState(
    storyBlock?.style?.primary_color
  );

  const onSave = async () => {
    if (updating) return;

    setUpdating(true);

    try {
      await updateStoryBlock({
        ...storyBlock,
        style: { ...storyBlock.style, primary_color: primaryColor },
      });
      collectionHasChanged(storyBlock.id);
      enqueueSnackbar("Story Block settings updated successfully!", {
        variant: "success",
      });
      onClose();
    } catch(error) {
      console.error('Error saving style settings', error);
      enqueueSnackbar("Story Block settings not able to be Updated", {
        variant: "error",
      });
    }

    setUpdating(false);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style.box}>
        <Typography id="modal-title" variant="h5" component="h2">
          Style
          <RightCloseIcon onClick={onClose} />
        </Typography>
        <Grid container style={style.topMargin}>
          <Grid item xs={12}>
            <Stack direction="row">
              <TextField
                label="Primary Color"
                helperText="Input as a hexadecimal color value, e.g. #000000"
                value={primaryColor}
                onChange={(e) => setPrimaryColor(e.target.value)}
                fullWidth
              />
              <div
                style={{
                  ...style.colorPreview,
                  backgroundColor: primaryColor ? primaryColor : "#000",
                }}
              ></div>
            </Stack>
          </Grid>
          <Typography sx={{ mt: 1 }}>
            This will be the color of CTA buttons and the story thumbnail
            border.
          </Typography>
        </Grid>
        <Stack direction="row" justifyContent="end" sx={style.topMargin}>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            loading={updating === "loading"}
            loadingPosition="start"
            variant="contained"
            startIcon={<SaveIcon />}
            onClick={onSave}
            style={{ marginLeft: "10px" }}
          >
            Save
          </LoadingButton>
        </Stack>
      </Box>
    </Modal>
  );
}
