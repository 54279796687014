import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  navOpen: true,
  buildStoryBlockModalOpen: false,
  editStoryBlock: false,
  deleteChapterModalOpen: false,
  deleteStoryModalOpen: false,
  editChapterModalOpen: false,
  editChapter: false,
  selectedChapter: {},
  mediaModalOpen: false,
  mediaModalFilter: {},
  selectedMedia: {},
  publishModalOpen: false,
  templatesModalOpen: false,
  status: "idle",
};

// Slice
export const viewSlice = createSlice({
  name: "view",
  initialState,
  reducers: {
    toggleBuildStoryBlockModal: (state, action) => {
      state.editStoryBlock = action.payload;
      state.buildStoryBlockModalOpen = !state.buildStoryBlockModalOpen;
    },
    toggleDeleteChapterModal: (state) => {
      state.deleteChapterModalOpen = !state.deleteChapterModalOpen;
    },
    toggleDeleteStoryModal: (state) => {
      state.deleteStoryModalOpen = !state.deleteStoryModalOpen;
    },
    toggleEditChapterModal: (state, action) => {
      state.editChapter = action.payload.mode;
      state.selectedChapter = action.payload.chapter;
      state.editChapterModalOpen = !state.editChapterModalOpen;
    },
    toggleMediaModal: (state, action) => {
      state.mediaModalFilter = action.payload;
      state.mediaModalOpen = !state.mediaModalOpen;
    },
    setSelectedMedia: (state, action) => {
      state.selectedMedia = action.payload;
    },
    togglePublishModal: (state) => {
      state.publishModalOpen = !state.publishModalOpen;
    },
    toggleTemplatesModal: (state) => {
      state.templatesModalOpen = !state.templatesModalOpen;
    },
  },
});

export const {
  toggleBuildStoryBlockModal,
  toggleDeleteChapterModal,
  toggleDeleteStoryModal,
  toggleEditChapterModal,
  toggleMediaModal,
  setSelectedMedia,
  togglePublishModal,
  toggleTemplatesModal,
} = viewSlice.actions;

// Selectors
export const selectBuildStoryBlockModalOpen = (state) =>
  state.view.buildStoryBlockModalOpen;
export const selectBuildStoryBlockEdit = (state) => state.view.editStoryBlock;
export const selectDeleteChapterModalOpen = (state) =>
  state.view.deleteChapterModalOpen;
export const selectDeleteStoryModalOpen = (state) =>
  state.view.deleteStoryModalOpen;
export const selectEditChapterModalOpen = (state) =>
  state.view.editChapterModalOpen;
export const selectChapterEdit = (state) => state.view.editChapter;
export const selectSelectedChapter = (state) => state.view.selectedChapter;
export const selectMediaModalOpen = (state) => state.view.mediaModalOpen;
export const selectMediaModalFilter = (state) => state.view.mediaModalFilter;
export const selectSelectedMedia = (state) => state.view.selectedMedia;
export const selectPublishModalOpen = (state) => state.view.publishModalOpen;
export const selectTemplatesModalOpen = (state) =>
  state.view.templatesModalOpen;

export default viewSlice.reducer;
