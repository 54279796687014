/* One function to determine what environment we are running in. The return options
are:
    - "production"
    - "development"
    - "pull_request"
    - "localhost"
    - "unknown"
*/

import preval from "preval.macro";
import env from "react-dotenv";

export const getEnvironment = () => {
  // Check if URL contains localhost or 127.0.0.1
  if (
    window.location.hostname.includes("localhost") ||
    window.location.hostname.includes("127.0.0.1") ||
    window.location.hostname.includes("m1t.local")
  ) {
    return "localhost";
  }

  // Check if URL contains ngrok
  if (window.location.hostname.includes("ngrok")) {
    return "development";
  }

  // @todo this is only required because env.js doesn't load right if you come in through a non-root URL
  if (typeof env !== "object") {
    return "production";
  }

  // Check if we are in a pull request
  if (
    "PULL_REQUEST" in env &&
    env?.PULL_REQUEST &&
    env?.PULL_REQUEST !== "false"
  ) {
    return "pull_request";
  }

  // Check env to see what branch we came from
  if (
    env.BRANCH === "main" ||
    env.BRANCH === "shopify-main" ||
    env.BRANCH === "shop.prd"
  ) {
    return "production";
  } else if (env.BRANCH === "develop") {
    return "development";
  } else {
    return "unknown";
  }
};

// Function to return the time since the build was made
// ex: 3 days, 15 hours, and 45 minutes
export const getTimeSinceBuild = () => {
  const buildDateStamp = preval`module.exports = new Date().toISOString();`;
  const buildDate = new Date(buildDateStamp);
  const now = new Date();

  // Calculate D, H, and M
  const D = Math.floor(
    (now.getTime() - buildDate.getTime()) / (1000 * 60 * 60 * 24)
  );
  const H = Math.floor(
    (now.getTime() - buildDate.getTime()) / (1000 * 60 * 60)
  );
  const M = Math.floor((now.getTime() - buildDate.getTime()) / (1000 * 60));

  // Return the time since the build was made
  if (D === 1) {
    return `${D} day ago`;
  } else if (D > 0) {
    return `${D} days ago`;
  }

  if (H === 1) {
    return `${H} hour ago`;
  } else if (H > 0) {
    return `${H} hours ago`;
  }

  if (M === 1) {
    return `${M} minute ago`;
  } else if (M > 0) {
    return `${M} minutes ago`;
  }

  return "just now";
};
