import React from "react";

import Page from "../components/Page";
import AccountOverrideAlert from "../components/AccountOverrideAlert";
import AccountList from "../modules/admin/AccountList";

// API Calls
import { useAccounts } from "../api/fetchAccountsData";

// State Management
import { useAuth } from "../context/auth";

// Styling
import {
  Alert,
  AlertTitle,
  Divider,
  Grid,
  Link,
  Typography,
} from "@mui/material";

export default function Admin() {
  const auth = useAuth();
  const { error, isLoading, accounts } = useAccounts();

  return (
    <Page title="Accounts" isLoading={isLoading}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Page.Title>Account Selector</Page.Title>
          <AccountList accounts={accounts} error={error} />
        </Grid>
        <Grid item xs={12} md={4} sx={{ mt: "48px" }}>
          <Alert severity="info">
            <AlertTitle>You are logged in as:</AlertTitle>
            <Typography variant="body1">{auth.user.name}</Typography>
          </Alert>
          <Divider style={{ margin: 30 }} />
          <AccountOverrideAlert />
          <Divider style={{ margin: 30 }} />
          <Alert severity="info">
            <AlertTitle>Tools</AlertTitle>
            <Link href="/admin/salesDemos">Sales Demo Tool</Link>
          </Alert>
        </Grid>
      </Grid>
    </Page>
  );
}
