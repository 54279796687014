import React from "react";

import { useAccount } from "../../api/fetchAccountData";

import useAccountDetails from "../../models/accountDetails";
import ErrorIcon from "@mui/icons-material/Error";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Grow,
  Chip,
  Stack,
  Paper,
  Alert,
  AlertTitle,
} from "@mui/material";
import KlaviyoAPIKey from "./KlaviyoAPIKey";
import RightCloseIcon from "../assets/RightCloseIcon";
import SaveIcon from "@mui/icons-material/Save";
import { accountURLs } from "../../constants/apiURLs";
import axios from "axios";
import { useSnackbar } from "notistack";
import { ERROR_MESSAGE_MAPPING } from "../../constants/textMappings";
import PasswordField from "../../components/PasswordField";
import { hasOverrideAccountID, useAuth } from "../../context/auth";
import BillingPlanEditor from "./BillingPlan";
import GA4OptInSwitch from "./GA4OptInSwitch";
import { Masonry } from "@mui/lab";
import Instagram from "./Instagram";
import { SlideUpFromBottom } from "../../utilities/transitions";
import { AccountLogo } from "./ShopLogo";

const AccountEditor = ({ onDone }) => {
  const auth = useAuth();

  const { enqueueSnackbar } = useSnackbar();
  const defaultPasswordState = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const [password, setPassword] = React.useState(defaultPasswordState);
  const [error, setError] = React.useState("");

  const clearErrorState = () => {
    if (error) setError("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (password.newPassword !== password.confirmPassword) {
      setError("New password must match confirm password value");
      return;
    }
    const notAdmin = !auth.isAdmin;

    axios
      .put(accountURLs.changePassword(), {
        new_password: password.newPassword,
        ...(notAdmin && { current_password: password.currentPassword }),
      })
      .then((res) => {
        // Notify success
        enqueueSnackbar("Password updated successfully!", {
          variant: "success",
        });
        setPassword(defaultPasswordState);
      })
      .catch((error) => {
        const errorMessage =
          ERROR_MESSAGE_MAPPING[error.response.data.error_code] ||
          error.response.data.hint;
        setError(errorMessage);
      });
  };
  return (
    <Masonry columns={{ xs: 1, lg: 2 }} spacing={2}>
      <Paper elevation={1} sx={{ p: 2 }}>
        <Typography variant="h6" gutterBottom>
          Google Analytics
        </Typography>
        <GA4OptInSwitch />
      </Paper>

      <Paper elevation={1} sx={{ p: 2 }}>
        <BillingPlanEditor />
      </Paper>

      {/* Only show the password form if not on shopify */}
      {!auth.isShopifyAccount && (
        <Paper elevation={1} sx={{ p: 2 }}>
          <form onSubmit={handleSubmit}>
            <Stack>
              <Typography variant="h6" color="inherit" id="password-set-header">
                Change password
              </Typography>
              {!auth.isAdmin && (
                <PasswordField
                  onFocus={clearErrorState}
                  label="Current password"
                  value={password.currentPassword}
                  onChange={(e) =>
                    setPassword({
                      ...password,
                      currentPassword: e.target.value,
                    })
                  }
                  required={true}
                  fullWidth
                />
              )}
              <PasswordField
                onFocus={clearErrorState}
                label="New password"
                value={password.newPassword}
                onChange={(e) =>
                  setPassword({ ...password, newPassword: e.target.value })
                }
                required={true}
                fullWidth
              />

              <PasswordField
                onFocus={clearErrorState}
                label="Confirm New password"
                value={password.confirmPassword}
                onChange={(e) =>
                  setPassword({
                    ...password,
                    confirmPassword: e.target.value,
                  })
                }
                required={true}
                fullWidth
              />

              <Button
                variant="contained"
                type="submit"
                startIcon={<SaveIcon />}
              >
                Update password
              </Button>
              {error && (
                <Grow
                  in={error?.length > 0}
                  style={{
                    marginTop: 10,
                  }}
                >
                  <Chip
                    label={error}
                    color={"warning"}
                    variant="outlined"
                    icon={<ErrorIcon />}
                  />
                </Grow>
              )}
            </Stack>
          </form>
        </Paper>
      )}

      {/* Logo editor */}
      <Paper elevation={1} sx={{ p: 2 }}>
        <Typography variant="h6" id="shop-logo-header" gutterBottom>
          Shop Logo
        </Typography>
        <AccountLogo.Form />
      </Paper>

      {/* Only show IG to admins for right now */}
      {hasOverrideAccountID() && (
        <Paper elevation={1} sx={{ p: 2 }}>
          <Alert
            severity="warning"
            sx={{
              marginBottom: 2,
            }}
          >
            <AlertTitle>Pre-Release</AlertTitle>
            This feature is still in test. It is only visible to admins.
          </Alert>
          <Typography variant="h6" id="klaviyo-settings-header" gutterBottom>
            Instagram
          </Typography>
          <Instagram />
        </Paper>
      )}

      <Paper elevation={1} sx={{ p: 2 }}>
        <Typography variant="h6" id="klaviyo-settings-header" gutterBottom>
          Klaviyo Key Settings
        </Typography>
        <KlaviyoAPIKey
          onSuccess={() => {
            console.info("Klaviyo API key saved");
          }}
        />
      </Paper>
      {/* 
      <Paper elevation={1} sx={{ p: 2 }}>
        <Typography variant="h6" gutterBottom>
          Theme
        </Typography>
        <SwitchModeButton />
      </Paper> */}
    </Masonry>
  );
};

//   Modal wrapper for the asset editor
const AccountEditorModal = ({ show, onClose }) => {
  const { account } = useAccount();
  const { name } = useAccountDetails(account);
  return (
    <Dialog
      TransitionComponent={SlideUpFromBottom}
      open={show}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth={"md"}
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title">
        Account Settings - {name}
        <RightCloseIcon onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6">
          🚧🚧🚧 Still under construction. Don't test. 🚧🚧🚧
        </Typography>
        <AccountEditor onDone={onClose} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AccountEditor.Modal = AccountEditorModal;

export default AccountEditor;
