import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import RightCloseIcon from "../../modules/assets/RightCloseIcon";
import { deleteStoryBlock } from "../../redux/api/storyBlockAPI";
import { invalidateCollectionCache } from "../../api/fetchCollectionData";

const style = {
  topMargin: {
    marginTop: 1,
  },
  box: {
    bgcolor: "background.paper",
    boxShadow: 24,
    display: "flex",
    flexDirection: "column",
    height: 275,
    justifyContent: "space-between",
    left: "50%",
    p: 3,
    top: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    width: 600,
  },
};

export default function DeleteStoryBlockModal({ storyBlock, open, onClose }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [deleting, setDeleting] = useState(false);

  const onDeleteClick = async () => {
    if (deleting) return;

    setDeleting(true);

    try {
      await deleteStoryBlock(storyBlock.id);
      invalidateCollectionCache();
      onClose();
      enqueueSnackbar("Story Block deleted successfully!", {variant: "success"});
      navigate("/story_blocks");
    } catch(error) {
      enqueueSnackbar("Story Block not able to be deleted", {variant: "error"});
    }

    setDeleting(false);
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style.box}>
        <Typography variant="h5">
          Delete Story Block
          <RightCloseIcon onClick={onClose} />
        </Typography>
        <Alert severity="error">
          <AlertTitle>
            <strong>
              Are you sure you want to delete the {storyBlock?.internal_name}{" "}
              Story Block
            </strong>
          </AlertTitle>
          This cannot be undone.
        </Alert>
        <Stack direction="row" justifyContent="end" sx={style.topMargin}>
          <Button variant="outlined" color="error" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            loading={deleting}
            color="error"
            loadingPosition="start"
            variant="contained"
            startIcon={<DeleteIcon />}
            onClick={onDeleteClick}
            style={{ marginLeft: "10px" }}
          >
            Delete
          </LoadingButton>
        </Stack>
      </Box>
    </Modal>
  );
}
