import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import {
  Box,
  Button,
  ButtonGroup,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import RightCloseIcon from "../../../modules/assets/RightCloseIcon";
import ImageIcon from "@mui/icons-material/Image";
import LinkIcon from "@mui/icons-material/Link";

import {
  toggleMediaModal,
  selectSelectedMedia,
  setSelectedMedia,
} from "../../../redux/slices/viewSlice";
import { updateStory } from "../../../redux/api/storyBlockAPI";
import { invalidateCollectionCache } from "../../../api/fetchCollectionData";

const style = {
  topMargin: {
    marginTop: 1,
  },
  box: {
    bgcolor: "background.paper",
    boxShadow: 24,
    display: "flex",
    flexDirection: "column",
    height: 525,
    justifyContent: "space-between",
    left: "50%",
    p: 3,
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
  },
};

export function EditStoryModal({ selectedStory, open, onClose }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const selectedMedia = useSelector(selectSelectedMedia);

  const [type, setType] = useState("multimedia");
  const [storyTitle, setStoryTitle] = useState(selectedStory.internal_name);
  const [navigationLink, setNavigationLink] = useState(selectedStory.link_url);

  useEffect(() => {
    setStoryTitle(selectedStory?.internal_name);
    if (selectedStory?.link_url !== "") {
      setType("link");
      setNavigationLink(selectedStory.link_url);
    }
  }, [selectedStory?.internal_name, selectedStory?.link_url]);

  const handleClose = () => {
    setType("multimedia");
    setStoryTitle("");
    setNavigationLink("");
    dispatch(setSelectedMedia({}));
    onClose();
  };

  const isDisabled = () => {
    const url =
        selectedStory?.thumbnail_url ??
        selectedStory?.asset?.ImageSquareCropURL ??
        selectedStory?.asset?.ImageOriginalURL;

    if (type === "multimedia") {
      if (!url || storyTitle === "") {
        return true;
      }
    } else if (type === "link") {
      if (!url || storyTitle === "" || navigationLink === "") {
        return true;
      }
    }
    return false;
  };

  const onSave = async () => {
    let data = {
      thumbnail_text: storyTitle,
      internal_name: storyTitle,
      internal_description: "",
      alt_text: storyTitle,
      asset_id: selectedMedia.id ?? selectedStory?.asset?.id,
      type: type,
      link_url: navigationLink ? navigationLink : "",
    };

    data.id = selectedStory.id;
    
    try {
      await updateStory(data);
      invalidateCollectionCache();
      enqueueSnackbar("Story updated successfully", {variant: "success"});
    } catch(error) {
      enqueueSnackbar("Not Able to Update Story", {variant: "error"});
    }

    handleClose();
  }

  const renderThumbnail = () => {
    if (selectedMedia?.id) {
      return (
        <Button
          onClick={() => dispatch(toggleMediaModal({ type: "images" }))}
          sx={{ "&:hover": { backgroundColor: "transparent" } }}
        >
          <img
            src={selectedMedia.image_square_crop_url}
            alt="story thumbnail"
            style={{
              maxHeight: "72px",
              maxWidth: "72px",
              borderRadius: "50%",
              marginBottom: "20px",
            }}
          />
        </Button>
      );
    }

    const url =
      selectedStory?.thumbnail_url ??
      selectedStory?.asset?.ImageSquareCropURL ??
      selectedStory?.asset?.ImageOriginalURL;

    return (
      <Button
        onClick={() => dispatch(toggleMediaModal({ type: "images" }))}
        sx={{ "&:hover": { backgroundColor: "transparent" } }}
      >
        <img
          src={url}
          alt="story thumbnail"
          style={{
            maxHeight: "72px",
            maxWidth: "72px",
            borderRadius: "50%",
            marginBottom: "20px",
          }}
        />
      </Button>
    );
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style.box}>
        <Typography variant="h5">
          Edit Story
          <RightCloseIcon onClick={handleClose} />
        </Typography>
        <ButtonGroup variant="outlined" fullWidth>
          <Button
            variant={type === "multimedia" ? "contained" : "outlined"}
            startIcon={<ImageIcon />}
            onClick={() => setType("multimedia")}
          >
            Multimedia
          </Button>
          <Button
            variant={type === "link" ? "contained" : "outlined"}
            startIcon={<LinkIcon />}
            onClick={() => setType("link")}
          >
            Navigation
          </Button>
        </ButtonGroup>
        <Typography variant="body2">
          Tapping the thumbnail will open your story full-screen. You'll add the
          story's chapters in the next step.
        </Typography>
        <Stack alignItems="center">
          {renderThumbnail()}
          <TextField
            label="Story Title"
            helperText="Keep this to 1-2 words (about 15 characters)"
            value={storyTitle}
            onChange={(e) => setStoryTitle(e.target.value)}
          />
          {type === "link" && (
            <TextField
              label="Link"
              helperText="Enter the URL of the page you want to link to"
              value={navigationLink}
              onChange={(e) => setNavigationLink(e.target.value)}
              sx={{ mt: 1 }}
            />
          )}
        </Stack>
        <Stack direction="row" justifyContent="end" sx={style.topMargin}>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ ml: 1 }}
            disabled={isDisabled()}
            onClick={onSave}
          >
            Update
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}
