import React from "react";
import { useSnackbar } from "notistack";
import flatMap from "lodash/flatMap";
import CreatableSelect from "react-select/creatable";

import {
  useAssetTags,
  invalidateAssetTagCache,
} from "../../api/fetchAssetTagData";
import { invalidateAssetCache } from "../../api/fetchAssetData";

import { apiCall } from "../../utilities/request";
import { assetURLs } from "../../constants/apiURLs";

import {
  AlertTitle,
  Alert,
  Grow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
  TextField,
  Stack,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import RightCloseIcon from "./RightCloseIcon";
import { AssetDownloader } from "./AssetDownload";
import { AssetFormMedia } from "./AssetFormMedia";
import { isVideo } from "../../utilities/assets";
import { SlideUpFromBottom } from "../../utilities/transitions";

// A component defining a modal to edit the metadata of an asset
const AssetEditor = ({ asset, onDone }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [error, _setError] = React.useState(null);
  const errorRef = React.useRef(null);
  const setError = (err) => {
    _setError(err);

    // Only do the rest if err not null
    if (err) {
      enqueueSnackbar("Error saving story", { variant: "error" });

      // Scroll to error alert message
      errorRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const usedTags = useAssetTags(); // Get currently used asset tags
  // When usedTags is set, set the tags to the options
  const tagOptions = usedTags
    ? usedTags.tags.map((tag) => ({
        value: tag,
        label: tag,
      }))
    : [];

  // Convert the simple string array to the format required by react-select
  const selectedTags = flatMap(asset.tags, (tag) => [
    {
      value: tag,
      label: tag,
    },
  ]);

  const [internal_name, setInternalName] = React.useState(asset.internal_name);
  const [tags, setTags] = React.useState(selectedTags);

  // Handle asset delete
  const deleteAsset = () => {
    // Send the request
    apiCall
      .delete(assetURLs.asset(asset.id))
      .then((res) => {
        console.info("Delete OK");

        // Invalidate assets data
        invalidateAssetTagCache();
        invalidateAssetCache();

        // Notify success
        enqueueSnackbar("Deleted Asset", {
          variant: "success",
        });

        // All done!
        if (onDone) {
          onDone();
        }
      })
      .catch((error) => {
        console.error("Error deleting asset", error.response);
        setError(error.response.data.error);
      });
  };

  // Function to upload metadata changes
  const saveMetadata = (e) => {
    e.preventDefault();

    setError(null);

    // Convert tags array to the simnple string array
    let outTags = [];
    tags.forEach((tag) => {
      outTags.push(tag.value);
    });

    // Send the request
    console.info("Attempting metadata update");
    apiCall
      .put(assetURLs.asset(asset.id), {
        internal_name: internal_name,
        internal_description: asset.internal_description,
        tags: outTags,
      })
      .then((res) => {
        console.info("Saved metadata");
        console.info(res);

        console.info(JSON.stringify(res));

        // Invalidate assets data
        invalidateAssetTagCache();
        invalidateAssetCache();

        // Notify success
        enqueueSnackbar("Saved Asset", {
          variant: "success",
        });

        // All done!
        if (onDone) {
          onDone();
        }
      })
      .catch((error) => {
        console.error("Error saving metadata");
        console.error(error.response);
        setError(error.response.data.error);
      });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={isVideo(asset) ? 4 : 12}>
        <AssetFormMedia asset={asset} />
      </Grid>

      <Grid item xs={12} md={isVideo(asset) ? 8 : 12}>
        <form onSubmit={saveMetadata}>
          <TextField
            label="Name"
            value={internal_name}
            onChange={(e) => setInternalName(e.target.value)}
            variant="outlined"
            required={true}
            margin="normal"
            fullWidth
          />
          <Typography variant="subtitle1" style={{ marginBottom: "10px" }}>
            Tags
          </Typography>
          <CreatableSelect
            value={tags}
            defaultValue={tags}
            onChange={setTags}
            options={tagOptions}
            isMulti={true}
            isSearchable={true}
            placeholder="Add or select tags"
          />
          <Typography variant="subtitle2" style={{ marginBottom: "10px" }}>
            Specify tags to make finding this asset easier in the future
          </Typography>
          <Stack
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={2}
            sx={{
              width: "100%",
              marginTop: "1rem",
            }}
          >
            <Button variant="contained" type="submit" startIcon={<SaveIcon />}>
              Save
            </Button>
            <Button
              color="error"
              type="button"
              variant="outlined"
              startIcon={<DeleteIcon />}
              onClick={(e) => {
                e.preventDefault();

                deleteAsset();
              }}
            >
              Delete
            </Button>
          </Stack>
        </form>
        <Grow in={error ? true : false}>
          <Alert
            sx={{
              marginTop: "1rem",
            }}
            ref={errorRef}
            severity="error"
          >
            <AlertTitle>Error</AlertTitle>
            {error}
          </Alert>
        </Grow>

        {/* Don't show the downloader for cloudflare assets */}
        {asset.type !== "cloudflare_stream" && (
          <Grid item xs={12}>
            <Typography variant="h6" style={{ marginTop: "10px" }}>
              Download Original
            </Typography>
            <AssetDownloader asset={asset} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

//   Modal wrapper for the asset editor
const AssetEditorModal = ({ show, asset, onClose }) => {
  return (
    <Dialog
      TransitionComponent={SlideUpFromBottom}
      open={show}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth={"md"}
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title">
        {asset.internal_name}
        <RightCloseIcon onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <AssetEditor asset={asset} onDone={onClose} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AssetEditor.Modal = AssetEditorModal;

export default AssetEditor;
