import * as React from "react";

import Backdrop from "@mui/material/Backdrop";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import TimedProgressBar from "./TimedProgressBar";

export default function LoadingBackdrop({ loading, timer }) {
  // If timer prop is not set, use a default value
  // @todo - make this switch to an indeterminate progress bar instead
  if (timer === undefined || timer === null || timer === 0) {
    timer = 10;
  }

  // If loading is not a bool, default to false
  if (typeof loading !== "boolean") {
    loading = false;
  }

  // Need to have the progress bar be gated by props.loading so the timer resets each time the module is re "opened"

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={loading}
    >
      <Grid container spacing={10}>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          {loading ? <TimedProgressBar duration={timer} type="linear" /> : null}
          <Typography variant="h6" color="inherit">
            Loading...
          </Typography>
        </Grid>
      </Grid>
    </Backdrop>
  );
}
