import React from "react";

import { useAssets } from "../api/fetchAssetData";

import PlanUpgradeBanner from "../components/PlanUpgradeBanner";
import Page from "../components/Page";
import AssetList from "../modules/assets/AssetList";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

import AssetEditor from "../modules/assets/AssetEditorForm";

const Assets = () => {
  const { error, isLoading, assets } = useAssets();

  const [selectedAsset, setSelectedAsset] = React.useState(null);
  const [metadataModalOpen, setMetadataModalOpen] = React.useState(false);

  // When the assets change, update the selected asset with the new version if appropriate
  React.useEffect(() => {
    if (selectedAsset) {
      const newAsset = assets.find((a) => a.id === selectedAsset.id);
      if (newAsset) {
        setSelectedAsset(newAsset);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assets]);

  return (
    <Page title="Media Library" isLoading={isLoading}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <PlanUpgradeBanner />
          <Page.Title>Media Library</Page.Title>

          {selectedAsset && (
            <AssetEditor.Modal
              show={metadataModalOpen}
              onClose={() => setMetadataModalOpen(false)}
              asset={selectedAsset}
            />
          )}

          {error ? (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {error}
            </Alert>
          ) : (
            <AssetList
              onSelect={(asset) => {
                setSelectedAsset(asset);
                setMetadataModalOpen(true);
              }}
              assets={assets}
              uploaderAllowMultiple={true}
              filter={{
                not_type: "thumbnails",
              }}
            />
          )}
        </Grid>
      </Grid>
    </Page>
  );
};

export default Assets;
