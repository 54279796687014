import request from "../../utilities/request";
import { accountURLs } from "../../constants/apiURLs";

export function fetchPageTargetingRulesets() {
  return new Promise((resolve) => {
    const response = request().get(accountURLs.pageTargeting());
    resolve(response);
  });
}

export function updatePageTargetingRulesets(rulesets) {
  return new Promise((resolve) => {
    const response = request().put(accountURLs.pageTargeting(), { rulesets });
    resolve(response);
  });
}
