const getShopifyURLParams = () => {
  const searchParams = new URLSearchParams(window.location.search);

  const code = searchParams.get("code");
  const hmac = searchParams.get("hmac");
  const shop = searchParams.get("shop");
  const timestamp = searchParams.get("timestamp");
  const host = searchParams.get("host");
  const nonce = searchParams.get("state");

  return {
    code,
    hmac,
    shop,
    timestamp,
    host,
    nonce,
  };
};

export { getShopifyURLParams };
