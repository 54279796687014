import React from "react";

import { useCTAs } from "../api/fetchCTAData";

import PlanUpgradeBanner from "../components/PlanUpgradeBanner";
import Page from "../components/Page";
import CTAList from "../modules/ctas/CTAList";
import CTAEditor from "../modules/ctas/CTAEditorForm";

import { Button, Stack, Grid, Alert, AlertTitle } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const CTAs = () => {
  const { error, isLoading, ctas } = useCTAs();

  const [selectedCTA, setSelectedCTA] = React.useState(null);
  const [metadataModalOpen, setMetadataModalOpen] = React.useState(false);

  return (
    <Page title="CTA Manager" isLoading={isLoading}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PlanUpgradeBanner />
          <Page.Title>
            <Stack
              direction="row"
              justifyItems="space-between"
              alignItems="center"
            >
              <>CTA Library</>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setSelectedCTA(null);
                  setMetadataModalOpen(true);
                }}
                startIcon={<AddIcon />}
                sx={{
                  marginLeft: "auto",
                }}
              >
                Create New CTA
              </Button>
            </Stack>
          </Page.Title>

          <CTAEditor.Modal
            show={metadataModalOpen}
            onClose={() => setMetadataModalOpen(false)}
            cta={selectedCTA}
          />

          {error ? (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {error}
            </Alert>
          ) : (
            <CTAList
              onSelect={(cta) => {
                setSelectedCTA(cta);
                setMetadataModalOpen(true);
              }}
              ctas={ctas}
              buttonLabel="Edit"
            />
          )}
        </Grid>
      </Grid>
    </Page>
  );
};

export default CTAs;
