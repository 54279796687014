import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accounts: [],
  status: "idle",
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
});

export default adminSlice.reducer;
